import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {FacadeService} from '../../../../../services/facade.service';
import {ShareService} from '../../../../../services/share.service';
import {Store} from '@ngrx/store';
import {BsModalService} from 'ngx-bootstrap';
import {AppState} from '../../../../../store/app.states';
import {LoadMedByIdAction} from '../../../../../store/actions/medicament.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {Medicament} from '../../../../../models/medicament';
import {MedNote} from '../../../../../models/med-note';
import {AuthService} from '../../../../../services/auth.service';
import {MedStrength} from '../../../../../models/med-strength';
import {SaveMedStrengthGQL} from '../../../../../api/graphql';
import {CustomValidate} from '../../../../../models/custom-validate';

@Component({
  selector: 'app-strength',
  templateUrl: './strength.component.html',
  styleUrls: ['./strength.component.scss']
})
export class StrengthComponent extends FormAbstract<MedStrength> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private saveMedStrengthGQL: SaveMedStrengthGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'medstrengthid'},
      {field: 'countryid'},
      {field: 'medicamentid'},
      {field: 'note'},
      {field: 'packingsizes'},
      {field: 'individual'},
      {field: 'strength', validator: [Validators.required]},
      {field: 'perdays', validator: [Validators.min(1), Validators.required, CustomValidate.numeric]},
      {field: 'morning', validator: [Validators.min(0), Validators.required, CustomValidate.numeric]},
      {field: 'midday', validator: [Validators.min(0), Validators.required, CustomValidate.numeric]},
      {field: 'evening', validator: [Validators.min(0), Validators.required, CustomValidate.numeric]},
      {field: 'night', validator: [Validators.min(0), Validators.required, CustomValidate.numeric]},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'medstrengthid',
      translationId: null
    };
    this.action = this.bsRef.config.initialState['action'];
    if (this.action === ActionStateEnum.CREATE) {
      const medicament = this.bsRef.config.initialState['data'] as Medicament;
      this.baseObject = new MedNote();
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['medicamentid']: medicament.medicamentid});
      this.formGroup.patchValue({['morning']: 0});
      this.formGroup.patchValue({['midday']: 0});
      this.formGroup.patchValue({['evening']: 0});
      this.formGroup.patchValue({['night']: 0});
    } else {
      this.baseObject = this.bsRef.config.initialState['data'];
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['medicamentid']: this.baseObject.medicamentid});
      this.formGroup.patchValue({['morning']: this.baseObject.morning ? this.baseObject.morning : 0});
      this.formGroup.patchValue({['midday']: this.baseObject.midday ? this.baseObject.midday : 0});
      this.formGroup.patchValue({['evening']: this.baseObject.evening ? this.baseObject.evening : 0});
      this.formGroup.patchValue({['night']: this.baseObject.night ? this.baseObject.night : 0});
    }
    this.updateValidators(this.baseObject.individual, true);
  }

  ngOnInit() {
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      this.baseObject.countryid = this.authService.user.country.countryid;
      const variables = {
        medStrength: this.baseObject
      };
      const _cascadeEffect = (args) => {
        this.closeModal(true);
        this.store.dispatch(new LoadMedByIdAction({id: args.data['saveMedStrength'].medicamentid}));
      };
      const _callback = () => {
        this.facadeService.mutateObject(this.saveMedStrengthGQL, variables, _cascadeEffect);
      };
      if (this.baseObject.morning === 0 && this.baseObject.midday === 0
        && this.baseObject.evening === 0 && this.baseObject.night === 0) {
        this.shareService.confirmAction('validationMessages.shouldBeStored', _callback);
      } else {
        this.facadeService.mutateObject(this.saveMedStrengthGQL, variables, _cascadeEffect);
      }
    }
  }
  public updateValidators($event, forceValue = false) {
    const validators = [Validators.min(1), Validators.required, CustomValidate.numeric];
    if ($event === true) {
      this.formGroup.setControl('perdays', new FormControl(null, [CustomValidate.numeric]));
    } else {
      if (forceValue) {
        this.formGroup.setControl('perdays', new FormControl(this.baseObject.perdays, validators));
      } else {
        this.formGroup.setControl('perdays', new FormControl(0, validators));
      }
    }
    this.formGroup.updateValueAndValidity();
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.EDIT: {
        return 'oraliadb.medicament.editStrength';
      }
      case ActionStateEnum.CREATE: {
        return 'oraliadb.medicament.newStrength';
      }
      default: {
        return 'oraliadb.medicament.editStrength';
      }
    }
  }
}
