import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppState, selectAddPreparationsReducer } from '../../../store/app.states';
import { select, Store } from '@ngrx/store';
import { ShareService } from '../../../services/share.service';
import { FormAbstract } from '../../../models/abstract/form.abstract';
import { FacadeService } from '../../../services/facade.service';
import { AddPreparation } from '../../../models/add-preparation';
import { LoadAddByIdAction } from '../../../store/actions/add-preparation.actions';
import { AuthService } from '../../../services/auth.service';
import { SUB_MENU_LIST_ORALIA_DB } from '../../../config/sub-menu';
import { ChangeSubMenuAction } from '../../../store/actions/sub-menu.actions';
import { FormGroup, Validators } from '@angular/forms';
import { ActionStateEnum } from '../../../models/enums/actions-state.enum';
import { SubstanceValidate } from '../../../models/substance';
import {
  SaveAddPreparationGQL,
  SaveAddStrengthGQL,
  UniqueAddPreparationGQL
} from '../../../api/graphql';
import { AddStrengthComponent } from './modal/add-strength/add-strength.component';
import { LinkKey } from '../../../models/enums/link-key.enum';
import { RolesService } from '../../../services/roles.service';
import { isNullOrUndefined } from 'util';
import { SideEffect } from '../../../models/side-effect';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { find } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import {BsLocaleService} from 'ngx-bootstrap';

@Component({
  selector: 'app-additional-preparations',
  templateUrl: './additional-preparations.component.html',
  styleUrls: ['./additional-preparations.component.scss']
})
export class AdditionalPreparationsComponent extends FormAbstract<AddPreparation> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public modalRef: BsModalRef;
  public sideEffectsList: Array<SideEffect>;
  public languageName: string;
  public lastChange: string;

  constructor(protected store: Store<AppState>, private rolesService: RolesService,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, protected authService: AuthService,
    protected sanitizer: DomSanitizer, private bsLocaleService: BsLocaleService,
    private saveAddPreparationGQL: SaveAddPreparationGQL, private coolDialogs: NgxCoolDialogsService,
    private saveAddStrengthGQL: SaveAddStrengthGQL,
    private uniqueAddPreparationGQL: UniqueAddPreparationGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.sideEffectsList = Array();
    this.baseFields = [
      { field: 'addpreparationid' },
      { field: 'countryid' },
      { field: 'hints' },
      { field: 'mischint' },
      { field: 'informations' },
      { field: 'label', validator: SubstanceValidate.rules.label },
      { field: 'status', defaultValue: 1 },
      { field: 'patientinfo', validator: [] },
      { field: 'lastchange' }
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    /**
     * @NGx-Bootstrap Modal
     */
    this.languageId = this.authService.userLanguage.languageid;
    this.baseId = {
      parentId: 'addpreparationid',
      translationId: 'addprelangid'
    };
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      if (this.action === ActionStateEnum.EDIT) {
        this.languageId = this.authService.user.country.languageid;
      }
    } else {
      this.store.pipe(select(selectAddPreparationsReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
          this.formGroup.patchValue({ 'status': this.baseObject.status });
          let date;
          if (!!this.baseObject.lastchange) {
            date = this.shareService.getDate(this.baseObject.lastchange);
            this.formGroup.patchValue({['lastchange']: date !== 'Invalid date' ? date : null });
          } else {
            this.formGroup.patchValue({['lastchange']: null });
          }
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
    const language = this.facadeService.availableLanguages.find(l => l.languageid === this.authService.user.country.languageid);
    if (language) {
      this.languageName = language.language;
    } else {
      this.languageName = 'English';
    }
  }

  public canEditAdd(args) {
    return this.rolesService.hasAccessTo(args.obj, args.access);
  }

  public editAddStrength(item) {
    this.modalRef = this.bsRef.show(AddStrengthComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...item,
          countryid: this.baseObject.countryid
        },
        action: ActionStateEnum.EDIT
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public addNewStrength() {
    this.modalRef = this.bsRef.show(AddStrengthComponent, {
      class: 'modal-lg', initialState: { data: this.baseObject, action: ActionStateEnum.CREATE }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      this.baseObject.countryid = this.authService.user.country.countryid;
      let date;
      if (!!this.formGroup.value['lastchange']) {
        date = this.shareService.getStorableDate(this.formGroup.value['lastchange']);
      } else {
        date = null;
      }
      if (date === 'Invalid Date') {
        this.baseObject.lastchange = this.formGroup.value['lastchange'];
      } else {
        this.baseObject.lastchange = date;
      }
      const variables = {
        parentObj: this.baseObject,
        languageId: this.languageId,
        action: this.action
      };
      const _cascadeUnique = (args) => {
        if (!isNullOrUndefined(args)) {
          if (args.data['UniqueAddPreparation'] === true) {
            this.facadeService.mutateObject(this.saveAddPreparationGQL, variables, _cascadeEffect);
          } else {
            this.formGroup.get('label').setErrors({ 'labelunique': true });
          }
        }
      };
      const _cascadeEffect = (args) => {
        this.languageId = this.authService.user.country.languageid;
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({ selected: SUB_MENU_LIST_ORALIA_DB()[6] }));
        if (this.facadeService.filterArrayContains(args.data['saveAddPreparation'], ['label', 'status'], LinkKey.ADDITIONAL_PREPARATIONS)) {
          this.store.dispatch(new LoadAddByIdAction({ id: args.data['saveAddPreparation'].addpreparationid }));
          this.facadeService.setDetailHidden(false);
        }
      };
      const VarUnique = {
        label: this.baseObject.label,
        objectId: isNullOrUndefined(this.baseObject.addpreparationid) ? 0 : this.baseObject.addpreparationid,
        countryId: this.authService.user.country.countryid
      };
      if (this.languageId === 1 || this.action === ActionStateEnum.EDIT || this.action === ActionStateEnum.CREATE) {
        this.facadeService.callQueryUnique(this.uniqueAddPreparationGQL, VarUnique, _cascadeUnique);
      } else {
        this.facadeService.mutateObject(this.saveAddPreparationGQL, variables, _cascadeEffect);
      }
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.TRANSLATE: {
        return 'oraliadb.additionalPreparations.translateAddPreparation';
      }
      case ActionStateEnum.CREATE: {
        return 'oraliadb.additionalPreparations.newAddPreparation';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.additionalPreparations.editAddPreparation';
      }
      default: {
        return 'oraliadb.additionalPreparations.AddPreparation';
      }
    }
  }

  public getStatusById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public deleteAddPrepStrength(args: any) {
    const _cascadeEffect = () => {
      this.saveAddStrengthGQL.mutate({
        action: ActionStateEnum.DELETE,
        addPrepStrength: this.shareService.objectWithoutKey(args, '__typename')
      }).subscribe(next => {
        this.store.dispatch(new LoadAddByIdAction({ id: args.preparationid }));
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public changeActionState(args: any) {
    this.bsLocaleService.use(this.shareService.getLocaleById(this.languageId));
    switch (args) {
      case ActionStateEnum[ActionStateEnum.EDIT]: {
        this.languageId = this.authService.user.country.languageid;
        this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields, this.languageId);
        if (!isNullOrUndefined(this.baseObject['status'])) {
          this.formGroup.patchValue({ 'status': this.baseObject['status'] });
        }
        const date = this.shareService.getDateFromStorableDate(this.baseObject.lastchange);
        this.formGroup.patchValue({['lastchange']: date !== 'Invalid date' ? date : null });
        break;
      }
      case ActionStateEnum[ActionStateEnum.TRANSLATE]: {
        const langToChange = [...this.facadeService.translationLanguages];
        if (langToChange.length) {
          let userLang = find(langToChange, ['languageid', this.authService.user.userLanguage.languageid]);
          if (userLang) {
            this.languageId = userLang.languageid;
          } else {
            userLang = find(langToChange, ['languageid', this.authService.user.country.languageid]);
            if (userLang) {
              this.languageId = userLang.languageid;
            } else {
              this.languageId = langToChange[0].languageid;
            }
          }
          this.prepareForm();
        } else {
          this.prepareForm();
        }
        break;
      }
      case ActionStateEnum[ActionStateEnum.VIEW]: {
        if (this.action === ActionStateEnum.CREATE) {
          this.closeModal(true);
        } else {
          this.closeModal();
        }
        this.formGroup.patchValue({['lastchange']: this.shareService.getDateFromStorableDate(this.baseObject.lastchange)});
        break;
      }
    }
    this.action = args;
  }
}
