import {Medicament} from '../../models/medicament';
import {All, DoctorActions} from '../actions/doctor.actions';
import {Doctor} from '../../models/doctor';

export interface DoctorState {
  selected: Doctor;
}

export const initialState: DoctorState = {
  selected: null
};

export function reducer(state = initialState, action: All): DoctorState {
  switch (action.type) {
    case DoctorActions.SELECT_DOCTOR: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
