/**
 * @author Jonathan Almanza
 * 30/11/2018
 */
export enum AvailableActions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  NEW = 'NEW',
  DELETE = 'DELETE',
  TRANSLATE = 'TRANSLATE',
  FILTER = 'FILTER',
  HISTORY = 'HISTORY',
  COPY = 'COPY',
}
