/**
 * @author Jonathan Almanza
 * 23/11/2018
 */
import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum MedicamentActions {
  LOAD_MEDICAMENT = '[Medicament] Load',
  LOAD_MED_BY_ID = '[Medicament] LOAD_MED_BY_ID',
  SELECT_MEDICAMENT = '[Medicament] Change Selected',
  LOAD_MEDICAMENT_LIST_SUCCESS = '[Medicament] Load medicament success',
  LOAD_MEDICAMENT_LIST_FAILURE = '[Medicament] Load medicament failure',
}

export class LoadMedicamentListAction implements Action {
  readonly type = MedicamentActions.LOAD_MEDICAMENT;

  constructor(public payload: PaginationData) {
  }
}

export class LoadMedByIdAction implements Action {
  readonly type = MedicamentActions.LOAD_MED_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectMedicamentAction implements Action {
  readonly type = MedicamentActions.SELECT_MEDICAMENT;

  constructor(public payload: any) {
  }
}

export class LoadMedicamentSuccess implements Action {
  readonly type = MedicamentActions.LOAD_MEDICAMENT_LIST_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadMedicamentFailure implements Action {
  readonly type = MedicamentActions.LOAD_MEDICAMENT_LIST_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadMedicamentListAction
  | SelectMedicamentAction
  | LoadMedicamentSuccess
  | LoadMedicamentFailure;
