import {Validators} from '@angular/forms';

export class Pharmacy {
  pharmacyid?: number;
  countryid?: number;
  userid?: number;
  logo?: any;
  bgano?: string;
  city?: string;
  name?: string;
  street?: string;
  zip?:  string;
  mail?: string;
  phone?: string;

  constructor() {
    this.pharmacyid = null;
    this.countryid = null;
    this.userid = null;
    this.logo = null;
    this.bgano = '';
    this.city = '';
    this.name = '';
    this.street = '';
    this.zip = '';
    this.mail = '';
    this.phone = '';
  }
}
export class PharmacyValidate {
  public static rules = {
    ['email']: [Validators.required, Validators.email, Validators.maxLength(100)],
    ['city']: [Validators.required, Validators.maxLength(40)],
    ['name']: [Validators.required, Validators.maxLength(60)],
    ['street']: [Validators.required, Validators.maxLength(60)],
    ['zip']: [Validators.required, Validators.maxLength(10)],
    ['phone']: [ Validators.maxLength(50)],
    ['mail']: [Validators.required, Validators.maxLength(100)]
  };
}
