import {Directive, ElementRef, Input} from '@angular/core';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';

@Directive({
  selector: '[isReadonly]'
})
export class IsReadonlyDirective {
  @Input('isReadonly')
  set isReadonly(args: ReadOnlyParams | any) {
    if (args.disableWhen.indexOf(args.action) >= 0) {
      this.el.nativeElement.setAttribute('readonly', true);
    } else {
      this.el.nativeElement.removeAttribute('readonly');
    }
  }

  constructor(private el: ElementRef) {
  }

}

export interface ReadOnlyParams {
  action: ActionStateEnum;
  disableWhen: ActionStateEnum[];
}
