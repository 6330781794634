import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {
  constructor() {}

  transform(value: any, code?: any, langList?: Map<number, any>): any {
    if (langList.has(code)) {
      return langList.get(code)[value.shortname];
    }
    return value.language;
  }

}
