import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { CountriesGQL, SaveTextGQL, UserLanguagesGQL } from '../../../api/graphql';
import { SUB_MENU_LIST_ADMINISTRATION } from '../../../config/sub-menu';
import { FormAbstract } from '../../../models/abstract/form.abstract';
import { Country } from '../../../models/country';
import { ActionStateEnum } from '../../../models/enums/actions-state.enum';
import { LinkKey } from '../../../models/enums/link-key.enum';
import { Text, TextValidate } from '../../../models/text';
import { UserLanguage } from '../../../models/user-language';
import { AuthService } from '../../../services/auth.service';
import { FacadeService } from '../../../services/facade.service';
import { ShareService } from '../../../services/share.service';
import { ChangeSubMenuAction } from '../../../store/actions/sub-menu.actions';
import { SelectTextAction } from '../../../store/actions/text.actions';
import { AppState, selectTextReducer } from '../../../store/app.states';

declare var $: any;

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends FormAbstract<Text> implements OnInit {
  public languageList: Array<UserLanguage>;
  public countryList: Array<Country>;
  public languageId: any;
  public countryid: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  // public showPopupOneTime: boolean;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService, private countryListGQL: CountriesGQL,
              private userLanguageGQL: UserLanguagesGQL, private saveTextGQL: SaveTextGQL) {

    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'textid'},
      {field: 'label', validator: TextValidate.rules.label},
      {field: 'text', validator: TextValidate.rules.text},
      {field: 'subject'},
      {field: 'showpopuponetime'},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'textid',
      translationId: null
    };
    this.languageId = this.authService.userLanguage.languageid;
    this.translateWithCountry = true;
    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.languageId = this.authService.userLanguage.languageid;
    } else {
      this.store.pipe(select(selectTextReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        this.languageId = this.authService.userLanguage.languageid;
        if (next.selected) {
          this.baseObject = next.selected;
          if (this.baseObject.textid == 5) {
            this.translateWithCountry = false;
          }
          this.prepareForm();
          if(this.baseObject && this.baseObject.textid && (this.baseObject.textid == 14 || this.baseObject.textid == 21 || this.baseObject.textid == 22)) {
            this.formGroup.get('text').clearValidators();
            this.formGroup.get('text').updateValueAndValidity();
          } else {
            this.formGroup.get('text').setValidators(TextValidate.rules.text);
            this.formGroup.get('text').updateValueAndValidity();
          }
        }
      });
    }
  }

  ngOnInit() {
    this.countryid =  this.baseObject.textid == 5 ? 0 : this.authService.user.country.countryid
    this.countryListGQL.watch({name: ''}
    ).result().then((data) => {
      this.countryList = data.data['Countries'] as any;
    });
    this.userLanguageGQL.watch({}
    ).result().then((data) => {
      this.languageList = data.data['UserLanguages'] as any;
    });
  }

  public onSubmit() {
    const checkbox = document.getElementById('showPopupOneTime') as HTMLInputElement;
    if (checkbox && checkbox.checked) {
      this.formGroup.patchValue({ 'showpopuponetime': true });
    } else {
      this.formGroup.patchValue({ 'showpopuponetime': false });
    }
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      countryId: this.authService.user.country.countryid,
      action: this.action,
      showpopuponetime: this.formGroup.get('showpopuponetime').value
    };
    const _cascadeEffect = (args) => {
      this.languageId = this.authService.userLanguage.languageid;
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[4]}));
      if (this.facadeService.filterArrayContains(args.data['saveText'], ['label'], LinkKey.TEXT)) {
        this.store.dispatch(new SelectTextAction({selected: args.data['saveText']}));
        this.facadeService.setDetailHidden(false);
      }
    };
    this.facadeService.mutateObject(this.saveTextGQL, variables, _cascadeEffect);
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        // (document.getElementById('showPopupOneTime') as HTMLInputElement).disabled = true;
        return 'administration.text.newText';
      }
      case ActionStateEnum.EDIT: {
        // (document.getElementById('showPopupOneTime') as HTMLInputElement).disabled = false;
        return 'administration.text.editText';
      }
      default: {
        // (document.getElementById('showPopupOneTime') as HTMLInputElement).disabled = false;
        return 'administration.text.text';
      }
    }
  }

}
