import {Doctor} from './doctor';
import {Validators} from '@angular/forms';

export class PatientDoctor {
  patientdoctorid: number;
  prescribing?: string;
  countryid?: number;
  patientid?: number;
  pharmacyid?: number;
  doctor?: Doctor;
}
export class PatientDoctorValidate {
  public static rules = {
    ['prescribing']: [Validators.required],
    ['countryid']: [Validators.required],
    ['pharmacyid']: [Validators.required],
    ['patientid']: [Validators.required],
    ['doctorid']: [Validators.required]
  };
}
