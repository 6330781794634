/**
 * @author Jonathan Almanza
 * 21/11/2018
 */
import {All, LangActionTypes} from '../actions/lang.actions';
import {UserLanguage} from '../../models/user-language';
import {PatientLanguage} from "../../models/patient-language";

export interface State {
  selectedLanguage: PatientLanguage | null;
  availableLang: Array<PatientLanguage>;
  actionType: string;
}

export const initialState: State = {
  selectedLanguage: {},
  availableLang: [],
  actionType : ''
};

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case LangActionTypes.CHANGE: {
      return {
        ...state,
        selectedLanguage: action.payload.selectedLanguage
      };
    }
    case LangActionTypes.LOAD_TRANSLATION_SUCCESS: {
      let langCookie = null;
      const match = document.cookie.match(new RegExp('(^| )' + 'languageId' + '=([^;]+)'));
      if (match) {
        langCookie = parseInt(match[2], 0);
      }
      return {
        ...state,
        availableLang: action.payload.availableLang,
        actionType: action.payload.actionType,
        selectedLanguage: action.payload.availableLang.find(t => {
          if (langCookie) {
            return t.languageid === langCookie;
          } else {
            if (t.shortname) {
              return t.shortname === 'en';
            }
          }
        })
      };
    }
    default: {
      return state;
    }
  }
}
