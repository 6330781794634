import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {find} from 'lodash';
import {Country} from "../../../models/country";

@Pipe({
  name: 'translateLogin'
})
export class TranslateLoginPipe implements PipeTransform {

  transform(value: any, label?: any, specialLanguage?: number, country?: Country): any {
    let currentTranslate = null;
    if (!isNullOrUndefined(value)) {
      if (!isNullOrUndefined(value.translations)) {
        let countryFilter = {};
        if (country) {
          countryFilter = { 'countryid': country.countryid };
        }
        let langId = 1;
        if (!isNullOrUndefined(specialLanguage)) {
          langId = specialLanguage;
        } else {
          if (country) {
            langId = country.languageid;
          }
        }
        currentTranslate = find(value.translations, { 'languageid': langId, ...countryFilter });
        if (currentTranslate) {
          return currentTranslate[label] === 'null' ? '' : currentTranslate[label];
        }
      }
      return value[label] === 'null' ? '' : value[label];
    } else {
      return '';
    }
  }

}
