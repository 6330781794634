import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../../../services/share.service';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {Store} from '@ngrx/store';
import {FacadeService} from '../../../../../services/facade.service';
import {Medicament} from '../../../../../models/medicament';
import {AppState} from '../../../../../store/app.states';
import {AuthService} from '../../../../../services/auth.service';
import {Substance} from '../../../../../models/substance';
import {FormGroup, Validators} from '@angular/forms';
import {CopyMedicamentGQL} from '../../../../../api/graphql';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {LoadMedByIdAction} from '../../../../../store/actions/medicament.actions';
import {ChangeSubMenuAction} from '../../../../../store/actions/sub-menu.actions';
import {LinkKey} from '../../../../../models/enums/link-key.enum';
import {SUB_MENU_LIST_ORALIA_DB} from '../../../../../config/sub-menu';

@Component({
  selector: 'app-copy-medicament',
  templateUrl: './copy-medicament.component.html',
  styleUrls: ['./copy-medicament.component.scss']
})
export class CopyMedicamentComponent extends FormAbstract<Medicament> implements OnInit {

  public substanceList: Array<Substance>;
  public selectedSubstance: Substance;
  public languageId: any;
  public nameForm: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, protected authService: AuthService,
    private copyMedicamentGQL: CopyMedicamentGQL) {
        super(store, bsRef, shareService, facadeService, authService);
        this.baseFields = [
          {field: 'name', validator: [Validators.required]},
        ];
        this.nameForm = this.facadeService.createFormWithValidators(this.baseFields);
        this.baseObject = this.bsRef.config.initialState['data'];
  }

  ngOnInit() {
  }

  public onSubmit() {
    const variables = {
      name: this.nameForm.get("name").value,
      medicamentId: this.baseObject.medicamentid
    };
    const _cascadeEffect = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ORALIA_DB()[0]}));
      // Filter Match with edited Item
      const filterBy = {...this.facadeService.filterData[LinkKey.MEDICAMENT].filterBy};
      if (filterBy.status.indexOf(this.baseObject.status) >= 0) {
        this.store.dispatch(new LoadMedByIdAction({id: args.data['copyMedicament'].medicamentid}));
        this.facadeService.setDetailHidden(false);
      }
      this.facadeService.loadManufacturers();
    };
    this.facadeService.mutateObject(this.copyMedicamentGQL, variables, _cascadeEffect);
  }
}
