import { Injectable } from '@angular/core';
import { select, State } from '@ngrx/store';
import { AppState, selectLanguageState } from '../store/app.states';
import { UserLanguage } from '../models/user-language';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { sortBy, filter, find, omit } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IntakePlan } from '../models/intake-plan';
import { IntakeMed } from '../models/intake-med';
import { TranslateSelectedPipe } from '../modules/shared/pipes/translate-selected.pipe';
import { AuthService } from './auth.service';
import { IntakeAddPrep } from '../models/intake-add-prep';
import { IntakePlanDate } from '../models/intake-plan-date';
import { Text } from '../models/text';
import { TextsGQL } from '../api/graphql';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable()
export class ShareService {
  public availableLang: Array<UserLanguage>;
  public languageCreate: UserLanguage;
  public textList: Array<Text>;
  public translateSelectedPipe: TranslateSelectedPipe;
  public modals = {
    'backgroundComponent': null,
    'basicDataEditComponent': null,
    'noteComponent': null,
    'leafletComponent': null,
    'strengthComponent': null,
  };
  modalRef: BsModalRef;
  @BlockUI() blockUI: NgBlockUI;

  constructor(private state: State<AppState>, private translate: TranslateService, private textsGQL: TextsGQL,
    private coolDialogs: NgxCoolDialogsService,
    private bsRef: BsModalService,
    private authService: AuthService) {
    this.translateSelectedPipe = new TranslateSelectedPipe(this.authService);
    this.languageCreate = new UserLanguage();
    this.state.pipe(select(selectLanguageState)).subscribe((next: any) => {
      this.availableLang = next.availableLang;
    });
  }

  public static getRightCountryCode(i18n) {
    return i18n === 'si' ? 'sl' : (i18n === 'ua' ? 'uk' : i18n);
  }

  public getLocaleById(langId): string {
    switch (langId) {
      case 1:
        return 'en';
      case 2:
        return 'de';
      case 3:
        return 'et';
      case 4:
        return 'sl';
      case 5:
        return 'pl';
      case 6:
        return 'ua';
      default:
        return 'en';
    }
  }

  public getDateByLanguage(dateString, lang) {

    const dateFormat = this.translate.getParsedResult(this.translate.translations[lang], 'common.dateInputFormat');
    return moment(dateString, 'DD/MM/YYYY').format(dateFormat);
  }
  
  public getDate(dateString) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    return moment(dateString).format(dateFormat);
  }

  public getPrintableDate(dateString) {
    const dateFormat = this.translate.instant('reports.formatDate');
    return moment(dateString).format(dateFormat);
  }

  public getDateFromStorableDate(dateString) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    return moment(dateString, 'YYYY-MM-DD').format(dateFormat);
  }

  public getStorableDate(date) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    return moment(date, dateFormat).format('YYYY-MM-DD');
  }

  public addTime(date, time, addType) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    return moment(date).add(time, addType).format(dateFormat);
  }

  public fromInputFormatToDate(date) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    return moment(date, dateFormat).toDate();
  }

  public addTimeFromStorableDate(date, time, addType, usei18n?: any) {
    const dateFormat = this.translate.instant('common.dateInputFormat');
    if (usei18n) {
      const day = moment(date, 'YYYY-MM-DD').add(time, addType).locale(ShareService.getRightCountryCode(usei18n)).format('ddd, ');
      return day + moment(date, 'YYYY-MM-DD').add(time, addType).locale(ShareService.getRightCountryCode(usei18n)).format('L');
    }
    return moment(date, 'YYYY-MM-DD').add(time, addType).format(dateFormat);
  }

  public getFromStorableDateUseLocale(date, i18n, format) {
    return moment(date, 'YYYY-MM-DD').locale(ShareService.getRightCountryCode(i18n)).format(format);
  }
  public getDifference(dateFrom, dateTo) {
    const dateFormat = 'YYYY-MM-DD';
    return moment(dateTo, dateFormat).diff(moment(dateFrom, dateFormat), 'days');
  }

  public confirmDelete(_cascadeEffect?: () => void, coolDialogs?: any) {
    const next = this.translate.instant(
      ['common.confirm.requestDelete', 'common.confirm.accept', 'common.confirm.cancel', 'common.confirm.confirm']);

    coolDialogs.confirm(next['common.confirm.requestDelete'], {
      okButtonText: next['common.confirm.accept'],
      cancelButtonText: next['common.confirm.cancel'],
      title: next['common.confirm.confirm']
    })
      .subscribe(res => {
        if (res) {
          _cascadeEffect();
        }
      });
  }

  public confirmPharmacyDelete(_cascadeEffect?: () => void, coolDialogs?: any) {
    const next = this.translate.instant(
      ['common.confirm.pharmacyDelete', 'common.confirm.accept', 'common.confirm.cancel', 'common.confirm.confirm']);

    coolDialogs.confirm(next['common.confirm.pharmacyDelete'].trim(), {
      okButtonText: next['common.confirm.accept'],
      cancelButtonText: next['common.confirm.cancel'],
      title: next['common.confirm.confirm']
    })
      .subscribe(res => {
        if (res) {
          _cascadeEffect();
        }
      });
  }

  // public confirmPlanDateChange(_cascadeEffect?: () => void, coolDialogs?: any) {
  //   const next = this.translate.instant(
  //     ['common.confirm.request.change.confirm', 'common.confirm.request.accept', 'common.confirm.cancel', 'common.confirm.confirm']);

  //   coolDialogs.confirm(next['common.confirm.request.change.confirm'], {
  //     okButtonText: next['common.confirm.request.accept'],
  //     cancelButtonText: next['common.confirm.cancel'],
  //     title: next['common.confirm.confirm']
  //   })
  //     .subscribe(res => {
  //       if (res) {
  //         _cascadeEffect();
  //       }
  //     });
  // }

  public confirmPlanDateChange(_cascadeEffect?: () => void, _cancelEffect?: () => void, coolDialogs?: any) {
    const next = this.translate.instant(
      ['common.confirm.request.change.confirm', 'common.confirm.request.accept', 'common.confirm.cancel', 'common.confirm.confirm']);

    coolDialogs.confirm(next['common.confirm.request.change.confirm'], {
      okButtonText: next['common.confirm.request.accept'],
      cancelButtonText: next['common.confirm.cancel'],
      title: next['common.confirm.confirm']
    })
      .subscribe(res => {
        if (res) {
          _cascadeEffect();
        } else {
          _cancelEffect();
        }
      });
  }

  public confirmAction(messageKey: string, _cascadeEffect?: () => void) {
    const next = this.translate.instant(
      [messageKey, 'common.confirm.accept', 'common.confirm.cancel', 'common.confirm.confirm']);
    this.coolDialogs.confirm(`${this.translate.instant(messageKey)}`, {
      cancelButtonText: next['common.confirm.cancel'],
      okButtonText: next['common.confirm.confirm'],
      title: next['common.confirm.confirm'],
    }).subscribe(t => {
      if (t && _cascadeEffect) {
        _cascadeEffect();
      }
    });
  }

  public objectWithoutKey(object, key) {
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  }

  public objectWithoutKeyArray(object, keys: Array<any>) {
    keys.forEach(t => {
      object = this.objectWithoutKey(object, t);
    });
    return object;
  }

  public range(start, end) {
    // @ts-ignore
    return Array(end - start + 1).fill().map((_, idx) => start + idx);
  }

  public getTextWithHtmlTags(textid: number): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.textList === undefined) {
        this.textsGQL.watch({ label: '' }).result().then((next: any) => {
          this.textList = next.data['Texts'];
          const text = this.translateSelectedPipe.transform(
            this.getTextByTextId(textid),
            'text',
            this.authService.user.userLanguage.languageid,
            this.authService.user.country.countryid
          );
          resolve(text); // Resolve the promise with the 'text' value
        }).catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
      } else {
        const text = this.translateSelectedPipe.transform(
          this.getTextByTextId(textid),
          'text',
          this.authService.user.userLanguage.languageid,
          this.authService.user.country.countryid
        );
        resolve(text); // Resolve the promise with the 'text' value
      }
    });
  }
  
  public loadAllTexts(_callback?: any) {
    if (this.textList === undefined) {
      this.textsGQL.watch({ label: '' }).result().then((next: any) => {
        this.textList = next.data['Texts'];
        if (_callback) {
          _callback();
        }
      });
    }
  }

  public getTextByTextId(textId: number) {
    return find(this.textList, ['textid', textId]);
  }

  public startLoading() {
    this.blockUI.start();
  }

  public stopLoading() {
    this.blockUI.stop();
  }

  public getPrintIntakePlanFromObj(intakeDates: Array<IntakePlanDate>, langId: number, i18n, format: string = 'ddd, L') {
    return intakeDates.map(t => {
      const date = this.getFromStorableDateUseLocale(t.date, i18n, format);
      return {
        ...this.objectWithoutKey(t, '__typename'),
        date,
        intakePlanDateTimes: t.intakePlanDateTimes.map(r => {
          return {
            ...this.objectWithoutKey(r, '__typename'),
            intakePlanPreparations: filter(sortBy(r.intakePlanPreparations.map(p => {
              return {
                ...this.objectWithoutKey(p, '__typename'),
                addPreparation: {
                  ...this.objectWithoutKey(p.addPreparation, '__typename'),
                  label: this.translateSelectedPipe.transform(p.addPreparation, 'label', langId),
                  addPrepStrengths: [],
                  translations: [],
                }
              };
            }), (o) => o.addPreparation.label), (p) => p.pieces !== '' && p.pieces !== 0),
            intakePlanMedicaments: filter(sortBy(r.intakePlanMedicaments.map(m => {
              return {
                ...this.objectWithoutKey(m, '__typename'),
                medicament: {
                  ...this.objectWithoutKey(m.medicament, '__typename'),
                  shortname: this.translateSelectedPipe.transform(m.medicament, 'shortname', langId),
                  medStrengths: [],
                  translations: [],
                  medSideEffects: []
                }
              };
            }), (o) => o.medicament.shortname), (p) => p.pieces !== '' && p.pieces !== 0),
          };
        }),
      };
    });
  }

  public translateSelectedItem(item, label, languageId) {
    return this.translateSelectedPipe.transform(item, label, languageId);
  }

  public prepareDailyIntakePlan(intake: IntakePlan) {

    const response = Array<IntakePlanDate>();
    const range = this.range(0, moment(intake.dateto).diff(intake.datefrom, 'days'));
    const timeRange = this.range(0, 23);
    range.forEach(i => {
      response.push({
        planid: intake.planid,
        date: this.getStorableDate(this.addTime(intake.datefrom, i, 'days')),
        intakePlanDateTimes: [],
      });
    });
    intake.intakeMeds.forEach((med: IntakeMed) => {
      // const index = 0;
      delete med.medicament.history;
      const selectedDays = this.getUniqueDays(med.days, range.length);
      for (let i = 0; i < selectedDays.length; i++) {
        const index = selectedDays[i] as number;
        timeRange.forEach(clock => {
          const key = 'pieces' + (clock < 10 ? ('0' + clock) : clock) + 'clock';
          if (med[key] > 0) {
            const time = `${(clock < 10 ? ('0' + clock) : clock)}:00`; //response[index] &&  && response[index].intakePlanDateTimes.length > 0
            if (response[index].intakePlanDateTimes) { // Check for null or undefined
              const timeIndex = response[index].intakePlanDateTimes.findIndex(t => t.time === time);
              if (timeIndex > -1) {
                response[index].intakePlanDateTimes[timeIndex].intakePlanMedicaments.push({
                  medicament: med.medicament,
                  pieces: med[key],
                  strength: med.strength,
                });
                response[index].intakePlanDateTimes[timeIndex].intakePlanMedicaments =
                  sortBy(response[index].intakePlanDateTimes[timeIndex].intakePlanMedicaments,
                    (o) => o.medicament.shortname);
              } else {
                response[index].intakePlanDateTimes.push({
                  time: time,
                  intakePlanMedicaments: [{
                    medicament: med.medicament,
                    pieces: med[key],
                    strength: med.strength,
                  }],
                  intakePlanPreparations: [],
                });
              }
            }
          }
        });
      }
    });
    intake.intakeAddPreps.forEach((med: IntakeAddPrep) => {
      let index = 0;
      delete med.additionalPreparation.history;
      const selectedDays = this.getUniqueDays(med.days, range.length);
      for (let i = 0; i < selectedDays.length; i++) {
        const index = selectedDays[i] as number;
        timeRange.forEach(clock => {
          const key = 'pieces' + (clock < 10 ? ('0' + clock) : clock) + 'clock';
          if (med[key] > 0) {
            const time = `${(clock < 10 ? ('0' + clock) : clock)}:00`; // && response[index].intakePlanDateTimes.length > 0
            if (response[index].intakePlanDateTimes) { // Check for null or undefined
              const timeIndex = response[index].intakePlanDateTimes.findIndex(t => t.time === time);
              if (timeIndex > -1) {
                response[index].intakePlanDateTimes[timeIndex].intakePlanPreparations.push({
                  addPreparation: med.additionalPreparation,
                  pieces: med[key],
                  strength: med.strength,
                });
                response[index].intakePlanDateTimes[timeIndex].intakePlanPreparations =
                  sortBy(response[index].intakePlanDateTimes[timeIndex].intakePlanPreparations,
                    (o) => o.addPreparation.label);
              } else {
                response[index].intakePlanDateTimes.push({
                  time: time,
                  intakePlanPreparations: [{
                    addPreparation: med.additionalPreparation,
                    pieces: med[key],
                    strength: med.strength,
                  }],
                });
              }
            }
          }
        });
      }
    });
    response.forEach(item => {
      item.intakePlanDateTimes.sort((a, b) => {
        const timeA = parseInt(a.time.split(':')[0], 10);
        const timeB = parseInt(b.time.split(':')[0], 10);
        if (timeA !== timeB) {
          return timeA - timeB;
        } else {
          const minutesA = parseInt(a.time.split(':')[1], 10);
          const minutesB = parseInt(b.time.split(':')[1], 10);
          return minutesA - minutesB;
        }
      });
    });
  
    // Sort the entire response array by date and time
    response.sort((a, b) => {
      const dateA = moment(a.date, 'YYYY-MM-DD');
      const dateB = moment(b.date, 'YYYY-MM-DD');
      if (dateA.isBefore(dateB)) {
        return -1;
      } else if (dateA.isAfter(dateB)) {
        return 1;
      } else {
        const timeA = a.intakePlanDateTimes[0].time;
        const timeB = b.intakePlanDateTimes[0].time;
        const minutesA = parseInt(timeA.split(':')[0], 10) * 60 + parseInt(timeA.split(':')[1], 10);
        const minutesB = parseInt(timeB.split(':')[0], 10) * 60 + parseInt(timeB.split(':')[1], 10);
        return minutesA - minutesB;
      }
    });
    return response;
  }
  
  public getUniqueDays(input, range) {

    if (input === null || input === undefined || input.trim() === '') {
      return [];
    }

    input = input.replace(/\s/g, '');

    const uniqueRecords = new Set();

    const elements = input.split(',');
    for (const element of elements) {
      if (element.includes('-')) {
        // Handle number range
        const range = element.split('-');
        let start = parseInt(range[0]);
        let end = parseInt(range[1]);

        // Swap start and end if start > end
        if (start > end) {
          [start, end] = [end, start];
        }

        // Add numbers within the range to the set
        for (let i = start; i <= end; i++) {
          uniqueRecords.add(i);
        }
      } else {
        // Handle single number
        const number = parseInt(element);
        uniqueRecords.add(number);
      }
    }

    // Convert the Set to a sorted array
    const days = Array.from(uniqueRecords).sort((a, b) => a - b);

    for (let i = 0; i < days.length; i++) {
      days[i] = Number(days[i]) - 1;
    }
    const filteredDays: any[] = days.filter(day => day < range);
    return filteredDays;
  }

  public copyIntakePlanDaily(intakePlan: IntakePlan, fromDate) {
    const result: Array<IntakePlanDate> = [];
    intakePlan.intakePlanDates.forEach(({ id, ...rest }) => {
      result.push({
        planid: null,
        date: fromDate.format('YYYY-MM-DD'),
        intakePlanDateTimes: rest.intakePlanDateTimes.map(({ id, plandateid, ...rest2 }) => {
          return {
            time: rest2.time,
            intakePlanMedicaments: rest2.intakePlanMedicaments.map(({ id, plandatetimeid, ...rest3 }) => rest3),
            intakePlanPreparations: rest2.intakePlanPreparations.map(({ id, plandatetimeid, ...rest4 }) => rest4),
          };
        }),
      });
      fromDate = fromDate.add(1, 'days');
    });
    return result;
  }
  // TODO: Open generic modal for all views with object mapping
  public openModal(component: string, action: any, data: any) {
    this.modalRef = this.bsRef.show(this.modals[component], {
      class: 'modal-lg', initialState: { data, action }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }
}
