import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShareService } from 'src/app/services/share.service';

declare var $: any;

@Component({
  selector: 'app-custom-popup-twenty-two',
  templateUrl: './custom-popup-twenty-two.component.html',
  styleUrls: ['./custom-popup-twenty-two.component.scss']
})
export class CustomPopupTwentyTwoComponent implements OnInit {

  public htmlContent: string = '';

  @Output('loginPopupTwentyTwo') loginPopupTwentyTwo = new EventEmitter<any>();
  constructor(private shareService: ShareService,  public sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.getTextWithHtmlTags(22);
  }

  public loginPopupTwentyTwoConfirm() {
    this.loginPopupTwentyTwo.emit();
  }
  
  public getTextWithHtmlTags(textid: number) {
    this.shareService.getTextWithHtmlTags(textid).then((text: string) => {
      this.htmlContent = text;
      if (this.htmlContent && this.htmlContent.length > 0) {
        $('#loginPopupTwentyTwo').modal();
      } else {
        $('#loginPopupTwentyTwo').modal('hide');
        setTimeout(() => {
          $('#loginPopupTwentyTwo').remove();
        }, 500);
      }
    }).catch((error) => {
      console.error('Cannot get login alert message twenty two:', error);
    });
  }
}
