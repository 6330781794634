import {Validators} from '@angular/forms';

export class Sponsor {
  sponsorid?: number;
  sponsor?: number;
  logo?: any;
  link?: string;
  text?: string;
  datestart?: string;
  dateend?:  string;
  countryid?: number;
}
export class SponsorValidate {
  public static rules = {
    ['sponsor']: [Validators.required, Validators.maxLength(60)],
    ['link']: [Validators.required, Validators.maxLength(200)],
    ['text']: [Validators.required],
  };
}
