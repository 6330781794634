/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';

export enum PatientActions {
  LOAD_PATIENT = '[PATIENT] Load',
  SELECT_PATIENT = '[PATIENT] Change Selected'
}

export class LoadPatientAction implements Action {
  readonly type = PatientActions.LOAD_PATIENT;

  constructor(public payload: PaginationData) {
  }
}

export class SelectPatientAction implements Action {
  readonly type = PatientActions.SELECT_PATIENT;

  constructor(public payload: any) {
  }
}


export type All =
  | LoadPatientAction
  | SelectPatientAction;
