import {SideEffect} from '../../models/side-effect';
import {All, SideEffectActions} from '../actions/side-effect.actions';

/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
export interface SideEffectState {
  selected: SideEffect;
}

export const initialState: SideEffectState = {
  selected: null
};

export function reducer(state = initialState, action: All): SideEffectState {
  switch (action.type) {
    case SideEffectActions.LOAD_SIDE_EFFECT_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case SideEffectActions.SELECT_SIDE_EFFECT: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
