import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../../services/auth.service';
import { FacadeService } from '../../../services/facade.service';
import { LoadAddByIdAction, SelectAddPreparationsAction } from '../../../store/actions/add-preparation.actions';
import { LoadHistoryAction } from '../../../store/actions/history.actions';
import { SelectIntakeNotesAction } from '../../../store/actions/intake-notes.actions';
import { SelectInteractionAction } from '../../../store/actions/interaction.actions';
import { LoadMedByIdAction } from '../../../store/actions/medicament.actions';
import { SelectOtherNotesAction } from '../../../store/actions/other-notes.actions';
import { SelectSideEffectAction } from '../../../store/actions/side-effect.actions';
import { SelectSubstanceAction } from '../../../store/actions/substance.actions';
import { AppState, selectLanguageState, selectSubMenusReducer } from '../../../store/app.states';
// @ts-ignore
import { TranslateService } from '@ngx-translate/core';
import { SelectPdfTextAction } from 'src/app/store/actions/pdf-text.actions';
import {
  AddPreparationsGQL,
  CountriesGQL,
  DoctorGQL,
  DoctorsGQL,
  EmployeesGQL,
  FileGQL,
  FilesGQL,
  HistoriesGQL,
  IntakeNotesGQL,
  InteractionsGQL,
  MedicamentsGQL,
  OtherNotesGQL,
  PatientGQL,
  PatientLanguagesGQL,
  PatientsGQL,
  PdfTextsGQL,
  PharmaciesGQL,
  RegistrationsGQL,
  SideEffectsGQL,
  SponsorsGQL,
  SubstancesGQL,
  TextsGQL,
  UserGQL,
  UsersGQL
} from '../../../api/graphql';
import { LinkKey } from '../../../models/enums/link-key.enum';
import { ReportType } from '../../../models/enums/report-type';
import { Sponsor } from '../../../models/sponsor';
import { LoadDetailByIdAction } from '../../../store/actions/common.actions';
import { SelectCountryAction } from '../../../store/actions/country.actions';
import { SelectDoctorAction } from '../../../store/actions/doctor.actions';
import { SelectEmployeeAction } from '../../../store/actions/employee.actions';
import { SelectFileAction } from '../../../store/actions/file.actions';
import { SelectPatientLanguagesAction } from '../../../store/actions/patient-language.actions';
import { SelectPatientAction } from '../../../store/actions/patient.actions';
import { SelectPharmacyAction } from '../../../store/actions/pharmacy.actions';
import { SelectSponsorAction } from '../../../store/actions/sponsor.actions';
import { SelectTextAction } from '../../../store/actions/text.actions';
import { SelectUserAction } from '../../../store/actions/user.actions';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent implements OnInit {
  public header: string;
  public selectedList: string;
  public displayProperty: string;
  public secondProperty: string;
  public selectedRouterLink: string;

  public indexString: string;
  public languageId: number;
  public dataList: Observable<any>;
  public selected: any;

  constructor(private store: Store<AppState>, private translate: TranslateService,
              private facadeService: FacadeService, private authService: AuthService,
              private medicamentGQL: MedicamentsGQL, private otherNoteGQL: OtherNotesGQL,
              private intakeNoteGQL: IntakeNotesGQL, private addPreparationGQL: AddPreparationsGQL,
              private interactionGQL: InteractionsGQL, private sideEffectGQL: SideEffectsGQL,
              private substanceGQL: SubstancesGQL, private patientsGQL: PatientsGQL,
              private doctorsGQL: DoctorsGQL, private countriesGQL: CountriesGQL,
              private patientLanguagesGQL: PatientLanguagesGQL, private usersGQL: UsersGQL,
              private textsGQL: TextsGQL, private pdfTextsGQL: PdfTextsGQL, private filesGQL: FilesGQL, private sponsorsGQL: SponsorsGQL,
              private pharmaciesGQL: PharmaciesGQL, private historiesGQL: HistoriesGQL,
              private employeesGQL: EmployeesGQL, private registrationsGQL: RegistrationsGQL,
              // Detail injection
              private doctorGQL: DoctorGQL, private patientGQL: PatientGQL, private userGQL: UserGQL, private fileGQL: FileGQL) {
    this.selectedList = '';
    this.displayProperty = '';
    this.indexString = '';
    this.selected = {};
    this.secondProperty = null;
  }

  ngOnInit() {
    this.dataList = this.facadeService.dataList;
    // this.dataList = this.store.pipe(select(selectListReducer));
    this.currentUserLanguageId();
    this.store.pipe(select(selectSubMenusReducer)).subscribe((state: any) => {
      if (!isNullOrUndefined(this.authService.user.country)) {
        // Refresh selected ite
        this.selected = {};
        this.facadeService.setDataList({});
        this.facadeService.setDetailHidden(true);
        this.secondProperty = null;
        // Update router link
        this.selectedRouterLink = state.selected.routerLink;
        switch (state.selected.routerLink) {
          case LinkKey.MEDICAMENT: {
            this.facadeService.callQueryListToUpdate(this.medicamentGQL, this.facadeService.filterData[LinkKey.MEDICAMENT]);
            this.setLabels('submenus.oraliadb.medicament', 'Medicaments', 'shortname', 'medicamentid');
            break;
          }
          case LinkKey.SUBSTANCES: {
            this.store.dispatch(new SelectSubstanceAction({selected: {}}));
            this.facadeService.callQueryListToUpdate(this.substanceGQL, this.facadeService.filterData[LinkKey.SUBSTANCES]);
            this.setLabels('submenus.oraliadb.substances', 'Substances', 'label', 'substanceid');
            break;
          }
          case LinkKey.SIDE_EFFECTS: {
            this.store.dispatch(new SelectSideEffectAction({selected: {}}));
            const user = this.authService.user;
            if ((user.isemployee || user.ispharmacy) &&
              (!user.iscountryadmin || !user.iscountryeditor || !user.ismainadmin || !user.ismaineditor)) {
              this.facadeService.filterData[LinkKey.SIDE_EFFECTS].status = [1];
            }
            this.facadeService.callQueryListToUpdate(this.sideEffectGQL, this.facadeService.filterData[LinkKey.SIDE_EFFECTS]);
            this.setLabels('submenus.oraliadb.sideEffects', 'SideEffects', 'label', 'sideeffectid');
            break;
          }
          case LinkKey.INTERACTIONS: {
            this.store.dispatch(new SelectInteractionAction({selected: {}}));
            this.facadeService.callQueryListToUpdate(this.interactionGQL, this.facadeService.filterData[LinkKey.INTERACTIONS]);
            this.setLabels('submenus.oraliadb.interactions', 'Interactions', 'label', 'interactionid');
            break;
          }
          case LinkKey.INTAKE_NOTES: {
            this.store.dispatch(new SelectIntakeNotesAction({selected: {}}));
            this.facadeService.callQueryListToUpdate(this.intakeNoteGQL, this.facadeService.filterData[LinkKey.INTAKE_NOTES]);
            this.setLabels('submenus.oraliadb.intakeNotes', 'IntakeNotes', 'label', 'intakenoteid');
            break;
          }
          case LinkKey.OTHER_NOTES: {
            this.store.dispatch(new SelectOtherNotesAction({selected: {}}));
            this.facadeService.callQueryListToUpdate(this.otherNoteGQL, this.facadeService.filterData[LinkKey.OTHER_NOTES]);
            this.setLabels('submenus.oraliadb.otherNotes', 'OtherNotes', 'label', 'othernoteid');
            break;
          }
          case LinkKey.ADDITIONAL_PREPARATIONS: {
            this.store.dispatch(new SelectAddPreparationsAction({selected: {}}));
            this.facadeService.callQueryListToUpdate(this.addPreparationGQL, {
              ...this.facadeService.filterData[LinkKey.ADDITIONAL_PREPARATIONS],
              countryId: this.authService.user.country.countryid
            });
            this.setLabels('submenus.oraliadb.additionalPreparations', 'AddPreparations', 'label', 'addpreparationid');
            break;
          }
          case LinkKey.HISTORY: {
            this.facadeService.callQueryListToUpdate(this.historiesGQL, this.facadeService.filterData[LinkKey.HISTORY]);
            this.setLabels('submenus.oraliadb.history', 'Histories', 'label', 'historyid');
            break;
          }
          case LinkKey.PATIENTS: {
            this.facadeService.callQueryListToUpdate(this.patientsGQL, this.facadeService.filterData[LinkKey.PATIENTS]);
            this.setLabels('submenus.patients.patients', 'Patients', 'patientno', 'patientid');
            break;
          }
          case LinkKey.DOCTORS: {
            this.facadeService.callQueryListToUpdate(this.doctorsGQL, this.facadeService.filterData[LinkKey.DOCTORS]);
            this.secondProperty = 'firstname';
            this.setLabels('submenus.patients.doctors', 'Doctors', 'lastname', 'doctorid');
            break;
          }
          case LinkKey.EVALUATIONS: {
            this.setLabels('submenus.patients.evaluations', 'Evaluations', 'name', 'id');
            this.secondProperty = null;
            this.facadeService.setDataList({
              data: {
                Evaluations: [
                  {
                    id: 1,
                    name: this.translate.instant('patient.evaluations.numbersByCountries'),
                    key: ReportType.NUMBERS_BY_COUNTRIES,
                    translation: 'patient.evaluations.numbersByCountries'
                  },
                  {
                    id: 2,
                    name: this.translate.instant('patient.evaluations.intakeByMedicament'),
                    key: ReportType.INTAKE_PLAN_BY_MEDICAMENT,
                    translation: 'patient.evaluations.intakeByMedicament',
                  },
                  {
                    id: 3,
                    name: this.translate.instant('patient.evaluations.intakeByPeriod'),
                    key: ReportType.INTAKE_PLAN_BY_PERIOD,
                    translation: 'patient.evaluations.intakeByPeriod'
                  },
                  {
                    id: 4,
                    name: this.translate.instant('patient.evaluations.changingMedicament'),
                    key: ReportType.CHANGE_OF_MEDICAMENT,
                    translation: 'patient.evaluations.changingMedicament'
                  },
                  {
                    id: 5,
                    name: this.translate.instant('patient.evaluations.supportiveMedication'),
                    key: ReportType.SUPPORTIVE_MEDICAMENT,
                    translation: 'patient.evaluations.supportiveMedication'
                  },
                  {
                    id: 6,
                    name: this.translate.instant('patient.evaluations.login'),
                    key: ReportType.LOGIN,
                    translation: 'patient.evaluations.login'
                  },
                  {
                    id: 7,
                    name: this.translate.instant('patient.evaluations.pharmaciesTitle'),
                    key: ReportType.PHARMACIES,
                    translation: 'patient.evaluations.pharmaciesTitle'
                  },
                  {
                    id: 8,
                    name: this.translate.instant('patient.evaluations.employeesTitle'),
                    key: ReportType.EMPLOYEES,
                    translation: 'patient.evaluations.employeesTitle'
                  },
                  {
                    id: 9,
                    name: this.translate.instant('patient.evaluations.medicamentsTitle'),
                    key: ReportType.MEDICAMENTS,
                    translation: 'patient.evaluations.medicamentsTitle'
                  },
                ]
              }
            });
            break;
          }
          case LinkKey.COUNTRIES: {
            this.facadeService.callQueryListToUpdate(this.countriesGQL, this.facadeService.filterData[LinkKey.COUNTRIES]);
            this.setLabels('submenus.administration.countries', 'Countries', 'name', 'countryid');
            break;
          }
          case LinkKey.PATIENT_LANGUAGES: {
            this.facadeService.callQueryListToUpdate(this.patientLanguagesGQL, this.facadeService.filterData[LinkKey.PATIENT_LANGUAGES]);
            this.setLabels('submenus.administration.patientLanguages', 'PatientLanguages', 'language', 'languageid');
            break;
          }
          case LinkKey.USERS: {
            this.facadeService.callQueryListToUpdate(this.usersGQL, {
              ...this.facadeService.filterData[LinkKey.USERS],
              userId: this.authService.user.userid,
              countryId: this.authService.user.country.countryid
            });
            this.secondProperty = 'firstname';
            this.setLabels('submenus.administration.users', 'Users', 'lastname', 'userid');
            break;
          }
          case LinkKey.REGISTRATIONS: {
            this.facadeService.callQueryListToUpdate(this.registrationsGQL, this.facadeService.filterData[LinkKey.REGISTRATIONS]);
            this.secondProperty = 'firstname';
            this.setLabels('submenus.administration.registrations', 'Registrations', 'lastname', 'userid');
            break;
          }
          case LinkKey.TEXT: {
            this.facadeService.callQueryListToUpdate(this.textsGQL, this.facadeService.filterData[LinkKey.TEXT]);
            this.setLabels('submenus.administration.text', 'Texts', 'label', 'textid');
            break;
          }
          case LinkKey.PDF_TEXT: {
            this.facadeService.callQueryListToUpdate(this.pdfTextsGQL, this.facadeService.filterData[LinkKey.PDF_TEXT]);
            this.setLabels('submenus.administration.pdfText', 'PdfTexts', 'labelText', 'pdftextlangid');
            break;
          }
          case LinkKey.FILES: {
            this.facadeService.callQueryListToUpdate(this.filesGQL, this.facadeService.filterData[LinkKey.FILES]);
            this.setLabels('submenus.administration.files', 'Files', 'label', 'fileid');
            break;
          }
          case LinkKey.SPONSORS: {
            this.facadeService.callQueryListToUpdate(this.sponsorsGQL, {
              ...this.facadeService.filterData[LinkKey.SPONSORS],
              countryId: this.authService.user.country.countryid
            });
            this.setLabels('submenus.administration.sponsor', 'Sponsors', 'sponsor', 'sponsorid');
            break;
          }
          case LinkKey.PHARMACY: {
            this.facadeService.callQueryListToUpdate(this.pharmaciesGQL, {
              ...this.facadeService.filterData[LinkKey.PHARMACY],
              userId: this.authService.user.userid
            });
            this.setLabels('submenus.masterData.pharmacy', 'Pharmacies', 'name', 'pharmacyid');
            break;
          }
          case LinkKey.EMPLOYEES: {
            this.facadeService.callQueryListToUpdate(this.employeesGQL, {
              ...this.facadeService.filterData[LinkKey.EMPLOYEES],
              userId: this.authService.user.userid
            });
            this.secondProperty = 'firstname';
            this.setLabels('submenus.masterData.employees', 'Employees', 'lastname', 'apothekemaid');
            break;
          }
          case LinkKey.USER_ACCOUNT: {
            this.facadeService.setDataList({});
            this.facadeService.setDetailHidden(false);
            this.secondProperty = '';
            this.setLabels('', '', '', '');
            break;
          }
          default: {
            this.facadeService.setDataList({});
            this.secondProperty = '';
            this.setLabels('', '', '', '');
            break;
          }
        }
      }
    });
  }

  public currentUserLanguageId() {
    this.store.pipe(select(selectLanguageState)).subscribe((next: any) => {
      this.languageId = next.selectedLanguage.languageid;
    });
  }

  public changeSelected(selected) {
    const payload = {
      selected: {...selected}
    };
    this.selected[this.indexString] = selected[this.indexString];
    this.facadeService.setDetailHidden(false);
    if (!isNullOrUndefined(selected[this.indexString])) {
      switch (this.selectedRouterLink) {
        case LinkKey.MEDICAMENT: {
          this.store.dispatch(new LoadMedByIdAction({id: selected['medicamentid']}));
          break;
        }
        case LinkKey.SUBSTANCES: {
          this.store.dispatch(new SelectSubstanceAction(payload));
          break;
        }
        case LinkKey.SIDE_EFFECTS: {
          this.store.dispatch(new SelectSideEffectAction(payload));
          break;
        }
        case LinkKey.INTERACTIONS: {
          this.store.dispatch(new SelectInteractionAction(payload));
          break;
        }
        case LinkKey.INTAKE_NOTES: {
          this.store.dispatch(new SelectIntakeNotesAction(payload));
          break;
        }
        case LinkKey.OTHER_NOTES: {
          this.store.dispatch(new SelectOtherNotesAction(payload));
          break;
        }
        case LinkKey.ADDITIONAL_PREPARATIONS: {
          this.store.dispatch(new LoadAddByIdAction({id: selected['addpreparationid']}));
          // this.store.dispatch(new SelectAddPreparationsAction(payload));
          break;
        }
        case LinkKey.HISTORY: {
          this.store.dispatch(new LoadHistoryAction({
            selected: selected,
            variables: {
              tableId: selected['tableid'],
              objectId: selected['objectid']
            }
          }));
          break;
        }
        case LinkKey.PATIENTS: {
          this.store.dispatch(new LoadDetailByIdAction({
            object: 'Patient', query: this.patientGQL,
            action: SelectPatientAction, variables: {id: selected[this.indexString]}
          }));
          break;
        }
        case LinkKey.DOCTORS: {
          this.store.dispatch(new SelectDoctorAction(payload));
          break;
        }
        case LinkKey.EVALUATIONS: {
          this.facadeService.setEvaluation(selected);
          break;
        }
        case LinkKey.COUNTRIES: {
          this.store.dispatch(new SelectCountryAction(payload));
          break;
        }
        case LinkKey.PATIENT_LANGUAGES: {
          this.store.dispatch(new SelectPatientLanguagesAction(payload));
          break;
        }
        case LinkKey.USERS: {
          // this.store.dispatch(new SelectUserAction(payload));
          this.store.dispatch(new LoadDetailByIdAction({
            object: 'User', query: this.userGQL,
            action: SelectUserAction, variables: {id: selected[this.indexString]}
          }));
          break;
        }
        case LinkKey.TEXT: {
          this.store.dispatch(new SelectTextAction(payload));
          break;
        }
        case LinkKey.PDF_TEXT: {
          this.store.dispatch(new SelectPdfTextAction(payload));
          break;
        }
        case LinkKey.FILES: {
          this.store.dispatch(new LoadDetailByIdAction({
            object: 'File', query: this.fileGQL,
            action: SelectFileAction, variables: {id: selected[this.indexString]}
          }));
          break;
        }
        case LinkKey.SPONSORS: {
          this.store.dispatch(new SelectSponsorAction(payload));
          break;
        }
        case LinkKey.PHARMACY: {
          this.store.dispatch(new SelectPharmacyAction(payload));
          break;
        }
        case LinkKey.EMPLOYEES: {
          this.store.dispatch(new SelectEmployeeAction(payload));
          break;
        }
        case LinkKey.USER_ACCOUNT: {
          this.store.dispatch(new SelectEmployeeAction(payload));
          break;
        }
        case LinkKey.REGISTRATIONS: {
          // this.store.dispatch(new SelectUserAction(payload));
          this.store.dispatch(new LoadDetailByIdAction({
            object: 'User', query: this.userGQL,
            action: SelectUserAction, variables: {id: selected[this.indexString]}
          }));
          break;
        }
      }
    }
  }

  public setLabels(header: string, selectedList: string, displayProperty: string, indexString: string) {
    this.header = header;
    this.selectedList = selectedList;
    this.displayProperty = displayProperty;
    this.indexString = indexString;
  }

  public calculateDates(item: Sponsor) {
    const now = new Date();
    return new Date(item.datestart) <= now && now <= new Date(item.dateend);
  }
}
