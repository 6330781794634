import {Component, EventEmitter, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectLanguageState} from '../../../store/app.states';
import {ChangeLanguageAction, LangActionTypes} from '../../../store/actions/lang.actions';
import {UserLanguage} from '../../../models/user-language';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-lang-login',
  templateUrl: './lang-login.component.html',
  styleUrls: ['./lang-login.component.scss']
})
export class LangLoginComponent implements OnInit {
  @Output() languageSection = new EventEmitter<UserLanguage>();
  @Input() dispatchEvent?: boolean;
  languageState: any;
  selectedLanguage: UserLanguage;
  availableLang: Array<UserLanguage>;

  constructor(private store: Store<AppState>,
              public authService: AuthService) {
    this.languageState = this.store.pipe(select(selectLanguageState));
  }

  ngOnInit() {
    this.store.pipe(select(selectLanguageState)).subscribe((next: any) => {
      if (next.actionType === LangActionTypes.LOAD_USER_LANGUAGES) {
        this.availableLang = next.availableLang;
        this.selectedLanguage = next.selectedLanguage;

        if (!this.selectedLanguage) {
          this.selectedLanguage = this.availableLang.find(l => l.languageid === 2);
        }
      }
    });
  }

  public changeLang($event) {
    const langList = this.availableLang;
    const data = langList.find(l => l.languageid === $event);
    const payload = {
      selectedLanguage: data
    };
    this.selectedLanguage = data;
    if (this.dispatchEvent) {
      this.languageSection.emit(data);
    }
    this.store.dispatch(new ChangeLanguageAction(payload));
  }

  public get getFlag() {
    if (this.selectedLanguage) {
      return this.selectedLanguage.shortname;
    }else{
      return "";
    }
  }
}
