import {Interaction} from '../../models/interaction';
import {All, InteractionActions} from '../actions/interaction.actions';

/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
export interface InteractionState {
  selected: Interaction;
  data: Array<Interaction>;
}

export const initialState: InteractionState = {
  selected: null,
  data: []
};

export function reducer(state = initialState, action: All): InteractionState {
  switch (action.type) {
    case InteractionActions.LOAD_INTERACTION_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data[0]
      };
    }
    case InteractionActions.SELECT_INTERACTION: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
