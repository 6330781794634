import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';
import {OtherNote} from '../../models/other-note';

export enum OtherNotesAction {
  LOAD_OTHER_NOTES = '[OTHER_NOTES] Load',
  SELECT_OTHER_NOTES = '[OTHER_NOTES] Change Selected',
  LOAD_OTHER_NOTES_SUCCESS = '[OTHER_NOTES] Load success',
  LOAD_OTHER_NOTES_FAILURE = '[OTHER_NOTES] Load failure'
}

export class LoadOtherNotesAction implements Action {
  readonly type = OtherNotesAction.LOAD_OTHER_NOTES;
  constructor(public payload: PaginationData) {}
}

export class SelectOtherNotesAction implements Action {
  readonly type = OtherNotesAction.SELECT_OTHER_NOTES;

  constructor(public payload: any) {
  }
}

export class LoadOtherNotesSuccess implements Action {
  readonly type = OtherNotesAction.LOAD_OTHER_NOTES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadOtherNotesFailure implements Action {
  readonly type = OtherNotesAction.LOAD_OTHER_NOTES_FAILURE;

  constructor(public payload: any) {
  }
}
export type All =
  | LoadOtherNotesAction
  | SelectOtherNotesAction
  | LoadOtherNotesSuccess
  | LoadOtherNotesFailure;
