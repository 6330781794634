import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { Patient } from 'src/app/models/patient';
import { FacadeService } from 'src/app/services/facade.service';
import { PatientGQL, SideEffectsByIdGQL, UserLanguagesGQL } from '../../../api/graphql';
import { Medicament } from '../../../models/medicament';
import { MedicamentLabel } from '../../../models/request/medicament-label';
import { SideEffect } from '../../../models/side-effect';
import { UserLanguage } from '../../../models/user-language';
import { AuthService } from '../../../services/auth.service';
import { ShareService } from '../../../services/share.service';
import { LoadDetailByIdAction } from '../../../store/actions/common.actions';
import { SelectPatientAction } from '../../../store/actions/patient.actions';
import { AppState } from '../../../store/app.states';
import { TranslateLoginPipe } from '../../shared/pipes/translate-login.pipe';

@Component({
  selector: 'app-modal-print',
  templateUrl: './modal-print.component.html',
  styleUrls: ['./modal-print.component.scss'],
  providers: [TranslateLoginPipe],
})
export class ModalPrintComponent implements OnInit {
  public languageList: Array<UserLanguage>;
  public selectedLanguageId: number;
  public object: string;
  public objectId: number;
  public checkedSideEffects: number[] = [];
  public checkedOtherSideEffects: number[] = [];
  public sideEffects: Array<SideEffect>;
  public otherSideEffects: Array<SideEffect> = [];
  public titleReport: string;
  public patientId: number;
  public printObject: any;
  public patientName: string;
  public checkedMedicaments: number[] = [];
  public checkedNotes: number[] = [];
  public checkedDoctors: number[] = [];
  public checkedIntakePlans: number[] = [];
  public checkedDocs: number[] = [];
  public medicaments: Array<Medicament>;
  public printCoverPage: boolean;
  public printNoteSpace: boolean;
  public printAddInfo: boolean;
  public evaluationObject: any;
  public translatedText: any;
  public patient: Patient;
  public printAllPatientData: boolean = true;
  public sex: boolean = true;
  public language: boolean = true
  public yearOfBirth: boolean = true;
  public medicalConditions: boolean = true;
  public printAllNotes: boolean = false;
  public printAllDoctors: boolean = false;
  public printAllIntakePlans: boolean = false;
  public printAllDocs: boolean = false;
  public printAllMedicament: boolean = false;

  constructor(private store: Store<AppState>, private userLanguageGQL: UserLanguagesGQL,
    private _http: HttpClient, public authService: AuthService,
    private bsRef: BsModalService, private shareService: ShareService,
    private patientGQL: PatientGQL, private translate: TranslateService, private translateLogin :TranslateLoginPipe,
    protected facadeService: FacadeService, private sideEffectsByIdGQL: SideEffectsByIdGQL) {
    this.object = '';
    this.objectId = 0;
    this.titleReport = '';
    this.selectedLanguageId = 0;
    this.patientName = null;
    this.sideEffects = new Array<SideEffect>();
    if (this.bsRef.config.initialState['object']) {
      this.object = this.bsRef.config.initialState['object'];
      this.objectId = this.bsRef.config.initialState['objectId'];
      if (this.object === 'intakePlan') {
        this.patientId = this.bsRef.config.initialState['patientId'];
        this.printObject = this.bsRef.config.initialState['printObject'];
        this.medicaments = this.bsRef.config.initialState['medicamentIntakePlan'];
        this.checkedMedicaments = this.medicaments.map(m => {
          return m.medicamentid;
        });
      }
      if (this.object === 'evaluation') {
        this.evaluationObject = this.bsRef.config.initialState['evaluationObject'];
      }
      if (this.object === 'patientReport') {
        this.patient = this.bsRef.config.initialState['patientData'];
      }
      this.titleReport = this.bsRef.config.initialState['title'];
      this.selectedLanguageId = this.bsRef.config.initialState['selectedLanguageId'];
    }
  }

  ngOnInit() {
    this.languageList = this.facadeService.availableLanguages
    this.userLanguageGQL.watch({}
    ).result().then((data) => {
      this.languageList = data.data['UserLanguages'] as any;
    });
    this.selectedLanguageId = this.bsRef.config.initialState['selectedLanguageId'];
    if (this.object === 'intakePlan' || this.object === 'medicament') {
      this.sideEffectsByIdGQL.watch({ ids: this.checkedSideEffects, notIn: true }).result().then((next: any) => {
        this.otherSideEffects = next.data.SideEffectsById;
      });
    }
    this.printCoverPage = false;
    this.printNoteSpace = false;
    this.printAddInfo = false;
  }

  public onSubmit() {
    switch (this.object) {
      case 'sideEffect': {
        this.printSideEffect();
        break;
      }
      case 'medicament': {
        this.printMedicament();
        break;
      }
      case 'intakePlan': {
        this.printIntakePlan();
        break;
      }
      case 'patientReport': {
        this.printPatientReport();
      }
      default: {
        this.closeModal();
      }
    }
  }

  public printSideEffect() {

    const url = 'graphql/sideEffect';
    const _cascade = () => {
      this.closeModal();
    };
    let language = this.shareService.availableLang.find(t => t.languageid === this.selectedLanguageId);
    let translation = language ? language.shortname : null;
    const urlForTranslate = 'graphql/getTranslatedReport';
    const requestBody = {
      shortname: translation,
      languageId: this.selectedLanguageId
    };
    translation = translation ? translation : 'en';
    this.getTranslated(urlForTranslate, requestBody).then(async res => {
      this.translatedText = res.data;
      let next: any = await this.shareService.getTranslation(translation);

      const medicamentLabel: MedicamentLabel = {
        medicamentTitle: this.translatedText['printLeafletMedicamentTitle'],
        headerSideEffect: this.translatedText['printLeafletHeaderSideEffect'],
        titleInteraction: this.translatedText['printLeafletTitleInteraction'],
        titleIntakeNote: this.translatedText['printLeafletTitleIntakeNote'],
        titleOtherNote: this.translatedText['printLeafletTitleOtherNote'],
        titleInformationSideEffect: this.translatedText['printLeafletTitleInformationSideEffect'],
        telefon: this.translatedText['printLeafletTelefon'],
        mail: this.translatedText['printLeafletMail'],
        formatDate: this.translatedText['printLeafletFormatDate'],
        page: this.translatedText['printLeafletPage'],
        sideEffectText: this.translatedText['printLeafletSideEffectText'],
        interactionText: this.translatedText['printLeafletInteractionText'],
        intakeNoteText: this.translatedText['printLeafletIntakeNoteText'],
        otherNoteText: this.translatedText['printLeafletOtherNoteText'],
      };
      const requestBody = {
        userId: this.authService.user.userid, sideEffectId: this.objectId,
        languageId: this.selectedLanguageId, pharmacyId: this.authService.user.pharmacyid,
        countryId: this.authService.user.country.countryid,
        medicamentLabel: medicamentLabel
      };
      this.pdfReport(url, requestBody).then(res => {
        this.afterDownload(res, _cascade);
      });
    });
  }

  public printMedicament() {
    const url = 'graphql/medicamentReport';
    const _cascade = () => {
      this.closeModal();
    };
    let language = this.shareService.availableLang.find(t => t.languageid === this.selectedLanguageId);
    let translation = language ? language.shortname : null;
    const urlForTranslate = 'graphql/getTranslatedReport';
    const requestBody = {
      shortname: translation,
      languageId: this.selectedLanguageId
    };
    translation = translation ? translation : 'en';
    this.getTranslated(urlForTranslate, requestBody).then(async res => {
      this.translatedText = res.data;
      let next: any = await this.shareService.getTranslation(translation);
      const medicamentLabel: MedicamentLabel = {
        medicamentTitle: this.translatedText['printLeafletMedicamentTitle'],
        headerSideEffect: this.translatedText['printLeafletHeaderSideEffect'],
        titleInteraction: this.translatedText['printLeafletTitleInteraction'],
        titleIntakeNote: this.translatedText['printLeafletTitleIntakeNote'],
        titleOtherNote: this.translatedText['printLeafletTitleOtherNote'],
        titleInformationSideEffect: this.translatedText['printLeafletTitleInformationSideEffect'],
        telefon: this.translatedText['printLeafletTelefon'],
        mail: this.translatedText['printLeafletMail'],
        formatDate: this.translatedText['printLeafletFormatDate'],
        page: this.translatedText['printLeafletPage'],
        sideEffectText: this.translatedText['printLeafletSideEffectText'],
        interactionText: this.translatedText['printLeafletInteractionText'],
        intakeNoteText: this.translatedText['printLeafletIntakeNoteText'],
        otherNoteText: this.translatedText['printLeafletOtherNoteText'],
      };
      const requestBody = {
        userId: this.authService.user.userid, medicamentId: this.objectId,
        languageId: this.selectedLanguageId, pharmacyId: this.authService.user.pharmacyid,
        countryId: this.authService.user.country.countryid,
        sideEffectId: this.checkedSideEffects.concat(...this.checkedOtherSideEffects),
        medicamentLabel: medicamentLabel,
      };
      this.pdfReport(url, requestBody).then(res => {
        this.afterDownload(res, _cascade);
      });
    });
  }

  public printPatientReport() {
    const url = 'graphql/paitentReport';
    const _cascade = () => {
      this.closeModal();
    };
    let language = this.shareService.availableLang.find(t => t.languageid === this.selectedLanguageId);
    let translation = language ? language.shortname : null;
    const urlForTranslate = 'graphql/getTranslatedReport';
    const requestBody = {
      shortname: translation,
      languageId: this.selectedLanguageId
    };
    translation = translation ? translation : 'en';
    this.getTranslated(urlForTranslate, requestBody).then(async res => {
      this.translatedText = res.data;
      let next: any = await this.shareService.getTranslation(translation);

      const patientReportLabel: any = {
        patientData: this.translate.instant("common.labels.patientData"),
        name: this.translate.instant("administration.user.name"),
        language: this.translate.instant("administration.user.language"),
        sex: this.translate.instant("common.labels.sex"),
        yearOfBirth: this.translate.instant("patient.patients.birthyear"),
        medicalCondition: this.translate.instant("patient.patients.disease"),
        memo: this.translate.instant("oraliadb.medicament.notes"),
        medicalPractitioners: this.translate.instant("patient.doctors.doctor"),
        phone: this.translate.instant("common.labels.phone"),
        fax: this.translate.instant("common.labels.fax"),
        mail: this.translate.instant("common.labels.mail"),
        mainPrescriber: this.translate.instant("patient.patients.supervision"),
        administrationSchedules: this.translate.instant("patient.patients.intakePlan"),
        medicament: this.translate.instant("common.labels.medicament"),
        strength: this.translate.instant("oraliadb.medicament.strength"),
        prescription: this.translate.instant("modals.newStrength.form.prescription"),
        intakeDays: this.translate.instant("modals.newStrength.form.intakedays"),
        dayTime: this.translate.instant("common.labels.dayTimeQuantity"),
        docs: this.translate.instant("patient.patients.documentationOfPharmaceuticalSupport"),
        protocol: this.translate.instant("patient.patients.protocol"),
        sideEffect: this.translate.instant("oraliadb.sideEffects.sideEffect"),
        level: this.translate.instant("oraliadb.sideEffects.gradDocumentation"),
        circumstances: this.translate.instant("patient.patients.explanation"),
        actionRecommendations: this.translate.instant("patient.patients.measures"),
        productsSold: this.translate.instant("patient.patients.soldPreparations"),
        medication: this.translate.instant("patient.patients.medication"),
        male: this.translate.instant("common.sex.male"),
        female: this.translate.instant("common.sex.female"),
      };
      const patientLanguage = this.facadeService.userTranslationLanguages.find(t => {
        return t.languageid === this.patient.languageid as unknown as number;
      })
      const translatedValue = this.translateLogin.transform(
        patientLanguage,
        'language',
        this.authService.userLanguage.languageid
      );
      const requestBody = {
        languageid: this.authService.user.userLanguage.languageid,
        patientId: this.patient.patientid,
        patientLanguage: translatedValue,
        name: this.patientName,
        sex: this.sex,
        language: this.language,
        yearOfBirth: this.yearOfBirth,
        medicalConditions: this.medicalConditions,
        noteIds: this.checkedNotes,
        patientDoctorIds: this.checkedDoctors,
        intakePlanIds: this.checkedIntakePlans,
        docIds: this.checkedDocs,
        mediacamentIds: this.checkedMedicaments,
        pharmacyId: this.authService.user.pharmacyid,
        countryId: this.authService.user.country.countryid,
        patientReportLabels: patientReportLabel
      };
      this.pdfReport(url, requestBody).then(res => {
        res.filename = this.patientName+".pdf"
        this.afterDownload(res, _cascade);
      });
    });

  }

  public printIntakePlan() {
    let intakePlanDates = [...this.bsRef.config.initialState['intakeDates']];
    intakePlanDates = intakePlanDates.map(t => {
      return {
        ...t,
        intakePlanDateTimes: t.intakePlanDateTimes.map(u => {
          return {
            ...u,
            intakePlanMedicaments: sortBy(u.intakePlanMedicaments, (o) => o.medicament.shortname + o.strength),
            intakePlanPreparations: sortBy(u.intakePlanPreparations, (o) => o.addPreparation.label + + o.strength),
          };
        })
      };
    });

    let language = this.shareService.availableLang.find(t => t.languageid === this.selectedLanguageId);
    let translation = language ? language.shortname : null;
    const urlForTranslate = 'graphql/getTranslatedReport';
    const requestBody = {
      shortname: translation,
      languageid: this.selectedLanguageId
    };
    translation = translation ? translation : 'en';
    let countryCode = translation;
    this.getTranslated(urlForTranslate, requestBody).then(async res => {
      this.translatedText = res.data;
      let next: any = await this.shareService.getTranslation(translation);
      translation = next && next.translationCode ? next.translationCode : translation;
      intakePlanDates = this.shareService.getPrintIntakePlanFromObj(intakePlanDates, this.selectedLanguageId, countryCode, next['reports.dailyFormatDate']);
      this.printObject.fromDate = this.shareService.getDateByLanguage(this.printObject.fromDate, translation);
      this.printObject.toDate = this.shareService.getDateByLanguage(this.printObject.toDate, translation);
      let subtitle = this.translatedText['printIntakePlanSubtitleFromDateToDatePatient'].replace('{{fromDate}}', this.printObject.fromDate);
      subtitle = subtitle.replace('{{toDate}}', this.printObject.toDate);
      const dateRange = this.translatedText['printIntakePlanSubtitleDateRange']
        .replace('{{fromDate}}', this.printObject.fromDate)
        .replace('{{toDate}}', this.printObject.toDate);
      subtitle = subtitle.replace('{{patient}}', this.printObject.patient);
      subtitle = subtitle + (this.patientName ? ` ${this.patientName}` : '');
      const printObject = {
        title: this.translatedText['intakePlan'] ? this.translatedText['intakePlan'] : 'Administration Schedule',
        subtitle: subtitle,
        printCoverPage: this.printCoverPage,
        printNoteSpace: this.printNoteSpace,
        printAddInfo: this.printAddInfo,
        dateRange: dateRange,
        forLabel: this.translatedText['printIntakePlanSubtitleFor'],
        patient: (this.patientName ? `${this.patientName}` : this.translatedText['printIntakePlanSubtitlePatient'].replace('{{patient}}', this.printObject.patient)),
        headerSideEffect: this.translatedText['printIntakePlanInformationSideEffects'],
        date: this.translatedText['printIntakePlanTableDate'],
        hrs: this.translatedText['printIntakePlanTableHrs'],
        medicament: this.translatedText['printIntakePlanTableMedicament'],
        condition: this.translatedText['printIntakePlanTableCondition'],
        sideEffect: this.translatedText['printIntakePlanTableSideEffect'],
        important: this.translatedText['printIntakePlanLegendImportant'],
        smiles: this.translatedText['printIntakePlanLegendSmiles'],
        legendSideEffect: this.translatedText['printIntakePlanLegendSideEffect'],
        veryBad: this.translatedText['printIntakePlanLegendVeryBad'],
        notWell: this.translatedText['printIntakePlanLegendNotWell'],
        normal: this.translatedText['printIntakePlanLegendNormal'],
        fine: this.translatedText['printIntakePlanLegendFine'],
        veryWell: this.translatedText['printIntakePlanLegendVeryWell'],
        noIntakes: this.translatedText['printIntakePlanTableNoIntakes'],
        sideEffect1: this.translatedText['printIntakePlanLegendSideEffect1'],
        sideEffect2: this.translatedText['printIntakePlanLegendSideEffect2'],
        sideEffect3: this.translatedText['printIntakePlanLegendSideEffect3'],
        patientInformationFor: this.translatedText['printIntakePlanLegendPatientInformationFor'],
        notes: this.translatedText['printIntakePlanNotes'],
        addInfo: this.translatedText['printIntakePlanAddInfo'],
      };
      const medicamentLabel: MedicamentLabel = {
        medicamentTitle: this.translatedText['printLeafletMedicamentTitle'],
        headerSideEffect: this.translatedText['printLeafletHeaderSideEffect'],
        titleInteraction: this.translatedText['printLeafletTitleInteraction'],
        titleIntakeNote: this.translatedText['printLeafletTitleIntakeNote'],
        titleOtherNote: this.translatedText['printLeafletTitleOtherNote'],
        titleInformationSideEffect: this.translatedText['printLeafletTitleInformationSideEffect'],
        telefon: this.translatedText['printLeafletTelefon'],
        mail: this.translatedText['printLeafletMail'],
        formatDate: this.translatedText['printLeafletFormatDate'],
        page: this.translatedText['printLeafletPage'],
        sideEffectText: this.translatedText['printLeafletSideEffectText'],
        interactionText: this.translatedText['printLeafletInteractionText'],
        intakeNoteText: this.translatedText['printLeafletIntakeNoteText'],
        otherNoteText: this.translatedText['printLeafletOtherNoteText'],
      };
      const requestBody = {
        userId: this.authService.user.userid,
        intakePlanId: this.objectId,
        patientId: this.patientId,
        languageId: this.selectedLanguageId,
        pharmacyId: this.authService.user.pharmacyid,
        countryId: this.authService.user.country.countryid,
        countryLangId: this.authService.user.country.languageid,
        sideEffectId: this.checkedSideEffects.concat(...this.checkedOtherSideEffects),
        printObject: printObject,
        intakePlanDates: intakePlanDates,
        medicamentIds: this.checkedMedicaments,
        medicamentLabel: medicamentLabel,
        medicamentShortNotices: this.bsRef.config.initialState['medicamentShortNotices']
      };
      this.titleReport = this.translate.instant('patient.patients.intakePlan') + ` ${this.printObject.patient}`;
      const url = 'graphql/intakeplanReport';
      const _cascade = () => {
        this.store.dispatch(new LoadDetailByIdAction({
          object: 'Patient', query: this.patientGQL,
          action: SelectPatientAction, variables: { id: this.patientId }
        }));
        this.closeModal();
      };
      this.pdfReport(url, requestBody).then(res => {
        this.afterDownload(res, _cascade);
      });
    });
  }

  public afterDownload(res, _cascade: () => void) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        res.data, res.filename);
    } else {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = res.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    }
    if (_cascade) {
      _cascade();
    }
  }

  public closeModal() {
    this.bsRef.hide(1);
  }

  public doSelect(value: any) {
    this.selectedLanguageId = value;
  }

  public updateChecked(selectableItem, $event) {
    const index = this.checkedSideEffects.indexOf(selectableItem['sideeffectid']);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedSideEffects.push(selectableItem['sideeffectid']);
      }
    } else {
      if (index !== -1) {
        this.checkedSideEffects.splice(index, 1);
      }
    }
  }

  public updateOtherChecked(selectableItem, $event) {
    const index = this.checkedOtherSideEffects.indexOf(selectableItem['sideeffectid']);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedOtherSideEffects.push(selectableItem['sideeffectid']);
      }
    } else {
      if (index !== -1) {
        this.checkedOtherSideEffects.splice(index, 1);
      }
    }
  }

  public updateMedicamentChecked(selectableItem, $event) {
    const index = this.checkedMedicaments.indexOf(selectableItem['medicamentid']);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedMedicaments.push(selectableItem['medicamentid']);
      }
    } else {
      if (index !== -1) {
        this.checkedMedicaments.splice(index, 1);
      }
    }
  }

  public selectAllSideEffects() {
    this.checkedSideEffects = [...this.sideEffects.map((s: SideEffect) => s.sideeffectid)];
  }

  public selectAllOtherSideEffects() {
    this.checkedOtherSideEffects = [...this.otherSideEffects.map((s: SideEffect) => s.sideeffectid)];
  }

  public uncheckAllSideEffects() {
    this.checkedSideEffects = [];
  }

  public uncheckAllOtherSideEffects() {
    this.checkedOtherSideEffects = [];
  }

  public selectAllMedicaments() {
    this.checkedMedicaments = [...this.medicaments.map((s: Medicament) => s.medicamentid)];
  }

  public uncheckAllMedicaments() {
    this.checkedMedicaments = [];
  }

  public pdfReport(urlmutation, requestBody) {
    return this._http.post(urlmutation, requestBody, {
      responseType: 'blob',
      params: {}
    })
      .map(res => {
        return {
          filename: this.titleReportObject(),
          data: res
        };
      })
      .toPromise();
  }

  public getTranslated(urlmutation, requestBody) {
    return this._http.post(urlmutation, requestBody, {
      responseType: 'json',
      params: {}
    })
      .map(res => {
        return {
          data: res,
        };
      })
      .toPromise();
  }

  public titleReportObject(): string {
    let title = '';
    switch (this.object) {
      case 'medicament':
      case 'sideEffect': {
        title = this.translate.instant('oraliadb.sideEffects.leaflet');
        return title + ' ' + this.titleReport + '.pdf';
      }
      case 'evaluation': {
        title = this.translate.instant(this.evaluationObject.translation);
        return title + '.pdf';
      }
    }
    return this.titleReport + '.pdf';
  }

  public selectAllPatientData(){
    this.printAllPatientData = !this.printAllPatientData;
    this.sex = this.printAllPatientData;
    this.language = this.printAllPatientData;
    this.yearOfBirth = this.printAllPatientData;
    this.medicalConditions = this.printAllPatientData;
  }

  public changeAllPatientData(value:any){
    if (value == false) {
      this.printAllPatientData = false;
    }
    if (this.sex == true && this.language == true && this.yearOfBirth == true && this.medicalConditions == true) {
      this.printAllPatientData = true;
    }
  }

  public selectAllNotes(){
    this.printAllNotes = !this.printAllNotes;
    if (this.printAllNotes) {
      this.checkedNotes = [];
      for (const note of this.patient.notes) {
          this.checkedNotes.push(note.noteid);
      }
    }else{
      this.checkedNotes = [];
    }
  }

  public changeNoteList(noteid, $event) {
    const index = this.checkedNotes.indexOf(noteid);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedNotes.push(noteid);
      }
    } else {
      if (index !== -1) {
        this.checkedNotes.splice(index, 1);
        this.printAllNotes = false;
      }
    }
    if (this.checkedNotes.length == this.patient.notes.length) {
      this.printAllNotes = true;
    }
  }

  public selectAllDoctors(){
    this.printAllDoctors = !this.printAllDoctors;
    if (this.printAllDoctors) {
      this.checkedDoctors = [];
      for (const doctor of this.patient.patientDoctors) {
        this.checkedDoctors.push(doctor.patientdoctorid);
      }
    }else{
      this.checkedDoctors = [];
    }
  }

  public changeDoctorsList(patientdoctorid, $event) {
    const index = this.checkedDoctors.indexOf(patientdoctorid);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedDoctors.push(patientdoctorid);
      }
    } else {
      if (index !== -1) {
        this.checkedDoctors.splice(index, 1);
        this.printAllDoctors = false;
      }
    }
    if (this.checkedDoctors.length == this.patient.patientDoctors.length) {
      this.printAllDoctors = true;
    }
  }

  public selectAllIntakePlan(){
    this.printAllIntakePlans = !this.printAllIntakePlans;
    if (this.printAllIntakePlans) {
      this.checkedIntakePlans = [];
      for (const intakePlan of this.patient.intakePlans) {
        this.checkedIntakePlans.push(intakePlan.planid);
      }
    }else{
      this.checkedIntakePlans = [];
    }
  }

  public changeIntakePlanList(planid, $event) {
    const index = this.checkedIntakePlans.indexOf(planid);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedIntakePlans.push(planid);
      }
    } else {
      if (index !== -1) {
        this.checkedIntakePlans.splice(index, 1);
        this.printAllIntakePlans = false;
      }
    }
    if (this.checkedIntakePlans.length == this.patient.intakePlans.length) {
      this.printAllIntakePlans = true;
    }
  }

  public selectAllDocs(){
    this.printAllDocs = !this.printAllDocs;
    if (this.printAllDocs) {
      this.checkedDocs = [];
      for (const doc of this.patient.docs) {
        this.checkedDocs.push(doc.docid);
      }
    }else{
      this.checkedDocs = [];
    }
  }

  public changeDocsList(docid, $event) {
    const index = this.checkedDocs.indexOf(docid);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedDocs.push(docid);
      }
    } else {
      if (index !== -1) {
        this.checkedDocs.splice(index, 1);
        this.printAllDocs = false;
      }
    }
    if (this.checkedDocs.length == this.patient.docs.length) {
      this.printAllDocs = true;
    }
  }

  public selectAllMedicament(){
    this.printAllMedicament = !this.printAllMedicament;
    if (this.printAllMedicament) {
      this.checkedMedicaments = [];
      for (const intakePlan of this.patient.intakePlans) {
        this.checkedMedicaments.push(intakePlan.planid);
      }
    }else{
      this.checkedMedicaments = [];
    }
  }

  public changeMedicamentList(planid, $event) {
    const index = this.checkedMedicaments.indexOf(planid);
    if ($event.target.checked) {
      if (index === -1) {
        this.checkedMedicaments.push(planid);
      }
    } else {
      if (index !== -1) {
        this.checkedMedicaments.splice(index, 1);
        this.printAllMedicament = false;
      }
    }
    if (this.checkedMedicaments.length == this.patient.intakePlans.length) {
      this.printAllMedicament = true;
    }
  }
}
