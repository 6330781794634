import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicamentsComponent } from './medicaments/medicaments.component';
// import {OraliaDbRoutingModule} from './oralia-db-routing.module';
import { HistoryComponent } from './history/history.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundComponent } from './medicaments/modals/background/background.component';
import { NoteComponent } from './medicaments/modals/note/note.component';
import { LeafletComponent } from './medicaments/modals/leaflet/leaflet.component';
import { StrengthComponent } from './medicaments/modals/strength/strength.component';
import { MedCheckBoxComponent } from './medicaments/modals/side-effect/med-check-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { DateValueAccessorModule } from 'angular-date-value-accessor';
import { AccordionModule, BsDatepickerModule, TooltipModule } from 'ngx-bootstrap';
import { EvaluationsComponent } from './evaluations/evaluations.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { PatientNotaComponent } from './patients/modals/patient-nota/patient-nota.component';
import { PatientMedicamentComponent } from './patients/modals/patient-medicament/patient-medicament.component';
import { InjectorService } from '../../services/injector.service';
import { NgxSelectModule } from 'ngx-select-ex';
import { PatientDocumentationComponent } from './patients/modals/patient-documentation/patient-documentation.component';
import { IntakePlanComponent } from './patients/intake-plan/intake-plan.component';
import { PatientsComponent } from './patients/patients.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { FileSizeModule } from 'ngx-filesize';
import { AddUserCountryComponent } from './patient-languages/modal/add-user-country/add-user-country.component';
import { RejectComponent } from './registrations/reject/reject.component';
import { DoctorEmailComponent } from './patients/modals/doctor-email/doctor-email.component';
import { FilesComponent } from './files/files.component';
import { PatientDailyComponent } from './patients/modals/patient-daily/patient-daily.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { SponsorViewComponent } from './medicaments/sponsor-view/sponsor-view.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CustomPopupFourteenComponent } from './custom-popup-fourteen/custom-popup-fourteen.component';
import { CustomPopupTwentyOneComponent } from './custom-popup-twenty-one/custom-popup-twenty-one.component';
import { CustomPopupTwentyTwoComponent } from './custom-popup-twenty-two/custom-popup-twenty-two.component';
import { CopyMedicamentComponent } from './medicaments/modals/copy-medicament/copy-medicament.component';

@NgModule({
  declarations: [MedicamentsComponent, BackgroundComponent,
    HistoryComponent, NoteComponent, LeafletComponent, StrengthComponent, MedCheckBoxComponent,
    EvaluationsComponent,
    RegistrationsComponent,
    PatientsComponent, DoctorsComponent,
    PatientNotaComponent,
    PatientMedicamentComponent,
    PatientDocumentationComponent,
    IntakePlanComponent,
    AddUserCountryComponent,
    RejectComponent,
    DoctorEmailComponent,
    FilesComponent,
    PatientDailyComponent,
    SponsorComponent,
    SponsorViewComponent,
    CustomPopupFourteenComponent,
    CustomPopupTwentyOneComponent,
    CustomPopupTwentyTwoComponent,
    CopyMedicamentComponent],
  imports: [
    CommonModule,
    // OraliaDbRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    // Replace CKEditor
    QuillModule,
    DateValueAccessorModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    NgxCoolDialogsModule.forRoot(),
    NgxSelectModule.forRoot(<any>{
      keyCodeToOptionsOpen: 'Space'
    }),
    FileSizeModule,
    NgApexchartsModule,
  ],
  exports: [
    MedicamentsComponent, BackgroundComponent, HistoryComponent, PatientsComponent, DoctorsComponent,
    PatientNotaComponent, PatientMedicamentComponent, PatientDocumentationComponent, PatientDailyComponent,
    IntakePlanComponent, SponsorViewComponent, CustomPopupFourteenComponent, CustomPopupTwentyOneComponent, CustomPopupTwentyTwoComponent],
  entryComponents: [RegistrationsComponent,
    MedicamentsComponent, BackgroundComponent, NoteComponent, LeafletComponent, PatientNotaComponent, StrengthComponent, MedCheckBoxComponent,
    PatientNotaComponent, PatientsComponent, DoctorsComponent,
    HistoryComponent, NoteComponent, LeafletComponent, StrengthComponent, MedCheckBoxComponent,
    EvaluationsComponent, PatientMedicamentComponent, PatientDocumentationComponent, PatientDailyComponent,
    AddUserCountryComponent, RejectComponent, DoctorEmailComponent, FilesComponent, SponsorComponent, CopyMedicamentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [InjectorService]
})

export class OraliaDbModule {
}
