import {Validators} from '@angular/forms';

export class Employee {
  apothekemaid?: number;
  countryid?: number;
  pharmacyid?: number;
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  salutation?: number; // smallint(6) DEFAULT NULL,
  shortname?: string;
  status?: number; // smallint(6) NOT NULL,
  title?: string;
  userid?: number;
  isSelected?: boolean;
  isPharmacy?: boolean;
  professionalgroup?: string;
}

export class EmployeeValidate {
  public static rules = {
    ['email']: [Validators.required, Validators.email, Validators.maxLength(255)],
    ['firstname']: [Validators.required, Validators.maxLength(60)],
    ['lastname']: [Validators.required, Validators.maxLength(60)],
    ['phone']: [Validators.maxLength(40)],
    ['shortname']: [Validators.maxLength(60)],
    ['salutation']: [Validators.required],
    ['professionalgroup']: [Validators.required]
  };
}
