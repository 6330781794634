import {LoginFormState} from '../../models/enums/login-form-state';
import {All, LoginActions} from '../actions/login.actions';
/**
 * @author Jonathan Almanza
 * 21/11/2018
 */
export interface State {
  loginFormState: LoginFormState;
}
export const initialState: State = {
  loginFormState: LoginFormState.LOGIN
};

export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case LoginActions.CHANGE: {
      return {
        ...state,
        loginFormState: action.payload.loginFormState
      };
    }
    default: {
      return state;
    }
  }
}
