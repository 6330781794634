import {IntakeAddPrep} from './intake-add-prep';
import {IntakeDoc} from './intake-doc';
import {IntakeMed} from './intake-med';
import {IntakePlanDate} from './intake-plan-date';

export class IntakePlan {
  planid?: number;
  datefrom?: string | any;
  dateto?: string | any;
  planperpatient?: number;
  status?: number;
  intakeAddPreps?: Array<IntakeAddPrep>;
  intakeDocs?: Array<IntakeDoc>;
  intakeMeds?: Array<IntakeMed>;
  intakePlanDates?: Array<IntakePlanDate>;
  pharmacyid?: number;
  patientid?: number;
  countryid?: number;
}
