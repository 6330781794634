import {SideEffect} from './side-effect';

export class PatientDocSide {
  doceffectid?: number;
  docid?: number;
  doctorreference?: number; //  smallint(6) NOT NULL,
  level?: number; // smallint(6) NOT NULL,
  measures?: string;
  note?: string;
  soldpreparates?: string;
  sideEffect?: SideEffect;
  patientid?: number;
  pharmacyid?: number;
  countryid?: number;
}
