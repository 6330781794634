import {All, PatientDoctorsActions} from '../actions/patient-doctors.actions';
import {PatientDoctor} from '../../models/patient-doctor';


/**
 * @author Jonathan Almanza
 * 02/12/2018
 */
export interface PatientDoctorsState {
  selected: PatientDoctor;
}

export const initialState: PatientDoctorsState = {
  selected: null
};

export function reducer(state = initialState, action: All): PatientDoctorsState {
  switch (action.type) {
    case PatientDoctorsActions.LOAD_PATIENT_DOCTORS_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case PatientDoctorsActions.SELECT_PATIENT_DOCTORS: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
