import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
/**
 * @author Jonathan Almanza
 * 26/11/2018
 */

@Injectable()
export class SubMenuEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private apollo: Apollo
  ) {
  }
}
