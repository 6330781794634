import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {UserLanguage} from '../models/user-language';

@Injectable()
export class AuthService {
  private _user: User;

  constructor() {
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getRole(): string {
    return localStorage.getItem('role');
  }

  public getCurrentUser() {
    return `${this._user.firstname} ${this._user.lastname}`;
  }

  public get user(): User {
    return this._user;
  }

  public set user(args: User) {
    this._user = args;
  }

  public get userLanguage(): UserLanguage {
    return this._user.userLanguage;
  }
  public setCookie(cName, cValue) {
    const d = new Date();
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
    document.cookie = `${cName} = ${cValue} ; expires = ${d.toUTCString()} + ';path=/`;
  }
}
