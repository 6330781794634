import {Country} from '../../models/country';
import {All, EmployeeActions} from '../actions/employee.actions';
import {Employee} from '../../models/employee';

export interface EmployeeState {
  selected: Employee;
}

export const initialState: EmployeeState = {
  selected: null
};

export function reducer(state = initialState, action: All): EmployeeState {
  switch (action.type) {
    case EmployeeActions.SELECT_EMPLOYEE: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
