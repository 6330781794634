export enum LinkKey {
  // ORALIA_DB MODULE
  MEDICAMENT =  'medicament',
  SUBSTANCES = 'substances',
  SIDE_EFFECTS = 'sideEffects',
  INTERACTIONS = 'interactions',
  INTAKE_NOTES = 'intakeNotes',
  OTHER_NOTES = 'otherNotes',
  ADDITIONAL_PREPARATIONS = 'additionalPreparations',
  HISTORY = 'history',
  // PATIENTS MODULE
  PATIENTS = 'patients',
  DOCTORS = 'doctors',
  EVALUATIONS = 'evaluations',
  // ADMINISTRATION MODULE
  COUNTRIES = 'countries',
  PATIENT_LANGUAGES = 'patientLanguages',
  USERS = 'users',
  REGISTRATIONS = 'registrations',
  TEXT = 'text',
  PDF_TEXT = 'pdfText',
  FILES = 'files',
  SPONSORS = 'sponsors',
  // MASTER DATA MODULE
  PHARMACY = 'pharmacy',
  EMPLOYEES = 'employees',
  USER_ACCOUNT = 'userAccount',
}
