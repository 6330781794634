import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';
import {IntakeNote} from '../../models/intake-note';

export enum IntakeNotesActions {
  LOAD_INTAKE_NOTES = '[INTAKE_NOTES] LOAD_INTAKE_NOTES',
  SELECT_INTAKE_NOTES = '[INTAKE_NOTES] SELECT_INTAKE_NOTES',
  LOAD_INTAKE_NOTES_SUCCESS = '[INTAKE_NOTES] LOAD_INTAKE_NOTES_SUCCESS',
  LOAD_INTAKE_NOTES_FAILURE = '[INTAKE_NOTES] LOAD_INTAKE_NOTES_FAILURE'
}

export class LoadIntakeNotesAction implements Action {
  readonly type = IntakeNotesActions.LOAD_INTAKE_NOTES;
  constructor(public payload: PaginationData) {}
}

export class SelectIntakeNotesAction implements Action {
  readonly type = IntakeNotesActions.SELECT_INTAKE_NOTES;

  constructor(public payload: any) {
  }
}

export class LoadIntakeNotesSuccess implements Action {
  readonly type = IntakeNotesActions.LOAD_INTAKE_NOTES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadIntakeNotesFailure implements Action {
  readonly type = IntakeNotesActions.LOAD_INTAKE_NOTES_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadIntakeNotesAction
  | SelectIntakeNotesAction
  | LoadIntakeNotesSuccess
  | LoadIntakeNotesFailure;
