/**
 * @author Jonathan Almanza
 * 21/11/2018
 */
import {Action} from '@ngrx/store';


export enum LangActionTypes {
  CHANGE = '[Lang] Change language',
  LOAD_TRANSLATION_SUCCESS = '[Lang] Load translations Success',
  LOAD_TRANSLATION_FAIL = '[Lang] Load translations Failure',
  LOAD_LANGUAGES = '[Lang] Load Languages',
  LOAD_USER_LANGUAGES = '[Lang] Load User Languages',
  LOAD_TRANSLATION_LANGUAGES = '[Lang] Load Translation Languages'
}

export class ChangeLanguageAction implements Action {
  readonly type = LangActionTypes.CHANGE;

  constructor(public payload: any) {
  }
}

export class LoadTranslationSuccessAction implements Action {
  readonly type = LangActionTypes.LOAD_TRANSLATION_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadTranslationFailureAction implements Action {
  readonly type = LangActionTypes.LOAD_TRANSLATION_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadLanguagesAction implements Action {
  readonly type = LangActionTypes.LOAD_LANGUAGES;

  constructor() {
  }
}

export class LoadUserLanguagesAction implements Action {
  readonly type = LangActionTypes.LOAD_USER_LANGUAGES;

  constructor() {
  }
}

export class LoadTranslationLanguageAction implements Action {
  readonly type = LangActionTypes.LOAD_TRANSLATION_LANGUAGES;

  constructor(public payload:any) {
  }
}

export type All =
  | ChangeLanguageAction
  | LoadTranslationSuccessAction
  | LoadTranslationFailureAction;
