import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {Doctor, DoctorValidate} from '../../../models/doctor';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {select, Store} from '@ngrx/store';
import {AppState, selectDoctorReducer} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {SaveDoctorGQL} from '../../../api/graphql';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_PATIENTS} from '../../../config/sub-menu';
import {SelectDoctorAction} from '../../../store/actions/doctor.actions';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent extends FormAbstract<Doctor> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public isComingFromPatient: boolean;

  constructor(protected store: Store<AppState>, private  translate: TranslateService,
              protected bsRef: BsModalService, protected shareService: ShareService,
              public facadeService: FacadeService, protected authService: AuthService, private saveDoctorGQL: SaveDoctorGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.isComingFromPatient = false;
    this.baseFields = [
      {field: 'doctorid'},
      {field: 'city', validator: DoctorValidate.rules.city},
      {field: 'fax', validator: DoctorValidate.rules.fax},
      {field: 'firstname', validator: DoctorValidate.rules.firstname},
      {field: 'lastname', validator: DoctorValidate.rules.lastname},
      {field: 'mail', validator: DoctorValidate.rules.email},
      {field: 'pharmacyid'},
      {field: 'phone', validator: DoctorValidate.rules.phone},
      {field: 'sex'},
      {field: 'street', validator: DoctorValidate.rules.street},
      {field: 'title', validator: DoctorValidate.rules.title},
      {field: 'zip', validator: DoctorValidate.rules.zip}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'doctorid',
      translationId: ''
    };
    /**
     * @NGx-Bootstrap Modal
     */
    this.languageId = this.authService.userLanguage.languageid;
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.isComingFromPatient = this.bsRef.config.initialState['isComingFromPatient'];
      if (this.action === ActionStateEnum.EDIT) {
        this.baseObject = this.bsRef.config.initialState['data'];
        this.prepareForm();
      }
    } else {
      this.store.pipe(select(selectDoctorReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
        }
      });
    }

  }

  ngOnInit() {
    // this.salutationList = this.facadeService.getGender(this.languageId);
    this.salutationList = this.facadeService.getSalutation();
  }

  public onSubmit() {
    this.baseObject = {...this.formGroup.value};
    this.baseObject.pharmacyid = this.authService.user.pharmacyid;
    this.baseObject.countryid = this.authService.user.country.countryid;
    const variables = {
      parentObj: this.baseObject,
      action: this.action
    };
    const _callback = this.bsRef.config.initialState['callback'];
    const _cascadeEffect = (args) => {
      if (this.isComingFromPatient) {
        _callback(args);
      } else {
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_PATIENTS()[1]}));
        this.store.dispatch(new SelectDoctorAction({selected: args.data['saveDoctor']}));
        this.facadeService.setDetailHidden(false);
      }
    };
    this.facadeService.mutateObject(this.saveDoctorGQL, variables, _cascadeEffect);
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'patient.doctors.newDoctor';
      }
      case ActionStateEnum.EDIT: {
        return 'patient.doctors.editDoctor';
      }
      default: {
        return 'patient.doctors.doctor';
      }
    }
  }

  public getSalutationById(args) {
    if (!isNullOrUndefined(args)) {
      return this.salutationList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public closeDoctors() {
    if (this.isComingFromPatient) {
      const _callback = this.bsRef.config.initialState['callback'];
      _callback(null);
    } else {
      this.changeActionState('VIEW');
    }
  }
}
