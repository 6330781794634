import { Component, OnInit, ViewChild } from '@angular/core';
import { FormAbstract } from '../../../models/abstract/form.abstract';
import { BsModalService } from 'ngx-bootstrap';
import { select, Store } from '@ngrx/store';
import { FacadeService } from '../../../services/facade.service';
import { ShareService } from '../../../services/share.service';
import { AppState, selectPatientLanguageReducer } from '../../../store/app.states';
import { PatientLanguage, PatientLanguageValidate } from '../../../models/patient-language';
import { ActionStateEnum } from '../../../models/enums/actions-state.enum';
import { FormGroup } from '@angular/forms';
import { ChangeSubMenuAction } from '../../../store/actions/sub-menu.actions';
import { SUB_MENU_LIST_ADMINISTRATION } from '../../../config/sub-menu';
import { SavePatientlanguageGQL, UserLanguageGQL } from '../../../api/graphql';
import { SelectPatientLanguagesAction } from '../../../store/actions/patient-language.actions';
import { AuthService } from '../../../services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddUserCountryComponent } from './modal/add-user-country/add-user-country.component';
import { LinkKey } from '../../../models/enums/link-key.enum';
import { UploadFileService } from '../../../services/upload-file.service';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../models/user';

@Component({
  selector: 'app-patient-languages',
  templateUrl: './patient-languages.component.html',
  styleUrls: ['./patient-languages.component.scss']
})
export class PatientLanguagesComponent extends FormAbstract<PatientLanguage> implements OnInit {
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public modalRef: BsModalRef;
  @ViewChild('file') file;
  public logoSrc: any;
  public userCountryList: Array<User>;
  public checked = [];
  public languageId: any;

  constructor(protected store: Store<AppState>, protected authService: AuthService,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, private savePatientLanguageGQL: SavePatientlanguageGQL,
    private uploadFileService: UploadFileService, private toastrService: ToastrService,
    private userLanguageGQL: UserLanguageGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.userCountryList = Array();
    this.action = ActionStateEnum.VIEW;
    this.logoSrc = null;
    this.baseFields = [
      { field: 'languageid' },
      { field: 'language', validator: PatientLanguageValidate.rules.language },
      { field: 'flag' },
      { field: 'status', validator: [], defaultValue: 1 },
    ];
    this.languageId = this.authService.userLanguage.languageid;
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'languageid',
      translationId: ''
    };

    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
    } else {
      this.store.pipe(select(selectPatientLanguageReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
          this.formGroup.patchValue({ 'status': this.baseObject.status });
          this.userLanguageGQL.watch({ languageId: this.baseObject.languageid }).result().then((args: any) => {
            this.userCountryList = args.data['UserLanguage'];
            this.checked = [];
            this.userCountryList.forEach(item => {
              this.checked.push(item['userid']);
            });
          });

          if (!isNullOrUndefined(this.baseObject.flag) && this.baseObject.flag !== '') {
            this.logoSrc = this.baseObject.flag;
            if (this.logoSrc.substring(0, 4) !== 'data') {
              this.logoSrc = 'data:image/png;base64,' + this.logoSrc;
            }
          } else {
            this.logoSrc = null;
          }
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public getStatusById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public replaceBase64Prefix() {
    return this.baseObject.flag.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', '');
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: {
        ...this.baseObject,
        flag: this.baseObject.flag ? this.replaceBase64Prefix() : null
      },
      usersId: this.checked.filter(this.unique),
      action: this.action
    };
    const _afterSave = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({ selected: SUB_MENU_LIST_ADMINISTRATION()[1] }));
      if (this.facadeService.filterArrayContains(args.data['savePatientlanguage'], ['language'], LinkKey.PATIENT_LANGUAGES)) {
        this.store.dispatch(new SelectPatientLanguagesAction({ selected: args.data['savePatientlanguage'] }));
        this.facadeService.setDetailHidden(false);
      } else {
        this.store.dispatch(new SelectPatientLanguagesAction({ selected: {} }));
      }
    };
    const _cascadeEffect = (args) => {
      this.uploadFileService.uploadGenericFile(this.file.nativeElement.files, args.data['savePatientlanguage'].languageid, 0, 'LANGUAGE')
        .subscribe(next => {
          if (next.type === 4) {
            args.data['savePatientlanguage'].flag = this.logoSrc;
            _afterSave(args);
          }
        });
    };
    this.facadeService.mutateObject(this.savePatientLanguageGQL, variables, _cascadeEffect);
  }

  public addAndRemoveUser() {
    this.modalRef = this.bsRef.show(AddUserCountryComponent, {
      class: 'modal-lg', initialState: {
        data: this.baseObject,
        action: ActionStateEnum.CREATE,
        usersActive: this.checked.filter(this.unique),
        isComingFromPatient: true,
        callback: (args) => {
          this.modalRef.hide();
          if (!isNullOrUndefined(args)) {
            this.userCountryList = args;
            this.checked = this.userCountryList.map(t => t.userid);
          }
        }
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public updateFileSource() {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.logoSrc = reader.result;
    };
    reader.readAsDataURL((<File>this.file.nativeElement.files[0]));
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.patientLanguage.newPatientLanguage';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.patientLanguage.editPatientLanguage';
      }
      default: {
        return 'administration.patientLanguage.patientLanguage';
      }
    }
  }
  private unique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
