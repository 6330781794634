import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IntakePlanDate} from '../../../../../models/intake-plan-date';
import {sortBy} from 'lodash';
declare var jsPDF: any;
declare var domtoimage: any;

@Component({
  selector: 'app-patient-daily',
  templateUrl: './patient-daily.component.html',
  styleUrls: ['./patient-daily.component.scss']
})
export class PatientDailyComponent implements OnInit {
  private printableContent;
  private _dailySchedule: Array<IntakePlanDate>;
  get dailySchedule() {
    return this._dailySchedule;
  }
  
  @Input('dailySchedule')
  set dailySchedule(value: Array<IntakePlanDate>) {
    this._dailySchedule = value.map(t => {
      return {
        ...t,
        intakePlanDateTimes: t.intakePlanDateTimes.map(u => {
          return {
            ...u,
            intakePlanMedicaments: sortBy(u.intakePlanMedicaments, (o) => o.medicament.shortname + o.strength),
            intakePlanPreparations: sortBy(u.intakePlanPreparations, (o) => o.addPreparation.label + + o.strength),
          };
        })
      };
    });
  }
  @Output('printDailyIntakePlan') printDailyIntakePlan = new EventEmitter<any>();
  @Output('callBackReturn') callBackReturn = new EventEmitter<any>();
  @Output('saveDaily') saveDaily = new EventEmitter<any>();
  constructor(private elementRef: ElementRef, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  // public updatePiecesValue(item: any, time: any, isIntakePlanMedicaments: boolean) {
  
  //   if (Array.isArray(this.dailySchedule) && this.dailySchedule.length > 0) {
  //     this.dailySchedule.forEach(dailyScheduleObject => {
  //       if (
  //         Array.isArray(dailyScheduleObject.intakePlanDateTimes) &&
  //         dailyScheduleObject.intakePlanDateTimes.length > 0
  //       ) {
  //         dailyScheduleObject.intakePlanDateTimes.forEach(intakePlanDateTime => {
  //           if (intakePlanDateTime.time == time) {
  //             if (isIntakePlanMedicaments) {
  //               if (
  //                 Array.isArray(intakePlanDateTime.intakePlanMedicaments) &&
  //                 intakePlanDateTime.intakePlanMedicaments.length > 0
  //               ) {
  //                 intakePlanDateTime.intakePlanMedicaments.forEach(intakePlanMedicament => {
  //                   if (intakePlanMedicament.medicament.label == item.medicament.label) {
  //                     intakePlanMedicament.pieces = item.pieces;
  //                   }
  //                 });
  //               }
  //             } else {
  //               if (
  //                 Array.isArray(intakePlanDateTime.intakePlanPreparations) &&
  //                 intakePlanDateTime.intakePlanPreparations.length > 0
  //               ) {
  //                 intakePlanDateTime.intakePlanPreparations.forEach(intakePlanPreparation => {
  //                   if (intakePlanPreparation.addPreparation.label == item.addPreparation.label) {
  //                     intakePlanPreparation.pieces = item.pieces;
  //                   }
  //                 });
  //               }
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }
  // }
  
  public onSubmitIntakePlan() {
    this.saveDaily.emit(true);
  }

  public returnToPatient() {
    this.callBackReturn.emit();
  }

  public onSubmitPrintDailyIntakePlan() {
    this.printDailyIntakePlan.emit();
  }

  public printHTML(htmlContent) {
    var printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.print();
    
  }
}
