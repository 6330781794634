import {Injectable, Injector} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Router, Event, NavigationError} from '@angular/router';

import {Observable} from 'rxjs';
import {of} from 'rxjs/observable/of';
import * as StackTraceParser from 'error-stack-parser';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ErrorsService {

  constructor(
    private injector: Injector,
    private router: Router,
    private toastService: ToastrService
  ) {
    this.router
      .events
      .subscribe((event: Event) => {
        if (event instanceof NavigationError) {
          this.log(event.error)
            .subscribe((errorWithContext) => {
              if (environment.showErrorMessage) {
                console.log(`${errorWithContext.status} - ${errorWithContext.message}`);
                this.toastService.error(errorWithContext.message);
              }
            });
        }
      });
  }

  log(error) {
    const errorToSend = this.addContextInfo(error);
    return FakeHttpService.post(errorToSend);
  }

  addContextInfo(error) {
    const name = error.name || null;
    const appId = 'ORALIA SYSTEM';
    const user = 'USER SYSTEM';
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    return {name, appId, user, time, id, url, status, message, stack};
  }

}


class FakeHttpService {
  static post(error): Observable<any> {
    return of(error);
  }
}
