import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectHistoriesReducer, selectIntakeNotesReducer} from '../../../store/app.states';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  public baseObject: Array<History>;

  constructor(private store: Store<AppState>, private authService: AuthService) {
    this.store.pipe(select(selectHistoriesReducer)).subscribe((next: any) => {
      if (next.list) {
        this.baseObject = next.list;
      }
    });
  }

  ngOnInit() {
  }

  public processOperation(op) {
    switch (op) {
      case 1: {
        return 'history.operation.created';
      }
      case 2: {
        return 'history.operation.changed';
      }
      case 3: {
        return 'history.operation.deleted';
      }
      case 4: {
        return 'history.operation.checked';
      }
      case 5: {
        return 'history.operation.printed';
      }
      case 6: {
        return 'history.operation.registered';
      }
      case 7: {
        return 'history.operation.confirmedCreated';
      }
      case 8: {
        return 'history.operation.refused';
      }
      case 9: {
        return 'history.operation.deactivated';
      }
      case 10: {
        return 'history.operation.loggedIn';
      }
    }
  }
}
