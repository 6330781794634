import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_MASTER_DATA} from '../../../config/sub-menu';
import {User, UserValidate} from '../../../models/user';
import {isNullOrUndefined} from 'util';
import {SaveUserAccountGQL, UserUniqueGQL} from '../../../api/graphql';
import {CustomValidate} from '../../../models/custom-validate';
import {ChangeLanguageAction, LoadUserLanguagesAction} from "../../../store/actions/lang.actions";
import { TranslateLoginPipe } from '../../shared/pipes/translate-login.pipe';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  providers: [TranslateLoginPipe],
})
export class UserAccountComponent extends FormAbstract<User> implements OnInit {

  public formGroup: FormGroup;
  public accountForm: FormGroup;
  public action: ActionStateEnum;
  public salutationList: Array<any>;
  public countryList: Array<any>;
  public isUniqueEmail = true;
  public userLanguageId: number;
  public lastUserEmail: string;

  constructor(private fb: FormBuilder, protected store: Store<AppState>,private translateLogin :TranslateLoginPipe,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private userUniqueGQL: UserUniqueGQL, private saveUserAccount: SaveUserAccountGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.accountForm = this.createAccountForm();
    this.action = ActionStateEnum.VIEW;
    this.baseObject = this.authService.user;
    this.languageId = this.authService.userLanguage.languageid;
    this.accountForm.patchValue(this.baseObject);
  }

  ngOnInit() {
    this.languageList = this.facadeService.activeUserLanguages;
    this.lastUserEmail = this.authService.user.email;
    this.userLanguageId = this.authService.user.userLanguage.languageid;
    this.statusList = this.facadeService.getUserStatus(this.languageId);
    this.salutationList = this.facadeService.getSalutation();
  }

  public editAccount() {
    this.action = ActionStateEnum.EDIT;
    // this.changeActionState('EDIT');
    this.accountForm.patchValue({'password' : this.baseObject.password});
    this.accountForm.patchValue({'confirmPassword' : this.baseObject.password});
  }

  public cancelAccount() {
    this.action = ActionStateEnum.VIEW;
    this.resetPatch();
    // changeActionState('VIEW')
  }

  public resetPatch() {
    this.accountForm.reset();
    this.accountForm.patchValue(this.baseObject);
  }

  public createAccountForm(): FormGroup {
    return this.fb.group(
      {
        userid: [null],
        salutation: [null, Validators.compose([Validators.required])],
        title: [null, UserValidate.rules.title],
        email: [
          null,
          Validators.compose([Validators.email, Validators.required])
        ],
        firstname: [null, UserValidate.rules.firstname],
        lastname: [null, UserValidate.rules.lastname],
        phone: [null, UserValidate.rules.phone],
        password: [
          null,
          Validators.compose([
            // check whether the entered password has a number
            CustomValidate.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidate.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidate.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidate.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null,]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidate.passwordMatchValidator
      }
    );
  }

  public checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPass.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  public checkUniqueEmployee(event: any) {
    if (event.target.value && this.accountForm.controls['email'].valid) {
      this.userUniqueGQL.watch(
        {email: event.target.value, userId: Number(this.authService.user.userid)}).result().then(
        (data) => {
          if (data.data['UserUnique'] && event.target.value !== this.lastUserEmail) {
            this.accountForm.get('email').setErrors({'emailUnique': true});
          }
        }
      );
    }
  }
  public doSelect(value: any) {
    this.userLanguageId = value;
  }

  public onSubmit() {
    this.baseObject = this.accountForm.value;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.userLanguageId,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.action = ActionStateEnum.VIEW;
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_MASTER_DATA()[2]}));
      this.authService.user = args.data['saveUserAccount'];
      this.authService.setCookie('languageId', this.authService.user.userLanguage.languageid);
      this.baseObject = this.authService.user;
      this.lastUserEmail = this.authService.user.email;
      this.resetPatch();
      const payload = {
        selectedLanguage: {
          shortname: this.authService.user.userLanguage.shortname,
        },
      };
      this.store.dispatch(new ChangeLanguageAction(payload));
    };
    this.facadeService.mutateObject(<any>this.saveUserAccount, variables, _cascadeEffect);
  }

  public getSalutationById(args) {
    if (!isNullOrUndefined(args)) {
      return this.salutationList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public getLanguageById(args) {
    if (!isNullOrUndefined(args)) {
      const selectedLanguage = this.languageList.find(t => t.languageid === args);
      const translatedValue = this.translateLogin.transform(
        selectedLanguage,
        'language',
        this.authService.userLanguage.languageid
      );
      return translatedValue ? translatedValue : selectedLanguage.language;
      // return this.languageList.find(t => t.languageid === args).language;
    } else {
      return '';
    }
  }

  public getStatusUById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.EDIT: {
        return 'master.userAccount.editUserAccount';
      }
      default: {
        return 'master.userAccount.userAccount';
      }
    }
  }

}
