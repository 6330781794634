import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { find } from 'lodash';
import { PatientLanguagesGQL } from 'src/app/api/graphql';
import { LinkKey } from 'src/app/models/enums/link-key.enum';
import { FacadeService } from 'src/app/services/facade.service';
import { isNullOrUndefined } from 'util';
import { UserLanguage } from '../../../models/user-language';
import { AuthService } from '../../../services/auth.service';
import { ChangeLanguageAction, LangActionTypes } from '../../../store/actions/lang.actions';
import { AppState, selectLanguageState } from '../../../store/app.states';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss']
})
export class LangSelectComponent implements OnInit {
  @Input() changeSection?: boolean;
  @Output() languageSection = new EventEmitter<UserLanguage>();
  @Input() defaultLang?: number;
  @Input() dispatchEvent?: boolean;
  @Input() displayAllLanguages?: boolean;
  languageState: any;
  selectedLanguage: UserLanguage;
  availableLang: Array<UserLanguage>;
  userLanguageId:number;
  public patientLangWithFlag: Readonly<any>;
  public logoSrc: any;
  constructor(private store: Store<AppState>,
              private apollo: Apollo,
              private facadeService:FacadeService,
              private patientLanguagesGQL:PatientLanguagesGQL,
              public authService: AuthService) {
    this.languageState = this.store.pipe(select(selectLanguageState));
  }

  ngOnInit() {
    this.userLanguageId = this.authService.userLanguage.languageid;
    this.store.pipe(select(selectLanguageState)).subscribe((next: any) => {
      if (next.actionType === LangActionTypes.LOAD_TRANSLATION_LANGUAGES) {
        this.availableLang = next.availableLang;
        this.selectedLanguage = next.selectedLanguage;
      }
    });
    if (!this.availableLang || !this.selectedLanguage) {
      this.availableLang = this.facadeService.userTranslationLanguages
      this.selectedLanguage = this.availableLang.find(t => {
        return t.languageid === this.authService.userLanguage.languageid;
      })
    }
    this.apollo.getClient().cache.reset();
    let variables =  this.facadeService.filterData[LinkKey.PATIENT_LANGUAGES]
    const variablesWithUser = {
      ...variables,
      languageId: this.authService.user.userLanguage.languageid || 1,
      countryId: this.authService.user.country.countryid || 1
    };
    this.patientLanguagesGQL.watch(variablesWithUser).result().then(next => {
      this.patientLangWithFlag = next.data.PatientLanguages;
      // console.log(next.data.PatientLanguages);
    });
  }

  public changeLang($event) {
    let langList;
    // if (this.changeSection && !this.displayAllLanguages) {
    //   langList = this.authService.user.translationLang;
    // } else {
    //   langList = this.availableLang;
    // }
    const langWithFlag = this.patientLangWithFlag.find(l => l.languageid === $event);
    this.logoSrc = langWithFlag.flag;
    if (this.logoSrc && this.logoSrc.substring(0, 4) !== 'data') {
      this.logoSrc = 'data:image/png;base64,' + this.logoSrc;
    }else{
      this.logoSrc = null;
    }
    langList = this.availableLang;
    const data = langList.find(l => l.languageid === $event);
    const payload = {
      selectedLanguage: data
    };
    this.selectedLanguage = data;
    if (this.changeSection) {
      this.languageSection.emit(data);
    } else {
      if (this.dispatchEvent) {
        this.languageSection.emit(data);
      }
      this.store.dispatch(new ChangeLanguageAction(payload));
    }
  }

  public get getFlag() {
    if (!isNullOrUndefined(this.defaultLang)) {
      return find(this.availableLang, ['languageid', this.defaultLang]).shortname;
    } else {
      if (this.selectedLanguage) {
        return this.selectedLanguage.shortname;
      }else{
        return "";
      }
    }
  }

}
