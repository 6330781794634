import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum UserActions {
  LOAD_USER = '[User] LOAD_USER',
  LOAD_USER_BY_ID = '[User] LOAD_USER_BY_ID',
  SELECT_USER = '[User] SELECT_USER'
}

export class LoadUserListAction implements Action {
  readonly type = UserActions.LOAD_USER;

  constructor(public payload: PaginationData) {
  }
}

export class LoadUserByIdAction implements Action {
  readonly type = UserActions.LOAD_USER_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectUserAction implements Action {
  readonly type = UserActions.SELECT_USER;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadUserListAction
  | LoadUserByIdAction
  | SelectUserAction;
