/**
 * @author Jonathan Almanza
 * 25/12/2018
 */
import {Action} from '@ngrx/store';

export enum CommonActions {
  LOAD_DETAIL_BY_ID = '[COMMON ACTION] LOAD_DETAIL_BY_ID',
}

export class LoadDetailByIdAction implements Action {
  readonly type = CommonActions.LOAD_DETAIL_BY_ID;

  constructor(public payload: LoadByIdQueryData) {
  }
}

export type All =
  | LoadDetailByIdAction;

export class LoadByIdQueryData {
  query: any;
  action: any;
  object: string;
  variables: any;
}
