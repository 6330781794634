import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FacadeService} from '../../../services/facade.service';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {User, UserValidate} from '../../../models/user';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {FormGroup, Validators} from '@angular/forms';
import {ConfirmRegistrationGQL, PharmaciesGQL, RejectRegistrationGQL, UniqueEmailGQL} from '../../../api/graphql';
import {select, Store} from '@ngrx/store';
import {ShareService} from '../../../services/share.service';
import {AppState, selectLanguageState, selectUserReducer} from '../../../store/app.states';
import {AuthService} from '../../../services/auth.service';
import {Pharmacy} from '../../../models/pharmacy';
import {TranslateService} from '@ngx-translate/core';
import {isNullOrUndefined} from 'util';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {RejectComponent} from './reject/reject.component';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss']
})
export class RegistrationsComponent extends FormAbstract<User> implements OnInit, OnDestroy {
  user: User;
  languageState: Observable<any>;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public pharmacyOption: Array<any>;
  public isUniqueEmail = true;
  public isPharmacy: number;
  public pharmacy: Pharmacy;
  public languageId: number;
  public salutationList: Array<any>;
  public proffesionalGroupList: Array<any>;
  public modalRef: BsModalRef;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private translate: TranslateService, private uniqueEmailGQL: UniqueEmailGQL,
              private rejectRegistrationGQL: RejectRegistrationGQL, private confirmRegistrationGQL: ConfirmRegistrationGQL,
              private pharmaciesGQL: PharmaciesGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.languageState = this.store.pipe(select(selectLanguageState));
    this.action = ActionStateEnum.EDIT;
    this.baseFields = [
      {field: 'userid'},
      {field: 'pharmacyid'},
      {field: 'city'},
      {field: 'doccheck'},
      {field: 'email', validator: UserValidate.rules.email},
      {field: 'enddate'},
      {field: 'firstname', validator: UserValidate.rules.firstname},
      {field: 'issimpleuser', defaultValue: 0},
      {field: 'institutiontype', defaultValue: 0},
      {field: 'iscountryadmin', defaultValue: 0},
      {field: 'iscountryeditor', defaultValue: 0},
      {field: 'isemployee', defaultValue: 0},
      {field: 'ismainadmin', defaultValue: 0},
      {field: 'ismaineditor', defaultValue: 0},
      {field: 'ispharmacy', defaultValue: 0},
      {field: 'lastname', validator: UserValidate.rules.lastname},
      {field: 'name', validator: UserValidate.rules.name},
      {field: 'password', validator: UserValidate.rules.password},
      {field: 'phone', validator: UserValidate.rules.phone},
      {field: 'salutation'},
      {field: 'startdate'},
      {field: 'street', validator: UserValidate.rules.street},
      {field: 'title', validator: UserValidate.rules.title},
      {field: 'zip', validator: UserValidate.rules.zip},
      {field: 'status', validator: [], defaultValue: 1},
      {field: 'country', validator: [Validators.required]},
      {field: 'userLanguage', validator: [Validators.required]},
      {field: 'professionalgroup', validator: [Validators.required]}
    ];
    this.formGroup = this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'patientid',
      translationId: null
    };
    this.baseObject = new User();
    this.prepareForm();
    this.formGroup.patchValue({'doccheck': 0});
    this.formGroup.patchValue({'salutation': 1});
    this.isPharmacy = 0;
    this.pharmacy = new Pharmacy();
    this.pharmacyOption = this.facadeService.getIntitutionType();
    this.languageId = this.authService.user.userLanguage.languageid;
    this.store.pipe(select(selectUserReducer)).subscribe((next: any) => {
      this.action = ActionStateEnum.VIEW;
      if (next.selected) {
        this.baseObject = next.selected;
        this.prepareForm();
        this.formGroup.patchValue({'status': this.baseObject.status});
        if (this.baseObject.ispharmacy && !isNullOrUndefined(this.baseObject.pharmacyid)) {
          this.pharmaciesGQL.watch({name: '', userId: this.baseObject.userid}).result().then(result => {
            this.pharmacy = result.data['Pharmacies'][0];
          });
        }
      }
    });
  }

  ngOnInit() {
    this.salutationList = this.facadeService.getSalutation();
    this.proffesionalGroupList = this.facadeService.getProffesionalGroupList();
    this.trueFalseList = this.facadeService.getTrueFalseList(this.languageId);
    this.languageList = this.facadeService.activeUserLanguages;
  }

  ngOnDestroy(): void {
  }

  public getLanguageById(args) {
    if (!isNullOrUndefined(args)) {
      return this.languageList.find(t => t.languageid === this.baseObject.userLanguage.languageid).language;
    } else {
      return '';
    }
  }

  public getSalutationById(args) {
    if (!isNullOrUndefined(args)) {
      return this.salutationList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public getWeAreById(args) {
    if (!isNullOrUndefined(args) && args !== 0) {
      return this.pharmacyOption.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public getTrueOrFalseById(args){
    if (!isNullOrUndefined(args)) {
      let id = 0
      if (args == true) {
        let id = 1;
      }
      return this.trueFalseList.find(t => t.id === id).name;
    } else {
      return '';
    }
  }

  public onAction(type: string) {
    const _cascadeEffect = (args) => {
      this.bsRef.hide(1);
      this[`${type}GQL`].mutate({
        userId: this.authService.user.userid,
        confirmUserId: this.baseObject.userid,
        reason: args
      }).subscribe(next => {
        if (next.data[type] === true) {
          this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[3]}));
        } else {
          this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[3]}));
        }
      });
    };
    this.modalRef = this.bsRef.show(RejectComponent, {
      class: 'modal-lg',
      initialState: {
        callback: _cascadeEffect,
        type: type
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public getProfessionalgroup(){
    let professionalgroup = this.proffesionalGroupList.find(f => f.value == this.formGroup.get("professionalgroup").value)
    return professionalgroup ? this.translate.instant(professionalgroup.name) : this.formGroup.get("professionalgroup").value; 
  }
}
