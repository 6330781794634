import {Validators} from '@angular/forms';

export class Doctor {
  doctorid?: number;
  city?: string;
  countryid?: number;
  pharmacyid?: number;
  fax?: string;
  firstname?: string;
  lastname?: string;
  mail?: string;
  phone?: string;
  sex?: string;
  street?: string;
  title?: string;
  zip?: string;
  fullName?: string;
}
export class DoctorValidate {
  public static rules = {
    ['pharmacyid']: [Validators.required],
    ['countryid']: [Validators.required],
    ['fax']: [Validators.maxLength(40)],
    ['firstname']: [Validators.maxLength(60)],
    ['lastname']: [Validators.required, Validators.maxLength(60)],
    ['email']: [Validators.maxLength(60), Validators.email],
    ['phone']: [Validators.maxLength(40)],
    ['street']: [Validators.maxLength(60)],
    ['title']: [Validators.maxLength(20)],
    ['zip']: [Validators.maxLength(20)],
    ['city']: [Validators.required, Validators.maxLength(40)],

  };
}
