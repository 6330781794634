import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';
import {AddPreparation} from '../../models/add-preparation';
import {MedicamentActions} from './medicament.actions';

export enum AddPreparationActions {
  LOAD_ADD_PREPARATIONS = '[ADD_PREPARATIONS] Load',
  LOAD_ADD_BY_ID = '[ADD_PREPARATIONS] LOAD_ADD_BY_ID',
  SELECT_ADD_PREPARATIONS = '[ADD_PREPARATIONS] Change Selected',
  LOAD_ADD_PREPARATIONS_SUCCESS = '[ADD_PREPARATIONS] Load add preparations success',
  LOAD_ADD_PREPARATIONS_FAILURE = '[ADD_PREPARATIONS] Load add preparations failure',
  LOAD_ADDPREPARATION_DETAIL = '[ADD_PREPARATIONS] Detail load',
  ADD_ADDPREPARATION = '[ADD_PREPARATIONS] Add Addpreparation'
}

export class LoadAddPreparationsAction implements Action {
  readonly type = AddPreparationActions.LOAD_ADD_PREPARATIONS;
  constructor(public payload: PaginationData) {}
}

export class SelectAddPreparationsAction implements Action {
  readonly type = AddPreparationActions.SELECT_ADD_PREPARATIONS;

  constructor(public payload: any) {
  }
}
export class LoadAddByIdAction implements Action {
  readonly type = AddPreparationActions.LOAD_ADD_BY_ID;

  constructor(public payload: any) {
  }
}
export class LoadAddPreparationsSuccess implements Action {
  readonly type = AddPreparationActions.LOAD_ADD_PREPARATIONS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadAddPreparationsFailure implements Action {
  readonly type = AddPreparationActions.LOAD_ADD_PREPARATIONS_FAILURE;

  constructor(public payload: any) {
  }
}
export class LoadDetailAddPreparationAction implements Action {
  readonly type = AddPreparationActions.LOAD_ADDPREPARATION_DETAIL;

  constructor(public payload: any) {
  }
}
export class AddAddPreparationAction implements Action {
  readonly type = AddPreparationActions.ADD_ADDPREPARATION;

  constructor(public payload: AddPreparation) {
  }
}
export type All =
  | LoadAddPreparationsAction
  | SelectAddPreparationsAction
  | LoadAddPreparationsSuccess
  | LoadAddPreparationsFailure
  | LoadDetailAddPreparationAction
  | AddAddPreparationAction;
