import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {User, UserValidate} from '../../../models/user';
import {BsModalService} from 'ngx-bootstrap';
import {select, Store} from '@ngrx/store';
import {FacadeService} from '../../../services/facade.service';
import {ShareService} from '../../../services/share.service';
import {AppState, selectUserReducer} from '../../../store/app.states';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {AuthService} from '../../../services/auth.service';
import {isNullOrUndefined} from 'util';
import {CountriesGQL, DeleteListOfEmployeesGQL, DeleteListOfPatientsGQL, DeletePharmacyGQL, ListOfEmployeesGQL, ListOfPatientsGQL, PharmacyGQL, SaveUserGQL, UserGQL, UserUniqueGQL} from '../../../api/graphql';
import {Country} from '../../../models/country';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {Pharmacy} from '../../../models/pharmacy';
import {LoadDetailByIdAction} from '../../../store/actions/common.actions';
import {SelectUserAction} from '../../../store/actions/user.actions';
import {TranslateService} from '@ngx-translate/core';

import {List, sortBy} from 'lodash';
import {CustomValidate} from "../../../models/custom-validate";
import { Employee } from 'src/app/models/employee';
import { Patient } from 'src/app/models/patient';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends FormAbstract<User> implements OnInit {
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public salutationList: Array<any>;
  public countryList: Array<any>;
  public countrySelect: Country;
  public userLanguageId: number;
  public freeofcharge: number;
  public endoffreeuse: Date;
  public isCurrentUserCountryAdministrator: boolean;
  /**
   * option two
   */
  public formUser: FormGroup;
  public userSelected: User;
  public listOfEmployees: Employee[];
  public listOfPatients: Patient[];
  public selected: boolean;
  public pharmacy: Pharmacy;
  public pharmacyOption: Array<any>;
  public isPharmacy: number;
  public languageId: any;
  public lastUserEmail: string;
  public isDeleteEmployee: boolean;
  public isDeletePatient: boolean;
  public isDeletePharmacy: boolean;
  public isEmployeeTable: boolean;
  public isPatientTable: boolean;
  public selectAll: boolean;
  public selectedProffesionalGroup: string;
  public proffesionalGroupList: Array<any>;

  constructor(public fb: FormBuilder, protected store: Store<AppState>, public listOfEmployeesGQL: ListOfEmployeesGQL,
              public deleteListOfEmployeesGQL: DeleteListOfEmployeesGQL, public listOfPatientsGQL: ListOfPatientsGQL,
              public deleteListOfPatientsGQL: DeleteListOfPatientsGQL,private coolDialogs: NgxCoolDialogsService,
              protected bsRef: BsModalService, protected shareService: ShareService, private translate: TranslateService,
              protected facadeService: FacadeService, protected authService: AuthService, private userGQL: UserGQL,
              private countryListGQL: CountriesGQL, private saveUserGQL: SaveUserGQL, private userUniqueGQL: UserUniqueGQL,
              private pharmacyGQL: PharmacyGQL, public deletePharmacyGQL: DeletePharmacyGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.selected = false;
    this.action = ActionStateEnum.VIEW;
    this.salutationList = Array();
    this.isPharmacy = 1;
    this.isDeleteEmployee = false;
    this.isDeletePatient = false;
    this.isDeletePharmacy = false;
    this.selectAll = false;
    this.isEmployeeTable = undefined;
    this.isPatientTable = undefined;
    this.formUser = this.fb.group({
      userid: [],
      pharmacyid: [],
      city: ['', UserValidate.rules.city],
      doccheck: [0],
      email: ['', UserValidate.rules.email],
      enddate: [null],
      firstname: ['', UserValidate.rules.firstname],
      institutiontype: ['', null, 0],
      iscountryadmin: [''],
      iscountryeditor: [''],
      isemployee: [''],
      ismainadmin: [''],
      ismaineditor: [''],
      ispharmacy: [''],
      issimpleuser: [''],
      lastname: [''],
      name: [null, UserValidate.rules.name],
      phone: [null, UserValidate.rules.phone],
      salutation: [null, Validators.required],
      startdate: [null],
      street: [null, UserValidate.rules.street],
      title: [null, UserValidate.rules.title],
      zip: ['', UserValidate.rules.zip],
      status: [0],
      userLanguage: this.fb.group({
        language: [],
        languageid: [Validators.required],
      }),
      country: this.fb.group({
        countryid: [null, Validators.required],
        name: ['']
      }),
      password: [
        null,
        Validators.compose([
          // check whether the entered password has a number
          CustomValidate.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidate.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidate.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidate.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],
      confirmPassword: [null],
      lastlogin: [''],
      created: [''],
      freeofcharge: [''],
      professionalgroup: ['', UserValidate.rules.professionalgroup]
    }, {
      // check whether our password and confirm password match
      validator: CustomValidate.passwordMatchValidator
    });
    this.languageId = this.authService.userLanguage.languageid;
    this.userLanguageId = this.authService.userLanguage.languageid;
    this.countrySelect = this.authService.user.country;
    this.isCurrentUserCountryAdministrator = (this.authService.user.iscountryadmin === 1 || this.authService.user.iscountryadmin == true )? true : false;
    this.pharmacy = new Pharmacy();
    this.pharmacyOption = this.facadeService.getIntitutionType();
    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      switch (this.action) {
        case ActionStateEnum.CREATE: {
          this.countrySelect.countryid = this.authService.user.country.countryid;
          this.userLanguageId = this.authService.user.country.languageid;
          this.baseObject = new User();
          this.baseObject.institutiontype = 4;
          this.freeofcharge = 0;
          break;
        }
      }
    } else {
      this.store.pipe(select(selectUserReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        this.cancelActionFromDeletePage();
        if (!isNullOrUndefined(next.selected)) {
          this.selected = true;
          if (next.selected.professionalgroup === null || next.selected.professionalgroup === undefined) {
            next.selected.professionalgroup = '';
          }
          this.userSelected = next.selected;
          this.isPharmacy = this.userSelected.ispharmacy ? 1 : 0;
          this.freeofcharge = this.userSelected.freeofcharge ? 1 : 0;
          if (this.userSelected.endoffreeuse != null) {
            this.endoffreeuse = new Date(this.userSelected.endoffreeuse);
          }
          this.userSelected.confirmPassword = this.userSelected.password;
          this.formUser.patchValue(this.userSelected);
          this.countrySelect = next.selected['country'];
          this.baseObject = next.selected;
          this.userLanguageId = this.baseObject.userLanguage.languageid;
          const defaultProfessionalGroups = this.facadeService.getDefaultProfessionalGroups();
          if (defaultProfessionalGroups.includes(this.baseObject.professionalgroup)) {
                this.selectedProffesionalGroup = this.baseObject.professionalgroup;
          }else{
            this.selectedProffesionalGroup = 'other';
          }
          if (!isNullOrUndefined(this.baseObject.pharmacyid)) {
            this.pharmacyGQL.watch({id: this.baseObject.pharmacyid}).result().then(result => {
              this.pharmacy = result.data['Pharmacy'];
            });
          } else {
            this.pharmacy = new Pharmacy();
          }
          this.lastUserEmail = this.baseObject.email;
          this.disableCheck();
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getUserStatus(this.languageId);
    this.salutationList = sortBy(this.facadeService.getSalutation(), ['name']);
    this.trueFalseList = this.facadeService.getTrueFalseList(this.languageId);
    this.proffesionalGroupList = this.facadeService.getProffesionalGroupList();
    this.countryListGQL.watch({name: ''}
    ).result().then((data) => {
      this.countryList = data.data['Countries'] as any;
    });
    this.languageList = this.facadeService.activeUserLanguages;
  }

  public get pharmacyOptionWithoutEmployee() {
    const list = [...this.pharmacyOption];
    list.pop();
    return list;
  }

  public getWeAreById(args) {
    if (!isNullOrUndefined(args) && args !== 0) {
      return this.pharmacyOption.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public checkUniqueUser(event: any) {
    if (this.action === ActionStateEnum.CREATE || this.action === ActionStateEnum.EDIT) {
      if (event.target.value && this.formUser.controls['email'].valid) {
        this.userUniqueGQL.watch(
          {email: event.target.value, userId: this.baseObject.userid | 0}).result().then(
          (data) => {
            if (data.data['UserUnique'] && event.target.value !== this.lastUserEmail) {
              this.formUser.get('email').setErrors({'emailUnique': true});
            }
          }
        );
      }
    }
  }

  public doSelect(value: any) {
    this.isPharmacy = value;
  }

  public getSalutationById(args) {
    if (!isNullOrUndefined(args) && this.selected === true) {
      return this.salutationList.find(t => t.id === this.userSelected.salutation).name;
    } else {
      return '';
    }
  }

  public getLanguageById(args) {
    if (!isNullOrUndefined(args) && this.selected === true) {
      return this.languageList.find(t => t.languageid === this.userSelected.userLanguage.languageid).language;
    } else {
      return '';
    }
  }

  public getTrueOrFalseById(args){
    if (!isNullOrUndefined(args) && this.selected === true) {
      return this.trueFalseList.find(t => t.id === this.freeofcharge).name;
    } else {
      return '';
    }
  }

  public getStatusUById(args) {
    if (!isNullOrUndefined(args) && this.selected) {
      return this.statusList.find(t => t.id === this.baseObject.status).name;
    } else {
      return '';
    }
  }

  public getProfessionalgroup(){
    let professionalgroup = this.proffesionalGroupList.find(f => f.value == this.formUser.get("professionalgroup").value)
    return professionalgroup ? this.translate.instant(professionalgroup.name) : this.formUser.get("professionalgroup").value;
  }

  public editAction() {
    this.enableCheck();
    this.action = ActionStateEnum.EDIT;
  }

  public cancelAction() {
    if (this.action === ActionStateEnum.CREATE) {
      this.closeModal();
    } else {
      this.store.dispatch(new LoadDetailByIdAction({
        object: 'User', query: this.userGQL,
        action: SelectUserAction, variables: {id: this.baseObject.userid}
      }));
    }
  }

  public cancelActionFromDeletePage() {
    this.isDeleteEmployee = false;
    this.isDeletePatient = false;
    this.isDeletePharmacy = false;
  }

  public doSelectOptionsLanguage(args) {
    this.formUser.patchValue({'userLanguage': args[0]['data']});
  }

  public doSelectOptionsCountry(args) {
    this.formUser.patchValue({'country': args[0]['data']});
  }

  public doSelectOptionsTrueFalse(args) {
    this.formUser.patchValue({'freeofcharge': args[0]['data'].id});
    if (args[0]['data'].id == 0) {
      this.endoffreeuse = undefined;
    }
  }

  public disableCheck() {
    this.formUser.controls.iscountryeditor.disable();
    this.formUser.controls.ismainadmin.disable();
    this.formUser.controls.isemployee.disable();
    this.formUser.controls.ismaineditor.disable();
    this.formUser.controls.ispharmacy.disable();
    this.formUser.controls.iscountryadmin.disable();
  }

  public enableCheck() {
    this.formUser.controls.iscountryeditor.enable();
    this.formUser.controls.ismainadmin.enable();
    this.formUser.controls.isemployee.enable();
    this.formUser.controls.ismaineditor.enable();
    this.formUser.controls.ispharmacy.enable();
    this.formUser.controls.iscountryadmin.enable();
  }

  public onSubmit() {
    const institutionType = this.baseObject.institutiontype;
    this.baseObject = {...this.formUser.value};
    this.baseObject.institutiontype = institutionType;
    this.baseObject.endoffreeuse = this.endoffreeuse;
    delete this.baseObject.confirmPassword;
    this.disableCheck();
    const variables = {
      parentObj: this.baseObject,
      pharmacy: {
        bgano: this.pharmacy.bgano,
        city: this.pharmacy.city,
        countryid: this.baseObject.country.countryid,
        mail: this.pharmacy.mail,
        name: this.pharmacy.name,
        pharmacyid: this.pharmacy.pharmacyid ? this.pharmacy.pharmacyid : 0,
        phone: this.pharmacy.phone,
        street: this.pharmacy.street,
        userid: this.pharmacy.userid,
        zip: this.pharmacy.zip
      },
      isPharmacy: this.baseObject.institutiontype === 1 ? 1 : 0,
      performer: this.authService.user.userid,
      action: this.action
    };
    // Removed this attributes when is a CREATION, cause doesn't exist yet
    if (this.action === ActionStateEnum.CREATE || this.action === ActionStateEnum.EDIT) {
      delete variables.parentObj.lastlogin;
      delete variables.parentObj.created;
    }
    const _cascadeEffect = (args) => {
      this.action = ActionStateEnum.VIEW;
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[2]}));
      if (this.facadeService.filterArrayContainsPairs(args.data['saveUser'],
        [
          {a: 'firstname', b: 'name'},
          {a: 'lastname', b: 'name'},
        ], LinkKey.USERS)) {
        this.store.dispatch(new LoadDetailByIdAction({
          object: 'User', query: this.userGQL,
          action: SelectUserAction, variables: {id: args.data['saveUser'].userid}
        }));
        this.facadeService.setDetailHidden(false);
      } else {
        this.closeModal();
        this.bsRef.hide(1);
      }
      this.bsRef.hide(1);
    };
    this.facadeService.mutateObject(this.saveUserGQL, variables, _cascadeEffect);
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.user.newUser';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.user.editUser';
      }
      default: {
        return 'administration.user.user';
      }
    }
  }

  public employeeStatus(status: number) {

    switch (status) {
      case 0:
        return 'administration.user.inactive.capital';
      case 1:
        return 'administration.user.active';
      case 2:
        return 'administration.user.register';
      case 3:
        return 'administration.user.deleted';
      case 4:
        return 'administration.user.disabled';
      case 5:
        return 'administration.user.rejected';

      default:
        break;
    }
  }

  public pharmacyInValid() {
    if (this.baseObject.institutiontype === 1) {
      const fields = [
        {field: 'name', defaultValue: this.pharmacy.name, validator: [Validators.required, Validators.maxLength(60)]},
        {field: 'street', defaultValue: this.pharmacy.street, validator: [Validators.maxLength(60)]},
        {field: 'zip', defaultValue: this.pharmacy.zip, validator: [Validators.required]},
        {field: 'city', defaultValue: this.pharmacy.city, validator: [Validators.required, Validators.maxLength(40)]},
        {field: 'mail', defaultValue: this.pharmacy.mail, validator: UserValidate.rules.email},
      ];
      const formGroup = this.facadeService.createFormWithValidators(fields);
      return formGroup.invalid;
    }
    return false;
  }

  public toggleSelectAll(type:number): void {
    if (type === 1) {
      this.listOfEmployees.forEach(employee => (employee.isSelected = this.selectAll));
    }
    if (type === 2) {
      this.listOfPatients.forEach(patient => (patient.isSelected = this.selectAll));
    }
  }

  public onItemSelectionChange(type:number): void {
    if (type === 1) {
      this.selectAll = this.listOfEmployees.every(employee => employee.isSelected);
    }
    if (type === 2) {
      this.selectAll = this.listOfPatients.every(patient => patient.isSelected);
    }
  }

  public deleteEmployee() {
    const _callDeleteEmployee = (args) => {
      this.isDeleteEmployee = true;
      if (args != null && args.data != null && args.data.ListOfEmployees != null && args.data.ListOfEmployees.length > 0) {
        this.listOfEmployees = args.data.ListOfEmployees.map((employee) => {
          return {
            ...employee,
            isSelected: false
          };
        });
        this.isEmployeeTable = true;
      } else {
        this.isEmployeeTable = false;
      }
    };
    const Variable = {
      pharmacyid: this.baseObject.pharmacyid
    };
    this.facadeService.callQueryUnique(this.listOfEmployeesGQL, Variable, _callDeleteEmployee);
  }

  public deletePatient() {
    const _callDeletePatient = (args) => {
      this.isDeletePatient = true;
      if (args != null && args.data != null && args.data.ListOfPatients != null && args.data.ListOfPatients.length > 0) {
        this.listOfPatients = args.data.ListOfPatients.map((patient) => {
          return {
            ...patient,
            isSelected: false
          };
        });
        this.isPatientTable = true;
      } else {
        this.isPatientTable = false;
      }
    };

    const Variable = {
      pharmacyid: this.baseObject.pharmacyid
    };
    this.facadeService.callQueryUnique(this.listOfPatientsGQL, Variable, _callDeletePatient);
  }

  public deletePharmacy() {
    const _cascadeEffect = () => {
      const Variable = {
        pharmacyid: this.baseObject.pharmacyid
      };
      const _callDeletePharmacy = (args) => {
        this.action = ActionStateEnum.VIEW;
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[2]}));
        this.bsRef.hide(1);
      };
      this.facadeService.callQueryUnique(this.deletePharmacyGQL, Variable, _callDeletePharmacy);
    };
    this.shareService.confirmPharmacyDelete(_cascadeEffect, this.coolDialogs);
  }


  public deleteEmployeeConfirm() {

    const selectedIds = this.listOfEmployees
      .filter(employee => employee.isSelected)
      .map(employee => employee.apothekemaid);

    if (selectedIds == null || selectedIds.length < 1) {
      return;
    }

    if (selectedIds.length > 0) {

      const _callDeleteEmployee = (args) => {
        this.isDeleteEmployee = false;
      }
      const Variable = {
        employeeIds: selectedIds,
        pharmacyid: this.baseObject.pharmacyid
      };
      this.facadeService.callQueryUnique(this.deleteListOfEmployeesGQL, Variable, _callDeleteEmployee);
    } else {
      this.isDeleteEmployee = true;
    }
  }

  public deletePatientConfirm(){
    const selectedIds = this.listOfPatients.filter(patient => patient.isSelected).map(patient => patient.patientid);

    if (selectedIds == null || selectedIds.length < 1) {
      return;
    }
    if (selectedIds.length > 0) {
      const _callDeletePatient = (args) => {
        this.isDeletePatient = false;
      }
      const Variable = {
        patientIds: selectedIds,
        pharmacyid: this.baseObject.pharmacyid
      };
      this.facadeService.callQueryUnique(this.deleteListOfPatientsGQL, Variable, _callDeletePatient);
    } else {
      this.isDeleteEmployee = true;
    }
  }

  public changeProffesionalGroupName() {
    if (this.selectedProffesionalGroup === 'other') {
      this.formUser.patchValue({'professionalgroup': ''});
    }else{
      this.formUser.patchValue({'professionalgroup': this.selectedProffesionalGroup});
    }
  }

}
