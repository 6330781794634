import {Substance} from '../../models/substance';
import {SubstanceActions, All} from '../actions/substance.actions';

/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
export interface SubstanceState {
  selected: Substance;
}

export const initialState: SubstanceState = {
  selected: null
};

export function reducer(state = initialState, action: All): SubstanceState {
  switch (action.type) {
    case SubstanceActions.LOAD_SUBSTANCE_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case SubstanceActions.SELECT_SUBSTANCE: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
