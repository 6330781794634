import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {FacadeService} from '../../../../../services/facade.service';
import {ShareService} from '../../../../../services/share.service';
import {Store} from '@ngrx/store';
import {BsLocaleService, BsModalService, defineLocale} from 'ngx-bootstrap';
import {AppState} from '../../../../../store/app.states';
import {LoadMedByIdAction} from '../../../../../store/actions/medicament.actions';
import {AuthService} from '../../../../../services/auth.service';
import {deLocale, enGbLocale} from 'ngx-bootstrap/locale';
import {FormGroup, Validators} from '@angular/forms';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {SaveMedNoteGQL} from '../../../../../api/graphql';
import {MedNote} from '../../../../../models/med-note';
import {Medicament} from '../../../../../models/medicament';
import * as moment from 'moment';

defineLocale('de', deLocale);
defineLocale('en', enGbLocale);
defineLocale('sl', deLocale);
defineLocale('et', deLocale);

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent extends FormAbstract<MedNote> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private bsLocaleService: BsLocaleService, private saveMedNoteGQL: SaveMedNoteGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.baseFields = [
      {field: 'mednoteid'},
      {field: 'countryid'}, {field: 'pharmacyid'},
      {field: 'date', validator: [Validators.required]},
      {field: 'medicamentid'},
      {field: 'note', validator: [Validators.required]},
      {field: 'status'},
      {field: 'theme', validator: [Validators.required]},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'mednoteid',
      translationId: null
    };
    this.action = this.bsRef.config.initialState['action'];
    const medicament = this.bsRef.config.initialState['data'] as Medicament;
    if (this.action === ActionStateEnum.CREATE) {
      this.baseObject = new MedNote();
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['date']: this.shareService.getDate(moment())});
      this.formGroup.patchValue({['status']: 1});
      this.formGroup.patchValue({['medicamentid']: medicament.medicamentid});
    } else {
      this.baseObject = this.bsRef.config.initialState['data'];
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['status']: 1});
      this.formGroup.patchValue({['medicamentid']: medicament.medicamentid});
      this.formGroup.patchValue({['date']: this.shareService.getDate(this.baseObject.date)});
    }
  }

  ngOnInit() {
    this.bsLocaleService.use(this.shareService.getLocaleById(this.authService.user.userLanguage.languageid));
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public onSubmit() {
    this.baseObject = {...this.formGroup.value};
    this.baseObject.countryid = this.authService.user.country.countryid;
    this.baseObject.pharmacyid = this.authService.user.pharmacyid;
    this.baseObject.date = this.shareService.getStorableDate(this.formGroup.value['date']);
    const variables = {
      medNote: this.baseObject
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new LoadMedByIdAction({id: args.data['saveMedNote'].medicamentid}));
    };
    this.facadeService.mutateObject(this.saveMedNoteGQL, variables, _cascadeEffect);
  }
  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'modals.newNote.title';
      }
      case ActionStateEnum.EDIT: {
        return 'modals.editNote.title';
      }
    }
  }
}
