import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';

@Directive({
  selector: '[focusableInput]'
})
export class FocusableInputDirective implements OnDestroy {
  @Input('focusableInput')
  set focusableInput(args: boolean) {
    this.timeOut = setTimeout(() => {
      this.el.nativeElement.setAttribute('autofocus', args);
      this.el.nativeElement.focus();
    }, 300);
  }

  private timeOut;

  constructor(private el: ElementRef) {
  }

  public ngOnDestroy(): void {
    this.el.nativeElement.removeAttribute('autofocus');
    clearTimeout(this.timeOut);
  }
}
