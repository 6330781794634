import {Validators} from '@angular/forms';

export class PdfText {
  pdftextlangid?: number;
  label?: string;
  text?: string;
  labelText?: string;
}

export class PdfTextValidate {
  public static rules = {
    ['labelText']: [Validators.required, Validators.maxLength(100)],
    ['text']: [Validators.required]
  };
}
