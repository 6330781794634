import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {select, Store} from '@ngrx/store';
import {AppState, selectPdfTextReducer} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {PdfText, PdfTextValidate} from '../../../models/pdf-text';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {UserLanguage} from '../../../models/user-language';
import {Country} from '../../../models/country';
import {CountriesGQL, SavePdfTextGQL, UserLanguagesGQL} from '../../../api/graphql';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {SelectPdfTextAction} from '../../../store/actions/pdf-text.actions';
import { log } from 'console';

@Component({
  selector: 'app-pdf-text',
  templateUrl: './pdf-text.component.html',
  styleUrls: ['./pdf-text.component.scss']
})
export class PdfTextComponent extends FormAbstract<PdfText>  implements OnInit {

  public languageList: Array<UserLanguage>;
  public countryList: Array<Country>;
  // public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService, private countryListGQL: CountriesGQL,
              private userLanguageGQL: UserLanguagesGQL, private savePdfTextGQL: SavePdfTextGQL) {

    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'pdftextlangid'},
      {field: 'label'},
      {field: 'languageid'},
      {field: 'labelText', validator: PdfTextValidate.rules.labelText},
      {field: 'text', validator: PdfTextValidate.rules.text},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'pdftextlangid',
      translationId: null
    };
    this.languageId = this.authService.userLanguage.languageid;
    this.translateWithCountry = true;
    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.languageId = this.authService.userLanguage.languageid;
    } else {
      this.store.pipe(select(selectPdfTextReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        this.languageId = this.authService.userLanguage.languageid;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
        }
      });
    }
  }

  ngOnInit() {
    this.countryListGQL.watch({name: ''}
    ).result().then((data) => {
      this.countryList = data.data['Countries'] as any;
    });
    this.userLanguageGQL.watch({}
    ).result().then((data) => {
      this.languageList = data.data['UserLanguages'] as any;
    });
  }

  changeSelctedLanguage(event){
    console.log(event);
    
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      countryId: this.authService.user.country.countryid,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.languageId = this.authService.userLanguage.languageid;
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[5]}));
      if (this.facadeService.filterArrayContains(args.data['savePdfText'], ['label'], LinkKey.PDF_TEXT)) {
        this.store.dispatch(new SelectPdfTextAction({selected: args.data['savePdfText']}));
        this.facadeService.setDetailHidden(false);
      }
    };
    this.facadeService.mutateObject(this.savePdfTextGQL, variables, _cascadeEffect);
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.pdfText.newPdfText';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.pdfText.editText';
      }
      default: {
        return 'administration.pdfText.text';
      }
    }
  }
}
