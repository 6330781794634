import gql from 'graphql-tag';
import {SideEffectLang} from './side-effect-lang';
import {CustomValidate} from './custom-validate';
import {Validators} from '@angular/forms';

export class SideEffect {
  sideeffectid?: number;
  doctorfromlevel?: number;
  label?: string;
  level1?: string;
  level2?: string;
  level3?: string;
  level4?: string;
  level5?: string;
  literature?: string;
  measures?: string;
  medicalterm?: string;
  riskfactors?: string;
  status?: string;
  sideeffect?: string;
  symptoms?: string;
  translations?: Array<SideEffectLang>;
}
export class SideEffectValidate {
  public static rules = {
    ['label']: [Validators.required, Validators.maxLength(100)],
    ['level1']: [Validators.maxLength(100)],
    ['level2']: [Validators.maxLength(100)],
    ['level3']: [Validators.maxLength(100)],
    ['level4']: [Validators.maxLength(100)],
    ['level5']: [Validators.maxLength(100)],
    ['medicalterm']: [Validators.maxLength(100)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}

export function gql_MED_SIDE_EFFECT_LIST() {
  return gql`
   query SideEffects($label: String, $status: [Int], $languageId: Int){
    SideEffects(label: $label, status: $status, languageId: $languageId) {
      label
      sideeffectid
      translations {
        sideeffectlangid
        sideeffectid
        label
        languageid
      }
    }
  }`;
}

export function gql_SIDE_EFFECT_LIST() {
  return gql`
  query SideEffects($label: String, $status: [Int]){
    SideEffects(label: $label, status: $status) {
      label
      sideeffectid
      sideeffect
      symptoms
      level1
      level2
      level3
      level4
      level5
      doctorfromlevel
      medicalterm
      riskfactors
      measures
      literature
      status
      translations {
        sideeffectlangid
        sideeffectid
        sideeffect
        label
        symptoms
        level1
        level2
        level3
        level4
        level5
        riskfactors
        measures
        languageid
      }
    }
  }
  `;
}

export function gql_INSERT_SIDEEFFECT($sideEffect: SideEffect) {
  let translations = `
    translations: []
  `;
  if ($sideEffect.translations) {
    let transItems = '';
    $sideEffect.translations.forEach(t => {
      transItems +=
        `{
          sideeffectid:  ${t.sideeffectid || null},
          sideeffectlangid: ${t.sideeffectlangid || null},
          languageid: ${t.languageid},
          level1: """${t.level1}""",
          level2: """${t.level2}""",
          level3: """${t.level3}""",
          level4: """${t.level4}""",
          level5: """${t.level5}""",
          measures: """${t.measures}""",
          riskfactors: """${t.riskfactors}""",
          sideeffect: """${t.sideeffect}""",
          symptoms: """${t.symptoms}""",
          label: """${t.label}"""
        },
         `;
    });
    translations = `
    translations: [
      ${transItems}
    ]`;
  }
  return gql`
    mutation saveSideEffect {
      saveSideEffect(
      sideEffect: {
        sideeffectid: ${$sideEffect.sideeffectid || null},
        label: """${$sideEffect.label}""",
        doctorfromlevel: ${$sideEffect.doctorfromlevel},
        level1: """${$sideEffect.level1}""",
        level2: """${$sideEffect.level2}""",
        level3: """${$sideEffect.level3}""",
        level4: """${$sideEffect.level4}""",
        level5: """${$sideEffect.level5}""",
        literature: """${$sideEffect.literature}""",
        measures: """${$sideEffect.measures}""",
        medicalterm: """${$sideEffect.medicalterm}""",
        riskfactors: """${$sideEffect.riskfactors}""",
        sideeffect: """${$sideEffect.sideeffect}""",
        symptoms: """${$sideEffect.symptoms}""",
        status: ${$sideEffect.status},
        ${translations}
      }) {
        label
        sideeffectid
        sideeffect
        symptoms
        level1
        level2
        level3
        level4
        level5
        doctorfromlevel
        medicalterm
        riskfactors
        measures
        literature
        status
        translations {
          sideeffectlangid
          sideeffectid
          sideeffect
          label
          symptoms
          level1
          level2
          level3
          level4
          level5
          riskfactors
          measures
          languageid
        }
      }
    }`;
}
