import {All, TextActions} from '../actions/text.actions';
import {Text} from '../../models/text';

export interface TextState {
  selected: Text;
}

export const initialState: TextState = {
  selected: null
};

export function reducer(state = initialState, action: All): TextState {
  switch (action.type) {
    case TextActions.SELECT_TEXT: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
