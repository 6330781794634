import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';
import {FormGroup, Validators} from '@angular/forms';
import {FacadeService} from '../../../../../services/facade.service';
import {EmailDoctorGQL} from '../../../../../api/graphql';

@Component({
  selector: 'app-doctor-email',
  templateUrl: './doctor-email.component.html',
  styleUrls: ['./doctor-email.component.scss']
})
export class DoctorEmailComponent implements OnInit {
  public letter: string;
  public subject: string;
  public email: string;
  public formGroup: FormGroup;

  constructor(private bsRef: BsModalService, private facadeService: FacadeService, private emailDoctorGQL: EmailDoctorGQL) {
    this.letter = '';
    this.subject = '';
    const baseFields = [
      {field: 'letter', validator: [Validators.required, Validators.minLength(3)]},
      {field: 'subject', validator: [Validators.required, Validators.minLength(1)]}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(baseFields);
    this.email = this.bsRef.config.initialState['email'];
  }

  ngOnInit() {
  }

  public onSubmit() {
    const data = {...this.formGroup.value};
    this.emailDoctorGQL.mutate({
      subject: data['subject'],
      letter: data['letter'],
      email: this.email
    }).subscribe(next => {
      this.bsRef.hide(1);
    });
  }

  public closeModal() {
    this.bsRef.hide(1);
  }

}
