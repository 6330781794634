import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BsLocaleService, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SUB_MENU_LIST_PATIENTS } from '../../../config/sub-menu';
import { FormAbstract } from '../../../models/abstract/form.abstract';
import { ActionStateEnum } from '../../../models/enums/actions-state.enum';
import { Patient, PatientValidate } from '../../../models/patient';
import { AuthService } from '../../../services/auth.service';
import { FacadeService } from '../../../services/facade.service';
import { ShareService } from '../../../services/share.service';
import { ChangeSubMenuAction } from '../../../store/actions/sub-menu.actions';
import { AppState, selectPatientReducer } from '../../../store/app.states';
// @ts-ignore
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import * as moment from 'moment';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import {
  ChangePatientDoctorGQL, DeleteDocumentationGQL,
  DeleteIntakePlanGQL, DeletePatientDoctorGQL,
  DeletePatientNoteGQL, PatientGQL, PatientUniqueGQL,
  SaveIntakePlanGQL, SavePatientGQL, SideEffectsByIdGQL
} from '../../../api/graphql';
import { Doctor } from '../../../models/doctor';
import { LinkKey } from '../../../models/enums/link-key.enum';
import { IntakeAddPrep } from '../../../models/intake-add-prep';
import { IntakeMed } from '../../../models/intake-med';
import { IntakePlan } from '../../../models/intake-plan';
import { Medicament } from '../../../models/medicament';
import { PatientDoc } from '../../../models/patient-doc';
import { PatientDocSide } from '../../../models/patient-doc-side';
import { PatientDoctor } from '../../../models/patient-doctor';
import { PatientNote } from '../../../models/patient-note';
import { UserLanguage } from '../../../models/user-language';
import { DownloadService } from '../../../services/download.service';
import { RolesService } from '../../../services/roles.service';
import { LoadDetailByIdAction } from '../../../store/actions/common.actions';
import { SelectPatientAction } from '../../../store/actions/patient.actions';
import { CustomDatePipe } from '../../shared/pipes/custom-date.pipe';
import { DoctorsComponent } from '../doctors/doctors.component';
import { MedicamentsComponent } from '../medicaments/medicaments.component';
import { ModalPrintComponent } from '../modal-print/modal-print.component';
import { DoctorEmailComponent } from './modals/doctor-email/doctor-email.component';
import { PatientDoctorComponent } from './modals/patient-doctor/patient-doctor.component';
import { PatientDocumentationComponent } from './modals/patient-documentation/patient-documentation.component';
import { PatientMedicamentComponent } from './modals/patient-medicament/patient-medicament.component';
import { PatientNotaComponent } from './modals/patient-nota/patient-nota.component';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent extends FormAbstract<Patient> implements OnInit {

  // private printableContent: ElementRef<HTMLDivElement>;

  public isIntakePlanActive: boolean;
  public isEditDaily: boolean;
  public isAddOrDeleteMedOrAppPrep: boolean;
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public selectedLanguage: UserLanguage;
  public selectedIntakePlan: number;
  public selectedIntakePlanToPrint: number;
  public SelectedPeriodDays: number;
  public printAfterLoad: boolean;
  public listOfMedicament: Array<{ dateFrom: string, dateTo: string, medicamentList: Array<Medicament> }>;
  public modalRef: BsModalRef;
  public actionIntakePlan: ActionStateEnum;
  public intakeFromDate: string;
  public intakeToDate: string;
  public firstLastDayValue: string = '';
  public subscription: Subscription;
  public keyUp = new Subject<any>();
  public intakeStatusList: Array<any>;
  public stateNotSaved: boolean;
  public calendarFromDate: Date = new Date();
  public calendarToDate: Date = new Date();
  public medicamentsIntakePlan: Array<Medicament>;
  public currentDaysDifference: number;
  public dateDiffChange: boolean;
  public currentFromDate: any;
  public currentToDate: any;
  public dateEditable: boolean;
  public temporaryIntakePlan: IntakePlan;
  public onCancelIntakePlan: string;
  public customDatePipe = new CustomDatePipe(this.shareService);

  constructor(protected store: Store<AppState>, private _http: HttpClient,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, protected authService: AuthService,
    private savePatientGQL: SavePatientGQL, private rolesService: RolesService,
    private patientGQL: PatientGQL, private deletePatientDoctorGQL: DeletePatientDoctorGQL,
    private deletePatientNoteGQL: DeletePatientNoteGQL, private patientUniqueGQL: PatientUniqueGQL,
    private saveIntakePlanGQL: SaveIntakePlanGQL, private changePatientDoctorGQL: ChangePatientDoctorGQL,
    private translate: TranslateService, private coolDialogs: NgxCoolDialogsService,
    private sideEffectsByIdGQL: SideEffectsByIdGQL,
    private deleteIntakePlanGQL: DeleteIntakePlanGQL, private deleteDocumentationGQL: DeleteDocumentationGQL,
    private downloadService: DownloadService, private localeService: BsLocaleService) {
    super(store, bsRef, shareService, facadeService, authService);
    this.isIntakePlanActive = false;
    this.isEditDaily = false;
    this.isAddOrDeleteMedOrAppPrep = false;
    this.dateEditable = true;
    this.dateDiffChange = false;
    this.action = ActionStateEnum.VIEW;
    this.subscription = new Subscription();
    this.stateNotSaved = false;
    this.selectedIntakePlanToPrint = 0;
    this.currentFromDate = undefined;
    this.currentToDate = undefined;
    this.currentDaysDifference = undefined;
    this.printAfterLoad = false;
    this.baseFields = [
      { field: 'patientid' },
      { field: 'doctorid' },
      { field: 'languageid', defaultValue: null, validator: PatientValidate.rules.languageid },
      { field: 'pharmacyid' },
      { field: 'countryid', validator: PatientValidate.rules.countryid },
      { field: 'acceptance', validator: PatientValidate.rules.acceptance },
      { field: 'birthyear', validator: PatientValidate.rules.birthyear },
      { field: 'disease' },
      { field: 'email', validator: PatientValidate.rules.email },
      { field: 'patientno', validator: PatientValidate.rules.patientno },
      { field: 'phone', validator: PatientValidate.rules.phone },
      { field: 'sex', validator: PatientValidate.rules.sex },
      { field: 'status', validator: PatientValidate.rules.status, defaultValue: 1 }
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'patientid',
      translationId: null
    };
    /**
     * @NGx-Bootstrap Modal
     */
    this.languageId = this.authService.userLanguage.languageid;
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.baseObject = new Patient();
      this.formGroup.patchValue({ 'countryid': this.authService.user.country.countryid });
      this.formGroup.patchValue({ 'pharmacyid': this.authService.user.pharmacyid });
    } else {
      this.store.pipe(select(selectPatientReducer)).subscribe((next: any) => {
        this.isIntakePlanActive = false;
        this.action = ActionStateEnum.VIEW;
        this.isEditDaily = false;
        this.dateEditable = true;
        if (next.selected) {
          this.listOfMedicament = next.selected.intakePlans.map(t => {
            return {
              dateFrom: t.datefrom,
              dateTo: t.dateto,
              medicamentList: t.intakeMeds.map(m => {
                return m.medicament;
              })
            };
          });
          this.baseObject = next.selected;
          this.prepareForm();
          this.formGroup.patchValue({ 'status': this.baseObject.status });
          this.formGroup.patchValue({ 'countryid': this.authService.user.country.countryid });
          this.formGroup.patchValue({ 'pharmacyid': this.authService.user.pharmacyid });
        }
        if (this.printAfterLoad) {
          this.printIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlanToPrint], this.selectedIntakePlanToPrint);
          this.printAfterLoad = false;
        }
      });
    }
    this.subscription = this.keyUp
      .map(event => event.target.value)
      .pipe(debounceTime(100))
      .pipe(distinctUntilChanged())
      .pipe(flatMap(search => this.checkUniquePatient(search).then(args => args.data)))
      .subscribe((next) => {
        if (next.PatientUnique['length'] > 0) {
          switch (this.action) {
            case ActionStateEnum.CREATE: {
              this.formGroup.get('patientno').setErrors({ 'patientnounique': true });
              break;
            }
            case ActionStateEnum.EDIT: {
              if (this.baseObject.patientid !== next.PatientUnique[0].patientid) {
                this.formGroup.get('patientno').setErrors({ 'patientnounique': true });
              }
              break;
            }
          }
        }
      });
    this.languageList = [{ languageid: null, language: '', shortname: '' }, ...this.facadeService.availableLanguages];
  }

  ngOnInit() {
    this.genderList = this.facadeService.getGender(this.languageId);
    this.statusList = this.facadeService.getStatus(this.languageId);
    this.intakeStatusList = [...this.facadeService.getMedicamentIntakePlanStatus(this.languageId)];
    this.localeService.use(this.shareService.getLocaleById(this.languageId));
    this.isEditDaily = false;
    this.dateEditable = true;
  }

  public checkUniquePatient(event: any) {
    return this.patientUniqueGQL.watch({ patientNo: event, pharmacyId: this.authService.user.pharmacyid }).result();
  }

  public checkTotalIntakeDays(){
    if (this.intakeFromDate && this.intakeToDate) {
      const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
      const dateto = this.shareService.getStorableDate(this.intakeToDate);

      const startDate:any = new Date(datefrom);
      const endDate:any = new Date(dateto);
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(0, 0, 0, 0);
      this.SelectedPeriodDays = Math.ceil(Math.abs((startDate - endDate) /  (1000 * 3600 * 24))) + 1;

      //new way
      // this.SelectedPeriodDays = Math.abs(Math.round((startDate - endDate) / (1000 * 3600 * 24))) + 1;
    }
  }

  public printDeclaration() {
    this.downloadService.downloadFileWithFileID(2, this.baseObject.languageid);
  }

  public newNotePatient() {
    this.modalRef = this.bsRef.show(PatientNotaComponent, {
      class: '', initialState: { data: this.baseObject, action: ActionStateEnum.CREATE }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public editPatientNote(item) {
    this.modalRef = this.bsRef.show(PatientNotaComponent, {
      class: '',
      initialState: {
        data: {
          ...item,
          countryid: this.baseObject.countryid,
          patientid: this.baseObject.patientid
        },
        action: ActionStateEnum.EDIT
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newPatientDoc() {
    this.modalRef = this.bsRef.show(PatientDoctorComponent, {
      class: 'modal-lg', initialState: { data: this.baseObject, action: ActionStateEnum.CREATE }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  // public editDailySchedule() {

  //   const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
  //   const dateto = this.shareService.getStorableDate(this.intakeToDate);
  //   const newDaysDifference = this.getDaysDifference(datefrom, dateto);
  //   this.firstLastDayValue = this.intakeFromDate + ' - ' + this.intakeToDate;

  //   if (this.currentDaysDifference == undefined) {

  //     if (this.isAddOrDeleteMedOrAppPrep) {
  //       this.generateDailyIntakePlan(true);
  //       this.isAddOrDeleteMedOrAppPrep = false;
  //     } else {
  //       this.generateDailyIntakePlan();
  //     }

  //     this.isEditDaily = true;
  //     this.dateEditable = false;
  //   } else {

  //     if (this.currentDaysDifference != newDaysDifference) {

  //       const _callbackX = () => {

  //         this.generateDailyIntakePlan(true);
  //         this.isEditDaily = true;
  //         this.dateEditable = false;
  //       };
  //       const _cancelEffect = () => {
  //         this.setDateToOldDates();
  //       };
  //       this.shareService.confirmPlanDateChange(_callbackX, _cancelEffect, this.coolDialogs);
  //     } else {
  //       if (this.isAddOrDeleteMedOrAppPrep) {
  //         this.generateDailyIntakePlan(true);
  //         this.isAddOrDeleteMedOrAppPrep = false;
  //       } else {
  //         this.generateDailyIntakePlan();
  //       }
  //       this.isEditDaily = true;
  //       this.dateEditable = false;
  //     }
  //   }
  // }

  public editDailySchedule(forceGenerate?: boolean, printAfter?: boolean) {

    if (printAfter) {
      this.printAfterLoad = true;
      this.selectedIntakePlanToPrint = this.selectedIntakePlan;
    }
    const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
    const dateto = this.shareService.getStorableDate(this.intakeToDate);
    const newDaysDifference = this.getDaysDifference(datefrom, dateto);


    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = datefrom;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = dateto;

    // const _cascadeEffect = (args) => {
    //   this.isEditDaily = true;
    //   this.dateEditable = false;
    // };

    if (this.currentDaysDifference == undefined) {

      if (forceGenerate === true) {
        if (this.currentDaysDifference == undefined) {

          if (this.isAddOrDeleteMedOrAppPrep) {
            this.generateDailyIntakePlan(true);
            this.isAddOrDeleteMedOrAppPrep = false;
          } else {
            this.generateDailyIntakePlan();
          }
        } else {
          if (this.currentDaysDifference != newDaysDifference) {
            this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
            this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
          } else {

            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }
          }
        }
      } else {

        if (this.isAddOrDeleteMedOrAppPrep) {
          this.generateDailyIntakePlan(true);
          this.isAddOrDeleteMedOrAppPrep = false;
        } else {
          this.generateDailyIntakePlan();
        }

      }

      // const variables = {
      //   intakePlan: {
      //     ...this.baseObject.intakePlans[this.selectedIntakePlan],
      //     countryid: this.baseObject.countryid,
      //     patientid: this.baseObject.patientid,
      //     pharmacyid: this.authService.user.pharmacyid,
      //     datefrom: datefrom,
      //     dateto: dateto,
      //     intakePlanDates: [],
      //   },
      //   intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
      //   action: this.actionIntakePlan,
      //   forceDelete: forceGenerate === true || this.stateNotSaved === true,
      // };
      // this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);

      this.isEditDaily = true;
      this.dateEditable = false;
    } else {

      if (this.currentDaysDifference != newDaysDifference) {

        this.dateDiffChange = true;
        const _callbackX = () => {

          if (forceGenerate === true) {
            if (this.currentDaysDifference == undefined) {
              if (this.isAddOrDeleteMedOrAppPrep) {
                this.generateDailyIntakePlan(true);
                this.isAddOrDeleteMedOrAppPrep = false;
              } else {
                this.generateDailyIntakePlan();
              }
            } else {
              if (this.currentDaysDifference != newDaysDifference) {
                this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
                this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
              } else {
                if (this.isAddOrDeleteMedOrAppPrep) {
                  this.generateDailyIntakePlan(true);
                  this.isAddOrDeleteMedOrAppPrep = false;
                } else {
                  this.generateDailyIntakePlan();
                }
              }
            }
          } else {
            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }
          }

          // const variables = {
          //   intakePlan: {
          //     ...this.baseObject.intakePlans[this.selectedIntakePlan],
          //     countryid: this.baseObject.countryid,
          //     patientid: this.baseObject.patientid,
          //     pharmacyid: this.authService.user.pharmacyid,
          //     datefrom: datefrom,
          //     dateto: dateto,
          //     intakePlanDates: [],
          //   },
          //   intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
          //   action: this.actionIntakePlan,
          //   forceDelete: forceGenerate === true || this.stateNotSaved === true,
          // };
          // this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);

          this.isEditDaily = true;
          this.dateEditable = false;
          this.currentDaysDifference = newDaysDifference;
        };
        const _cancelEffect = () => {
          this.setDateToOldDates();
          // this.currentDaysDifference = undefined;
        };
        this.shareService.confirmPlanDateChange(_callbackX, _cancelEffect, this.coolDialogs);
      } else {

        if (forceGenerate === true) {
          if (this.currentDaysDifference == undefined) {

            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }

          } else {
            if (this.currentDaysDifference != newDaysDifference) {
              this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
            } else {

              if (this.isAddOrDeleteMedOrAppPrep) {
                this.generateDailyIntakePlan(true);
                this.isAddOrDeleteMedOrAppPrep = false;
              } else {
                this.generateDailyIntakePlan();
              }

            }
          }
        } else {

          if (this.isAddOrDeleteMedOrAppPrep) {
            this.generateDailyIntakePlan(true);
            this.isAddOrDeleteMedOrAppPrep = false;
          } else {
            this.generateDailyIntakePlan();
          }

        }

        // const variables = {
        //   intakePlan: {
        //     ...this.baseObject.intakePlans[this.selectedIntakePlan],
        //     countryid: this.baseObject.countryid,
        //     patientid: this.baseObject.patientid,
        //     pharmacyid: this.authService.user.pharmacyid,
        //     datefrom: datefrom,
        //     dateto: dateto,
        //     intakePlanDates: [],
        //   },
        //   intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
        //   action: this.actionIntakePlan,
        //   forceDelete: forceGenerate === true || this.stateNotSaved === true,
        // };
        // this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);

        this.isEditDaily = true;
        this.dateEditable = false;
      }
    }
  }

  public saveIntakePlan(forceGenerate?: boolean, printAfter?: boolean) {

    if (printAfter) {
      this.printAfterLoad = true;
      this.selectedIntakePlanToPrint = this.selectedIntakePlan;
    }
    const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
    const dateto = this.shareService.getStorableDate(this.intakeToDate);
    const newDaysDifference = this.getDaysDifference(datefrom, dateto);

    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = datefrom;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = dateto;
    if (forceGenerate === true) {
      this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
    } else {

      if (this.isAddOrDeleteMedOrAppPrep) {
        this.generateDailyIntakePlan(true);
        this.isAddOrDeleteMedOrAppPrep = false;
      } else {
        this.generateDailyIntakePlan();
      }

    }
    const variables = {
      intakePlan: {
        ...this.baseObject.intakePlans[this.selectedIntakePlan],
        countryid: this.baseObject.countryid,
        patientid: this.baseObject.patientid,
        pharmacyid: this.authService.user.pharmacyid,
        datefrom: datefrom,
        dateto: dateto,
        intakePlanDates: [],
      },
      intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
      action: this.actionIntakePlan,
      forceDelete: forceGenerate === true || this.stateNotSaved === true,
    };
    const _cascadeEffect = (args) => {
      this.stateNotSaved = false;
      this.closeModal();
      // Filter Match with edited Item
      this.store.dispatch(new LoadDetailByIdAction({
        object: 'Patient', query: this.patientGQL,
        action: SelectPatientAction, variables: { id: args.data['saveIntakePlan'].patient.patientid }
      }));
      this.facadeService.setDetailHidden(false);
      this.isEditDaily = false;
      this.dateEditable = true;
    };

    if (this.currentDaysDifference == undefined) {

      this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
    } else {

      if (this.currentDaysDifference != newDaysDifference) {
        this.dateDiffChange = true;
        const _callbackX = () => {
          this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
          this.currentDaysDifference = newDaysDifference;
        };
        const _cancelEffect = () => {
          this.setDateToOldDates();
          // this.currentDaysDifference = undefined;
        };
        this.shareService.confirmPlanDateChange(_callbackX, _cancelEffect, this.coolDialogs);
      } else {

        this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
      }
    }

  }

  public generateDailyIntakePlan(force?) {
    const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
    const dateto = this.shareService.getStorableDate(this.intakeToDate);
    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = datefrom;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = dateto;

    if (force) {
      this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
      this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
    } else {
      const intakePlanDates = this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates;
      if (isNullOrUndefined(intakePlanDates) || !intakePlanDates.length) {
        this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
        this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
      } else {
        const firstDate = new Date(intakePlanDates[0].date);
        const lastDate = new Date(intakePlanDates[intakePlanDates.length - 1].date);
        const fromDate = new Date(datefrom);
        const toDate = new Date(dateto);

        if (firstDate.getTime() === fromDate.getTime() && lastDate.getTime() === toDate.getTime()) {
          // No need to shift, intakePlanDates remains unchanged
          this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
          return;
        }

        // Calculate the time difference between original datefrom and new datefrom
        const timeDiff = fromDate.getTime() - firstDate.getTime();

        // Shifting the intakePlanDates array according to datefrom and dateto
        const shiftedDates = intakePlanDates.map((intakePlanDate) => {
          const date = new Date(intakePlanDate.date);
          const shiftedDate = new Date(date.getTime() + timeDiff);
          return {
            ...intakePlanDate,
            date: shiftedDate.toISOString().slice(0, 10), // Convert date to string in "YYYY-MM-DD" format
          };
        });

        this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = shiftedDates;
        this.temporaryIntakePlan = JSON.parse(JSON.stringify(this.baseObject.intakePlans[this.selectedIntakePlan]));
      }
    }
  }


  public newIntakeMed(type: 'intakeMeds' | 'intakeAddPreps') {
    const baseObject = type === 'intakeMeds' ? new IntakeMed() : new IntakeAddPrep();
    this.modalRef = this.bsRef.show(PatientMedicamentComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...baseObject,
          countryid: this.baseObject.countryid,
          patientid: this.baseObject.patientid,
          pharmacyid: this.authService.user.pharmacyid
        },
        action: ActionStateEnum.CREATE,
        callback: (args) => {
          this.onAddItem(args, type);
          this.stateNotSaved = true;
        }
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public onAddItem(args, list: any) {
    const intakePlans: IntakePlan = this.baseObject.intakePlans[this.selectedIntakePlan];
    // @ts-ignore
    intakePlans[list].push(args);
    this.generateDailyIntakePlan(true);
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.store.dispatch(new ChangeSubMenuAction({ selected: SUB_MENU_LIST_PATIENTS()[0] }));
      this.baseObject = args.data['savePatient'];
      this.closeModal();
      if (variables.action === ActionStateEnum.CREATE) {
        this.store.dispatch(new LoadDetailByIdAction({
          object: 'Patient', query: this.patientGQL,
          action: SelectPatientAction, variables: { id: this.baseObject.patientid }
        }));
      } else {
        this.prepareForm();
      }
      if (this.facadeService.filterArrayContains(args.data['savePatient'], ['patientno', 'pharmacyid'], LinkKey.PATIENTS)) {
        this.store.dispatch(new LoadDetailByIdAction({
          object: 'Patient', query: this.patientGQL,
          action: SelectPatientAction, variables: { id: this.baseObject.patientid }
        }));
        this.facadeService.setDetailHidden(false);
      }
    };
    this.facadeService.mutateObject(this.savePatientGQL, variables, _cascadeEffect);
  }

  public onSubmitIntakePlanStayOnPatientDaily(forceGenerate?: boolean, printAfter?: boolean) {
    if (printAfter) {
      this.printAfterLoad = true;
      this.selectedIntakePlanToPrint = this.selectedIntakePlan;
    }
    const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
    const dateto = this.shareService.getStorableDate(this.intakeToDate);
    const newDaysDifference = this.getDaysDifference(datefrom, dateto);

    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = datefrom;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = dateto;

    if (forceGenerate === true) {
      if (this.currentDaysDifference == undefined) {

        if (this.isAddOrDeleteMedOrAppPrep) {
          this.generateDailyIntakePlan(true);
          this.isAddOrDeleteMedOrAppPrep = false;
        } else {
          this.generateDailyIntakePlan();
        }

      } else {
        if (this.currentDaysDifference != newDaysDifference) {
          this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
        } else {

          if (this.isAddOrDeleteMedOrAppPrep) {
            this.generateDailyIntakePlan(true);
            this.isAddOrDeleteMedOrAppPrep = false;
          } else {
            this.generateDailyIntakePlan();
          }

        }
      }
    } else {

      if (this.isAddOrDeleteMedOrAppPrep) {
        this.generateDailyIntakePlan(true);
        this.isAddOrDeleteMedOrAppPrep = false;
      } else {
        this.generateDailyIntakePlan();
      }

    }
    const variables = {
      intakePlan: {
        ...this.baseObject.intakePlans[this.selectedIntakePlan],
        countryid: this.baseObject.countryid,
        patientid: this.baseObject.patientid,
        pharmacyid: this.authService.user.pharmacyid,
        datefrom: datefrom,
        dateto: dateto,
        intakePlanDates: [],
      },
      intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
      action: this.actionIntakePlan,
      forceDelete: forceGenerate === true || this.stateNotSaved === true,
    };
    const _cascadeEffect = (args) => {
      if(isNullOrUndefined(this.baseObject.intakePlans[this.selectedIntakePlan].planid)) {
        this.baseObject.intakePlans[this.selectedIntakePlan].planid = args.data['saveIntakePlan'].planid;
      }
      this.printIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlanToPrint], this.selectedIntakePlanToPrint);
      this.printAfterLoad = false;
    };

    if (this.currentDaysDifference == undefined) {

      this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
    } else {

      if (this.currentDaysDifference != newDaysDifference) {

        this.dateDiffChange = true;
        const _callbackX = () => {
          this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
          this.currentDaysDifference = newDaysDifference;
        };
        const _cancelEffect = () => {
          this.setDateToOldDates();
          // this.currentDaysDifference = undefined;
        };
        this.shareService.confirmPlanDateChange(_callbackX, _cancelEffect, this.coolDialogs);
      } else {

        this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
      }
    }

  }

  public onSubmitIntakePlan(forceGenerate?: boolean, printAfter?: boolean) {

    if (printAfter) {
      this.printAfterLoad = true;
      this.selectedIntakePlanToPrint = this.selectedIntakePlan;
    }
    const datefrom = this.shareService.getStorableDate(this.intakeFromDate);
    const dateto = this.shareService.getStorableDate(this.intakeToDate);
    const newDaysDifference = this.getDaysDifference(datefrom, dateto);


    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = datefrom;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = dateto;

    const _cascadeEffect = (args) => {
      this.stateNotSaved = false;
      this.closeModal();
      // Filter Match with edited Item
      this.store.dispatch(new LoadDetailByIdAction({
        object: 'Patient', query: this.patientGQL,
        action: SelectPatientAction, variables: { id: args.data['saveIntakePlan'].patient.patientid }
      }));
      this.facadeService.setDetailHidden(false);
      this.isEditDaily = false;
      this.dateEditable = true;
    };

    if (this.currentDaysDifference == undefined) {

      if (forceGenerate === true) {
        if (this.currentDaysDifference == undefined) {

          if (this.isAddOrDeleteMedOrAppPrep) {
            this.generateDailyIntakePlan(true);
            this.isAddOrDeleteMedOrAppPrep = false;
          } else {
            this.generateDailyIntakePlan();
          }

        } else {
          if (this.currentDaysDifference != newDaysDifference) {
            this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
          } else {

            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }

          }
        }
      } else {

        if (this.isAddOrDeleteMedOrAppPrep) {
          this.generateDailyIntakePlan(true);
          this.isAddOrDeleteMedOrAppPrep = false;
        } else {
          this.generateDailyIntakePlan();
        }

      }

      const variables = {
        intakePlan: {
          ...this.baseObject.intakePlans[this.selectedIntakePlan],
          countryid: this.baseObject.countryid,
          patientid: this.baseObject.patientid,
          pharmacyid: this.authService.user.pharmacyid,
          datefrom: datefrom,
          dateto: dateto,
          intakePlanDates: [],
        },
        intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
        action: this.actionIntakePlan,
        forceDelete: forceGenerate === true || this.stateNotSaved === true,
      };

      this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
    } else {

      if (this.currentDaysDifference != newDaysDifference) {

        this.dateDiffChange = true;
        const _callbackX = () => {

          if (forceGenerate === true) {
            if (this.currentDaysDifference == undefined) {

              if (this.isAddOrDeleteMedOrAppPrep) {
                this.generateDailyIntakePlan(true);
                this.isAddOrDeleteMedOrAppPrep = false;
              } else {
                this.generateDailyIntakePlan();
              }

            } else {
              if (this.currentDaysDifference != newDaysDifference) {
                this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
              } else {

                if (this.isAddOrDeleteMedOrAppPrep) {
                  this.generateDailyIntakePlan(true);
                  this.isAddOrDeleteMedOrAppPrep = false;
                } else {
                  this.generateDailyIntakePlan();
                }

              }
            }
          } else {

            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }

          }

          const variables = {
            intakePlan: {
              ...this.baseObject.intakePlans[this.selectedIntakePlan],
              countryid: this.baseObject.countryid,
              patientid: this.baseObject.patientid,
              pharmacyid: this.authService.user.pharmacyid,
              datefrom: datefrom,
              dateto: dateto,
              intakePlanDates: [],
            },
            intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
            action: this.actionIntakePlan,
            forceDelete: forceGenerate === true || this.stateNotSaved === true,
          };

          this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
          this.currentDaysDifference = newDaysDifference;
        };
        const _cancelEffect = () => {
          this.setDateToOldDates();
          // this.currentDaysDifference = undefined;
        };
        this.shareService.confirmPlanDateChange(_callbackX, _cancelEffect, this.coolDialogs);
      } else {

        if (forceGenerate === true) {
          if (this.currentDaysDifference == undefined) {

            if (this.isAddOrDeleteMedOrAppPrep) {
              this.generateDailyIntakePlan(true);
              this.isAddOrDeleteMedOrAppPrep = false;
            } else {
              this.generateDailyIntakePlan();
            }

          } else {
            if (this.currentDaysDifference != newDaysDifference) {
              this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates = this.shareService.prepareDailyIntakePlan(this.baseObject.intakePlans[this.selectedIntakePlan]);
            } else {

              if (this.isAddOrDeleteMedOrAppPrep) {
                this.generateDailyIntakePlan(true);
                this.isAddOrDeleteMedOrAppPrep = false;
              } else {
                this.generateDailyIntakePlan();
              }

            }
          }
        } else {

          if (this.isAddOrDeleteMedOrAppPrep) {
            this.generateDailyIntakePlan(true);
            this.isAddOrDeleteMedOrAppPrep = false;
          } else {
            this.generateDailyIntakePlan();
          }

        }

        const variables = {
          intakePlan: {
            ...this.baseObject.intakePlans[this.selectedIntakePlan],
            countryid: this.baseObject.countryid,
            patientid: this.baseObject.patientid,
            pharmacyid: this.authService.user.pharmacyid,
            datefrom: datefrom,
            dateto: dateto,
            intakePlanDates: [],
          },
          intakePlanDates: this.baseObject.intakePlans[this.selectedIntakePlan].intakePlanDates,
          action: this.actionIntakePlan,
          forceDelete: forceGenerate === true || this.stateNotSaved === true,
        };

        this.facadeService.mutateObject(this.saveIntakePlanGQL, variables, _cascadeEffect);
      }
    }

  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'patient.patients.newPatient';
      }
      case ActionStateEnum.EDIT: {
        return 'patient.patients.editPatient';
      }
      default: {
        return 'patient.patients.patient';
      }
    }
  }

  public deleteNote(args: PatientNote) {
    // Confirm
    const _cascadeEffect = () => {
      this.deletePatientNoteGQL.mutate({
        patientNoteId: args.noteid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        this.store.dispatch(new SelectPatientAction({ selected: next.data.deletePatientNote }));
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public canEdit(args) {
    return this.rolesService.hasAccessTo(args.obj, args.access);
  }

  /**
   * @Duplicate last intake plan or create a new ones
   * */
  public newIntakePlan() {
    this.selectedIntakePlan = 0;
    if (this.baseObject.intakePlans.length > 0) {
      this.isIntakePlanActive = true;
      this.actionIntakePlan = ActionStateEnum.CREATE;
      const lastIntakePlan: IntakePlan = {
        ...this.baseObject.intakePlans[0],
        planid: null,
        intakeMeds: Array(),
        intakeAddPreps: Array(),
        status: 1,
        planperpatient: this.baseObject.intakePlans.length
      };
      if (moment().diff(lastIntakePlan.dateto) >= 1) {
        this.intakeFromDate = this.shareService.getDate(moment());
        this.intakeToDate = this.shareService.addTime(moment(), 21, 'days');
      } else {
        this.intakeFromDate = this.shareService.addTimeFromStorableDate(lastIntakePlan.dateto, 0, 'days');
        this.intakeToDate = this.shareService.addTimeFromStorableDate(lastIntakePlan.dateto, 21, 'days');
      }
      this.baseObject.intakePlans.unshift(lastIntakePlan);
    } else {
      this.isIntakePlanActive = true;
      this.actionIntakePlan = ActionStateEnum.CREATE;
      this.intakeFromDate = this.shareService.getDate(moment());
      this.intakeToDate = this.shareService.addTime(moment(), 21, 'days');

      const intakePlan: IntakePlan = {
        planid: null,
        intakeMeds: Array(),
        intakeAddPreps: Array(),
        datefrom: '',
        dateto: '',
        status: 1,
        planperpatient: this.baseObject.intakePlans.length
      };
      this.baseObject.intakePlans.unshift(intakePlan);
    }
    this.calendarFromDate = this.shareService.fromInputFormatToDate(this.intakeFromDate);
    this.calendarToDate = this.shareService.fromInputFormatToDate(this.intakeToDate);
    this.firstLastDayValue = this.customDatePipe.transform(this.intakeFromDate) + ' - ' + this.customDatePipe.transform(this.intakeToDate);
  }

  public onValueChange(args, affectLabel: string) {
    this[affectLabel] = this.shareService.getDate(args);
    if (affectLabel === 'intakeFromDate') {
      this['intakeToDate'] = this.shareService.getDate(args);
    }
    this.checkTotalIntakeDays();
  }

  public printDailyIntakePlan(args: IntakePlan, i) {

    this.returnToPatient();
    this.printIntakePlan(args, i);
  }

  public returnToPatient() {

    this.baseObject.intakePlans[this.selectedIntakePlan] = this.temporaryIntakePlan;
    this.temporaryIntakePlan = undefined;
    this.isEditDaily = false;
    this.dateEditable = true;
  }

  public saveAndReturnToPatient() {

    this.onSubmitIntakePlan(true);
  }

  public cancelAndReturnToPatient() {
    this.baseObject.intakePlans[this.selectedIntakePlan] = JSON.parse(this.onCancelIntakePlan);
    this.isIntakePlanActive = false;
    this.onCancelIntakePlan = null;
  }

  public deleteDoctor(args: PatientDoctor) {
    const _cascadeEffect = () => {
      this.deletePatientDoctorGQL.mutate({
        patientDoctorId: args.patientdoctorid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        this.store.dispatch(new SelectPatientAction({ selected: next.data.deletePatientDoctor }));
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public openSupervision(args: PatientDoctor) {
    const _cascadeEffect = () => {
      this.changePatientDoctorGQL.mutate({
        patientDoctorId: args.patientdoctorid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        this.store.dispatch(new SelectPatientAction({ selected: next.data.changePatientDoctor }));
      });
    };
    const coolDialogs: any = this.coolDialogs;
    this.translate.get(['patient.patients.supervision', 'common.actions.save', 'common.confirm.cancel', 'common.confirm.confirm'])
      .subscribe(next => {
        coolDialogs.confirm(next['patient.patients.supervision'], {
          okButtonText: next['common.actions.save'],
          cancelButtonText: next['common.confirm.cancel'],
          title: next['common.confirm.confirm']
        })
          .subscribe(res => {
            if (res) {
              _cascadeEffect();
            }
          });
      });
  }

  public editIntakePlan(args: IntakePlan, index: number) {
    this.onCancelIntakePlan = JSON.stringify(args);
    this.currentFromDate = args.datefrom;
    this.currentToDate = args.dateto;
    this.currentDaysDifference = this.getDaysDifference(args.datefrom, args.dateto);
    this.isIntakePlanActive = true;
    this.selectedIntakePlan = index;
    this.actionIntakePlan = ActionStateEnum.EDIT;
    this.intakeFromDate = this.shareService.getDateFromStorableDate(args.datefrom);
    this.intakeToDate = this.shareService.getDateFromStorableDate(args.dateto);
    this.calendarFromDate = this.shareService.fromInputFormatToDate(this.intakeFromDate);
    this.calendarToDate = this.shareService.fromInputFormatToDate(this.intakeToDate);
    this.firstLastDayValue = this.customDatePipe.transform(this.intakeFromDate)  + ' - ' + this.customDatePipe.transform(this.intakeToDate);
  }

  public getDaysDifference(datefrom: string, dateto: string) {

    const startDate = new Date(datefrom);
    const endDate = new Date(dateto);
    this.firstLastDayValue =  this.customDatePipe.transform(datefrom)  + ' - ' + this.customDatePipe.transform(dateto);

    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  }

  public setDateToOldDates() {
    const fromDate = new Date(this.currentFromDate);
    const toDate = new Date(this.currentToDate);

    const formattedFromDate = this.formatDate(fromDate);
    const formattedToDate = this.formatDate(toDate);

    this.intakeFromDate = formattedFromDate;
    this.intakeToDate = formattedToDate;

    this.calendarFromDate = this.shareService.fromInputFormatToDate(this.intakeFromDate);
    this.calendarToDate = this.shareService.fromInputFormatToDate(this.intakeToDate);

    this.onValueChange(this.calendarFromDate, 'intakeFromDate');
    this.onValueChange(this.calendarToDate, 'intakeToDate');

    this.baseObject.intakePlans[this.selectedIntakePlan].datefrom = this.intakeFromDate;
    this.baseObject.intakePlans[this.selectedIntakePlan].dateto = this.intakeToDate;

    this.currentDaysDifference == undefined;
  }

  private formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
  }

  public printIntakePlan(args: IntakePlan, i) {
    this.selectedIntakePlan = i;
    this.medicamentsIntakePlan = args['intakeMeds'].map(m => {
      return m.medicament;
    });
    const ids = this.facadeService.reduceRightArray(args).map(s => s ? s.sideeffectid : null).filter(id => !isNullOrUndefined(id));
    const staticData = {
      fromDate: this.shareService.getDateFromStorableDate(args.datefrom),
      toDate: this.shareService.getDateFromStorableDate(args.dateto),
      patient: this.baseObject.patientno
    };
    if (ids.length > 0) {
      this.sideEffectsByIdGQL.watch({ ids: ids, notIn: false }).result().then((next: any) => {
        this.prepareIntakePlan(this.baseObject.intakePlans[i], staticData, next.data['SideEffectsById'], ids);
      });
    } else {
      this.prepareIntakePlan(this.baseObject.intakePlans[i], staticData, [], []);
    }
  }

  public copyIntakePlan(args: IntakePlan, index) {
    this.isIntakePlanActive = true;
    this.actionIntakePlan = ActionStateEnum.CREATE;
    const lastIntakePlan: IntakePlan = {
      ...this.baseObject.intakePlans[index],
      planid: null,
      intakeMeds: [...this.baseObject.intakePlans[index].intakeMeds.map(i => {
        return {
          ...i,
          intakemedid: null
        };
      })],
      intakeAddPreps: [...this.baseObject.intakePlans[index].intakeAddPreps.map(i => {
        return {
          ...i,
          planaddprepid: null
        };
      })],
      status: 1,
      planperpatient: this.baseObject.intakePlans.length
    };
    // Calculate the days
    const now = moment();
    const finish = moment()
      .add(this.shareService.getDifference(lastIntakePlan.datefrom, lastIntakePlan.dateto), 'days');
    this.intakeFromDate = this.shareService.getDate(now);

    this.intakeToDate = this.shareService.getDate(finish);
    // Showed date
    lastIntakePlan.intakePlanDates = this.shareService
      .copyIntakePlanDaily(
        this.baseObject.intakePlans[index],
        now);
    this.calendarFromDate = this.shareService.fromInputFormatToDate(this.intakeFromDate);
    this.calendarToDate = this.shareService.fromInputFormatToDate(this.intakeToDate);
    this.baseObject.intakePlans.unshift(lastIntakePlan);
    this.selectedIntakePlan = 0;
  }

  private prepareIntakePlan(args: IntakePlan, staticData, sideEffectsList, checkedSideEffects) {
    let printObject = [];
    const medicamentShortNotices = [];
    args.intakeMeds = sortBy(args.intakeMeds, (o) => o.medicament.shortname + o.strength);
    args.intakeMeds.forEach(t => {
      medicamentShortNotices.push({
        medicamentid: t.medicament.medicamentid,
        shortname: t.medicament.shortname,
        shortnotice: t.shortnotice,
        strength: t.strength,
      });
    });
    if (!isNullOrUndefined(args.intakePlanDates) && args.intakePlanDates.length) {
      printObject = args.intakePlanDates;
    } else {
      printObject = this.shareService.prepareDailyIntakePlan(args);
    }
    this.modalRef = this.bsRef.show(ModalPrintComponent, {
      class: 'modal-lg',
      initialState: {
        object: 'intakePlan',
        objectId: args.planid,
        patientId: this.baseObject.patientid,
        title: this.translate.instant('patient.patients.intakePlan') + ' ' + this.baseObject.patientno,
        intakePlan: IntakePlan,
        sideEffects: sideEffectsList,
        checkedSideEffects: checkedSideEffects,
        selectedLanguageId: this.baseObject.languageid,
        printObject: staticData,
        intakePlanObject: args,
        intakeDates: printObject,
        medicamentIntakePlan: this.medicamentsIntakePlan,
        medicamentShortNotices,
      },
      ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public get userPharmacyId() {
    return this.authService.user.pharmacyid;
  }

  public intakeOnEditCallback(args) {
    this.stateNotSaved = true;
    this.onEditItem(args.object, args.list, args.index);

    if (this.isAddOrDeleteMedOrAppPrep) {
      this.generateDailyIntakePlan(true);
      this.isAddOrDeleteMedOrAppPrep = false;
    } else {
      this.generateDailyIntakePlan();
    }

    this.isAddOrDeleteMedOrAppPrep = true;
  }

  public intakeOnDeleteCallback(args) {
    (this.baseObject.intakePlans[this.selectedIntakePlan])[args.list].splice(args.index, 1);
    this.isAddOrDeleteMedOrAppPrep = true;
  }

  public onEditItem(args, list: string, k: number) {
    const intakePlans: IntakePlan = this.baseObject.intakePlans[this.selectedIntakePlan];
    // @ts-ignore
    intakePlans[list][k] = args;
  }

  public objectWithoutKey(object, key) {
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  }

  public deleteIntakePlan(args: IntakePlan) {
    const _cascadeEffect = () => {
      this.deleteIntakePlanGQL.mutate({
        planId: args.planid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        if (next.data.deleteIntakePlan.patientid) {
          this.store.dispatch(new SelectPatientAction({ selected: next.data.deleteIntakePlan }));
        }
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public deleteDocumentation(args: PatientDoc) {
    const _cascadeEffect = () => {
      this.deleteDocumentationGQL.mutate({
        item: args.docid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        if (next.data['deleteDocumentation'].patientid) {
          this.store.dispatch(new SelectPatientAction({ selected: next.data['deleteDocumentation'] }));
        }
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public editDocumentation(action: ActionStateEnum | string, item?: PatientDoc) {
    let doc;
    if (action === ActionStateEnum.CREATE) {
      doc = new PatientDoc();
    } else {
      doc = item;
    }
    this.modalRef = this.bsRef.show(PatientDocumentationComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...doc,
          countryid: this.baseObject.countryid,
          patientid: this.baseObject.patientid,
          pharmacyid: this.authService.user.pharmacyid
        } as PatientDoc,
        action: action,
        callback: (args) => this.onAddNewReport(args)
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newReport() {
    const doc = new PatientDoc();
    this.modalRef = this.bsRef.show(PatientDocumentationComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...doc,
          countryid: this.baseObject.countryid,
          patientid: this.baseObject.patientid,
          pharmacyid: this.authService.user.pharmacyid
        } as PatientDoc,
        action: ActionStateEnum.CREATE,
        callback: (args) => this.onAddNewReport(args)
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public onAddNewReport(args) {
    console.dir(args);
  }

  public getLanguageNameById(args) {
    if (args) {
      return this.languageList.find(t => t.languageid === args).language;
    }
  }

  public sortDoctors(array?: Array<any>) {
    if (array) {
      return array.sort((a, b) => {
        const textA = a.patientDoctorDoctor['lastname'] + ', ' + a.patientDoctorDoctor['firstname'];
        const textB = b.patientDoctorDoctor['lastname'] + ', ' + b.patientDoctorDoctor['firstname'];
        return (<string>textA).localeCompare(textB);
      });
    } else {
      return [];
    }
  }

  public editDoctorData(args: Doctor) {
    this.modalRef = this.bsRef.show(DoctorsComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.EDIT,
        data: args,
        isComingFromPatient: true,
        callback: (data) => {
          this.modalRef.hide();
          if (!isNullOrUndefined(data)) {
            this.store.dispatch(new LoadDetailByIdAction({
              object: 'Patient', query: this.patientGQL,
              action: SelectPatientAction, variables: { id: this.baseObject.patientid }
            }));
          }
        }
      }
    });
    this.modalRef.content.isModal = true;
  }

  public userViewLabel() {
    switch (this.actionIntakePlan) {
      case ActionStateEnum.CREATE: {
        return 'patient.patients.newIntakePlan';
      }
      case ActionStateEnum.EDIT: {
        return 'patient.patients.editIntakePlan';
      }
    }
  }

  public getIntakePlanStatus(args) {
    switch (args) {
      case 1: {
        return 'intakePlan.operation.created';
      }
      case 2: {
        return 'intakePlan.operation.deleted';
      }
      case 3: {
        return 'intakePlan.operation.canceled';
      }
      case 4: {
        return 'intakePlan.operation.printed';
      }
    }
  }

  public getNoteStatusById(id) {
    return this.statusList.find(t => t.id === id);
  }

  public getSideEffectsPlain(list: Array<PatientDocSide>) {
    return list.map(t => t.sideEffect);
  }

  public openMedicamentDetail(med: Medicament) {
    this.modalRef = this.bsRef.show(MedicamentsComponent, {
      class: 'modal-lg',
      initialState: {
        data: med,
        action: ActionStateEnum.MODAL,
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public sendEmailToDoctor(email: string) {
    this.modalRef = this.bsRef.show(DoctorEmailComponent, {
      class: 'modal-lg',
      initialState: {
        email: email,
        action: ActionStateEnum.MODAL,
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  printPatientReport(){
    this.modalRef = this.bsRef.show(ModalPrintComponent, {
      class: 'modal-lg',
      initialState: {
        object: 'patientReport',
        objectId: this.baseObject.patientid,
        patientData: this.baseObject,
        patientMedicament: this.listOfMedicament,
        title: this.translate.instant('common.print') + ': ' + this.baseObject.patientno,
        selectedLanguageId: this.baseObject.languageid,
      },
      ignoreBackdropClick: true
    });
  }
}
