import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {
  @Input() control: FormControl;
  @Input() isLabel: boolean;
  constructor() { }

  ngOnInit() {
  }
  get errorMessage() {
    const validationMessages = 'validationMessages';
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || !this.control.pristine) && !this.control.untouched) {
        // this.control.errors[propertyName]
        return validationMessages + '.' + propertyName;
      }
    }
    return null;
  }
}
