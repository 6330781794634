import {Pipe, PipeTransform} from '@angular/core';
import {find} from 'lodash';
import {isNullOrUndefined} from 'util';
import {AuthService} from '../../../services/auth.service';

@Pipe({
  name: 'translateSelected'
})
export class TranslateSelectedPipe implements PipeTransform {
  constructor(private authService: AuthService) {
  }

  transform(value: any, label?: any, specialLanguage?: number, countryId?: number): any {
    let currentTranslate = null;
    if (!isNullOrUndefined(value)) {
      if (!isNullOrUndefined(value.translations) && value.translations !== []) {
        let langId = 1;
        if (!isNullOrUndefined(this.authService)) {
          if (!isNullOrUndefined(this.authService.userLanguage)) {
            langId = this.authService.userLanguage.languageid;
          }
        }
        if (!isNullOrUndefined(specialLanguage)) {
          langId = specialLanguage;
        }
        currentTranslate = find(value.translations, (translate) => {
          if (countryId != null && countryId != undefined) {
            return translate.languageid === langId && translate.countryid === countryId;
          }
          return translate.languageid === langId;
        });
        if (currentTranslate && !isNullOrUndefined(currentTranslate[label])) {
          return currentTranslate[label] === 'null' ? '' : currentTranslate[label];
        }
      }
      return value[label] === 'null' ? '' : value[label];
    } else {
      return '';
    }
  }
}
