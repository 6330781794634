import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';

export enum PdfTextActions {
  LOAD_PDF_TEXT = '[PdfText] LOAD_PDF_TEXT',
  LOAD_PDF_TEXT_BY_ID = '[PdfText] LOAD_PDF_TEXT_BY_ID',
  SELECT_PDF_TEXT = '[PdfText] SELECT_PDF_TEXT'
}

export class LoadPdfTextListAction implements Action {
  readonly type = PdfTextActions.LOAD_PDF_TEXT;

  constructor(public payload: PaginationData) {
  }
}

export class LoadPdfTextByIdAction implements Action {
  readonly type = PdfTextActions.LOAD_PDF_TEXT_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectPdfTextAction implements Action {
  readonly type = PdfTextActions.SELECT_PDF_TEXT;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadPdfTextListAction
  | LoadPdfTextByIdAction
  | SelectPdfTextAction;
