import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';

export class CustomValidate {
  public static onlyNumber = Validators.pattern('^[0-9]*$');

  public static onlyNumberCommaRange = Validators.pattern('^(1|8|15|22|([1-4]|\\d+(?:-\\d+)?)(?:\\s*,\\s*(?:1|8|15|22|[1-4]|\\d+(?:-\\d+)?))*)$');

  public static link(control: AbstractControl) {
    const val = control.value;
    if (isNullOrUndefined(val)) {
      return null;
    }
    const reg = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*?');
    if (val !== '' && !val.toString().match(reg)) {
      return {'invalidLink': true};
    }
    return null;
  }
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }
  public static  isValidPhone(control: AbstractControl) {
    const val = control.value;
    if (isNullOrUndefined(val)) {
      return null;
    }
    if (val !== '' && !val.toString().match(/^((00|\+)[1-9][0-9]{0,3}[- ]?|0)([1-9][0-9]*[- ]?)([- 0-9]+)$/)) {
      return {'invalidPhoneNumber': true};
    }
    return null;
  }
  public static numeric(control: AbstractControl) {
    const val = control.value;
    if (isNullOrUndefined(val)) {
      return null;
    }
    if (val !== '' && !val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
      return {'invalidNumber': true};
    }
    return null;
  }
  public static alphanumeric(control: AbstractControl) {
    const val = control.value;
    if (val == null || val === '') {
      return null;
    }
    if (!val.toString().match(/^[a-zA-Z0-9]+$/)) {
      return {'invalidAlphanumeric': true};
    }
    return null;
  }
  public static decimal(control: AbstractControl) {
    const val = control.value;
    if (isNullOrUndefined(val)) {
      return null;
    }
    if (!val.toString().match(/^-?\d*[.,]?\d{0,2}$/)) {
      return {'invalidDecimal': true};
    }
    return null;
  }
  public    static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;

    const confirmPassword = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors( {ConfirmPassword: true} );
    } else {
      return null;
    }
  }

  public static birthyearLength(control: AbstractControl) {
    const val = control.value;
    if (isNullOrUndefined(val)) {
      return null;
    }
    if (val.toString().length !== 4) {
      return {'birthyearLength': true};
    }
    return null;
  }
}
export function isValidPhone(control: AbstractControl): { [key: string]: any } | null {
  const valid = /^((00|\+)[1-9][0-9]{0,3}[- ]?|0)([1-9][0-9]*[- ]?)([- 0-9]+)$/.test(control.value);
  return valid ? null : { invalidNumber: { valid: false, value: control.value } };
}
