import {OtherNote} from '../../models/other-note';
import {All, OtherNotesAction} from '../actions/other-notes.actions';

/**
 * @author Jonathan Almanza
 * 02/12/2018
 */
export interface OtherNotesState {
  selected: OtherNote;
}

export const initialState: OtherNotesState = {
  selected: null
};

export function reducer(state = initialState, action: All): OtherNotesState {
  switch (action.type) {
    case OtherNotesAction.LOAD_OTHER_NOTES_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case OtherNotesAction.SELECT_OTHER_NOTES: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
