import {Medicament} from '../../models/medicament';
import {All, MedicamentActions} from '../actions/medicament.actions';

/**
 * @author Jonathan Almanza
 * 26/11/2018
 */

export interface MedicamentState {
  selected: Medicament;
}

export const initialState: MedicamentState = {
  selected: null
};

export function reducer(state = initialState, action: All): MedicamentState {
  switch (action.type) {
    case MedicamentActions.SELECT_MEDICAMENT: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
