import {Validators} from '@angular/forms';
import {TextCountryLang} from './text-country-lang';

export class Text {
  textid?: number;
  label?: string;
  subject?: string;
  text?: string;
  translations?: Array<TextCountryLang>;
}

export class TextValidate {
  public static rules = {
    ['label']: [Validators.required, Validators.maxLength(100)],
    ['text']: [Validators.required]
  };
}
