import {Pharmacy} from '../../models/pharmacy';
import {All, PharmacyActions} from '../actions/pharmacy.actions';
export interface PharmacyState {
  selected: Pharmacy;
}

export const initialState: PharmacyState = {
  selected: null
};

export function reducer(state = initialState, action: All): PharmacyState {
  switch (action.type) {
    case PharmacyActions.SELECT_PHARMACY: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
