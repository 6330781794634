import {Country} from '../../models/country';
import {All, CountryActions} from '../actions/country.actions';

export interface CountryState {
  selected: Country;
}

export const initialState: CountryState = {
  selected: null
};

export function reducer(state = initialState, action: All): CountryState {
  switch (action.type) {
    case CountryActions.SELECT_COUNTRY: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
