import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShareService } from 'src/app/services/share.service';

declare var $: any;

@Component({
  selector: 'app-custom-popup-twenty-one',
  templateUrl: './custom-popup-twenty-one.component.html',
  styleUrls: ['./custom-popup-twenty-one.component.scss']
})
export class CustomPopupTwentyOneComponent implements OnInit {
  
  @ViewChild('modalBody') modalBody: ElementRef;
  public htmlContent: string = '';

  @Output('loginPopupTwentyOne') loginPopupTwentyOne = new EventEmitter<any>();
  constructor(private shareService: ShareService, public sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.getTextWithHtmlTags(21);
  }

  public loginPopupTwentyOneConfirm() {
    this.loginPopupTwentyOne.emit();
  }
  
  public getTextWithHtmlTags(textid: number) {
    this.shareService.getTextWithHtmlTags(textid).then((text: string) => {
      this.htmlContent = text;
      if (this.htmlContent && this.htmlContent.length > 0) {
        $('#loginPopupTwentyOne').modal();
      } else {
        $('#loginPopupTwentyOne').modal('hide');
        setTimeout(() => {
          $('#loginPopupTwentyOne').remove();
        }, 500);
      }
    }).catch((error) => {
      console.error('Cannot get login alert message twenty one:', error);
    });
  }

  ngAfterViewInit() {
    // Check if the content exceeds the desired height
    const modalBodyElement = this.modalBody.nativeElement;
    if (modalBodyElement.scrollHeight > 200) {
      modalBodyElement.classList.add('increase-width');
    }
  }
}
