import * as auth from './reducers/auth.reducer';
import * as lang from './reducers/lang.reducer';
import * as login from './reducers/login.reducer';
import * as subMenu from './reducers/sub-menu.reducer';
import * as med from './reducers/medicament.reducer';
import * as substance from './reducers/substance.reducer';
import * as sideEffect from './reducers/side-effect.reducer';
import * as interaction from './reducers/interaction.reducer';
import * as intakeNotes from './reducers/intake-notes.reducer';
import * as otherNotes from './reducers/other-notes.reducer';
import * as addPreparations from './reducers/add-preparations.reducer';
import * as histories from './reducers/history.reducer';
import * as patient from './reducers/patient.reducer';
import * as doctor from './reducers/doctor.reducer';
import * as country from './reducers/country.reducer';
import * as patientLanguage from './reducers/patient-language.reducer';
import * as user from './reducers/user.reducer';
import * as text from './reducers/text.reducer';
import * as pdfText from './reducers/pdf-text.reducer';
import * as file from './reducers/file.reducer';
import * as sponsor from './reducers/sponsor.reducer';
import * as pharmacy from './reducers/pharmacy.reducer';
import * as employee from './reducers/employee.reducer';
import * as patientNotes from './reducers/patient-notes.reducer';
import * as patientDoctors from './reducers/patient-doctors.reducer';
import {Action, ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer} from '@ngrx/store';

export interface AppState {
  auth: auth.State;
  lang: lang.State;
  login: login.State;
  subMenu: subMenu.SubMenuState;
  Medicaments: med.MedicamentState;
  Substances: substance.SubstanceState;
  SideEffects: sideEffect.SideEffectState;
  Interactions: interaction.InteractionState;
  IntakeNotes: intakeNotes.IntakeNotesState;
  OtherNotes: otherNotes.OtherNotesState;
  AddPreparations: addPreparations.AddPreparationsState;
  histories: histories.HistoryState;
  patient: patient.PatientState;
  doctor: doctor.DoctorState;
  country: country.CountryState;
  patientLanguage: patientLanguage.PatientLanguagesState;
  user: user.UserState;
  text: text.TextState;
  pdfText: pdfText.PdfTextState;
  file: file.FileState;
  sponsor: sponsor.SponsorState;
  pharmacy: pharmacy.PharmacyState;
  employee: employee.EmployeeState;
  patientNotes: patientNotes.PatientNotesState;
  patientDoctors: patientDoctors.PatientDoctorsState;
  // router: RouterState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth.reducer,
  lang: lang.reducer,
  login: login.reducer,
  subMenu: subMenu.reducer,
  Medicaments: med.reducer,
  Substances: substance.reducer,
  SideEffects: sideEffect.reducer,
  Interactions: interaction.reducer,
  IntakeNotes: intakeNotes.reducer,
  OtherNotes: otherNotes.reducer,
  AddPreparations: addPreparations.reducer,
  histories: histories.reducer,
  patient: patient.reducer,
  doctor: doctor.reducer,
  country: country.reducer,
  patientLanguage: patientLanguage.reducer,
  user: user.reducer,
  text: text.reducer,
  pdfText: pdfText.reducer,
  file: file.reducer,
  sponsor: sponsor.reducer,
  pharmacy: pharmacy.reducer,
  employee: employee.reducer,
  patientNotes: patientNotes.reducer,
  patientDoctors: patientDoctors.reducer
  // router: routerReducer
};

export class ClearState implements Action {
  readonly type = 'CLEAR_STATE';

  constructor() {
  }
}

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    if (action.type === 'CLEAR_STATE') {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];
export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectLanguageState = createFeatureSelector<AppState>('lang');
export const selectLoginState = createFeatureSelector<AppState>('login');
export const selectApolloState = createFeatureSelector<AppState>('apollo');
export const selectSubMenusReducer = createFeatureSelector<AppState>('subMenu');
export const selectMedicamentReducer = createFeatureSelector<AppState>('Medicaments');
export const selectSubstanceReducer = createFeatureSelector<AppState>('Substances');
export const selectSideEffectReducer = createFeatureSelector<AppState>('SideEffects');
export const selectInteractionReducer = createFeatureSelector<AppState>('Interactions');
export const selectIntakeNotesReducer = createFeatureSelector<AppState>('IntakeNotes');
export const selectOtherNotesReducer = createFeatureSelector<AppState>('OtherNotes');
export const selectAddPreparationsReducer = createFeatureSelector<AppState>('AddPreparations');
export const selectHistoriesReducer = createFeatureSelector<AppState>('histories');
export const selectPatientReducer = createFeatureSelector<AppState>('patient');
export const selectDoctorReducer = createFeatureSelector<AppState>('doctor');
export const selectCountryReducer = createFeatureSelector<AppState>('country');
export const selectPatientLanguageReducer = createFeatureSelector<AppState>('patientLanguage');
export const selectUserReducer = createFeatureSelector<AppState>('user');
export const selectTextReducer = createFeatureSelector<AppState>('text');
export const selectPdfTextReducer = createFeatureSelector<AppState>('pdfText');
export const selectFileReducer = createFeatureSelector<AppState>('file');
export const selectSponsorReducer = createFeatureSelector<AppState>('sponsor');
export const selectPharmacyReducer = createFeatureSelector<AppState>('pharmacy');
export const selectEmployeeReducer = createFeatureSelector<AppState>('employee');
export const selectPatientNotesReducer = createFeatureSelector<AppState>('patientNotes');
export const selectPatientDoctorsReducer = createFeatureSelector<AppState>('patientDoctors');
