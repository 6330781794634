import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {Employee, EmployeeValidate} from '../../../models/employee';
import {BsModalService, TypeaheadMatch} from 'ngx-bootstrap';
import {select, Store} from '@ngrx/store';
import {FacadeService} from '../../../services/facade.service';
import {ShareService} from '../../../services/share.service';
import {AppState, selectEmployeeReducer, selectSubstanceReducer} from '../../../store/app.states';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_MASTER_DATA} from '../../../config/sub-menu';
import {AuthService} from '../../../services/auth.service';
import {SelectEmployeeAction} from '../../../store/actions/employee.actions';
import {UserLanguage} from '../../../models/user-language';
import {SaveEmployeeGQL, SavePatientDoctorGQL, UserLanguagesGQL, UserUniqueGQL} from '../../../api/graphql';
import {isNullOrUndefined} from 'util';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {SelectIntakeNotesAction} from '../../../store/actions/intake-notes.actions';
import { LogOut } from 'src/app/store/actions/auth.actions';
import { ThrowStmt } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent extends FormAbstract<Employee> implements OnInit {
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public languageList: Array<UserLanguage>;
  public salutationList: Array<any>;
  public selectedLanguageId: number;
  public selectedProffesionalGroup: string;
  public proffesionalGroupList: Array<any>;

  constructor(protected store: Store<AppState>, private userLanguageGQL: UserLanguagesGQL,
              protected bsRef: BsModalService, protected shareService: ShareService,
              public facadeService: FacadeService, protected authService: AuthService,
              private translate: TranslateService,
              private  saveEmployeeGQL: SaveEmployeeGQL, private userUniqueGQL: UserUniqueGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'apothekemaid'},
      {field: 'countryid'},
      {field: 'pharmacyid'},
      {field: 'email', validator: EmployeeValidate.rules.email},
      {field: 'firstname', validator: EmployeeValidate.rules.firstname},
      {field: 'lastname', validator: EmployeeValidate.rules.lastname},
      {field: 'phone', validator: EmployeeValidate.rules.phone},
      {field: 'salutation', validator: EmployeeValidate.rules.salutation},
      {field: 'shortname', validator: EmployeeValidate.rules.shortname},
      {field: 'status', validator: [], defaultValue: 1},
      {field: 'title'},
      {field: 'userid'},
      {field: 'professionalgroup', validator: EmployeeValidate.rules.professionalgroup}

    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'apothekemaid',
      translationId: null
    };
    this.selectedLanguageId = this.authService.userLanguage.languageid;
    this.languageId = this.authService.userLanguage.languageid;
    if (this.bsRef.config.initialState['action'] && this.bsRef.config.initialState['action'] === ActionStateEnum.CREATE) {
      this.action = this.bsRef.config.initialState['action'];
      this.baseObject = new Employee();
    } else {
      this.store.pipe(select(selectEmployeeReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          if (next.selected.professionalgroup === null || next.selected.professionalgroup === undefined) {
            next.selected.professionalgroup = '';
          }
          this.baseObject = next.selected;
          const defaultProfessionalGroups = this.facadeService.getDefaultProfessionalGroups();
          // const defaultProfessionalGroups = ['PTA', 'pharmacist', 'pharmacy engineer', 'student'];  
          if (defaultProfessionalGroups.includes(this.baseObject.professionalgroup)) {
                this.selectedProffesionalGroup = this.baseObject.professionalgroup;
          }else{
            this.selectedProffesionalGroup = 'other';
          }
          this.changeProffesionalGroupName();
          this.prepareForm();
        }
      });
    }
  }

  ngOnInit() {
    this.salutationList = this.facadeService.getSalutation();
    this.proffesionalGroupList = this.facadeService.getProffesionalGroupList();
    this.userLanguageGQL.watch({}
    ).result().then((data) => {
      this.languageList = data.data['UserLanguages'] as any;
    });

    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public getStatusById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public getSalutationEById(args) {
    if (!isNullOrUndefined(args)) {
      return this.salutationList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public getLanguageById(args) {
    if (!isNullOrUndefined(args)) {
      return this.languageList.find(t => t.languageid === args).language;
    } else {
      return '';
    }
  }

  public checkUniqueEmployee(event: any) {
    if (event.target.value && this.formGroup.controls['email'].valid) {
      this.userUniqueGQL.watch(
        {email: event.target.value, userId: !isNullOrUndefined(this.baseObject.userid) ? this.baseObject.userid : 0}).result().then(
        (data) => {
          if (data.data['UserUnique']) {
            this.formGroup.get('email').setErrors({'emailUnique': true});
          }
        }
      );
    }
  }

  public getProfessionalgroup(){
    let professionalgroup = this.proffesionalGroupList.find(f => f.value == this.formGroup.get("professionalgroup").value)
    return professionalgroup ? this.translate.instant(professionalgroup.name) : this.formGroup.get("professionalgroup").value; 
  }

  public onSubmit() {
    const isPharmacy = this.baseObject.isPharmacy === true ? 1 : 0;
    this.baseObject = this.formGroup.value;
    this.baseObject.countryid = this.authService.user.country.countryid;
    this.baseObject.pharmacyid = this.authService.user.pharmacyid;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.selectedLanguageId,
      isPharmacy: isPharmacy,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_MASTER_DATA()[1]}));
      if (this.facadeService.filterArrayContainsPairs(args.data['saveEmployee'], [
        {a: 'firstname', b: 'name'},
        {a: 'lastname', b: 'name'}], LinkKey.EMPLOYEES)) {
        this.store.dispatch(new SelectEmployeeAction({selected: args.data['saveEmployee']}));
        this.facadeService.setDetailHidden(false);
      }
    };
    this.facadeService.mutateObject(this.saveEmployeeGQL, variables, _cascadeEffect);
  }

  public changeMainConatct(){
    this.baseObject = this.formGroup.value;
    this.baseObject.countryid = this.authService.user.country.countryid;
    this.baseObject.pharmacyid = this.authService.user.pharmacyid;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.selectedLanguageId,
      isPharmacy: this.baseObject.isPharmacy === true ? 1 : 0,
      action: 'CHANGE_MAIN'
    };
    const _cascadeEffect = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_MASTER_DATA()[1]}));
      if (this.facadeService.filterArrayContainsPairs(args.data['saveEmployee'], [
        {a: 'firstname', b: 'name'},
        {a: 'lastname', b: 'name'}], LinkKey.EMPLOYEES)) {
        this.store.dispatch(new SelectEmployeeAction({selected: args.data['saveEmployee']}));
        this.facadeService.setDetailHidden(false);
        this.store.dispatch(new LogOut());
      }
    };
    this.facadeService.mutateObject(this.saveEmployeeGQL, variables, _cascadeEffect);
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'master.employee.newEmployee';
      }
      case ActionStateEnum.EDIT: {
        return 'master.employee.editEmployee';
      }
      default: {
        return 'master.employee.employee';
      }
    }
  }

  public editAction() {
    this.action = ActionStateEnum.EDIT;
  }

  public changeProffesionalGroupName() {
    if (this.selectedProffesionalGroup === 'other') {
      this.formGroup.patchValue({'professionalgroup': ''});
    }else{
      this.formGroup.patchValue({'professionalgroup': this.selectedProffesionalGroup});
    }
  }
}
