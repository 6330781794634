import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';

export enum PatientDoctorsActions {
  LOAD_PATIENT_DOCTORS = '[PATIENT_DOCTORS] LOAD_PATIENT_DOCTORS',
  SELECT_PATIENT_DOCTORS = '[PATIENT_DOCTORS] SELECT_PATIENT_DOCTORS',
  LOAD_PATIENT_DOCTORS_SUCCESS = '[PATIENT_DOCTORS] LOAD_PATIENT_DOCTORS_SUCCESS',
  LOAD_PATIENT_DOCTORS_FAILURE = '[PATIENT_DOCTORS] LOAD_PATIENT_DOCTORS_FAILURE'
}

export class LoadPatientDoctorsAction implements Action {
  readonly type = PatientDoctorsActions.LOAD_PATIENT_DOCTORS;
  constructor(public payload: PaginationData) {}
}

export class SelectPatientDoctorsAction implements Action {
  readonly type = PatientDoctorsActions.SELECT_PATIENT_DOCTORS;

  constructor(public payload: any) {
  }
}

export class LoadPatientDoctorsSuccess implements Action {
  readonly type = PatientDoctorsActions.LOAD_PATIENT_DOCTORS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPatientDoctorsFailure implements Action {
  readonly type = PatientDoctorsActions.LOAD_PATIENT_DOCTORS_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadPatientDoctorsAction
  | SelectPatientDoctorsAction
  | LoadPatientDoctorsSuccess
  | LoadPatientDoctorsFailure;
