import {PatientDocSide} from './patient-doc-side';

export class PatientDoc {
  docid?: number;
  date?: Date;
  protocol?: string;
  countryid?: number;
  patientid?: number;
  pharmacyid?: number;
  sideEffects?: Array<PatientDocSide>;
}
