import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Apollo, Mutation, Query } from 'apollo-angular';
import { find, reduceRight } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CountriesGQL, PharmaciesGQL } from '../api/graphql';
import { Country } from '../models/country';
import { LinkKey } from '../models/enums/link-key.enum';
import { LoginFormState } from '../models/enums/login-form-state';
import { EvaluationInput } from '../models/evaluations/evaluation-input';
import { IntakePlan } from '../models/intake-plan';
import { UserLanguage } from '../models/user-language';
import { SortListPipe } from '../modules/shared/pipes/sort-list.pipe';
import { TranslateSelectedPipe } from '../modules/shared/pipes/translate-selected.pipe';
import { AuthService } from './auth.service';

/**
 * @author Jonathan Almanza
 * 10/12/2018
 */
@Injectable()
export class FacadeService implements OnInit, OnDestroy {
  private _filterData: { [key: string]: any };
  private _dataList: BehaviorSubject<any>;
  private _detailHidden: BehaviorSubject<boolean>;
  private _manufacturer: BehaviorSubject<Array<{ name: string }>>;
  private _evaluation: BehaviorSubject<any>;
  private _availableLanguages: Array<UserLanguage>;
  private _activeUserLanguages: Array<UserLanguage>;
  private _availableCountries: Array<Country>;
  private _translationLanguages: Array<UserLanguage>;
  private _userTranslationLanguages: Array<UserLanguage>;
  private _mutationSubscription: Subscription;
  private _translateSelectedPipe: TranslateSelectedPipe;
  public _pharmacyLogo: string;
  private _passDockCheck: boolean;
  private _forcePassDockCheck: boolean;
  private _loginState: BehaviorSubject<LoginFormState>;

  constructor(private authService: AuthService, private apollo: Apollo,
    private _http: HttpClient, private countriesGQL: CountriesGQL, private pharmaciesGQL: PharmaciesGQL,
    private translate: TranslateService) {
    this._dataList = new BehaviorSubject<any>(null);
    this._detailHidden = new BehaviorSubject<boolean>(true);
    this._evaluation = new BehaviorSubject<any>(null);
    this._filterData = {};
    this.startFilterData();
    this._mutationSubscription = new Subscription();
    this._translateSelectedPipe = new TranslateSelectedPipe(this.authService);
    this._manufacturer = new BehaviorSubject([{ name: '' }]);
    this._pharmacyLogo = 'assets/images/logos/dgop.gif';
    this._passDockCheck = false;
    this._forcePassDockCheck = false;
    this._loginState = new BehaviorSubject(LoginFormState.LOGIN);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._mutationSubscription.unsubscribe();
  }

  public startFilterData() {
    this._filterData[LinkKey.MEDICAMENT] = {
      filterBy: {
        label: '',
        manufactor: '',
        status: [1],
        countryid: 1,
        label2: ''
      }
    };
    this._filterData[LinkKey.SUBSTANCES] = {
      label: '',
      status: [0, 1]
    };
    this._filterData[LinkKey.SIDE_EFFECTS] = {
      label: '',
      status: [0, 1]
    };
    this._filterData[LinkKey.INTERACTIONS] = {
      label: '',
      status: [1]
    };
    this._filterData[LinkKey.INTAKE_NOTES] = {
      label: '',
      status: [1]
    };
    this._filterData[LinkKey.OTHER_NOTES] = {
      label: '',
      status: [1]
    };
    this._filterData[LinkKey.ADDITIONAL_PREPARATIONS] = {
      label: '',
      status: [1]
    };
    this._filterData[LinkKey.HISTORY] = {
      tableId: 1,
      label: ''
    };
    // PATIENTS
    this._filterData[LinkKey.PATIENTS] = {
      patientno: '',
      pharmacyid: '0'
    };
    this._filterData[LinkKey.DOCTORS] = {
      name: '',
      pharmacyId: null
    };
    // Administration
    this._filterData[LinkKey.COUNTRIES] = {
      name: ''
    };
    this._filterData[LinkKey.PATIENT_LANGUAGES] = {
      language: ''
    };
    this._filterData[LinkKey.PHARMACY] = {
      name: '',
      userId: null
    };
    this._filterData[LinkKey.EMPLOYEES] = {
      name: '',
      userId: null
    };
    this._filterData[LinkKey.USERS] = {
      name: '',
      city: '',
      zip: '',
      pharmacy: '',
      email: '',
    };
    this._filterData[LinkKey.REGISTRATIONS] = {
      name: '',
      countryId: 1,
    };
    this._filterData[LinkKey.TEXT] = {
      label: '',
    };
    this._filterData[LinkKey.PDF_TEXT] = {
      label: '',
    };
    this._filterData[LinkKey.FILES] = {
      label: '',
    };
    this._filterData[LinkKey.SPONSORS] = {
      label: '',
      countryId: 1,
    };
  }

  public loadManufacturers() {
    this._http.get(`/graphql/manufacturers/${this.authService.user.country.countryid}`).subscribe((next: any) => {
      this.setManufacturer(next);
    });
  }

  public loadUserListByIds(ids, _callback) {
    return this._http.get(`/graphql/users/${ids}`).subscribe((next) => {
      _callback(next);
    });
  }
  public getEvaluationsData(_url: string = '/graphql/evaluation', requestBody?: EvaluationInput) {
    return this._http.post(_url, requestBody).pipe(share());
  }

  public evaluationsDataForExcelExport(_url: string = '/graphql/evaluation/excel-export', requestBody?: EvaluationInput) {
    return this._http.post(_url, requestBody).pipe(share());
  }

  public loadCountries() {
    this.countriesGQL.watch({ name: '' }).result().then((next: any) => {
      this.availableCountries = next.data['Countries'] as Array<Country>;
    });
  }

  public setLogoFromCountryLogo() {
    const one = this.availableCountries.find(c => c.countryid === this.authService.user.country.countryid);
    if (one) {
      this._pharmacyLogo = one.logo ? ('data:image/png;base64,' + one.logo) : 'assets/images/logos/dgop.gif';
    } else {
      this._pharmacyLogo = 'assets/images/logos/dgop.gif';
    }
  }

  public get pharmacyLogo() {
    return this._pharmacyLogo;
  }

  get dataList(): Observable<any> {
    return this._dataList.asObservable();
  }

  public setDataList(args) {
    this._dataList.next(args);
  }


  public get detailHidden(): Observable<boolean> {
    return this._detailHidden.asObservable();
  }

  public setDetailHidden(args) {
    this._detailHidden.next(args);
  }

  get availableLanguages(): Array<UserLanguage> {
    return this._availableLanguages;
  }

  set availableLanguages(value: Array<UserLanguage>) {
    this._availableLanguages = value;
  }

  get activeUserLanguages(): Array<UserLanguage> {
    return this._activeUserLanguages;
  }

  set activeUserLanguages(value: Array<UserLanguage>) {
    this._activeUserLanguages = value;
  }

  get availableCountries(): Array<Country> {
    return this._availableCountries;
  }

  set availableCountries(value: Array<Country>) {
    this._availableCountries = value;
  }

  get translationLanguages(): Array<UserLanguage> {
    return this._translationLanguages;
  }

  set translationLanguages(value: Array<UserLanguage>) {
    this._translationLanguages = value;
  }

  get userTranslationLanguages(): Array<UserLanguage> {
    return this._userTranslationLanguages;
  }

  set userTranslationLanguages(value: Array<UserLanguage>) {
    this._userTranslationLanguages = value;
  }

  get filterData(): { [p: string]: any } {
    return this._filterData;
  }

  set filterData(value: { [p: string]: any }) {
    this._filterData = value;
  }

  public get evaluation(): Observable<any> {
    return this._evaluation.asObservable();
  }

  public setEvaluation(args) {
    this._evaluation.next(args);
  }


  get manufacturer(): Observable<Array<{ name: string }>> {
    return this._manufacturer.asObservable();
  }

  public get passDockCheck(): boolean {
    return this._passDockCheck;
  }

  public set passDockCheck(value: boolean) {
    this._passDockCheck = value;
  }

  public get forcePassDockCheck(): boolean {
    return this._forcePassDockCheck;
  }

  public set forcePassDockCheck(value: boolean) {
    this._forcePassDockCheck = value;
  }

  public $loginState(): Observable<LoginFormState> {
    return this._loginState.asObservable();
  }

  public loginState(value: LoginFormState) {
    this._loginState.next(value);
  }

  public setManufacturer(value: Array<{ name: string }>) {
    const manufacturer = [];
    manufacturer.push({ name: '' });
    manufacturer.push(...value);
    this._manufacturer.next(manufacturer);
  }

  public callQueryListToUpdate(queryObject: Query<any, any>, variables: any) {
    this.apollo.getClient().cache.reset();
    const variablesWithUser = {
      ...variables,
      languageId: this.authService.user.userLanguage.languageid || 1,
      countryId: this.authService.user.country.countryid || 1
    };
    queryObject.watch(variablesWithUser).result().then(next => {
      this._dataList.next(next);
    });
  }

  public callQueryUnique(queryObject: Query<any, any>, variables: any, callback?: (args) => void): any {
    this.apollo.getClient().cache.reset();
    queryObject.watch(variables).result().then(next => {
      if (callback) {
        callback(next);
      }
    });
  }

  public mutateObject(queryObject: Mutation<any, any>, variables: any, callback?: (args) => void) {
    const variablesWithUser = {
      ...variables,
      userId: this.authService.user.userid
    };
    this.apollo.getClient().cache.reset();
    this._mutationSubscription = queryObject.mutate(variablesWithUser).subscribe(next => {
      if (callback) {
        callback(next);
      }
    });
  }

  public mutateRegistration(queryObject: Mutation<any, any>, variables: any, callback?: (args) => void) {
    this.apollo.getClient().cache.reset();
    this._mutationSubscription = queryObject.mutate(variables).subscribe(next => {
      if (callback) {
        callback(next);
      }
    });
  }

  public createFormWithValidators(fields: TranslateFormData[]): FormGroup {
    const controls = {};
    for (const labelInstance of fields) {
      if (labelInstance.subFields) {
        const subControls = {};
        for (const subInstance of labelInstance.subFields) {
          subControls[subInstance.field] = this.createFormWithValidators(labelInstance.subFields);
        }
        controls[labelInstance.field] = new FormGroup(subControls);
      } else {
        controls[labelInstance.field] = new FormControl(labelInstance.defaultValue,
          labelInstance.validator ? labelInstance.validator : []);
      }
    }
    return new FormGroup(controls);
  }

  public patchFormWithValidator(form: FormGroup, object: any, fields: TranslateFormData[]) {
    if (fields) {
      for (const labelInstance of fields) {
        if (labelInstance.subFields) {
          const subControls = form.get([labelInstance.field])['controls'];
          for (const subInstance of labelInstance.subFields) {
            if ((object[labelInstance.field])[subInstance.field]) {
              subControls[subInstance.field].patchValue({ [subInstance.field]: (object[labelInstance.field])[subInstance.field] });
            }
          }
          form.patchValue({ [labelInstance.field]: subControls });
        } else {
          try {
            form.patchValue({
              [labelInstance.field]: object[labelInstance.field] === 'null' ? null : object[labelInstance.field]
            });
          } catch (e) {
            console.error('Cannot patch label:', [labelInstance]);
          }
        }
      }
    }
  }

  public patchWholeObjectAndTranslations(form: FormGroup, object: any, fields?: TranslateFormData[],
    languageId?: number, countryId?: number) {
    form.patchValue(object);
    if (fields) {
      let currentTranslate;
      if (object.translations) {
        currentTranslate = find(object.translations, (translate) => {
          if (countryId) {
            return translate.languageid === languageId && translate.countryid === countryId;
          }
          return translate.languageid === languageId;
        });
      }
      if (!isNullOrUndefined(currentTranslate)) {
        this.patchTranslations(form, currentTranslate, fields);
      } else {
        currentTranslate = find(object.translations, (translate) => {
          if (countryId) {
            return translate.languageid === languageId && translate.countryid === countryId;
          }
          return translate.languageid === 1;
        });
        if (!isNullOrUndefined(currentTranslate)) {
          this.patchTranslations(form, currentTranslate, fields);
        } else {
          currentTranslate = find(object.translations, (translate) => {
            return translate.languageid === 1;
          });
          if (!isNullOrUndefined(currentTranslate)) {
            this.patchTranslations(form, currentTranslate, fields);
          }
        }
      }
    }
  }

  public patchTranslations(form: FormGroup, currentTranslate: any, fields: TranslateFormData[]) {
    for (const labelInstance of fields) {
      // if(labelInstance.field === 'labelText') {
      //   form.patchValue({ ['labelText']: currentTranslate['label'] });
      // } else
      if (currentTranslate.hasOwnProperty(labelInstance.field)) {
        form.patchValue({ [labelInstance.field]: currentTranslate[labelInstance.field] });
      }
    }
  }

  /**
   * 1 = Mr.
   * 2 = Mrs.
   */
  public getSalutation() {
    const keyValue = this.translate.instant(['common.salutation.mr', 'common.salutation.mrs']);
    const array = [];
    for (const k in keyValue) {
      if (k === 'common.salutation.mr') {
        array.push({ id: 1, name: keyValue[k] });
      } else {
        array.push({ id: 2, name: keyValue[k] });
      }
    }
    array.push({ id: null, name: '' });
    return new SortListPipe(this.authService).transform(array, 'name');
  }

  /**
   * 1 = open, 2 = done
   * @param languageId
   */
  public getMedicamentIntakePlanStatus(languageId) {
    return [
      { id: 1, name: this.translate.instant('intakePlan.operation.created') },
      { id: 2, name: this.translate.instant('intakePlan.operation.deleted') },
      { id: 3, name: this.translate.instant('intakePlan.operation.canceled') },
      { id: 4, name: this.translate.instant('intakePlan.operation.printed') },
    ];
  }

  /**
   * 1 = unconfirmed (registered), 2 = confirmed/active,
   * 3 = declined, 4 = disabled
   * @param languageId
   */
  public getUserStatus(languageId) {
    return [{ id: 0, name: this.translate.instant('administration.user.inactive') },
    { id: 1, name: this.translate.instant('administration.user.confirmedActive') },
    { id: 2, name: this.translate.instant('administration.user.unconfirmedRegistered') },
    { id: 3, name: this.translate.instant('administration.user.deleted') },
    { id: 4, name: this.translate.instant('administration.user.disabled') },
    { id: 5, name: this.translate.instant('administration.user.rejected') }];
  }

  /**
   * 0 = false , 1 = tue,
   * @param languageId
   */
  public getTrueFalseList(languageId) {
    return [{ id: 0, name: this.translate.instant('common.boolean.no') },
            { id: 1, name: this.translate.instant('common.boolean.yes') }];
  }

  /**
   * 1 = male
   * 2 = female
   * @param languageId
   */
  public getGender(languageId) {
    const keyValue = this.translate.instant(['common.sex.male', 'common.sex.female']);
    const array = [];
    for (const k in keyValue) {
      if (k === 'common.sex.male') {
        array.push({ id: 1, name: keyValue[k] });
      } else {
        array.push({ id: 2, name: keyValue[k] });
      }
    }
    array.push({ id: null, name: '' });
    return new SortListPipe(this.authService).transform(array, 'name');
  }

  /**  pharmacy (1), doctor (2), PTA (3), user (4)
   * @param languageId
   */

  public getIntitutionType() {
    return [
      { id: 1, name: this.translate.instant('master.pharmacy.pharmacy') },
      { id: 2, name: this.translate.instant('patient.doctors.doctor') },
      { id: 3, name: this.translate.instant('common.labels.pta') },
      { id: 4, name: this.translate.instant('administration.user.user') },
      { id: 5, name: this.translate.instant('master.employee.employee') }];
  }

  /**  active (1), inactive (0)
   * @param languageId
   */
  public getStatus(languageId) {
    return [
      { id: 1, name: this.translate.instant('searchArea.active') },
      { id: 0, name: this.translate.instant('searchArea.inactive') },
    ];
  }

  public getDefaultProfessionalGroups(){
    return ['PTA', 'pharmacist', 'pharmacy engineer', 'student'];
  }

  public getProffesionalGroupList(){
    return [
      { value: 'pharmacist', name: this.translate.instant('common.professionalgroup.pharmacist') },
      { value: 'pharmacy engineer', name: this.translate.instant('common.professionalgroup.pharmacyEngineer') },
      { value: 'PTA', name: this.translate.instant('common.professionalgroup.pta') },
      { value: 'student', name: this.translate.instant('common.professionalgroup.student') },
      { value: "other", name: this.translate.instant('common.professionalgroup.other') }];
  }

  public reduceRightArray(array: IntakePlan) {
    return reduceRight(array.intakeMeds.map(m => {
      return m.medicament.medSideEffects;
    }), (flattened, other) => {
      return flattened.concat(other);
    }, []);
  }

  public filterArrayContains(object: any, labelArray: Array<string>, filterKey: LinkKey) {
    let response = true;
    labelArray.forEach(t => {
      response = response && this.filterContains(object, t, filterKey);
    });
    return response;
  }

  public filterContains(object: any, label: string, filterKey: LinkKey) {
    switch (typeof this._filterData[filterKey][label]) {
      case 'string': {
        return this._translateSelectedPipe.transform(object, label).indexOf(this._filterData[filterKey][label]) >= 0 ||
          this._filterData[filterKey][label] === '';
      }
      case 'object': {
        if (this._filterData[filterKey][label] instanceof Array) {
          return this._filterData[filterKey][label].indexOf(object[label]) >= 0;
        }
        return false;
      }
      case 'number': {
        return this._filterData[filterKey][label] === object[label];
      }
    }
    return false;
  }

  public filterArrayContainsPairs(object: any, labelArray: Array<{ a: string, b: string }>, filterKey: LinkKey) {
    let response = false;
    labelArray.forEach(t => {
      response = response || this.filterPairsContains(object, t, filterKey);
    });
    return response;
  }

  public filterPairsContains(object: any, label: { a: string, b: string }, filterKey: LinkKey) {
    switch (typeof this._filterData[filterKey][label.b]) {
      case 'string': {
        return object[label.a].indexOf(this._filterData[filterKey][label.b]) >= 0 ||
          this._filterData[filterKey][label.b] === '';
      }
      case 'object': {
        if (this._filterData[filterKey][label.b] instanceof Array) {
          return this._filterData[filterKey][label.a].indexOf(object[label.b]) >= 0;
        }
        return false;
      }
      case 'number': {
        return this._filterData[filterKey][label.a] === object[label.b];
      }
    }
    return false;
  }
  public getEvaluationLabelsByKey(key, list, auxData?) {
    switch (key) {
      case list.NUMBERS_BY_COUNTRIES: {
        const translations = (this.translate.instant([
          'reports.pharmacies',
          'submenus.masterData.employees',
          'submenus.administration.users',
          'reports.intakePlans',
          'submenus.oraliadb.medicament',
          'submenus.oraliadb.additionalPreparations',
        ]));
        return [
          translations['reports.pharmacies'],
          translations['submenus.masterData.employees'],
          translations['submenus.administration.users'],
          translations['reports.intakePlans'],
          translations['submenus.oraliadb.medicament'],
          translations['submenus.oraliadb.additionalPreparations'],
        ];
      }
      case list.INTAKE_PLAN_BY_PERIOD: {
        return auxData.map(t => t.year);
      }
      case list.SUPPORTIVE_MEDICAMENT: {
        return auxData.map(t => t.addPrep);
      }
      default: {
        break;
      }
    }
  }
  public transInstByKey(key) {
    return this.translate.instant(key);
  }
}

export class TranslateFormData {
  field: string;
  defaultValue?: any = null;
  subFields?: TranslateFormData[];
  validator?: any;
}
