import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectOtherNotesReducer} from '../../../store/app.states';
import {OtherNote, OtherNoteValidate} from '../../../models/other-note';
import {ShareService} from '../../../services/share.service';
import {BsModalService} from 'ngx-bootstrap';
import {FacadeService} from '../../../services/facade.service';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {SelectOtherNotesAction} from '../../../store/actions/other-notes.actions';
import {SUB_MENU_LIST_ORALIA_DB} from '../../../config/sub-menu';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {AuthService} from '../../../services/auth.service';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {SaveOtherNoteGQL, UniqueOtherNoteGQL} from '../../../api/graphql';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-other-notes',
  templateUrl: './other-notes.component.html',
  styleUrls: ['./other-notes.component.scss']
})
export class OtherNotesComponent extends FormAbstract<OtherNote> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private saveOtherNoteGQL: SaveOtherNoteGQL, private uniqueOthernoteGQL: UniqueOtherNoteGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'othernoteid'},
      {field: 'othernote', validator: OtherNoteValidate.rules.othernote},
      {field: 'label', validator: OtherNoteValidate.rules.label},
      {field: 'status', validator: [], defaultValue: 1},
      {field: 'countryid'}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'othernoteid',
      translationId: 'othernotelangid'
    };
    this.languageId = this.authService.userLanguage.languageid;
    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
    } else {
      this.store.pipe(select(selectOtherNotesReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.originalObject = next.selected;
          this.prepareForm();
          this.formGroup.patchValue({'countryid': this.baseObject.countryid});
          this.formGroup.patchValue({'status': this.baseObject.status});
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      this.baseObject.countryid = this.authService.user.country.countryid;
      const variables = {
        parentObj: this.baseObject,
        languageId: this.languageId,
        action: this.action
      };
      const _cascadeUnique = (args) => {
        if (!isNullOrUndefined(args)) {
          if (args.data['UniqueOtherNote'] === true) {
            this.facadeService.mutateObject(this.saveOtherNoteGQL, variables, _cascadeEffect);
          } else {
            this.formGroup.get('label').setErrors({'labelunique': true});
          }
        }
      };
      const _cascadeEffect = (args) => {
        this.languageId = this.authService.userLanguage.languageid;
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ORALIA_DB()[5]}));
        if (this.facadeService.filterArrayContains(args.data['saveOtherNote'], ['label', 'status'], LinkKey.OTHER_NOTES)) {
          this.store.dispatch(new SelectOtherNotesAction({selected: args.data['saveOtherNote']}));
          this.facadeService.setDetailHidden(false);
        } else {
          this.store.dispatch(new SelectOtherNotesAction({selected: {}}));
        }
      };
      const VarUnique = {
        label: this.baseObject.label,
        objectId: isNullOrUndefined(this.baseObject.othernoteid) ? 0 : this.baseObject.othernoteid,
      };
      if (this.languageId === 1 || this.action === ActionStateEnum.EDIT || this.action === ActionStateEnum.CREATE) {
        this.facadeService.callQueryUnique(this.uniqueOthernoteGQL, VarUnique, _cascadeUnique);
      } else {
        this.facadeService.mutateObject(this.saveOtherNoteGQL, variables, _cascadeEffect);
      }
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

  public getStatusById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }


  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.TRANSLATE: {
        return 'oraliadb.otherNotes.translateOtherNote';
      }
      case ActionStateEnum.CREATE: {
        return 'oraliadb.otherNotes.newOtherNote';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.otherNotes.editOtherNote';
      }
      default: {
        return 'oraliadb.otherNotes.otherNote';
      }
    }
  }
}
