import {Component, OnInit} from '@angular/core';
import {ChangeModuleAction} from '../../../store/actions/sub-menu.actions';
import {AppState, selectSubMenusReducer} from '../../../store/app.states';
import {select, Store} from '@ngrx/store';
import {
  SUB_MENU_LIST_ADMINISTRATION,
  SUB_MENU_LIST_MASTER_DATA,
  SUB_MENU_LIST_ORALIA_DB,
  SUB_MENU_LIST_PATIENTS
} from '../../../config/sub-menu';
import {AvailableActions} from '../../../models/roles/available-actions';
import {RolesService} from '../../../services/roles.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  public oraliaDB = SUB_MENU_LIST_ORALIA_DB();
  public patients = SUB_MENU_LIST_PATIENTS();
  public administration = SUB_MENU_LIST_ADMINISTRATION();
  public masterData = SUB_MENU_LIST_MASTER_DATA();
  public mainMenu: any;

  constructor(private store: Store<AppState>, private rolesService: RolesService) {
    this.mainMenu = this.store.pipe(select(selectSubMenusReducer));
  }

  ngOnInit() {
  }

  public hasAcces($option: string): boolean {
    return true;
    // this.role.hasAcces($option);
  }

  getSkipLocationChange(): boolean {
    return true;
  }

  public dispatchChangeList(list: any) {
    list = list.filter(obj => this.rolesService.hasAccessTo(obj.routerLink, AvailableActions.VIEW));
    const payload = {
      selected: list[0],
      list: list
    };
    // this.store.dispatch(new ChangeSubMenuAction(payload));
    this.store.dispatch(new ChangeModuleAction(payload));
  }

  public splitMainMenuActive(data) {
    return data.translateKey.split('.')[1];
  }
}
