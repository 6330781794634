import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSelectModule} from 'ngx-select-ex';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Plugins
import {TranslateModule} from '@ngx-translate/core';
import {BsDatepickerModule, ModalModule, PopoverModule, TooltipModule} from 'ngx-bootstrap';
import {QuillModule} from 'ngx-quill';
// Shared Components
import {LangSelectComponent} from './lang-select/lang-select.component';
import {ListComponent} from './list/list.component';
import {SearchComponent} from './search/search.component';
// Regular Components
import {AdditionalPreparationsComponent} from '../oralia-db/additional-preparations/additional-preparations.component';
import {BasicDataEditComponent} from '../oralia-db/medicaments/modals/basic-data-edit/basic-data-edit.component';
import {IntakeNotesComponent} from '../oralia-db/intake-notes/intake-notes.component';
import {InteractionsComponent} from '../oralia-db/interactions/interactions.component';
import {OtherNotesComponent} from '../oralia-db/other-notes/other-notes.component';
import {SideEffectsComponent} from '../oralia-db/side-effects/side-effects.component';
import {SubstancesComponent} from '../oralia-db/substances/substances.component';
// Pipes
import {CustomDatePipe} from './pipes/custom-date.pipe';
import {RemoveTimePipe} from './pipes/deep-property.pipe';
import {IntakeDaysPipe} from './pipes/intake-days.pipe';
import {SortListPipe} from './pipes/sort-list.pipe';
import {TranslateSelectedPipe} from './pipes/translate-selected.pipe';
// Directives
import {CheckRolesDirective} from './directives/check-roles.directive';
import {ActionVisibilityDirective} from './directives/action-visibility.directive';
import {IsReadonlyDirective} from './directives/is-readonly.directive';
import {CountriesComponent} from '../oralia-db/countries/countries.component';
import {PatientLanguagesComponent} from '../oralia-db/patient-languages/patient-languages.component';
import {UsersComponent} from '../oralia-db/users/users.component';
import {TextComponent} from '../oralia-db/text/text.component';
import {PharmaciesComponent} from '../oralia-db/pharmacies/pharmacies.component';
import {EmployeesComponent} from '../oralia-db/employees/employees.component';
import {UserAccountComponent} from '../oralia-db/user-account/user-account.component';
import {ValidationMessagesComponent} from './validation-messages/validation-messages.component';
import {AddStrengthComponent} from '../oralia-db/additional-preparations/modal/add-strength/add-strength.component';
import {FocusableInputDirective} from './directives/focusable-input.directive';
import {PatientDoctorComponent} from '../oralia-db/patients/modals/patient-doctor/patient-doctor.component';
import {ModalPrintComponent} from '../oralia-db/modal-print/modal-print.component';
import {TranslateLoginPipe} from './pipes/translate-login.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {SingleSortPipe} from './pipes/single-sort.pipe';
import {OnlyNumbersDirective} from './directives/only-numbers.directive';
import { HistoryFilterPipe } from './pipes/history-filter.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { LangLoginComponent } from './lang-login/lang-login.component';
import { PdfTextComponent } from '../oralia-db/pdf-text/pdf-text.component';

@NgModule({
  declarations: [SearchComponent, ListComponent, RemoveTimePipe,
    LangSelectComponent, TranslateSelectedPipe, BasicDataEditComponent,
    IntakeNotesComponent, SubstancesComponent, SideEffectsComponent, OtherNotesComponent,
    CountriesComponent, PatientLanguagesComponent, UsersComponent, TextComponent, PdfTextComponent, PharmaciesComponent, PatientDoctorComponent,
    EmployeesComponent, UserAccountComponent, AddStrengthComponent, ModalPrintComponent,
    AdditionalPreparationsComponent, InteractionsComponent, CheckRolesDirective, SortListPipe, CustomDatePipe, IntakeDaysPipe,
    ActionVisibilityDirective,
    IsReadonlyDirective,
    ValidationMessagesComponent,
    FocusableInputDirective,
    TranslateLoginPipe,
    SafePipe,
    SingleSortPipe,
    OnlyNumbersDirective,
    HistoryFilterPipe,
    LanguagePipe,
    LangLoginComponent],
  imports: [
    CommonModule,
    // Replace CKEditor
    QuillModule,
    TranslateModule,
    FormsModule,
    NgxSelectModule.forRoot(<any>{
      keyCodeToOptionsOpen: 'Espace'
    }),
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
  ],
  exports: [SearchComponent, ListComponent, RemoveTimePipe,
    LangSelectComponent, LangLoginComponent, TranslateSelectedPipe, TranslateLoginPipe,
    SubstancesComponent, SideEffectsComponent, CheckRolesDirective,
    CountriesComponent, PatientLanguagesComponent, UsersComponent, TextComponent, PdfTextComponent, PharmaciesComponent, EmployeesComponent,
    UserAccountComponent, AddStrengthComponent, PatientDoctorComponent, ModalPrintComponent,
    IntakeNotesComponent, OtherNotesComponent, AdditionalPreparationsComponent, InteractionsComponent,
    SortListPipe, CustomDatePipe, IntakeDaysPipe, BasicDataEditComponent, ActionVisibilityDirective, IsReadonlyDirective,
    FocusableInputDirective, ValidationMessagesComponent, SafePipe, SingleSortPipe, HistoryFilterPipe, LanguagePipe],
  entryComponents: [IntakeNotesComponent, SideEffectsComponent, SubstancesComponent,
    CountriesComponent, PatientLanguagesComponent, UsersComponent, TextComponent, PdfTextComponent, PharmaciesComponent, EmployeesComponent,
    UserAccountComponent, AddStrengthComponent, PatientDoctorComponent, ModalPrintComponent,
    OtherNotesComponent, AdditionalPreparationsComponent, InteractionsComponent, BasicDataEditComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
