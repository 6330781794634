import gql from 'graphql-tag';
import { MedStrength } from './med-strength';
import { MedNote } from './med-note';
import { History } from './history';
import { Substance } from './substance';
import { MedLang } from './med-lang';
import { MedSideEffect } from './med-side-effect';
import { MedInteraction } from './med-interaction';
import { MedIntakeNote } from './med-intake-note';
import { MedOtherNote } from './med-other-note';
import { Validators } from '@angular/forms';
import { CustomValidate } from './custom-validate';

export class Medicament {
  medicamentid?: number;
  countryid?: number;
  background?: string;
  substance?: Substance;
  datemedicalinfo?: string;
  editors?: string;
  intakelong?: string;
  intakeshort?: string;
  interaction?: string;
  label?: string;
  link1?: string;
  link1text?: string;
  link2?: string;
  link2text?: string;
  link3?: string;
  link3text?: string;
  manufactor?: string;
  mischint?: string;
  shortname?: string;
  sideeffects?: string;
  status?: number;
  notes?: Array<MedNote>;
  medStrengths?: Array<MedStrength>;
  medSideEffects?: Array<MedSideEffect>;
  medInteractions?: Array<MedInteraction>;
  medIntakeNotes?: Array<MedIntakeNote>;
  medOtherNotes?: Array<MedOtherNote>;
  history?: Array<History>;
  translations?: Array<MedLang>;
}

export class MedicamentValidate {
  public static rules = {
    ['countryid']: [Validators.required],
    ['datemedicalinfo']: [Validators.maxLength(100)],
    ['link1']: [Validators.maxLength(200), CustomValidate.link],
    ['link1text']: [Validators.maxLength(60)],
    ['link2']: [Validators.maxLength(200), CustomValidate.link],
    ['link2text']: [Validators.maxLength(60)],
    ['link3']: [Validators.maxLength(200), CustomValidate.link],
    ['link3text']: [Validators.maxLength(60)],
    ['manufactor']: [Validators.maxLength(60)],
    ['shortname']: [Validators.maxLength(60), Validators.required],
    ['label']: [Validators.required, Validators.maxLength(60)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}

export function gql_MEDICAMENT_BY_ID() {
  return gql`
    query Medicament($id: Int, $pharmacyId: Int){
      Medicament(id: $id, pharmacyId: $pharmacyId) {
        ${gql_MEDICAMENT_FRAGMENT()}
      }
    }
  `;
}

export function gql_MEDICAMENT_FRAGMENT() {
  return `
      medicamentid
      label
      shortname
      manufactor
      status
      datemedicalinfo
      editors
      link1
      link1text
      link2
      link2text
      link3
      link3text
      countryid
      medSideEffects {
        medeffectid
        sideeffectid
      }
      medInteractions {
        medinteractionid
        interactionid
      }
      medIntakeNotes {
        mednoteid
        intakenoteid
      }
      medOtherNotes {
        medothernoteid
        othernoteid
      }
      notes {
        mednoteid
        medicamentid
        note
        status
        theme
        date
      }
      medStrengths {
        medstrengthid
        countryid
        medicamentid
        note
        packingsizes
        perdays
        strength
        morning
        midday
        evening
        night
        individual
      }
      sideeffects
      interaction
      intakelong
      intakeshort
      shortnotice
      background
      history {
        historyid
        operation
        objectid
        datetime
        user{
          userid
          firstname
          lastname
        }
      }
      translations {
        medlanguageid
        languageid
        medicamentid
        label
        shortname
        sideeffects
        interaction
        shortnotice
        intakelong
        intakeshort
        background
      }
      substance {
        substanceid
        label
        status
        translations {
          substancelangid
          substanceid
          languageid
          label
        }
      }`;
}
