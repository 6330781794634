import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.states';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../../../services/share.service';
import {FacadeService} from '../../../../../services/facade.service';
import {AuthService} from '../../../../../services/auth.service';
import {PatientDoctorsFreeGQL, PatientGQL, SavePatientDoctorGQL} from '../../../../../api/graphql';
import {Doctor} from '../../../../../models/doctor';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {Patient} from '../../../../../models/patient';
import {PatientDoctor} from '../../../../../models/patient-doctor';
import {FormGroup} from '@angular/forms';
import {LoadDetailByIdAction} from '../../../../../store/actions/common.actions';
import {SelectPatientAction} from '../../../../../store/actions/patient.actions';
import {DoctorsComponent} from '../../../doctors/doctors.component';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-patient-doctor',
  templateUrl: './patient-doctor.component.html',
  styleUrls: ['./patient-doctor.component.scss']
})
export class PatientDoctorComponent extends FormAbstract<PatientDoctor> implements OnInit {
  public doctorList: Array<Doctor>;
  public patient: Patient;
  public formGroup: FormGroup;
  public selectedDoctorId: number;
  public isPrescriptor: boolean;
  public modalRef: BsModalRef;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              public facadeService: FacadeService, protected authService: AuthService,
              private savePatientDoctorGQL: SavePatientDoctorGQL,
              private patientDoctorList: PatientDoctorsFreeGQL, private patientGQL: PatientGQL) {

    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.selectedDoctorId = 0;
    this.isPrescriptor = false;
    this.baseFields = [
      {field: 'patientdoctorid'},
      {field: 'prescribing'},
      {field: 'countryid'},
      {field: 'patientid'},
      {field: 'pharmacyid'},
      {field: 'lastname'},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'patientdoctorid',
      translationId: null
    };
    /**
     * @NGx-Bootstrap Modal
     */
    this.doctorList = new Array<Doctor>();
    if (this.bsRef.config.initialState['action']) {
      this.patient = this.bsRef.config.initialState['data'];
      this.action = this.bsRef.config.initialState['action'];
      if (this.action === ActionStateEnum.CREATE) {
        this.baseObject = new PatientDoctor();
      }
    }
  }

  ngOnInit() {
    this.loadDoctors();
  }

  public loadDoctors() {
    this.patientDoctorList.watch({
        patientId: this.patient.patientid,
        pharmacyId: this.authService.user.pharmacyid
      }
    ).result().then((data) => {
      this.doctorList = data.data['PatientDoctorsFree'] as any;
    });
  }

  public onSubmit() {
    const variables = {
      pharmacyId: this.authService.user.pharmacyid,
      countryId: this.authService.user.country.countryid,
      patientId: this.patient.patientid,
      doctorId: this.selectedDoctorId,
      userId: this.authService.user.userid,
      prescribing: this.isPrescriptor === true ? 1 : 0,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new LoadDetailByIdAction({
        object: 'Patient', query: this.patientGQL,
        action: SelectPatientAction, variables: {id: args.data['savePatientDoctor'].patientid}
      }));
    };
    this.facadeService.mutateObject(this.savePatientDoctorGQL, variables, _cascadeEffect);
  }

  public doSelectDoctor(value: any) {
    this.selectedDoctorId = value;
  }

  public createNewDoctor() {
    this.modalRef = this.bsRef.show(DoctorsComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE,
        data: new Doctor(),
        isComingFromPatient: true,
        callback: (args) => {
          this.modalRef.hide();
          if (!isNullOrUndefined(args)) {
            this.loadDoctors();
            this.doSelectDoctor(args.data['saveDoctor'].doctorid);
          }
        }
      }
    });
    this.modalRef.content.isModal = true;
  }
}
