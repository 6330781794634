import {All, UserActions} from '../actions/user.actions';
import {User} from '../../models/user';

export interface UserState {
  selected: User;
}

export const initialState: UserState = {
  selected: null
};

export function reducer(state = initialState, action: All): UserState {
  switch (action.type) {
    case UserActions.SELECT_USER: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
