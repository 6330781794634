import {Component, OnInit} from '@angular/core';
import {LoginFormState} from '../../../models/enums/login-form-state';
import {AppState, selectAuthState, selectLoginState} from '../../../store/app.states';
import {User} from '../../../models/user';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {LogIn} from '../../../store/actions/auth.actions';
import { LoadLanguagesAction, LoadUserLanguagesAction } from 'src/app/store/actions/lang.actions';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginFormState = LoginFormState;
  user: User = new User();
  authState: Observable<any>;
  loginState: Observable<any>;

  constructor(
    private store: Store<AppState>, private router: Router
  ) {
    this.authState = this.store.pipe(select(selectAuthState));
    this.loginState = this.store.pipe(select(selectLoginState));
  }

  ngOnInit() {
  }

  public onSubmit(): void {
    const payload = {
      email: this.user.email,
      password: this.user.password
    };
    this.store.dispatch(new LogIn(payload));
    this.store.dispatch(new LoadLanguagesAction());
    this.store.dispatch(new LoadUserLanguagesAction());
  }
}
