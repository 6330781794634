import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddPreparationsGQL, MedicamentsWithSideEffectsGQL, StrengthsAddPreparationGQL, StrengthsMedicamentGQL, UpdateIntakeAddPrepGQL } from '../../../../../api/graphql';
import { FormAbstract } from '../../../../../models/abstract/form.abstract';
import { AddPrepStrength } from '../../../../../models/add-prep-strength';
import { AddPreparation } from '../../../../../models/add-preparation';
import { CustomValidate } from '../../../../../models/custom-validate';
import { ActionStateEnum } from '../../../../../models/enums/actions-state.enum';
import { IntakeAddPrep } from '../../../../../models/intake-add-prep';
import { IntakeMed } from '../../../../../models/intake-med';
import { MedStrength } from '../../../../../models/med-strength';
import { Medicament } from '../../../../../models/medicament';
import { AuthService } from '../../../../../services/auth.service';
import { FacadeService } from '../../../../../services/facade.service';
import { ShareService } from '../../../../../services/share.service';
import { AppState } from '../../../../../store/app.states';
import { SortListPipe } from '../../../../shared/pipes/sort-list.pipe';

@Component({
  selector: 'app-patient-medicament',
  templateUrl: './patient-medicament.component.html',
  styleUrls: ['./patient-medicament.component.scss']
})
export class PatientMedicamentComponent extends FormAbstract<IntakeMed | IntakeAddPrep> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public listOfItems: Array<Medicament | AddPreparation>;
  public callback: any;
  public listNameId: string;
  public listNameLabel: string;
  public translateLabel: string;
  public baseModel: string;
  public baseModelId: number;
  public listStrength: Array<any>;
  public listStrengthName: string;
  public sortListPipe: SortListPipe;
  public packageList: Array<any>;
  isIntakemed: boolean;
  public noteEditable: boolean = false;
  public stateNotSaved: boolean;

  public incorrectDays = CustomValidate.patternValidator(new RegExp('^(1|8|15|22|([1-4]|\\d+(?:-\\d+)?)(?:\\s*,\\s*(?:1|8|15|22|[1-4]|\\d+(?:-\\d+)?))*)$'), { incorrectDays: true });

  constructor(protected store: Store<AppState>, private updateIntakeAddPrepGQL: UpdateIntakeAddPrepGQL,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              /* private medicamentListGQL: MedicamentsGQL, */ private medicamentListGQL: MedicamentsWithSideEffectsGQL,
              private addPreparationsGQL: AddPreparationsGQL,
              private strengthsMedicamentGQL: StrengthsMedicamentGQL, private strengthPreparation: StrengthsAddPreparationGQL,
              private toastrService: ToastrService, private translate: TranslateService) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.action = this.bsRef.config.initialState['action'];
    this.callback = this.bsRef.config.initialState['callback'];
    this.baseObject = this.bsRef.config.initialState['data'];
    this.listStrength = Array<any>();
    this.packageList = Array<any>();
    this.baseFields = [
      {field: this.baseObject.hasOwnProperty('intakemedid') ? 'intakemedid' : 'planaddprepid'},
      {field: 'strength', validator: this.baseObject.hasOwnProperty('intakemedid') ? [Validators.required] : []},
      {field: 'instruction'},
      {field: 'days', validator: this.baseObject.hasOwnProperty('intakemedid') ? [Validators.required, this.incorrectDays] : [this.incorrectDays] },
      {field: 'morning', validator: [CustomValidate.numeric]},
      {field: 'midday', validator: [CustomValidate.numeric]},
      {field: 'evening', validator: [CustomValidate.numeric]},
      {field: 'night', validator: [CustomValidate.numeric]},
      {field: 'pieces00clock'}, {field: 'pieces01clock'}, {field: 'pieces02clock'},
      {field: 'pieces03clock'}, {field: 'pieces04clock'}, {field: 'pieces05clock'},
      {field: 'pieces06clock'}, {field: 'pieces07clock'}, {field: 'pieces08clock'},
      {field: 'pieces09clock'}, {field: 'pieces10clock'}, {field: 'pieces11clock'},
      {field: 'pieces12clock'}, {field: 'pieces13clock'}, {field: 'pieces14clock'},
      {field: 'pieces15clock'}, {field: 'pieces16clock'}, {field: 'pieces17clock'},
      {field: 'pieces18clock'}, {field: 'pieces19clock'}, {field: 'pieces20clock'},
      {field: 'pieces21clock'}, {field: 'pieces22clock'}, {field: 'pieces23clock'},
      {field: this.baseObject.hasOwnProperty('intakemedid') ? 'medicament' : 'additionalPreparation'},
      {field: 'planid'},
      {field: 'countryid'},
      {field: 'patientid'},
      {field: 'pharmacyid'},
    ];
    this.baseModel = this.baseObject.hasOwnProperty('intakemedid') ? 'medicament' : 'additionalPreparation';
    if (this.baseModel === 'medicament') {
      this.baseFields.push({
        field: 'shortnotice',
      });
    }
    this.sortListPipe = new SortListPipe(this.authService);
  }

  ngOnInit() {
    if (this.baseObject.hasOwnProperty('intakemedid')) {
      this.isIntakemed = true;
      this.baseFields.push({field: 'noofpackings'});
      this.baseFields.push({field: 'packingsize'});
      this.baseFields.push({field: 'startstock'});
      this.baseFields.push({field: 'endstock'});
      this.listNameId = 'medicamentid';
      this.listNameLabel = 'shortname';
      this.translateLabel = 'common.labels.medicament';

      this.listStrengthName = 'medStrengths';
      this.medicamentListGQL.watch({
        filterBy: {
          label: '',
          manufactor: '',
          status: [1],
          countryid: 1,
          label2: ''
        }
      }).result().then((next: any) => {
        this.listOfItems = this.sortListPipe.transform(next.data['Medicaments'], 'shortname');
      });
    } else {
      this.isIntakemed = false;
      this.listNameId = 'addpreparationid';
      this.listNameLabel = 'label';
      this.translateLabel = 'oraliadb.additionalPreparations.AddPreparation';

      this.listStrengthName = 'addPrepStrengths';
      this.addPreparationsGQL.watch({
        label: '',
        status: [1],
        countryId: this.authService.user.country.countryid
      }).result().then((next: any) => {
        this.listOfItems = this.sortListPipe.transform(next.data['AddPreparations'], 'label');
      });
    }
    this.formGroup = this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: this.baseObject.hasOwnProperty('intakemedid') ? 'intakemedid' : 'planaddprepid',
      translationId: null
    };
    if (this.action === ActionStateEnum.CREATE) {
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
    } else {
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.baseModelId = this.baseObject[this.baseModel][this.listNameId];
      this.formGroup.patchValue({[this.baseModel]: this.baseModelId});
      this.listStrength = this.baseObject[this.baseModel][this.listStrengthName];
      if (this.baseObject.hasOwnProperty('intakemedid')) {
        const args = this.listStrength.find(t => t.strength === this.formGroup.get('strength').value);
        // const args = this.listStrength.find(t => t.strength === this.baseObject.strength);
        this.bindPackage(args);
        this.formGroup.patchValue({'packingsize': `${this.baseObject['packingsize']}`});
      }
      this.baseModelId = this.baseObject[this.baseModel][this.listNameId];
    }

    const user = this.authService.user;

    if (ActionStateEnum.EDIT && this.listNameId === 'addpreparationid') {
      this.noteEditable = true;
    } else {
      this.noteEditable = false;
    }
  }

  public doSelectMedPatient(value: any) {
    this.baseModelId = value;
    if (this.baseObject.hasOwnProperty('intakemedid')) {
      this.strengthsMedicamentGQL.watch({medicamentId: value}).result().then(
        (data) => {
          if (data.data['StrengthsMedicament']) {
            this.listStrength = data.data['StrengthsMedicament'] as any;
          }
        }
      );
      this.formGroup.patchValue({
        'shortnotice': this.shareService.translateSelectedItem(
          this.listOfItems.find(t => t[this.listNameId] === this.baseModelId), 'shortnotice', this.languageId)
      });
    } else {
      this.strengthPreparation.watch({preparationId: value}).result().then(
        (data) => {
          if (data.data['StrengthsAddPreparation']) {
            this.listStrength = data.data['StrengthsAddPreparation'] as any;
          }
        }
      );
      this.formGroup.patchValue({'shortnotice': ''});
    }
    this.packageList = [];
    this.cleanHrs();
    this.formGroup.patchValue({'instruction': ''});
    // this.formGroup.patchValue({'strength': 0});
    // this.baseObject.strength = 0;
    this.baseObject.pieces = 0;
    this.baseObject.intakes = 0;
    this.baseObject.days = '0';
  }

  public bindPackage(args) {
    if (this.baseObject.hasOwnProperty('intakemedid')) {
      this.formGroup.patchValue({'instruction': (<MedStrength>args) ? (<MedStrength>args).note : ""});
      this.packageList = args ? args.packingsizes.split(',') : [];
    } else {
      this.formGroup.patchValue({'instruction': ''});
    }
    // this.formGroup.patchValue({'days': args.days});
  }

  public bindOtherFields($event) {
    const args = this.listStrength.find(t => t.strength === $event);
    this.bindPackage(args);
    this.formGroup.patchValue({'strength': args.strength});
    // this.baseObject.strength = args.strength;
    this.baseObject.pieces = args.pieces;
    this.baseObject.days = args.days;
    this.cleanHrs();
    this.updateHours(args);
  }

  public cleanHrs() {
    const hrs = this.shareService.range(0, 23);
    hrs.forEach(t => {
      const key = 'pieces' + (t < 10 ? ('0' + t) : t) + 'clock';
      this.formGroup.patchValue({[key]: 0});
    });
  }

  public updateHours(args: MedStrength | AddPrepStrength) {
    this.formGroup.patchValue({'pieces07clock': args.morning || 0});
    this.formGroup.patchValue({'morning': args.morning === 0 ? ((args.midday === 0 &&  args.evening === 0 &&  args.night === 0) ? null : 0) : args.morning});
    this.formGroup.patchValue({'pieces13clock': args.midday || 0});
    this.formGroup.patchValue({'midday': args.midday === 0 ? ((args.morning === 0 &&  args.evening === 0 &&  args.night === 0) ? null : 0) : args.midday});
    this.formGroup.patchValue({'pieces19clock': args.evening || 0});
    this.formGroup.patchValue({'evening': args.evening === 0 ? ((args.morning === 0 &&  args.midday === 0 &&  args.night === 0) ? null : 0) : args.evening});
    this.formGroup.patchValue({'pieces23clock': args.night || 0});
    this.formGroup.patchValue({'night': args.night === 0 ? ((args.morning === 0 &&  args.midday === 0 &&  args.evening === 0) ? null : 0) : args.night});
  }

  public updateTime({ target }, args: 'pieces07clock' | 'pieces13clock' | 'pieces19clock' | 'pieces23clock') {

    this.formGroup.patchValue({[args]: parseInt(target.value, 0) || 0});

    if (this.baseObject.hasOwnProperty('planaddprepid')) {

      if ((target.id === 'morning' && target.value === '' && this.formGroup.get('midday').value == 0  && this.formGroup.get('evening').value == 0 && this.formGroup.get('night').value == 0) ||
          (target.id === 'midday' && target.value === '' && this.formGroup.get('morning').value == 0  && this.formGroup.get('evening').value == 0 && this.formGroup.get('night').value == 0) ||
          (target.id === 'evening' && target.value === '' && this.formGroup.get('morning').value == 0 && this.formGroup.get('midday').value == 0  && this.formGroup.get('night').value == 0) ||
          (target.id === 'night' && target.value === '' && this.formGroup.get('morning').value == 0 && this.formGroup.get('midday').value == 0  && this.formGroup.get('evening').value == 0)
      ) {
        this.formGroup.patchValue({
          'morning': null,
          'midday': null,
          'evening': null,
          'night': null
        });
      }else{
        if (!this.formGroup.get('morning').value || this.formGroup.get('morning').value < 0) {
          this.formGroup.get('morning').patchValue((this.formGroup.get('morning').value >= 0 || this.formGroup.get('midday').value >= 0 || this.formGroup.get('evening').value >= 0 || this.formGroup.get('night').value >= 0) ? 0 : null);
        }
        if (!this.formGroup.get('midday').value || this.formGroup.get('midday').value < 0) {
            this.formGroup.get('midday').patchValue((this.formGroup.get('morning').value >= 0 || this.formGroup.get('midday').value >= 0 || this.formGroup.get('evening').value >= 0 || this.formGroup.get('night').value >= 0) ? 0 : null);
        }
        if (!this.formGroup.get('evening').value || this.formGroup.get('evening').value < 0) {
            this.formGroup.get('evening').patchValue((this.formGroup.get('morning').value >= 0 || this.formGroup.get('midday').value >= 0 || this.formGroup.get('evening').value >= 0 || this.formGroup.get('night').value >= 0) ? 0 : null);
        }
        if (!this.formGroup.get('night').value || this.formGroup.get('night').value < 0) {
            this.formGroup.get('night').patchValue((this.formGroup.get('morning').value >= 0 || this.formGroup.get('midday').value >= 0 || this.formGroup.get('evening').value >= 0 || this.formGroup.get('night').value >= 0) ? 0 : null);
        }
      }
    }
  }

  onSubmit(): void {
    this.baseObject = {
      ...this.baseObject,
      ...this.formGroup.value
    };
    let object = this.objectWithoutKey(this.listOfItems.find(i => i[this.listNameId] === this.baseModelId), '__typename');
    object = this.objectWithoutKey(object, 'translations');
    this.baseObject[this.baseModel] = object;
    if (this.checkPackageFrequency() === false) {
      // this.toastrService.warning(this.translate.instant('packagesFrequencyDontMatchWithAmount'));
      // this.closeModal();
      // this.callback(this.baseObject);
    } else {
      // if (object.addpreparationid) {
      //   const variables = {
      //     intakeAddPrep: {
      //       addpreparationid: this.baseObject['addpreparationid'],
      //       days: this.baseObject.days,
      //       evening: this.baseObject.evening,
      //       instruction: this.baseObject.instruction,
      //       midday: this.baseObject.midday,
      //       morning: this.baseObject.morning,
      //       night: this.baseObject.night,
      //       patientid: this.baseObject.patientid,
      //       pieces07clock: this.baseObject.pieces07clock,
      //       pieces13clock: this.baseObject.pieces13clock,
      //       pieces19clock: this.baseObject.pieces19clock,
      //       pieces23clock: this.baseObject.pieces23clock,
      //       planaddprepid: this.baseObject['planaddprepid'],
      //       strength: this.baseObject.strength,
      //     },
      //   };

      //   const _cascadeEffect = (args) => {
      //     this.stateNotSaved = false;
      //     this.closeModal(true);
      //     this.callback(this.baseObject);
      //   };

      //   this.facadeService.mutateObject(this.updateIntakeAddPrepGQL, variables, _cascadeEffect);
      // } else {

      // }
    }
    this.closeModal(true);
    this.callback(this.baseObject);
  }

  public checkPackageFrequency(): boolean {
    let sum = 0;
    const hrs = this.shareService.range(0, 23);
    hrs.forEach(t => {
      const key = 'pieces' + (t < 10 ? ('0' + t) : t) + 'clock';
      sum += parseInt(this.baseObject[key], 0);
    });
    return sum === this.baseObject.morning + this.baseObject.midday + this.baseObject.evening + this.baseObject.night;
  }

  public objectWithoutKey(object, key) {
    const {[key]: deletedKey, ...otherKeys} = object;
    return otherKeys;
  }

  public userViewLabel() {
    if (this.listNameId === 'medicamentid') {
      switch (this.action) {
        case ActionStateEnum.CREATE: {
          return 'modals.newMedicament.title';
        }
        case ActionStateEnum.EDIT: {
          return 'oraliadb.medicament.editMedicament';
        }
      }
    } else {
      switch (this.action) {
        case ActionStateEnum.CREATE: {
          return 'modals.newAddPreparation.title';
        }
        case ActionStateEnum.EDIT: {
          return 'oraliadb.additionalPreparations.editAddPreparation';
        }
      }
    }
  }

  public noteNameChange() {
    if (this.listNameId === 'medicamentid') {
      return 'common.labels.instruction';
    } else {
      return 'common.labels.instruction.patient';
    }
  }

  public range(start, end) {
    return this.shareService.range(start, end);
  }

  public updateTimeControl(controlName: string, $event) {
    this.formGroup.patchValue({[controlName]: $event.target.value});
    if (controlName === 'pieces07clock') {
      this.formGroup.patchValue({'morning': $event.target.value});
      this.updateTime($event, 'pieces07clock')

    }else if (controlName === 'pieces13clock') {
      this.formGroup.patchValue({'midday': $event.target.value});
      this.updateTime($event, 'pieces13clock')

    }else if (controlName === 'pieces19clock') {
      this.formGroup.patchValue({'evening': $event.target.value});
      this.updateTime($event, 'pieces19clock')

    }else if (controlName === 'pieces23clock') {
      this.formGroup.patchValue({'night': $event.target.value});
      this.updateTime($event, 'pieces23clock')
    }
  }

  public get isMedicament() {
    return this.baseObject.hasOwnProperty('intakemedid');
  }
}
