import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../services/auth.service';
// @ts-ignore
import {Observable, of} from 'rxjs';
// @ts-ignore
import {tap, map, switchMap, catchError} from 'rxjs/operators';
import {AuthActionTypes, LogIn, LogInFailure, LogInSuccess} from '../actions/auth.actions';
import {Apollo} from 'apollo-angular';
import {TranslateService} from '@ngx-translate/core';
import {AppState, ClearState} from '../app.states';
import {Store} from '@ngrx/store';
import {LoadLanguagesAction, LoadTranslationLanguageAction, LoadUserLanguagesAction} from '../actions/lang.actions';
import {isNullOrUndefined} from 'util';
import {DoLoginGQL} from '../../api/graphql';
import {User} from '../../models/user';
import {ChangeModuleAction} from '../actions/sub-menu.actions';
import {SUB_MENU_LIST_ORALIA_DB} from '../../config/sub-menu';
import {FacadeService} from '../../services/facade.service';
import {LinkKey} from '../../models/enums/link-key.enum';
import {ShareService} from "../../services/share.service";


@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private facadeService: FacadeService,
    private shareService: ShareService,
    private router: Router,
    private apollo: Apollo,
    private translate: TranslateService,
    private store: Store<AppState>,
    private doLoginGQL: DoLoginGQL
  ) {
  }

  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: LogIn) => action.payload),
    switchMap(payload => {
      return this.doLoginGQL
        .watch({
          email: payload.email,
          password: payload.password
        })
        .valueChanges
        .pipe(
          map(fetched => {
            const currentUser = (<any>fetched.data['doLogin']);
            if (!isNullOrUndefined(currentUser)) {
              this.translate.use(currentUser.userLanguage.shortname);
              this.authService.user = currentUser;
              this.facadeService.translationLanguages = currentUser.translationLang;
              this.facadeService.filterData[LinkKey.MEDICAMENT].filterBy.countryid = currentUser.country.countryid;
              this.facadeService.filterData[LinkKey.PATIENTS].pharmacyid = !isNullOrUndefined(currentUser.pharmacyid) ?
                currentUser.pharmacyid : 0;
              this.facadeService.filterData[LinkKey.DOCTORS].pharmacyId = !isNullOrUndefined(currentUser.pharmacyid) ?
                currentUser.pharmacyid : 0;
              localStorage.setItem('languageId', (currentUser).userLanguage.languageid.toString());
              currentUser.token = 'alsdkj1l2u312l3kj90jk';
              this.authService.setCookie('languageId', currentUser.userLanguage.languageid);
              return new LogInSuccess(currentUser);
            } else {
              return new LogInFailure({error: 'Login failed'});
            }
          }),
          catchError(error => {
            return of(new LogInFailure({error: error}));
          })
        );
    }));

  @Effect({dispatch: false})
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user: any) => {
      localStorage.setItem('token', user.payload.token);
      localStorage.setItem('userId', user.payload.userid);
      const list = SUB_MENU_LIST_ORALIA_DB();
      const payload = {
        selected: list[0],
        list: list
      };
      this.store.dispatch(new ChangeModuleAction(payload));
      const translationPayload = {userId : user.payload.userid}
      this.store.dispatch(new LoadTranslationLanguageAction(translationPayload));
      this.router.navigateByUrl('/main', {skipLocationChange: true});
    })
  );
  @Effect({dispatch: false})
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );


  @Effect({dispatch: false})
  SignUpSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGN_UP_SUCCESS),
    tap((user: any) => {
      localStorage.setItem('token', user.payload.token);
      this.router.navigateByUrl('/');
    })
  );
  @Effect({dispatch: false})
  SignUpFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGN_UP_FAILURE)
  );

  @Effect({dispatch: false})
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap(() => {
      // localStorage.removeItem('token');
      // localStorage.removeItem('userId');
      // localStorage.removeItem('role');
      // localStorage.removeItem('languageId');
      localStorage.clear();
      this.store.dispatch(new ClearState());
      // this.store.dispatch(new ChangeSubMenuAction(payload));
      this.authService.user = new User();
      this.facadeService.startFilterData();
      this.apollo.getClient().cache.reset();
      this.router.navigateByUrl('/');
      // this.store.dispatch(new LoadLanguagesAction());
      this.store.dispatch(new LoadUserLanguagesAction());
      this.facadeService.loadCountries();
      this.shareService.textList = undefined;
    })
  );
}
