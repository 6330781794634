import {CustomValidate, isValidPhone} from './custom-validate';
import {Validators} from '@angular/forms';

export class Country {
  countryid?: number;
  name?: string;
  email?: string;
  languageid?: number;
  logo?: any;
  organisation?: string;
  doccheck?: number; // smallint(4) NOT NULL,
  documents?: string; // smallint(4) NOT NULL,
  phone?: string;
  street?: string;
  zip?: string;
  city?: string;
  status?: number; // smallint(4) NOT NULL,
}

export class CountryValidate {
  public static rules = {
    ['languageid']: [Validators.required],
    ['name']: [Validators.required, Validators.maxLength(40)],
    ['email']: [Validators.required, Validators.email, Validators.maxLength(250)],
    ['phone']: [ CustomValidate.isValidPhone, Validators.maxLength(40)],
    ['street']: [Validators.maxLength(99)],
    ['zip']: [Validators.maxLength(20)],
    ['city']: [Validators.maxLength(60)],
    ['doccheck']: [],
    ['organisation']: [Validators.required, Validators.maxLength(99)]
  };
}
