import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {PatientDoc} from '../../../../../models/patient-doc';
import {Store} from '@ngrx/store';
import {ShareService} from '../../../../../services/share.service';
import {BsModalService} from 'ngx-bootstrap';
import {AuthService} from '../../../../../services/auth.service';
import {AppState} from '../../../../../store/app.states';
import {FacadeService} from '../../../../../services/facade.service';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import * as moment from 'moment';
import {DeleteDocSideEffectGQL, SavePatientDocumentationGQL, SideEffectsGQL} from '../../../../../api/graphql';
import {SelectPatientAction} from '../../../../../store/actions/patient.actions';
import {PatientDocSide} from '../../../../../models/patient-doc-side';
import {SideEffect} from '../../../../../models/side-effect';
import {find} from 'lodash';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-patient-documentation',
  templateUrl: './patient-documentation.component.html',
  styleUrls: ['./patient-documentation.component.scss']
})
export class PatientDocumentationComponent extends FormAbstract<PatientDoc> implements OnInit {
  public date: string;
  public sideEffectList: Array<SideEffect>;
  public levelGradList: Array<{ id: number, level: string }>;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private savePatientDocumentationGQL: SavePatientDocumentationGQL, private sideEffectsGQL: SideEffectsGQL,
              private deleteDocSideEffectGQL: DeleteDocSideEffectGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.baseObject = new PatientDoc();
    this.sideEffectList = Array();
    this.action = ActionStateEnum.VIEW;
    this.action = this.bsRef.config.initialState['action'];
    const patient = this.bsRef.config.initialState['data'];
    // this.formGroup = this.facadeService.createFormWithValidators(this.baseFields);
    if (this.action === ActionStateEnum.CREATE) {
      this.baseObject.countryid = this.authService.user.country.countryid;
      this.baseObject.pharmacyid = this.authService.user.pharmacyid;
      this.baseObject.patientid = patient.patientid;
      this.baseObject.docid = null;
      this.baseObject.protocol = '';
      this.date = this.shareService.getDate(moment());
      // this.formGroup.patchValue({['patientid']: patient.patientid});
      this.baseObject.sideEffects = Array();
      this.levelGradList = Array();
      this.addDocumentSideEffect();
    } else {
      this.baseObject = {...this.bsRef.config.initialState['data']};
      this.date = this.shareService.getDateFromStorableDate(this.baseObject.date);
    }
    // this.prepareForm();
  }

  ngOnInit() {
    this.sideEffectsGQL.watch({
      label: '',
      status: [1],
      languageId: this.authService.userLanguage.languageid
    }).result().then((next: any) => {
      this.sideEffectList = next.data['SideEffects'];
    });
  }

  onSubmit() {
    this.baseObject = this.shareService.objectWithoutKey({
      ...this.baseObject,
      date: this.shareService.getStorableDate(this.date),
      countryid: this.authService.user.country.countryid,
      pharmacyid: this.authService.user.pharmacyid,
    }, '__typename');
    this.baseObject.sideEffects = this.baseObject.sideEffects.map(t => {
      if (t.sideEffect.sideeffectid !== 0) {
        return {
          ...this.shareService.objectWithoutKeyArray(t, ['__typename']),
          sideEffect: {
            ...this.shareService.objectWithoutKey(t.sideEffect, '__typename'),
            translations: []
          }
        };
      }
    });
    this.baseObject.sideEffects = this.baseObject.sideEffects.filter(t => {
      if (t) {
        return t;
      }
    });
    if (this.baseObject.sideEffects[0] === undefined) {
      this.baseObject.sideEffects = [];
    }
    const variables = {
      parentObj: this.baseObject,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new SelectPatientAction({selected: args.data['savePatientDocumentation']}));
    };
    this.facadeService.mutateObject(this.savePatientDocumentationGQL, variables, _cascadeEffect);
  }

  public onValueChange(args, affectLabel: string) {
    this[affectLabel] = this.shareService.getDate(args);
  }

  public doSelectSideEffect($event, k: number) {
    const sideEffect = {...find(this.sideEffectList, ['sideeffectid', $event])};
    this.baseObject.sideEffects[k].sideEffect = {...this.shareService.objectWithoutKey(sideEffect, '__typeName')};
  }

  public addDocumentSideEffect() {
    let patientDocSide = new PatientDocSide();
    patientDocSide = {
      ...patientDocSide,
      docid: this.baseObject.docid,
      doctorreference: 0,
      level: 0,
      sideEffect: {
        ...new SideEffect(),
        sideeffectid: 0
      }
    };
    this.baseObject.sideEffects.push(patientDocSide);
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.TRANSLATE: {
        return 'patient.patients.translateInteraction';
      }
      case ActionStateEnum.CREATE: {
        return 'patient.patients.newReport';
      }
      case ActionStateEnum.EDIT: {
        return 'patient.patients.editReport';
      }
      default: {
        return 'patient.patients.interaction';
      }
    }
  }

  public get canAddMoreSideEffects() {
    if (this.baseObject.sideEffects.length === 0) {
      return true;
    } else {
      try {
        const sideEffect = this.baseObject.sideEffects[this.baseObject.sideEffects.length - 1].sideEffect;
        if (sideEffect) {
          if (sideEffect.sideeffectid !== 0) {
            return true;
          }
        }
      } catch (e) {
      }
    }
    return false;
  }

  public removeNoIndexItems() {
    this.baseObject.sideEffects = this.baseObject.sideEffects.map(t => {
      if (t.sideEffect.sideeffectid !== 0) {
        return {
          ...this.shareService.objectWithoutKey(t, '__typename'),
          sideEffect: {
            ...this.shareService.objectWithoutKey(t.sideEffect, '__typename'),
            translations: []
          }
        };
      }
    });
  }

  public deleteSideEffect(docSide: PatientDocSide, index: number) {
    if (isNullOrUndefined(docSide.doceffectid)) {
      this.baseObject.sideEffects.splice(index, 1);
    } else {
      this.deleteDocSideEffectGQL.mutate({
        item: docSide.doceffectid,
        patientId: this.baseObject.patientid
      }).subscribe(next => {
        if (next.data['deleteDocSideEffect']) {
          this.baseObject.sideEffects.splice(index, 1);
        }
      });
    }
  }

  public sideLevels(docSide) {
    const list = [0];
    for (const one of [1, 2, 3, 4, 5]) {
      const key = 'level' + one;
      if (!isNullOrUndefined(docSide.sideEffect) && docSide.sideEffect[key] !== '' && !isNullOrUndefined(docSide.sideEffect[key])) {
        list.push(one);
      }
    }
    return list;
  }
}
