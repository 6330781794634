import {Component, OnInit, ViewChild} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {Pharmacy, PharmacyValidate} from '../../../models/pharmacy';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {select, Store} from '@ngrx/store';
import {AppState, selectPharmacyReducer} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION, SUB_MENU_LIST_MASTER_DATA} from '../../../config/sub-menu';
import {SelectPharmacyAction} from '../../../store/actions/pharmacy.actions';
import {SavePharmacyGQL, UserGQL} from '../../../api/graphql';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {User} from '../../../models/user';
import {isNullOrUndefined} from 'util';
import {UploadFileService} from '../../../services/upload-file.service';
import {SelectCountryAction} from "../../../store/actions/country.actions";

@Component({
  selector: 'app-pharmacies',
  templateUrl: './pharmacies.component.html',
  styleUrls: ['./pharmacies.component.scss']
})
export class PharmaciesComponent extends FormAbstract<Pharmacy> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public contacPerson: User;
  @ViewChild('file') file;
  public logoSrc: any;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private savePharmacyGQL: SavePharmacyGQL, private userGQL: UserGQL,
              private uploadFileService: UploadFileService) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.logoSrc = null;
    this.baseFields = [
      {field: 'pharmacyid'},
      {field: 'countryid'},
      {field: 'userid'},
      // {field: 'logo'},
      {field: 'bgano'},
      {field: 'city', validator: PharmacyValidate.rules.city},
      {field: 'name', validator: PharmacyValidate.rules.name},
      {field: 'street', validator: PharmacyValidate.rules.street},
      {field: 'zip', validator: PharmacyValidate.rules.zip},
      {field: 'phone', validator: PharmacyValidate.rules.phone},
      {field: 'mail', validator: PharmacyValidate.rules.mail}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'pharmacyid',
      translationId: null
    };
    this.contacPerson = new User();

    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.baseObject = new Pharmacy();
      this.baseObject.userid = Number(this.authService.user.userid);
      this.baseObject.countryid = this.authService.user.country.countryid;
    } else {
      this.store.pipe(select(selectPharmacyReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.baseObject.userid = Number(this.authService.user.userid);
          this.baseObject.countryid = this.authService.user.country.countryid;
          this.languageId = this.authService.userLanguage.languageid;
          this.prepareForm();
          if (!isNullOrUndefined(this.baseObject.logo)) {
            this.logoSrc = this.baseObject.logo;
            if (this.logoSrc.substring(0, 4) !== 'data') {
              this.logoSrc = 'data:image/png;base64,' + this.logoSrc;
            }
          } else {
            this.logoSrc = null;
          }
          if (this.baseObject.userid && !isNullOrUndefined(this.baseObject.userid)) {
            this.userGQL.watch({id: this.baseObject.userid}).result().then(result => {
              this.contacPerson = result.data['User'];
            });
          }
        }
      });
    }
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    this.baseObject.userid = Number(this.authService.user.userid);
    this.baseObject.countryid = this.authService.user.country.countryid;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      action: this.action
    };
    const _afterSave = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_MASTER_DATA()[0]}));
      if (this.facadeService.filterArrayContains(args.data['savePharmacy'], ['name'], LinkKey.PHARMACY)) {
        this.store.dispatch(new SelectPharmacyAction({selected: args.data['savePharmacy']}));
        this.facadeService.setDetailHidden(false);
      } else {
        this.store.dispatch(new SelectPharmacyAction({selected: {}}));
      }
    };
    const _cascadeEffect = (args) => {
      this.formGroup.reset();
      this.closeModal();
      this.uploadFileService.uploadGenericFile(this.file.nativeElement.files, args.data['savePharmacy'].pharmacyid, 0, 'PHARMACY')
        .subscribe(next => {
          if (next.type === 4) {
            args.data['savePharmacy'].logo = this.logoSrc;
             _afterSave(args);
          }
        });
    };
    this.facadeService.mutateObject(this.savePharmacyGQL, variables, _cascadeEffect);
  }

  public updateFileSource() {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.logoSrc = reader.result;
    };
    reader.readAsDataURL((<File>this.file.nativeElement.files[0]));
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'master.pharmacy.newPharmacy';
      }
      case ActionStateEnum.EDIT: {
        return 'master.pharmacy.editPharmacy';
      }
      default: {
        return 'master.pharmacy.pharmacy';
      }
    }
  }

  public getContactPerson() {
    if (this.contacPerson.lastname) {
      return this.contacPerson.lastname + (this.contacPerson.firstname ? (', ' + this.contacPerson.firstname) : '');
    } else {
      return this.contacPerson.firstname ? this.contacPerson.firstname : '';
    }
  }

  public editAction() {
    this.action = ActionStateEnum.EDIT;
  }
}
