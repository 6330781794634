export enum ReportType {
  NUMBERS_BY_COUNTRIES = 'NUMBERS_BY_COUNTRIES',
  INTAKE_PLAN_BY_MEDICAMENT = 'INTAKE_PLAN_BY_MEDICAMENT',
  INTAKE_PLAN_BY_PERIOD = 'INTAKE_PLAN_BY_PERIOD',
  CHANGE_OF_MEDICAMENT = 'CHANGE_OF_MEDICAMENT',
  SUPPORTIVE_MEDICAMENT = 'SUPPORTIVE_MEDICAMENT',
  LOGIN = 'LOGIN',
  PHARMACIES = 'PHARMACIES',
  EMPLOYEES = 'EMPLOYEES',
  MEDICAMENTS = 'MEDICAMENTS',
}
