import {All, FileActions} from '../actions/file.actions';
import {File} from '../../models/file';

export interface FileState {
  selected: File;
}

export const initialState: FileState = {
  selected: null
};

export function reducer(state = initialState, action: All): FileState {
  switch (action.type) {
    case FileActions.SELECT_FILE: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
