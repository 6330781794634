import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum FileActions {
  LOAD_FILE = '[File] LOAD_FILE',
  LOAD_FILE_BY_ID = '[File] LOAD_FILE_BY_ID',
  SELECT_FILE = '[File] SELECT_FILE'
}

export class LoadFilesListAction implements Action {
  readonly type = FileActions.LOAD_FILE;

  constructor(public payload: PaginationData) {
  }
}

export class LoadFileByIdAction implements Action {
  readonly type = FileActions.LOAD_FILE_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectFileAction implements Action {
  readonly type = FileActions.SELECT_FILE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadFilesListAction
  | LoadFileByIdAction
  | SelectFileAction;
