import {Component, OnInit, ViewChild} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {select, Store} from '@ngrx/store';
import {AppState, selectSponsorReducer} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {SaveSponsorGQL} from '../../../api/graphql';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {isNullOrUndefined} from 'util';
import {UploadFileService} from '../../../services/upload-file.service';
import {Sponsor, SponsorValidate} from "../../../models/sponsor";
import {SelectSponsorAction} from "../../../store/actions/sponsor.actions";

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent extends FormAbstract<Sponsor> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  @ViewChild('file') file;
  public logoSrc: any;
  public fromDate: string;
  public toDate: string;
  public calendarFromDate: Date = new Date();
  public calendarToDate: Date = new Date();
  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private saveSponsorGQL: SaveSponsorGQL, private uploadFileService: UploadFileService) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.logoSrc = null;
    this.baseFields = [
      {field: 'sponsorid'},
      {field: 'sponsor', validator: SponsorValidate.rules.sponsor},
      {field: 'link', validator: SponsorValidate.rules.link},
      {field: 'text', validator: SponsorValidate.rules.text},
      {field: 'datestart'},
      {field: 'dateend'},
      {field: 'countryid'},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'sponsorid',
      translationId: null
    };

    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.baseObject = new Sponsor();
      this.baseObject.countryid = this.authService.user.country.countryid;
    } else {
      this.store.pipe(select(selectSponsorReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.baseObject.countryid = this.authService.user.country.countryid;
          this.fromDate = this.shareService.getDateFromStorableDate(this.baseObject.datestart);
          this.toDate = this.shareService.getDateFromStorableDate(this.baseObject.dateend);
          this.calendarFromDate = this.shareService.fromInputFormatToDate(this.fromDate);
          this.calendarToDate = this.shareService.fromInputFormatToDate(this.toDate);
          this.languageId = this.authService.userLanguage.languageid;
          this.prepareForm();
          if (!isNullOrUndefined(this.baseObject.logo)) {
            this.logoSrc = this.baseObject.logo;
            if (this.logoSrc.substring(0, 4) !== 'data') {
              this.logoSrc = 'data:image/png;base64,' + this.logoSrc;
            }
          } else {
            this.logoSrc = null;
          }
        }
      });
    }
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    this.baseObject.datestart = this.shareService.getStorableDate(this.fromDate);
    this.baseObject.dateend = this.shareService.getStorableDate(this.toDate);
    this.baseObject.countryid = this.authService.user.country.countryid;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.formGroup.reset();
      this.closeModal();
      this.uploadFileService.uploadGenericFile(this.file.nativeElement.files, args.data['saveSponsor'].sponsorid, 0, "SPONSOR")
        .subscribe(next => {
          if (next.type === 4) {
            args.data['saveSponsor'].logo = this.logoSrc;
            //  _afterSave(args);
          }
        });
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[6]}));
      if (this.facadeService.filterArrayContains(args.data['saveSponsor'], ['sponsor'], LinkKey.SPONSORS)) {
        this.store.dispatch(new SelectSponsorAction({selected: args.data['saveSponsor']}));
        this.facadeService.setDetailHidden(false);
      } else {
        this.store.dispatch(new SelectSponsorAction({selected: {}}));
      }
    };
    this.facadeService.mutateObject(this.saveSponsorGQL, variables, _cascadeEffect);
  }

  public updateFileSource() {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.logoSrc = reader.result;
    };
    reader.readAsDataURL((<File>this.file.nativeElement.files[0]));
  }

  public viewTitle() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.sponsors.newSponsor';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.sponsors.editSponsor';
      }
      default: {
        return 'administration.sponsors.sponsor';
      }
    }
  }

  public onValueChange(args, affectLabel: string) {
    this[affectLabel] = this.shareService.getDate(args);
    if (affectLabel === 'fromDate') {
      this['toDate'] = this.shareService.getDate(args);
    }
  }
}
