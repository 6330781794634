import {Patient} from '../../models/patient';
import {All, PatientActions} from '../actions/patient.actions';


/**
 * @author Alavi
 * 26/11/2018
 */
export interface PatientState {
  selected: Patient;
}

export const initialState: PatientState = {
  selected: null
};

export function reducer(state = initialState, action: All): PatientState {
  switch (action.type) {
    case PatientActions.SELECT_PATIENT: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
