import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum EmployeeActions {
  LOAD_EMPLOYEE = '[Employee] LOAD_EMPLOYEE',
  LOAD_EMPLOYEE_BY_ID = '[Employee] LOAD_EMPLOYEE_BY_ID',
  SELECT_EMPLOYEE = '[Employee] SELECT_EMPLOYEE'
}

export class LoadEmployeeListAction implements Action {
  readonly type = EmployeeActions.LOAD_EMPLOYEE;

  constructor(public payload: PaginationData) {
  }
}

export class LoadEmployeeByIdAction implements Action {
  readonly type = EmployeeActions.LOAD_EMPLOYEE_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectEmployeeAction implements Action {
  readonly type = EmployeeActions.SELECT_EMPLOYEE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadEmployeeListAction
  | LoadEmployeeByIdAction
  | SelectEmployeeAction;
