import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IntakeMed} from '../../../../models/intake-med';
import {IntakeAddPrep} from '../../../../models/intake-add-prep';
import {PatientMedicamentComponent} from '../modals/patient-medicament/patient-medicament.component';
import {ActionStateEnum} from '../../../../models/enums/actions-state.enum';
import {isNullOrUndefined} from 'util';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {DeleteIntakeAddPrepGQL, DeleteIntakeMedGQL} from '../../../../api/graphql';
import {ShareService} from '../../../../services/share.service';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';

@Component({
  selector: 'app-intake-plan',
  templateUrl: './intake-plan.component.html',
  styleUrls: ['./intake-plan.component.scss']
})
export class IntakePlanComponent implements OnInit {
  @Input('intake') intake: IntakeMed | IntakeAddPrep;
  @Input('baseName') baseName: string;
  @Input('index') index: number;
  @Input('patientid') patientid: number;
  @Input('countryid') countryid: number;
  @Input('pharmacyid') pharmacyid: number;
  @Output('callBackEdit') callBackEdit = new EventEmitter<{ object: IntakeMed, list: string, index: number }>();
  @Output('callBackDelete') callBackDelete = new EventEmitter<{ object: IntakeMed, list: string, index: number }>();
  public modalRef: BsModalRef;
  public headerLabel: string;
  public intakeIndexName: string;
  public intakeListName: string;
  public deleteIntakeName: string;
  public intakeGQL: any;
  public rangeOfHrs: Array<number>;

  constructor(private bsRef: BsModalService,
              private deleteIntakeMedGQL: DeleteIntakeMedGQL, private deleteIntakeAddPrepGQL: DeleteIntakeAddPrepGQL,
              private shareService: ShareService, private coolDialogs: NgxCoolDialogsService) {
    this.headerLabel = 'label';
    this.rangeOfHrs = this.range(0, 23);
  }

  ngOnInit() {
    if (this.intake.hasOwnProperty('intakemedid')) {
      this.headerLabel = 'shortname';
      this.intakeIndexName = 'intakemedid';
      this.intakeListName = 'intakeMeds';
      this.deleteIntakeName = 'deleteIntakeMed';
      this.intakeGQL = this.deleteIntakeMedGQL;
    } else {
      this.headerLabel = 'label';
      this.intakeIndexName = 'planaddprepid';
      this.intakeListName = 'intakeAddPreps';
      this.deleteIntakeName = 'deleteIntakeAddPrep';
      this.intakeGQL = this.deleteIntakeAddPrepGQL;
    }
    this.updatePiecesValues(7);
    this.updatePiecesValues(13);
    this.updatePiecesValues(19);
    this.updatePiecesValues(23);
  }

  public updatePiecesValues(clock: number) {
    const clockKey = 'pieces' + (clock < 10 ? ('0' + clock) : clock) + 'clock';
    if (clock === 7) {
      this.intake[clockKey] = this.intake.morning;
    }
    if (clock === 13) {
      this.intake[clockKey] = this.intake.midday;
    }
    if (clock === 19) {
      this.intake[clockKey] = this.intake.evening;
    }
    if (clock === 23) {
      this.intake[clockKey] = this.intake.night;
    }
  }

  public noteNameChange() {
    if (this.intake.hasOwnProperty('intakemedid')) {
      return 'common.labels.instruction';
    } else {
      return 'common.labels.instruction.patient';
    }
  }

  public deleteIntake() {
    let _cascadeEffect;
    if (isNullOrUndefined(this.intake[this.intakeIndexName])) {
      _cascadeEffect = () => {
        this.callBackDelete.emit({object: this.intake, list: this.intakeListName, index: this.index});
      };
    } else {
      _cascadeEffect = () => {
        this.intakeGQL.mutate({
          item: this.intake[this.intakeIndexName],
          patientId: this.patientid
        }).subscribe(next => {
          if (next.data[this.deleteIntakeName].patientid) {
            this.callBackDelete.emit({object: this.intake, list: this.intakeListName, index: this.index});
          }
        });
      };
    }
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public editIntake() {
    this.modalRef = this.bsRef.show(PatientMedicamentComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...this.intake,
          countryid: this.countryid,
          patientid: this.patientid,
          pharmacyid: this.pharmacyid
        },
        action: ActionStateEnum.EDIT,
        callback: (args) => {
          this.callBackEdit.emit({object: args, list: this.intakeListName, index: this.index});
        }
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public get isMedicament() {
    return this.intake.hasOwnProperty('intakemedid');
  }

  public range(start, end) {
    return this.shareService.range(start, end);
  }
}
