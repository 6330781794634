/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {Action} from '@ngrx/store';

export enum SubMenuActions {
  CHANGE = '[SUB MENU] CHANGE',
  CHANGE_MODULE = '[SUB MENU] CHANGE_MODULE',
}

export class ChangeSubMenuAction implements Action {
  readonly type = SubMenuActions.CHANGE;

  constructor(public payload: any) {
  }
}


export class ChangeModuleAction implements Action {
  readonly type = SubMenuActions.CHANGE_MODULE;

  constructor(public payload: any) {
  }
}

export type All =
  | ChangeSubMenuAction
  | ChangeModuleAction;
