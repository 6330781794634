import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild, ViewContainerRef
} from '@angular/core';
// @ts-ignore
import {LogOut} from '../../store/actions/auth.actions';
import {AppState} from '../../store/app.states';
import {Store} from '@ngrx/store';
import {AuthService} from '../../services/auth.service';
import {FacadeService} from '../../services/facade.service';
import {Observable, Subscription} from 'rxjs';
import {InjectorService} from '../../services/injector.service';
import {isNullOrUndefined} from 'util';
import {ShareService} from '../../services/share.service';
import { CheckToShowPopupGQL } from 'src/app/api/graphql';

declare var $: any;

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public sidebarClass = '';
  public isDetailHidden: Observable<boolean>;
  public componentRef: ComponentRef<any>;
  public selector: string;
  public lastStatusOfDatabase: string = '';
  @ViewChild('containerRef', {read: ViewContainerRef}) containerRef: ViewContainerRef;
  public subscription: Subscription;
  public isLoginPopupFourteen: boolean = false;
  public isLoginPopupTwentyOne: boolean = false;
  public isLoginPopupTwentyTwo: boolean = false;

  constructor(private store: Store<AppState>, private checkToShowPopupGQL: CheckToShowPopupGQL,
              public authService: AuthService, private cd: ChangeDetectorRef, private shareService: ShareService,
              public facadeService: FacadeService, private injectorService: InjectorService) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.isDetailHidden = this.facadeService.detailHidden;
    this.callPopupByTextid(14);
    this.getLastStatusOfDatabase();
  }

  public callPopupByTextid(textid: number) {

    const _callCustomPopup = (args) => {
      switch (textid) {
        case 14:
          if (args.data['CheckToShowPopup'] === true) {
            this.isLoginPopupFourteen = true;
          } else {
            this.callPopupByTextid(21);
          }
          break;
        case 21:
          if (args.data['CheckToShowPopup'] === true) {
            this.isLoginPopupTwentyOne = true;
          } else {
            this.callPopupByTextid(22);
          }
          break;
        case 22:
          if (args.data['CheckToShowPopup'] === true) {
            this.isLoginPopupTwentyTwo = true;
          } else {
            this.removeAllPopup();
          }
          break;
        default:
          break;
      }
    };
    
    const Variable = {
      userid: this.authService.user.userid,
      textid: textid
    };
    this.facadeService.callQueryUnique(this.checkToShowPopupGQL, Variable, _callCustomPopup);
  }
  
  ngAfterViewInit(): void {
    this.subscription = this.injectorService.getDisplayableComponent().subscribe(next => {
      if (this.componentRef) {
        this.componentRef.destroy();
      }
      if (!isNullOrUndefined(next)) {
        this.selector = next.selector;
        this.componentRef = this.containerRef.createComponent(next);
      }
    });
    this.cd.detectChanges();
    this.facadeService.setLogoFromCountryLogo();
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.subscription.unsubscribe();
  }

  public getLastStatusOfDatabase() {
    this.shareService.getTextWithHtmlTags(20).then((text: string) => {
      this.lastStatusOfDatabase = this.extractContentBetweenFirstPTags(text);
    }).catch((error) => {
      console.error('Cannot get last Status of Database:', error);
    });
  }

  public loginPopupForteenConfirm() {

    $('#loginPopupForteen').modal('hide');
    setTimeout(() => {
      $('#loginPopupForteen').remove();
    }, 500);
    this.isLoginPopupFourteen = false;
    this.isLoginPopupTwentyTwo = false;
    this.callPopupByTextid(21);
  }
  
  public loginPopupTwentyOneConfirm() {
    
    $('#loginPopupTwentyOne').modal('hide');
    setTimeout(() => {
      $('#loginPopupTwentyOne').remove();
    }, 500);
    this.isLoginPopupFourteen = false;
    this.isLoginPopupTwentyOne = false;
    this.callPopupByTextid(22);
  }
  
  public loginPopupTwentyTwoConfirm() {
    
    $('#loginPopupTwentyTwo').modal('hide');
    setTimeout(() => {
      $('#loginPopupTwentyTwo').remove();
    }, 500);
    this.isLoginPopupFourteen = false;
    this.isLoginPopupTwentyOne = false;
    this.isLoginPopupTwentyTwo = false;
  }

  public removeAllPopup() {

    this.isLoginPopupFourteen = false;
    this.isLoginPopupTwentyOne = false;
    this.isLoginPopupTwentyTwo = false;
    $('#loginPopupForteen').modal('hide');
    setTimeout(() => {
      $('#loginPopupForteen').remove();
    }, 500);
    $('#loginPopupTwentyOne').modal('hide');
    setTimeout(() => {
      $('#loginPopupTwentyOne').remove();
    }, 500);
    $('#loginPopupTwentyTwo').modal('hide');
    setTimeout(() => {
      $('#loginPopupTwentyTwo').remove();
    }, 500);
  }

  public extractContentBetweenFirstPTags(htmlContent: string): string {
    
    // Find the content between the first <p> tags
    const match = htmlContent.match(/<p\b[^>]*>(.*?)<\/p>/);
    if (!match) {
      return '';
    }
  
    const content = match[1].replace(/<(?!\/?a\b)[^<]+?>/g, '');

    // Keep only the first 87 characters and add "..." after that
    // return content.length > 87 ? content.substring(0, 87) + '...' : content;

    return content;
  }

  getSkipLocationChange(): boolean {
    return true;
  }

  public logout() {
    this.store.dispatch(new LogOut());
  }

  toggleSidebar(): void {
    if (this.sidebarClass === 'active') {
      this.sidebarClass = '';
    } else {
      this.sidebarClass = 'active';
    }
  }

  onResize(event) {
    const width = event.target.innerWidth;

    if (width <= 768) {
      this.sidebarClass = 'active';
    } else {
      this.sidebarClass = '';
    }
  }

  public get mainLogo() {
    if (this.facadeService.availableCountries) {
      const one = this.facadeService.availableCountries.find(c => c.countryid === this.authService.user.country.countryid);
      if (one) {
        return one.logo ? ('data:image/png;base64,' + one.logo) : 'assets/images/logos/dgop.gif';
      }
    }
    return 'assets/images/logos/dgop.gif';
  }
}



