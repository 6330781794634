import {UserLanguage} from './user-language';
import {Country} from './country';
import {Validators} from '@angular/forms';
import {CustomValidate} from './custom-validate';
import {UserDoc} from './user-doc';

export class User {
  userid?: number;
  userLanguage?: UserLanguage;
  country?: Country;
  pharmacyid?: number;
  city?: string;
  doccheck?: string;
  email?: string;
  enddate?: any;
  firstname?: string;
  institutiontype?: number;
  issimpleuser?: boolean | number;
  iscountryadmin?: boolean | number;
  iscountryeditor?: boolean | number;
  isemployee?: boolean | number;
  ismainadmin?: boolean | number;
  ismaineditor?: boolean | number;
  ispharmacy?: boolean | number;
  lastname?: string;
  name?: string;
  password?: string;
  confirmPassword?: string;
  phone?: string;
  salutation?: number;
  startdate?: any;
  status?: number | string;
  street?: string;
  title?: string;
  zip?: string;
  token?: string;
  translationLang?: Array<any>;
  userDocs?: Array<UserDoc>;
  lastlogin?: Date;
  created?: Date;
  freeofcharge?: boolean | number;
  endoffreeuse?: Date;
  professionalgroup?: string;
}

export class UserValidate {
  public static rules = {
    ['email']: [Validators.required, Validators.email, Validators.maxLength(355)],
    ['firstname']: [Validators.required, Validators.maxLength(60)],
    ['lastname']: [Validators.required, Validators.maxLength(60)],
    ['name']: [Validators.maxLength(60)],
    ['password']: [Validators.minLength(8), Validators.maxLength(100)],
    ['phone']: [Validators.maxLength(20)],
    ['city']: [Validators.maxLength(40)],
    ['street']: [Validators.maxLength(60)],
    ['title']: [Validators.maxLength(20)],
    ['zip']: [Validators.maxLength(10)],
    ['doccheck']: [Validators.required],
    ['professionalgroup']: [Validators.required]
  };

}
