import {AddPreparation} from '../../models/add-preparation';
import {All, AddPreparationActions} from '../actions/add-preparation.actions';

/**
 * @author Alavi
 * 02/12/2018
 */
export interface AddPreparationsState {
  selected: AddPreparation;
}

export const initialState: AddPreparationsState = {
  selected: null
};

export function reducer(state = initialState, action: All): AddPreparationsState {
  switch (action.type) {
    case AddPreparationActions.LOAD_ADD_PREPARATIONS_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case AddPreparationActions.LOAD_ADDPREPARATION_DETAIL: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    case AddPreparationActions.SELECT_ADD_PREPARATIONS: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
