import {Component, OnInit, ViewChild} from '@angular/core';
import {FacadeService} from '../../../services/facade.service';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {Country} from '../../../models/country';
import {ShareService} from '../../../services/share.service';
import {AuthService} from '../../../services/auth.service';
import {ReportType} from '../../../models/enums/report-type';
import {
  ApexAxisChartSeries,
  ApexChart, ApexDataLabels,
  ApexGrid,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import {apexLocales} from './locales';
import * as _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';

export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  title?: ApexTitleSubtitle;
  yaxis?: ApexYAxis[];
  labels?: string [];
  plotOptions?: ApexPlotOptions;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  dataLabels?: ApexDataLabels;
}

@Component({
  selector: 'app-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.scss']
})
export class EvaluationsComponent implements OnInit {
  get secondTableData(): Array<any> {
    return this._secondTableData;
  }

  set secondTableData(value: Array<any>) {
    this._secondTableData = value;
  }
  get tableData(): Array<any> {
    return this._tableData;
  }

  set tableData(value: Array<any>) {
    this._tableData = value;
  }
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('tableElement') tableElement;
  public chartOptions: ChartOptions;
  public activeEvaluation: any;
  public action: ActionStateEnum;
  public countryList: Array<Country>;
  public intakeFromDate: string;
  public intakeToDate: string;
  public calendarFromDate: Date = new Date(new Date().getFullYear() - 1, 0, 1);
  public calendarToDate: Date = new Date();
  public _reportType: any = ReportType;
  public selectedCountry: Country;
  public canEditCountry: boolean;
  public showCharts: boolean;
  private _tableData: Array<any>;
  private _secondTableData: Array<any>;
  public mapOfKeys: Map<String, any>;
  public listOfAges = ['unknown', '0-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-80', '81-90', '>90'];
  public COMMON_RESPONSE: Array<any>;
  public centerMiddle = {
    halign: 'center',
    valign: 'middle',
    lineWidth: 0.1,
    textColor: '#000',
    lineColor: '#525252',
  };
  public centerMiddleHeader = {
    ...this.centerMiddle,
    fillColor: '#9AC7E9',
  };
  public rightMiddle = {
    halign: 'right',
    valign: 'middle',
    lineWidth: 0.1,
    textColor: '#000',
    lineColor: '#525252',
  };
  public leftMiddle = {
    halign: 'left',
    valign: 'middle',
    lineWidth: 0.1,
    textColor: '#000',
    lineColor: '#525252',
  };
  constructor(private facadeService: FacadeService,
              public shareService: ShareService,
              private authService: AuthService,
              private translate: TranslateService) {
    this.action = ActionStateEnum.EDIT;
    this.activeEvaluation = {};
    this.selectedCountry = this.authService.user.country;
    this.canEditCountry = false;
    this.checkCountryRoles();
    this.showCharts = false;
    this.chartOptions = {
      series: [],
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
        height: 450,
        type: 'bar',
        stacked: false,
        defaultLocale: apexLocales.defaultLocale,
        locales: apexLocales.locales,
      },
      title: {
        text: '',
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      tooltip: {
        followCursor: true,
        shared: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      grid: {
        padding: {
          left: 0, // or whatever value that works
          right: 0, // or whatever value that works
        },
      },
      stroke: {
        curve: 'smooth',
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
    };
    this.intakeFromDate = this.shareService.getDate(this.calendarFromDate);
    this.intakeToDate = this.shareService.getDate(this.calendarToDate);
  }

  ngOnInit() {
    if (!this.authService.user.ismaineditor) {
      this.countryList = [...this.facadeService.availableCountries.filter(t => t.countryid === this.authService.user.country.countryid)];
    } else {
      this.countryList = [...this.facadeService.availableCountries];
    }
    this.facadeService.evaluation.subscribe(next => {
      if (next) {
        this.showCharts = false;
        this.chartOptions.series = [];
        this.facadeService.setDetailHidden(false);
        this.activeEvaluation = next;
        const reportType = this.activeEvaluation.key;
        this.reloadChart(reportType);
        const allCountry = {
          countryid: -1,
          name: ` (*) ${this.facadeService.transInstByKey('common.select.all')}`,
        };
        if (this.activeEvaluation.key === this._reportType.LOGIN) {
          this.countryList = [allCountry, ...this.facadeService.availableCountries];
        }
      }
    });
  }
  public reloadChart(reportType) {
    this.shareService.startLoading();
    this.facadeService.getEvaluationsData('/graphql/evaluation', {
      reportType,
      countryId: this.selectedCountry.countryid,
      startDate: this.shareService.fromInputFormatToDate(this.intakeFromDate),
      endDate: this.shareService.fromInputFormatToDate(this.intakeToDate),
      startYear: parseInt(this.intakeFromDate.substr(-4), 0),
      endYear: parseInt(this.intakeToDate.substr(-4), 0),
      languageId: this.authService.user.userLanguage.languageid
    }).subscribe(response => {
      this.COMMON_RESPONSE = response[reportType];
      this.processData(response[reportType], reportType);
    });
  }
  public processData(data, type) {
    this.chartOptions.yaxis = [];
    this.mapOfKeys = new Map<String, any>();
    switch (type) {
      case this._reportType.NUMBERS_BY_COUNTRIES: {
        this.chartOptions.labels = this.facadeService.getEvaluationLabelsByKey(type, this._reportType);

        data.forEach((item) => {
          const series = {
            name: item.countryName,
            type: 'bar',
            data: [item.pharmacies, item.employees, item.otherUsers, item.intakePlans, item.medicaments, item.addPreparations],
          };
          this.chartOptions.series.push(series);
        });
        this.tableData = this.chartOptions.series;
        break;
      }
      case this._reportType.INTAKE_PLAN_BY_PERIOD: {
        this.chartOptions.labels = data.map(t => t.year);
        let countries = Object.keys(data[0]);
        _.remove(countries, (n) => n === 'year');
        if (!this.authService.user.ismaineditor) {
          countries = [this.authService.user.country.name];
        }
        countries.forEach(c => {
          const series = {
            name: c,
            type: 'bar',
            data: data.map(l => l[c]),
          };
          this.chartOptions.series.push(series);
        });
        this.chartOptions.series = _.sortBy(this.chartOptions.series, 'name');
        this.tableData = this.chartOptions.series;
        this.secondTableData = countries.sort();
        break;
      }
      case this._reportType.SUPPORTIVE_MEDICAMENT: {
        this.chartOptions.labels = data.map(t => t[0]);
        const series = {
          name: this.facadeService.transInstByKey('reports.numberOfIntakePlans'),
          type: 'bar',
          data: data.map(t => t[1]),
        };
        const list = [];
        list.push(series);
        const series2 = {
          name: this.facadeService.transInstByKey('reports.duration'),
          type: 'bar',
          data: data.map(t => t[2]),
        };
        list.push(series2);
        const labels = {
          formatter: function (val) {
            return val.toFixed(0);
          }
        };
        this.chartOptions.yaxis = [
          { title: { text: this.facadeService.transInstByKey('reports.numberOfIntakePlans') }, labels },
          { opposite: true, title: { text: this.facadeService.transInstByKey('reports.duration') }, labels }
        ];
        this.chartOptions.series = list;
        this.tableData = this.chartOptions.series;
        break;
      }
      case this._reportType.CHANGE_OF_MEDICAMENT: {
        data.forEach(t => {
          if (this.mapOfKeys.has(t[0])) {
            t[0] = '';
          } else {
            this.mapOfKeys.set(t[0], t);
          }
        });
        this.tableData = data;
        break;
      }
      case this._reportType.INTAKE_PLAN_BY_MEDICAMENT: {
        data.intakeMed = data.intakeMed.map(t => ({
          label: t[0],
          strength: t[1],
          decade: t[2],
          intakePlans: t[3],
          averageDuration: t[4],
        }));
        data.intakeMedAge = data.intakeMedAge.map(t => ({
          label: t[0],
          strength: t[1],
          sex: t[2],
          intakePlans: t[3],
          averageDuration: t[4],
        }));
        this.tableData = this.abstractTheList(data.intakeMed);
        this.secondTableData = this.abstractTheList(data.intakeMedAge);
        break;
      }
      case this._reportType.LOGIN: {
        const startYear = parseInt(this.intakeFromDate.substr(-4), 0);
        const endYear = parseInt(this.intakeToDate.substr(-4), 0);
        if (this.intakeFromDate.substr(-4) === this.intakeToDate.substr(-4)) {
          this.chartOptions.labels = Array.from(Array(12), (_, i) => `${startYear}-${ (i + 1) < 10 ? `0${(i + 1)}` : (i + 1)}`);
        } else {
          const yearsLength = endYear - startYear + 1;
          this.chartOptions.labels = Array.from(Array(yearsLength), (_, i) =>  `${i + startYear}`);
        }
        const series = [];
        let index = 0;
        const objects = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
        for (const [key, value] of objects) {
          series.push({
            name: key,
            data: [],
          });
          this.chartOptions.labels.forEach(year => {
            series[index].data.push({
              x: year,
              y: value[year] ? value[year].qty : 0,
            });
          });
          index ++;
        }
        this.chartOptions.series = series;
        break;
      }
      default: {
        break;
      }
    }
    this.showCharts = true;
    this.shareService.stopLoading();
  }
  public abstractTheList(baseList) {
    return _.chain(baseList)
      .groupBy('label')
      .map((value, key) => ({
        medicament: key,
        items: _.chain(value)
          .groupBy('strength')
          .map((v, k) => ({
            strength: k,
            // @ts-ignore
            items: v,
          }))
          .value()
      }))
      .value();
  }
  public existInList(list, key, value) {
    return _.find(list, t => t[key] === value);
  }
  public calculateTotal(list, label) {
    let counter = 0;
    list.forEach(t => {
      counter += t[label];
    });
    return counter;
  }
  public onValueChange(args, affectLabel: string) {
    this[affectLabel] = this.shareService.getDate(args);
    this.reloadChart(this.activeEvaluation.key);
  }
  public checkCountryRoles() {
    if (this.authService.user.iscountryeditor) {
      this.canEditCountry = false;
    }
    if (this.authService.user.ismaineditor) {
      this.canEditCountry = true;
    }
  }
  public printChart() {
    const date = this.shareService.getPrintableDate(new Date());
    // @ts-ignore
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
      floatPrecision: 16 // or "smart", default is 16
    });
    switch (this.activeEvaluation.key) {
      case this._reportType.CHANGE_OF_MEDICAMENT: {
        const country = this.selectedCountry.name;
        doc.setFontSize(12);
        // FIRST TABLE
        const headerText = `${this.facadeService.transInstByKey('reports.numberOfChanges')} (${country})`;
        doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
        doc.autoTable({
          theme: 'grid',
          headStyles: { ...this.leftMiddle, fillColor: '#9AC7E9' },
          columnStyles: { 0: this.leftMiddle, 1: this.leftMiddle, 2: this.centerMiddle },
          margin: { top: 25, left: 58.5 },
          tableWidth: 'wrap',
          html: `#${this.activeEvaluation.key}`
        });
        this.setLinesOfPDF(doc, date, this.activeEvaluation.name);
        // @ts-ignore
        doc.save(`${this.activeEvaluation.name}.pdf`);
        break;
      }
      case this._reportType.INTAKE_PLAN_BY_MEDICAMENT: {
        const ageGroup = this.facadeService.transInstByKey('reports.ageGroup');
        const country = this.selectedCountry.name;
        doc.setFontSize(12);
        // // FIRST TABLE
        let headerText = `${this.facadeService.transInstByKey('reports.numberOfIntakePlans')} - ${ageGroup} (${country})`;
        doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
        doc.autoTable({
          theme: 'grid',
          margin: { top: 25 },
          bodyStyles: this.centerMiddle,
          ...this.buildTableForPdf(this.tableData, 'intakePlans'),
        });
        // // SECOND TABLE
        doc.addPage();
        headerText = `${this.facadeService.transInstByKey('reports.averageDuration')} - ${ageGroup} (${country})`;
        doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
        doc.autoTable({
          theme: 'grid',
          margin: { top: 25 },
          bodyStyles: this.centerMiddle,
          ...this.buildTableForPdf(this.tableData, 'averageDuration'),
        });
        // THIRD TABLE
        doc.addPage();
        // tslint:disable-next-line:max-line-length
        headerText = `${this.facadeService.transInstByKey('reports.intakePlans')} - ${this.facadeService.transInstByKey('common.labels.sex')} (${country})`;
        doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
        doc.autoTable({
          theme: 'grid',
          margin: { top: 25, left: 44 },
          tableWidth: 200,
          bodyStyles: { ...this.centerMiddle, minCellWidth: 25 },
          ...this.buildTableForPdf2(this.secondTableData, 'intakePlans', 'averageDuration'),
        });
        this.setLinesOfPDF(doc, date, this.activeEvaluation.name);
        // @ts-ignore
        doc.save(`${this.activeEvaluation.name}.pdf`);
        break;
      }
      default: {
        // @ts-ignore
        this.chart.dataURI().then(({ imgURI, blob }) => {
          doc.addImage(imgURI, 'PNG', 10, 15, 277, 105);
          const key = this.activeEvaluation.key;
          if (key === this._reportType.INTAKE_PLAN_BY_PERIOD || key === this._reportType.SUPPORTIVE_MEDICAMENT) {
            doc.addPage();
            doc.setFontSize(12);
            let headerText = `${this.facadeService.transInstByKey('reports.numberOfIntakePlans')}`;
            headerText = key === this._reportType.SUPPORTIVE_MEDICAMENT ? `${headerText} (${this.selectedCountry.name})` : headerText;
            doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
            const style = key === this._reportType.SUPPORTIVE_MEDICAMENT ? this.rightMiddle : this.centerMiddle;
            doc.autoTable({
              theme: 'grid',
              headStyles: { ...this.leftMiddle, fillColor: '#9AC7E9' },
              bodyStyles: style,
              columnStyles: { 0: this.leftMiddle },
              margin: { top: 25, left: 68.5 },
              tableWidth: 150,
              html: `#${this.activeEvaluation.key}`
            });
          }
          if (key === this._reportType.NUMBERS_BY_COUNTRIES) {
            doc.addPage();
            doc.setFontSize(12);
            // FIRST TABLE
            const headerText = `${this.facadeService.transInstByKey('reports.numbers')}`;
            doc.text(this.getMiddleOfPdf(doc, headerText), 22, headerText);
            doc.autoTable({
              theme: 'grid',
              headStyles: { ...this.rightMiddle, fillColor: '#9AC7E9' },
              bodyStyles: this.rightMiddle,
              columnStyles: { 0: this.leftMiddle },
              margin: { top: 25, left: 44 },
              tableWidth: 200,
              html: `#${this.activeEvaluation.key}`
            });
          }
          this.setLinesOfPDF(doc, date, this.activeEvaluation.name);
          doc.save(`${this.activeEvaluation.name}.pdf`);
        });
        break;
      }
    }
  }
  public printChartExcel(reportT) {
    
    if(reportT == 'CHANGE_OF_MEDICAMENT') {
      var readyToExport = this._tableData;
      var Heading =[
        [ "Changed from","Changed to","Number of changes"]  
      ];
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(readyToExport);
      XLSX.utils.sheet_add_aoa(worksheet, Heading);
      const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      XLSX.writeFile(workbook, 'Change_of_product.xlsx');
    }
    if(reportT == 'INTAKE_PLAN_BY_PERIOD' || reportT == 'SUPPORTIVE_MEDICAMENT' 
      || reportT == 'PHARMACIES' || reportT == 'NUMBERS_BY_COUNTRIES' || 
      reportT == 'EMPLOYEES' || reportT == 'MEDICAMENTS') {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.COMMON_RESPONSE);
      const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      if(reportT == 'SUPPORTIVE_MEDICAMENT') {
        var Heading =[
          [ "Medicinal product","Number of Administration schedules", "Duration"]  
        ];
        XLSX.utils.sheet_add_aoa(worksheet, Heading);
      }
      if(reportT == 'PHARMACIES') {
        var HeadingForPharmacies =[
          [
            this.translate.instant('patient.evaluations.pharmacies.pharmacyName'),
            this.translate.instant('patient.evaluations.pharmacies.id'),
            this.translate.instant('patient.evaluations.pharmacies.status'),
            this.translate.instant('patient.evaluations.pharmacies.mainUserFirstname'),
            this.translate.instant('patient.evaluations.pharmacies.mainUserLastname'),
            this.translate.instant('patient.evaluations.pharmacies.zip'),
            this.translate.instant('patient.evaluations.pharmacies.city'),
            this.translate.instant('patient.evaluations.pharmacies.street'),
            this.translate.instant('patient.evaluations.pharmacies.email'),
            this.translate.instant('patient.evaluations.pharmacies.numberofPatientsCurrentYear'),
            this.translate.instant('patient.evaluations.pharmacies.numberofPatientsPreviousYear'),
            this.translate.instant('patient.evaluations.pharmacies.intakePlansOfCurrentYear'),
            this.translate.instant('patient.evaluations.pharmacies.intakePlansOfPreviousYear'),
            this.translate.instant('patient.evaluations.pharmacies.registeredSince'),
            this.translate.instant('patient.evaluations.pharmacies.freeofcharge'),
            this.translate.instant('patient.evaluations.pharmacies.endoffreeuse')
          ]
        ];
        XLSX.utils.sheet_add_aoa(worksheet, HeadingForPharmacies);
      }
      if(reportT == 'EMPLOYEES') {
        var HeadingForEmployees =[
          [
            this.translate.instant('patient.evaluations.employees.pharmacyName'),
            this.translate.instant('patient.evaluations.employees.status'),
            this.translate.instant('patient.evaluations.employees.registeredAs'),
            this.translate.instant('patient.evaluations.employees.zip'),
            this.translate.instant('patient.evaluations.employees.city'),
            this.translate.instant('patient.evaluations.employees.mainUserLastname'),
            this.translate.instant('patient.evaluations.employees.mainUserFirstname')
          ]
        ];
        XLSX.utils.sheet_add_aoa(worksheet, HeadingForEmployees);
      }
      if(reportT == 'MEDICAMENTS') {
        var HeadingForMedicaments =[
          [
            this.translate.instant('patient.evaluations.medicaments.label'),
            this.translate.instant('patient.evaluations.medicaments.manufactor'),
            this.translate.instant('patient.evaluations.medicaments.pharmacyName'),
            this.translate.instant('patient.evaluations.medicaments.zip'),
            this.translate.instant('patient.evaluations.medicaments.city'),
            this.translate.instant('patient.evaluations.medicaments.numberofPatients')
          ]
        ];
        XLSX.utils.sheet_add_aoa(worksheet, HeadingForMedicaments);
      }
      if (reportT == 'PHARMACIES') {
        XLSX.writeFile(workbook, this.translate.instant('patient.evaluations.pharmaciesTitleForExcel')+'.xlsx')
      } else if (reportT == 'EMPLOYEES') {
        XLSX.writeFile(workbook, this.translate.instant('patient.evaluations.employeesTitleForExcel')+'.xlsx')
      } else if (reportT == 'MEDICAMENTS') {
        XLSX.writeFile(workbook, this.translate.instant('patient.evaluations.medicamentsTitleForExcel')+'.xlsx')
      }else {
        XLSX.writeFile(workbook, reportT+'.xlsx');
      }
    }
    if(reportT == 'INTAKE_PLAN_BY_MEDICAMENT') {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.COMMON_RESPONSE['intakeMed']);
      const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      XLSX.writeFile(workbook, 'intakeMed.xlsx');

      const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.COMMON_RESPONSE['intakeMedAge']);
      const workbook2: XLSX.WorkBook = {Sheets: {'data': worksheet2}, SheetNames: ['data']};
      XLSX.writeFile(workbook2, 'intakeMedAge.xlsx');
    }
  
  }

  public setLinesOfPDF(doc, date, text) {
    // PAGE NUMBERING
    // Add Page number at bottom-right
    // Get the number of pages
    const pageCount = doc.internal.getNumberOfPages();
    // For each page, print the page number and the total pages
    for (let i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      // Print top line
      doc.setLineWidth(0.5);
      doc.setDrawColor(217,121,121);
      doc.line(10, 15, 287, 15);
      // Title in the middle
      doc.setFontSize(16);
      doc.text(this.getMiddleOfPdf(doc, text), 12, text);
      // End line
      doc.line(10, 200, 287, 200);
      // Date
      doc.setFontSize(8);
      doc.text(date, 10, 204);
      // Oralia database
      doc.text(this.getMiddleOfPdf(doc, text), 204, this.facadeService.transInstByKey('menus.main.oraliadb'));
      // Pages
      doc.text(`${this.facadeService.transInstByKey('printLeaflet.page')} ${i}/${pageCount}`, 272, 204);
    }
  }
  public getMiddleOfPdf(doc, text) {
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    return (doc.internal.pageSize.width - textWidth) / 2;
  }
  public buildTableForPdf(data, key) {
    const styles = this.centerMiddleHeader;
    return {
      head: [
        [{
          content: this.facadeService.transInstByKey('common.labels.medicament'),
          rowSpan: 2,
          styles: {
            ...styles,
            halign: 'left',
          },
        },
        {
          content: this.facadeService.transInstByKey('common.labels.strength'),
          rowSpan: 2,
          styles,
        },
        {
          content: this.facadeService.transInstByKey('reports.ageGroup'),
          colSpan: 11,
          styles,
        },
        {
          content: this.facadeService.transInstByKey('reports.total'),
          rowSpan: 2,
          styles,
        }],
        [
          ...this.getTableHeaderAges(styles),
        ]
      ],
      columns: [
        { header: this.facadeService.transInstByKey('common.labels.medicament'), dataKey: 'medicament' },
        { header: this.facadeService.transInstByKey('common.labels.strength'), dataKey: 'strength' },
        ...this.listOfAges.map(i => ({ header: i, dataKey: i })),
        { header: this.facadeService.transInstByKey('reports.total'), dataKey: 'total' },
      ],
      body: this.getDataStrengthRows(data, key),
    };
  }
  public getDataStrengthRows(args, key) {
    const mapRow: Map<String, number> = new Map();
    this.listOfAges.forEach(item => {
      mapRow.set(item, 0);
    });
    mapRow.set('total', 0);
    const response = [];
    args.forEach(med => {
      let tempRow = {};
      med.items.forEach((str, index) => {
        const itemsObj = {
          total: null,
        };
        str.items.forEach(i => {
          mapRow.set(i.decade, mapRow.get(i.decade) + i[key]);
          itemsObj[i.decade] = {
            content: i[key],
            styles: this.rightMiddle,
          };
        });
        const content = this.calculateTotal(str.items, key);
        mapRow.set('total', mapRow.get('total') + content);
        itemsObj.total = {
          content,
          styles: this.rightMiddle,
        };
        const values = {
          strength: { content: str.strength, styles: this.rightMiddle, },
          ...itemsObj
        };
        if (index === 0) {
          tempRow = {
            medicament: {
              content: med.medicament,
              rowSpan: med.items.length,
              styles: this.leftMiddle,
            },
            ...values
          };
        } else {
          tempRow = {
            ...values
          };
        }
        response.push(tempRow);
      });
    });
    response.push(this.createTotalRow(mapRow));
    return response;
  }
  public getTableHeaderAges(styles) {
    const arr = this.listOfAges.map(i => ({ content: i, styles, }));
    arr.shift();
    return [
      {
        content: this.facadeService.transInstByKey('reports.unknown'),
        styles,
      },
      ...arr,
    ];
  }
  public buildTableForPdf2(data, key1, key2) {
    const styles = this.centerMiddleHeader;
    return {
      head: [
        [
          {
            content: this.facadeService.transInstByKey('common.labels.medicament'),
            rowSpan: 2,
            styles: {
              ...styles,
              halign: 'left',
            },
          },
          {
            content: this.facadeService.transInstByKey('common.labels.strength'),
            rowSpan: 2,
            styles,
          },
          {
            content: this.facadeService.transInstByKey('reports.numberOfIntakePlans'),
            colSpan: 2,
            styles,
          },
          {
            content: this.facadeService.transInstByKey('reports.averageDuration'),
            colSpan: 2,
            styles,
          },
        ],
        [
          {
            content: this.facadeService.transInstByKey('common.sex.female'),
            styles,
          },
          {
            content: this.facadeService.transInstByKey('common.sex.male'),
            styles,
          },
          {
            content: this.facadeService.transInstByKey('common.sex.female'),
            styles,
          },
          {
            content: this.facadeService.transInstByKey('common.sex.male'),
            styles,
          },
        ]
      ],
      columns: [
        { header: this.facadeService.transInstByKey('common.labels.medicament'), dataKey: 'medicament' },
        { header: this.facadeService.transInstByKey('common.labels.strength'), dataKey: 'strength' },
        { header: this.facadeService.transInstByKey('common.sex.female'), dataKey: 'female1' },
        { header: this.facadeService.transInstByKey('common.sex.male'), dataKey: 'male1' },
        { header: this.facadeService.transInstByKey('common.sex.female'), dataKey: 'female2' },
        { header: this.facadeService.transInstByKey('common.sex.male'), dataKey: 'male2' },
      ],
      body: this.getDataStrengthRows2(data, key1, key2),
    };
  }
  public getDataStrengthRows2(args, key1, key2) {
    const mapRow: Map<String, number> = new Map();
    mapRow.set('female1', 0);
    mapRow.set('female2', 0);
    mapRow.set('male1', 0);
    mapRow.set('male2', 0);
    const response = [];
    args.forEach(med => {
      let tempRow = {};
      med.items.forEach((str, index) => {
        const valueFemale = this.existInList(str.items, 'sex', 'female');
        const valueMale = this.existInList(str.items, 'sex', 'male');
        mapRow.set('female1', mapRow.get('female1') + (valueFemale ? valueFemale[key1] : 0));
        mapRow.set('female2', mapRow.get('female2') + (valueFemale ? valueFemale[key2] : 0));
        mapRow.set('male1', mapRow.get('male1') + (valueMale ? valueMale[key1] : 0));
        mapRow.set('male2', mapRow.get('male2') + (valueMale ? valueMale[key2] : 0));
        const itemsObj = {
          female1: valueFemale ? { content: valueFemale[key1], styles: this.rightMiddle } : '',
          female2: valueFemale ? { content: valueFemale[key2], styles: this.rightMiddle } : '',
          male1: valueMale ? { content: valueMale[key1], styles: this.rightMiddle } : '',
          male2: valueMale ? { content: valueMale[key2], styles: this.rightMiddle } : '',
        };
        const values = {
          strength: { content: str.strength, styles: this.rightMiddle, },
          ...itemsObj
        };
        if (index === 0) {
          tempRow = {
            medicament: {
              content: med.medicament,
              rowSpan: med.items.length,
              styles: this.leftMiddle,
            },
            ...values
          };
        } else {
          tempRow = {
            ...values
          };
        }
        response.push(tempRow);
      });
    });
    response.push(this.createTotalRow(mapRow));
    return response;
  }
  public createTotalRow(mapRow) {
    const totalRow = {};
    totalRow['medicament'] = {
      content: this.facadeService.transInstByKey('reports.total'),
      styles: { ...this.leftMiddle, fontStyle: 'bold' },
    };
    mapRow.forEach((value, key) => {
      totalRow[`${key}`] = {
        content: value,
        styles: { ...this.rightMiddle, fontStyle: 'bold' },
      }
    });
    return totalRow;
  }
}
