/**
 * @author Jonathan Almanza
 * 11/12/2018
 */
import {FormInterface} from './form.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {ShareService} from '../../services/share.service';
import {Store, select} from '@ngrx/store';
import {BsModalService} from 'ngx-bootstrap';
import {FacadeService, TranslateFormData} from '../../services/facade.service';
import {AppState} from '../../store/app.states';
import {find} from 'lodash';
import {UserLanguage} from '../user-language';
import {ActionStateEnum} from '../enums/actions-state.enum';
import {isNullOrUndefined} from 'util';
import {Medicament} from '../medicament';
import {AuthService} from '../../services/auth.service';
import { LoadLanguagesAction } from 'src/app/store/actions/lang.actions';

export abstract class FormAbstract<B> implements FormInterface<B> {
  public formGroup: FormGroup;
  public baseObject: B;
  public originalObject: B;
  public languageId: number;
  public showPopupOneTime: boolean;
  public baseId: {
    parentId: string,
    translationId: string
  };
  public action: ActionStateEnum;
  public baseFields: TranslateFormData[];
  public languageList: Array<UserLanguage>;
  public allLanguageList: Array<UserLanguage>;
  public genderList: Array<any>;
  public statusList: Array<any>;
  public salutationList: Array<any>;
  public trueFalseList: Array<any>;
  public translateWithCountry = false;

  protected constructor(protected store: Store<AppState>,
                        protected bsRef: BsModalService, protected shareService: ShareService,
                        protected facadeService: FacadeService, protected authService: AuthService) {
    this.languageList = this.facadeService.availableLanguages;
    this.genderList = this.facadeService.getGender(this.languageId);
    this.statusList = this.facadeService.getStatus(this.languageId);
    this.salutationList = this.facadeService.getSalutation();
  }

  public changeSectionLanguage(language: UserLanguage, cancelPatch?: boolean): void {
    this.languageId = language.languageid;
    if (isNullOrUndefined(cancelPatch)) {
      this.prepareForm();
    }
    this.genderList = this.facadeService.getGender(this.languageId);
    this.statusList = this.facadeService.getStatus(this.languageId);
    this.salutationList = this.facadeService.getSalutation();
  }

  public languageAction1() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'common.language.action';
      }
      case ActionStateEnum.EDIT: {
        return 'common.language.action';
      }
      default: {
        return '';
      }
    }
  }

  public onSubmit(): void {
  }

  public userViewLabel(): string {
    return '';
  }

  public languageAction() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'common.language.action';
      }
      case ActionStateEnum.EDIT: {
        return 'common.language.action';
      }
      default: {
        return '';
      }
    }
  }

  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public prepareForm() {
    if (this.translateWithCountry) {
      this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields,
        this.languageId, this.authService.user.country.countryid);
    } else {
      this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields, this.languageId);
    }
  }

  /** required  name of input
   * setter error when field is empty
   * @param event
   */
  public noRequiredFieldSetError(event: any) {
    if ('' === event.target.value) {
      this.formGroup.get(event.target.name).setErrors(null);
      this.formGroup.get(event.target.name).setValue(null);
    }
  }

  public getCurrentTranslation(languageId, base) {
    if (base) {
      if (base.translations) {
        return find(base.translations, (translate) => {
          return translate.languageid === languageId;
        });
      }
    }
  }

  public closeModal(cancelPatch?: boolean) {
    if (this.baseObject instanceof Medicament) {
      this.languageId = this.authService.user.country.languageid;
      this.changeSectionLanguage({languageid: this.languageId}, true);
    } else {
      this.languageId = this.authService.userLanguage.languageid;
      this.changeSectionLanguage({languageid: this.languageId}, true);
    }
    if (this.action === ActionStateEnum.EDIT) {
      if (isNullOrUndefined(cancelPatch)) {
        this.prepareForm();
      }
    }
    if (this.action === ActionStateEnum.TRANSLATE) {
      this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields, this.languageId);
    }
    this.action = ActionStateEnum[ActionStateEnum.VIEW];
    this.bsRef.hide(1);
    this.bsRef.config.initialState = {};
  }

  public changeActionState(args: any) {
    switch (args) {
      case ActionStateEnum[ActionStateEnum.EDIT]: {
        if (this.baseObject instanceof Medicament) {
          // console.dir('Is Medicament');
        } else {
          const langToChange = this.facadeService.availableLanguages.find(t => t.language === 'English');
          if (langToChange) {
            this.languageId = langToChange.languageid;
          } else {
            this.languageId = 1;
          }
          this.prepareForm();
        }
        if (!isNullOrUndefined(this.baseObject['status'])) {
          this.formGroup.patchValue({'status': this.baseObject['status']});
        }
        break;
      }
      case ActionStateEnum[ActionStateEnum.TRANSLATE]: {
        const langToChange = [...this.facadeService.translationLanguages];
        if (langToChange.length) {
          let userLang = find(langToChange, ['languageid', this.authService.user.userLanguage.languageid]);
          if (userLang) {
            this.languageId = userLang.languageid;
          } else {
            userLang = find(langToChange, ['languageid', this.authService.user.country.languageid]);
            if (userLang) {
              this.languageId = userLang.languageid;
            } else {
              this.languageId = langToChange[0].languageid;
            }
          }
          this.prepareForm();
        } else {
          this.prepareForm();
        }
        break;
      }
      case ActionStateEnum[ActionStateEnum.VIEW]: {
        if (this.action === ActionStateEnum.CREATE) {
          this.closeModal(true);
        } else {
          const langToChange = this.facadeService.availableLanguages.find(t => t.language === 'English');
          if (langToChange) {
            this.languageId = langToChange.languageid;
          } else {
            this.languageId = 1;
          }
          if (this.originalObject) {
            this.baseObject = this.originalObject;
            this.prepareForm();
          }
          this.closeModal();
        }
        break;
      }
    }
    if (this.formGroup.get('textid') && this.formGroup.get('textid').value) {
      this.checkToShowPopupOneTime(this.formGroup.get('textid').value);
    }
    this.action = args;
  }

  public checkToShowPopupOneTime(textid: number) {

    if (textid == 14 || textid == 21 || textid == 22) {
      this.showPopupOneTime = true;
    } else {
      this.showPopupOneTime = false;
    }
  }
}
