import {AddPreparation} from './add-preparation';

export class IntakeAddPrep {
  planaddprepid?: number;
  instruction?: string;
  intakes?: number; //  smallint(6) NOT NULL,
  days?: string; // smallint(6) NOT NULL,
  pieces?: number; //  decimal(3,1) NOT NULL,
  pieces00clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces01clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces02clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces03clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces04clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces05clock?: number; //  decimal(3,1) DEFAULT NULL,
  pieces06clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces07clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces08clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces09clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces10clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces11clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces12clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces13clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces14clock?: number; //  decimal(3,1) DEFAULT NULL,
  pieces15clock?: number; //  decimal(3,1) DEFAULT NULL,
  pieces16clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces17clock?: number; //  decimal(3,1) DEFAULT NULL,
  pieces18clock?: number; //  decimal(3,1) DEFAULT NULL,
  pieces19clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces20clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces21clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces22clock?: number; // decimal(3,1) DEFAULT NULL,
  pieces23clock?: number; // decimal(3,1) DEFAULT NULL,
  strength?: string;
  morning?: number;
  midday?: number;
  evening?: number;
  night?: number;
  additionalPreparation?: AddPreparation;
  planid?: number;
  countryid?: number;
  pharmacyid?: number;
  patientid?: number;

  constructor() {
    this.planaddprepid = null;
    this.instruction = '';
    this.intakes = 0;
    this.days = '';
    this.pieces = 0;
    this.pieces00clock = 0;
    this.pieces01clock = 0;
    this.pieces02clock = 0;
    this.pieces03clock = 0;
    this.pieces04clock = 0;
    this.pieces05clock = 0;
    this.pieces06clock = 0;
    this.pieces07clock = 0;
    this.pieces08clock = 0;
    this.pieces09clock = 0;
    this.pieces10clock = 0;
    this.pieces11clock = 0;
    this.pieces12clock = 0;
    this.pieces13clock = 0;
    this.pieces14clock = 0;
    this.pieces15clock = 0;
    this.pieces16clock = 0;
    this.pieces17clock = 0;
    this.pieces18clock = 0;
    this.pieces19clock = 0;
    this.pieces20clock = 0;
    this.pieces21clock = 0;
    this.pieces22clock = 0;
    this.pieces23clock = 0;
    this.strength = '';
    this.additionalPreparation = null;
    this.planid = null;
    this.countryid = null;
    this.pharmacyid = null;
    this.patientid = null;
  }
}
