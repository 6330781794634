import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClientOptions} from 'apollo-client';
import {setContext} from 'apollo-link-context';
import {HttpHeaders} from '@angular/common/http';
export function createApollo(httpLink: HttpLink) {
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      }
    };
  });
  return <ApolloClientOptions<any>>{
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: httpLink.create(<any>{
      aut: authLink,
      uri: '/graphql',
      headers: new HttpHeaders({
        charset: 'UTF-8'
      })
      // If needed, you can set custom headers here
      // headers: new HttpHeaders({
      //   Authorization: `Bearer TOKEN`
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache'
      },
      watchQuery: {
        fetchPolicy: 'no-cache'
      }
    },
    connectToDevTools: true
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
}
