import {All, PdfTextActions} from '../actions/pdf-text.actions';
import { PdfText } from '../../models/pdf-text';

export interface PdfTextState {
  selected: PdfText;
}

export const initialState: PdfTextState = {
  selected: null
};

export function reducer(state = initialState, action: All): PdfTextState {
  switch (action.type) {
    case PdfTextActions.SELECT_PDF_TEXT: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
