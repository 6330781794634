export type Maybe<T> = T | null;

export interface MedStrengthInput {
  readonly individual: Maybe<boolean>;

  readonly medstrengthid: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly midday: Maybe<number>;

  readonly night: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly countryid: Maybe<number>;
}

export interface EmployeeInput {
  readonly firstname: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly title: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly lastname: Maybe<string>;

  readonly status: Maybe<number>;

  readonly apothekemaid: Maybe<number>;

  readonly email: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly salutation: Maybe<number>;
}

export interface MedicamentInput {
  readonly link3text: Maybe<string>;

  readonly datemedicalinfo: Maybe<string>;

  readonly substance: Maybe<SubstanceInput>;

  readonly link1: Maybe<string>;

  readonly link3: Maybe<string>;

  readonly status: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medIntakeNotes: Maybe<ReadonlyArray<MedIntakeNoteInput>>;

  readonly shortname: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<MedLangInput>>;

  readonly notes: Maybe<ReadonlyArray<MedNoteInput>>;

  readonly medSideEffects: Maybe<ReadonlyArray<MedSideEffectInput>>;

  readonly link1text: Maybe<string>;

  readonly editors: Maybe<string>;

  readonly medStrengths: Maybe<ReadonlyArray<MedStrengthInput>>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly link2: Maybe<string>;

  readonly medicamentid: Maybe<number>;

  readonly intakeshort: Maybe<string>;

  readonly sideeffects: Maybe<string>;

  readonly medOtherNotes: Maybe<ReadonlyArray<MedOtherNoteInput>>;

  readonly background: Maybe<string>;

  readonly history: Maybe<ReadonlyArray<HistoryInput>>;

  readonly shortnotice: Maybe<string>;

  readonly intakelong: Maybe<string>;

  readonly manufactor: Maybe<string>;

  readonly medInteractions: Maybe<ReadonlyArray<MedInteractionInput>>;

  readonly link2text: Maybe<string>;

  readonly mischint: Maybe<string>;
}

export interface SubstanceInput {
  /** A Substance label */
  readonly label: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<SubstanceLangInput>>;

  readonly status: Maybe<number>;
  /** A Substance id */
  readonly substanceid: Maybe<number>;
}

export interface SubstanceLangInput {
  readonly substancelangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly substanceid: Maybe<number>;

  readonly languageid: Maybe<number>;
}

export interface MedIntakeNoteInput {
  readonly mednoteid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;
}

export interface MedLangInput {
  readonly interaction: Maybe<string>;

  readonly medicamentid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly medlanguageid: Maybe<number>;

  readonly intakeshort: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly othernote: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly background: Maybe<string>;

  readonly sideeffects: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly intakelong: Maybe<string>;
}

export interface MedNoteInput {
  readonly mednoteid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly note: Maybe<string>;

  readonly theme: Maybe<string>;

  readonly date: Maybe<SqlDate>;
}

export interface MedSideEffectInput {
  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;

  readonly medeffectid: Maybe<number>;

  readonly type: number;
}

export interface MedOtherNoteInput {
  readonly othernoteid: Maybe<number>;

  readonly medothernoteid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly countryid: Maybe<number>;
}

export interface HistoryInput {
  readonly objectid: Maybe<number>;

  readonly label2: Maybe<string>;

  readonly operation: Maybe<number>;

  readonly label: Maybe<string>;

  readonly tableid: Maybe<number>;

  readonly historyid: Maybe<number>;

  readonly datetime: Maybe<Date>;

  readonly user: Maybe<UserInput>;
}

export interface UserInput {
  readonly doccheck: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly ismainadmin: boolean;

  readonly pharmacyid: Maybe<number>;

  readonly iscountryeditor: boolean;

  readonly issimpleuser: boolean;

  readonly street: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly lastlogin: Maybe<Date>;

  readonly status: Maybe<number>;

  readonly password: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly isemployee: boolean;

  readonly translationLang: Maybe<ReadonlyArray<PatientLanguageInput>>;

  readonly created: Maybe<Date>;

  readonly salutation: Maybe<number>;

  readonly title: Maybe<string>;

  readonly email: Maybe<string>;

  readonly startdate: Maybe<Date>;

  readonly country: Maybe<CountryInput>;

  readonly name: Maybe<string>;

  readonly institutiontype: Maybe<number>;

  readonly ispharmacy: boolean;

  readonly city: Maybe<string>;

  readonly iscountryadmin: boolean;

  readonly userLanguage: Maybe<UserLanguageInput>;

  readonly enddate: Maybe<Date>;

  readonly ismaineditor: boolean;

  readonly userDocs: Maybe<ReadonlyArray<UserDocInput>>;

  readonly endoffreeuse: Maybe<Date>;

  readonly freeofcharge: boolean;
}

export interface PatientLanguageInput {
  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly status: Maybe<number>;

  readonly flag: Maybe<Base64String>;
}

export interface CountryInput {
  readonly languageid: Maybe<number>;

  readonly street: Maybe<string>;

  readonly doccheck: Maybe<number>;

  readonly status: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly documents: Maybe<string>;

  readonly organisation: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly logo: Maybe<Base64String>;

  readonly city: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly name: Maybe<string>;

  readonly email: Maybe<string>;
}

export interface UserLanguageInput {
  readonly language: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<UserLanguageLangInput>>;

  readonly languageid: Maybe<number>;

  readonly shortname: Maybe<string>;
}

export interface UserLanguageLangInput {
  readonly languagelangid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly userlanguageid: Maybe<number>;
}

export interface UserDocInput {
  readonly countryid: Maybe<number>;

  readonly document: Maybe<Base64String>;

  readonly content: Maybe<string>;

  readonly size: number;

  readonly filetype: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly userdocid: Maybe<number>;

  readonly label: Maybe<string>;
}

export interface MedInteractionInput {
  readonly interactionid: Maybe<number>;

  readonly medinteractionid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;
}

export interface PatientNoteInput {
  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly status: Maybe<number>;

  readonly theme: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly noteid: Maybe<number>;
}

export interface AddPreparationInput {
  readonly lastchange: Maybe<SqlDate>;

  readonly mischint: Maybe<string>;

  readonly status: Maybe<number>;

  readonly addPrepStrengths: Maybe<ReadonlyArray<AddPrepStrengthInput>>;

  readonly translations: Maybe<ReadonlyArray<AddPrepLangInput>>;

  readonly history: Maybe<ReadonlyArray<HistoryInput>>;

  readonly countryid: Maybe<number>;

  readonly addPrepSides: Maybe<ReadonlyArray<AddPrepSideInput>>;

  readonly informations: Maybe<string>;

  readonly addpreparationid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly patientinfo: Maybe<string>;

  readonly hints: Maybe<string>;
}

export interface AddPrepStrengthInput {
  readonly intakes: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly strengthid: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly preparationid: Maybe<number>;

  readonly night: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly pieces: Maybe<number>;
}

export interface AddPrepLangInput {
  readonly mischint: Maybe<string>;

  readonly hints: Maybe<string>;

  readonly addpreplangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly addpreparationid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly patientinfo: Maybe<string>;

  readonly countryid: Maybe<number>;
}

export interface AddPrepSideInput {
  readonly countryid: Maybe<number>;

  readonly addprepsidegid: Maybe<number>;

  readonly addpreparationid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;
}

export interface IntakeNoteInput {
  readonly translations: Maybe<ReadonlyArray<IntakeNoteLangInput>>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly intakenoteid: Maybe<number>;

  readonly intakenote: Maybe<string>;
}

export interface IntakeNoteLangInput {
  readonly intakenotelangid: Maybe<number>;

  readonly intakenote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;
}

export interface SponsorInput {
  readonly sponsorid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly link: Maybe<string>;

  readonly dateend: Maybe<SqlDate>;

  readonly logo: Maybe<Base64String>;

  readonly sponsor: Maybe<string>;

  readonly text: Maybe<string>;

  readonly datestart: Maybe<SqlDate>;
}

export interface DoctorInput {
  readonly fax: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly city: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly doctorid: Maybe<number>;
}

export interface IntakePlanInput {
  readonly dateto: Maybe<SqlDate>;

  readonly intakeMeds: Maybe<ReadonlyArray<IntakeMedInput>>;

  readonly patientid: Maybe<number>;

  readonly datefrom: Maybe<SqlDate>;

  readonly planperpatient: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly intakeDocs: Maybe<ReadonlyArray<IntakeDocInput>>;

  readonly firstplanid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly intakePlanDates: Maybe<ReadonlyArray<IntakePlanDateInput>>;

  readonly pharmacyid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly intakeAddPreps: Maybe<ReadonlyArray<IntakeAddPrepInput>>;
}

export interface IntakeMedInput {
  readonly intakemedid: Maybe<number>;

  readonly shortnotice: Maybe<string>;

  readonly pieces06clock: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces12clock: Maybe<number>;

  readonly startstock: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly pieces21clock: Maybe<number>;

  readonly days: Maybe<string>;

  readonly pieces15clock: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly intakes: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly packingsize: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly endstock: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly night: Maybe<number>;

  readonly medicament: Maybe<MedicamentInput>;

  readonly noofpackings: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;
}

export interface IntakeDocInput {
  readonly plandocid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly datetime: Maybe<SqlDate>;
}

export interface IntakePlanDateInput {
  readonly date: Maybe<SqlDate>;

  readonly id: Maybe<number>;

  readonly intakePlanDateTimes: Maybe<ReadonlyArray<IntakePlanDateTimeInput>>;

  readonly planid: Maybe<number>;
}

export interface IntakePlanDateTimeInput {
  readonly id: Maybe<number>;

  readonly intakePlanMedicaments: Maybe<
    ReadonlyArray<IntakePlanMedicamentInput>
  >;

  readonly time: Maybe<SqlTime>;

  readonly intakePlanPreparations: Maybe<
    ReadonlyArray<IntakePlanPreparationInput>
  >;

  readonly plandateid: Maybe<number>;
}

export interface IntakePlanMedicamentInput {
  readonly plandatetimeid: Maybe<number>;

  readonly id: Maybe<number>;

  readonly medicament: Maybe<MedicamentInput>;

  readonly strength: Maybe<string>;

  readonly pieces: Maybe<number>;
}

export interface IntakePlanPreparationInput {
  readonly plandatetimeid: Maybe<number>;

  readonly id: Maybe<number>;

  readonly addPreparation: Maybe<AddPreparationInput>;

  readonly strength: Maybe<string>;

  readonly pieces: Maybe<number>;
}

export interface IntakeAddPrepInput {
  readonly patientid: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly intakes: number;

  readonly pieces21clock: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly night: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces15clock: Maybe<number>;

  readonly pieces06clock: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly additionalPreparation: Maybe<AddPreparationInput>;

  readonly countryid: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly days: number;

  readonly pieces12clock: Maybe<number>;

  readonly planaddprepid: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly pharmacyid: Maybe<number>;
}

export interface PharmacyInput {
  readonly userid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly street: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly logo: Maybe<Base64String>;

  readonly name: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly mail: Maybe<string>;

  readonly bgano: Maybe<string>;
}

export interface PatientDocInput {
  readonly date: Maybe<SqlDate>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly docid: Maybe<number>;

  readonly protocol: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly sideEffects: Maybe<ReadonlyArray<PatientDocSideInput>>;
}

export interface PatientDocSideInput {
  readonly doceffectid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly soldpreparates: Maybe<string>;

  readonly doctorreference: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly measures: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly sideEffect: Maybe<SideEffectInput>;

  readonly docid: Maybe<number>;

  readonly level: Maybe<number>;

  readonly patientid: Maybe<number>;
}

export interface SideEffectInput {
  readonly medicalterm: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<SideEffectLangInput>>;

  readonly measures: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly status: Maybe<number>;

  readonly symptoms: Maybe<string>;

  readonly sideeffect: Maybe<string>;

  readonly sideeffectid: Maybe<number>;

  readonly literature: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly doctorfromlevel: Maybe<number>;

  readonly level5: Maybe<string>;

  readonly level3: Maybe<string>;

  readonly label: Maybe<string>;

  readonly level1: Maybe<string>;

  readonly level4: Maybe<string>;
}

export interface SideEffectLangInput {
  readonly sideeffect: Maybe<string>;

  readonly symptoms: Maybe<string>;

  readonly sideeffectlangid: Maybe<number>;

  readonly level5: Maybe<string>;

  readonly measures: Maybe<string>;

  readonly level1: Maybe<string>;

  readonly level4: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly label: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly sideeffectid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly level3: Maybe<string>;
}

export interface OtherNoteInput {
  readonly othernote: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<OtherNoteLangInput>>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly othernoteid: Maybe<number>;
}

export interface OtherNoteLangInput {
  readonly languageid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly othernotelangid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly othernoteid: Maybe<number>;

  readonly label: Maybe<string>;
}

export interface FileInput {
  readonly fileid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly filename: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<FileCountryLangInput>>;
}

export interface FileCountryLangInput {
  readonly label: Maybe<string>;

  readonly filetype: Maybe<string>;

  readonly fileid: Maybe<number>;

  readonly filename: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly filecountrylangid: Maybe<number>;

  readonly file: Maybe<Base64String>;

  readonly countryid: Maybe<number>;
}

export interface AccountInputInput {
  readonly lastname: Maybe<string>;

  readonly password: Maybe<string>;

  readonly email: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly title: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly confirmPassword: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly salutation: Maybe<number>;
}

export interface PatientInput {
  readonly birthyear: Maybe<number>;

  readonly status: Maybe<number>;

  readonly acceptance: Maybe<number>;

  readonly docs: Maybe<ReadonlyArray<PatientDocInput>>;

  readonly patientDoctors: Maybe<ReadonlyArray<PatientDoctorInput>>;

  readonly doctorid: Maybe<number>;

  readonly disease: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly notes: Maybe<ReadonlyArray<PatientNoteInput>>;

  readonly email: Maybe<string>;

  readonly patientid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly intakePlans: Maybe<ReadonlyArray<IntakePlanInput>>;

  readonly countryid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;
}

export interface PatientDoctorInput {
  readonly patientDoctorDoctor: Maybe<DoctorInput>;

  readonly patientid: Maybe<number>;

  readonly patientdoctorid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly prescribing: Maybe<number>;
}

export interface InteractionInput {
  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<InteractionLangInput>>;

  readonly interaction: Maybe<string>;

  readonly interactionid: Maybe<number>;
}

export interface InteractionLangInput {
  readonly languageid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly interactionlangid: Maybe<number>;

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;
}

export interface TextInput {
  readonly text: Maybe<string>;

  readonly textid: Maybe<number>;

  readonly subject: Maybe<string>;

  readonly label: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<TextCountryLangInput>>;
}

export interface PdfTextInput {
  readonly text: Maybe<string>;

  readonly pdftextlangid: Maybe<number>;

  readonly labelText: Maybe<string>;

  readonly translations: Maybe<Object>;
}

export interface PdfTextLangInput {
  readonly text: Maybe<string>;

  readonly pdftextlangid: Maybe<number>;

  readonly labelText: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

}

export interface TextCountryLangInput {
  readonly label: Maybe<string>;

  readonly textcountrylangid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly subject: Maybe<string>;

  readonly text: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly textid: Maybe<number>;
}

export type ActionStateEnum = "CREATE" | "DELETE" | "EDIT" | "TRANSLATE";

export type MedCheckBoxEnum =
  | "INTAKE_NOTES"
  | "INTERACTIONS"
  | "OTHER_NOTES"
  | "SIDE_EFFECTS";

/** Built-in scalar representing a SQL compliant local date */
export type SqlDate = any;

/** Base64-encoded binary */
export type Base64String = any;

/** Built-in scalar representing an instant in time */
export type Date = any;

/** Long type */
export type Long = number;

/** Built-in scalar representing a SQL compliant local time */
export type SqlTime = any;

/** Built-in scalar for map-like structures */
export type MapStringObjectScalar = any;

/** Built-in scalar for dynamic values */
export type ObjectScalar = any;

/** Unrepresentable type */
export type Unrepresentable = any;

// ====================================================
// Documents
// ====================================================

export type AddPreparationsVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly countryId: number;
};

export type AddPreparationsQuery = {
  readonly __typename?: "Query";

  readonly AddPreparations: Maybe<
    ReadonlyArray<AddPreparationsAddPreparations>
  >;
};

export type AddPreparationsAddPreparations = AddPreparationFragmentFragment;

export type SaveAddSideCheckBoxVariables = {
  readonly addpreparationId: Maybe<number>;
  readonly countryId: Maybe<number>;
  readonly idList: ReadonlyArray<number>;
  readonly userId: Maybe<number>;
};

export type SaveAddSideCheckBoxMutation = {
  readonly __typename?: "Mutation";

  readonly saveAddSideCheckBox: Maybe<SaveAddSideCheckBoxSaveAddSideCheckBox>;
};

export type SaveAddSideCheckBoxSaveAddSideCheckBox = AddPreparationFragmentFragment;

export type SaveAddPreparationVariables = {
  readonly parentObj: AddPreparationInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: Maybe<number>;
};

export type SaveAddPreparationMutation = {
  readonly __typename?: "Mutation";

  readonly saveAddPreparation: Maybe<SaveAddPreparationSaveAddPreparation>;
};

export type SaveAddPreparationSaveAddPreparation = AddPreparationFragmentFragment;

export type SaveAddStrengthVariables = {
  readonly addPrepStrength: AddPrepStrengthInput;
  readonly action: ActionStateEnum;
};

export type SaveAddStrengthMutation = {
  readonly __typename?: "Mutation";

  readonly saveAddStrength: Maybe<SaveAddStrengthSaveAddStrength>;
};

export type SaveAddStrengthSaveAddStrength = {
  readonly __typename?: "AddPrepStrength";

  readonly countryid: Maybe<number>;

  readonly intakes: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly preparationid: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly strengthid: Maybe<number>;
};

export type UniqueAddPreparationVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
  readonly countryId: Maybe<number>;
};

export type UniqueAddPreparationQuery = {
  readonly __typename?: "Query";

  readonly UniqueAddPreparation: Maybe<boolean>;
};

export type CountryVariables = {
  readonly id: Maybe<number>;
};

export type CountryQuery = {
  readonly __typename?: "Query";

  readonly Country: Maybe<CountryCountry>;
};

export type CountryCountry = CountryFragmentFragment;

export type CountriesVariables = {
  readonly name: Maybe<string>;
};

export type CountriesQuery = {
  readonly __typename?: "Query";

  readonly Countries: Maybe<ReadonlyArray<CountriesCountries>>;
};

export type CountriesCountries = CountryFragmentFragment;

export type SaveCountryVariables = {
  readonly parentObj: CountryInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveCountryMutation = {
  readonly __typename?: "Mutation";

  readonly saveCountry: Maybe<SaveCountrySaveCountry>;
};

export type SaveCountrySaveCountry = CountryFragmentFragment;

export type UniqueCountryVariables = {
  readonly name: Maybe<string>;
  readonly objectId: number;
};

export type UniqueCountryQuery = {
  readonly __typename?: "Query";

  readonly UniqueCountry: Maybe<boolean>;
};

export type DoctorVariables = {
  readonly id: Maybe<number>;
};

export type DoctorQuery = {
  readonly __typename?: "Query";

  readonly Doctor: Maybe<DoctorDoctor>;
};

export type DoctorDoctor = {
  readonly __typename?: "Doctor";

  readonly doctorid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
};

export type DoctorsVariables = {
  readonly name: Maybe<string>;
  readonly pharmacyId: number;
};

export type DoctorsQuery = {
  readonly __typename?: "Query";

  readonly Doctors: Maybe<ReadonlyArray<DoctorsDoctors>>;
};

export type DoctorsDoctors = {
  readonly __typename?: "Doctor";

  readonly doctorid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
};

export type EmailDoctorVariables = {
  readonly subject: Maybe<string>;
  readonly email: string;
  readonly letter: Maybe<string>;
};

export type EmailDoctorMutation = {
  readonly __typename?: "Mutation";

  readonly emailDoctor: boolean;
};

export type SaveDoctorVariables = {
  readonly parentObj: DoctorInput;
  readonly action: ActionStateEnum;
};

export type SaveDoctorMutation = {
  readonly __typename?: "Mutation";

  readonly saveDoctor: Maybe<SaveDoctorSaveDoctor>;
};

export type SaveDoctorSaveDoctor = {
  readonly __typename?: "Doctor";

  readonly doctorid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
};

export type EmployeeVariables = {
  readonly id: Maybe<number>;
};

export type EmployeeQuery = {
  readonly __typename?: "Query";

  readonly Employee: Maybe<EmployeeEmployee>;
};

export type EmployeeEmployee = EmployeeFragmentFragment;

export type EmployeesVariables = {
  readonly name: Maybe<string>;
  readonly userId: Maybe<number>;
};

export type EmployeesQuery = {
  readonly __typename?: "Query";

  readonly Employees: Maybe<ReadonlyArray<EmployeesEmployees>>;
};

export type EmployeesEmployees = EmployeeOutputFragmentFragment;

export type SaveEmployeeVariables = {
  readonly parentObj: EmployeeInput;
  readonly languageId: Maybe<number>;
  readonly isPharmacy: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveEmployeeMutation = {
  readonly __typename?: "Mutation";

  readonly saveEmployee: Maybe<SaveEmployeeSaveEmployee>;
};

export type SaveEmployeeSaveEmployee = EmployeeOutputFragmentFragment;

export type FilesVariables = {
  readonly label: Maybe<string>;
  readonly languageId: Maybe<number>;
};

export type FilesQuery = {
  readonly __typename?: "Query";

  readonly Files: Maybe<ReadonlyArray<FilesFiles>>;
};

export type FilesFiles = FileFragmentFragment;

export type FileVariables = {
  readonly id: Maybe<number>;
};

export type FileQuery = {
  readonly __typename?: "Query";

  readonly File: Maybe<FileFile>;
};

export type FileFile = FileFragmentFragment;

export type SaveFileVariables = {
  readonly parentObj: FileInput;
  readonly countryId: Maybe<number>;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveFileMutation = {
  readonly __typename?: "Mutation";

  readonly saveFile: Maybe<SaveFileSaveFile>;
};

export type SaveFileSaveFile = FileFragmentFragment;

export type HistoryVariables = {
  readonly tableId: number;
  readonly objectId: number;
};

export type HistoryQuery = {
  readonly __typename?: "Query";

  readonly History: Maybe<ReadonlyArray<HistoryHistory>>;
};

export type HistoryHistory = {
  readonly __typename?: "History";

  readonly historyid: Maybe<number>;

  readonly datetime: Maybe<Date>;

  readonly label: Maybe<string>;

  readonly label2: Maybe<string>;

  readonly objectid: Maybe<number>;

  readonly operation: Maybe<number>;

  readonly tableid: Maybe<number>;

  readonly user: Maybe<HistoryUser>;
};

export type HistoryUser = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;
};

export type HistoriesVariables = {
  readonly tableId: number;
  readonly label: Maybe<string>;
};

export type HistoriesQuery = {
  readonly __typename?: "Query";

  readonly Histories: Maybe<ReadonlyArray<HistoriesHistories>>;
};

export type HistoriesHistories = {
  readonly __typename?: "History";

  readonly historyid: Maybe<number>;

  readonly datetime: Maybe<Date>;

  readonly label: Maybe<string>;

  readonly label2: Maybe<string>;

  readonly objectid: Maybe<number>;

  readonly operation: Maybe<number>;

  readonly tableid: Maybe<number>;

  readonly user: Maybe<HistoriesUser>;
};

export type HistoriesUser = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;
};

export type IntakeNotesByIdVariables = {
  readonly ids: Maybe<ReadonlyArray<number>>;
};

export type IntakeNotesByIdQuery = {
  readonly __typename?: "Query";

  readonly IntakeNotesById: Maybe<
    ReadonlyArray<IntakeNotesByIdIntakeNotesById>
  >;
};

export type IntakeNotesByIdIntakeNotesById = {
  readonly __typename?: "IntakeNote";

  readonly intakenoteid: Maybe<number>;

  readonly intakenote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<IntakeNotesByIdTranslations>>;
};

export type IntakeNotesByIdTranslations = {
  readonly __typename?: "IntakeNoteLang";

  readonly intakenotelangid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly intakenote: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type IntakeNotesVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly languageId: number;
};

export type IntakeNotesQuery = {
  readonly __typename?: "Query";

  readonly IntakeNotes: Maybe<ReadonlyArray<IntakeNotesIntakeNotes>>;
};

export type IntakeNotesIntakeNotes = {
  readonly __typename?: "IntakeNote";

  readonly intakenoteid: Maybe<number>;

  readonly intakenote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<IntakeNotesTranslations>>;
};

export type IntakeNotesTranslations = {
  readonly __typename?: "IntakeNoteLang";

  readonly intakenotelangid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly intakenote: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type SaveIntakeNoteVariables = {
  readonly parentObj: IntakeNoteInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveIntakeNoteMutation = {
  readonly __typename?: "Mutation";

  readonly saveIntakeNote: Maybe<SaveIntakeNoteSaveIntakeNote>;
};

export type SaveIntakeNoteSaveIntakeNote = {
  readonly __typename?: "IntakeNote";

  readonly intakenoteid: Maybe<number>;

  readonly intakenote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SaveIntakeNoteTranslations>>;
};

export type SaveIntakeNoteTranslations = {
  readonly __typename?: "IntakeNoteLang";

  readonly intakenotelangid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;

  readonly intakenote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type UniqueIntakeNoteVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
};

export type UniqueIntakeNoteQuery = {
  readonly __typename?: "Query";

  readonly UniqueIntakeNote: Maybe<boolean>;
};

export type InteractionsByIdVariables = {
  readonly ids: Maybe<ReadonlyArray<number>>;
};

export type InteractionsByIdQuery = {
  readonly __typename?: "Query";

  readonly InteractionsById: Maybe<
    ReadonlyArray<InteractionsByIdInteractionsById>
  >;
};

export type InteractionsByIdInteractionsById = {
  readonly __typename?: "Interaction";

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<InteractionsByIdTranslations>>;
};

export type InteractionsByIdTranslations = {
  readonly __typename?: "InteractionLang";

  readonly interactionlangid: Maybe<number>;

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type InteractionsVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly languageId: number;
};

export type InteractionsQuery = {
  readonly __typename?: "Query";

  readonly Interactions: Maybe<ReadonlyArray<InteractionsInteractions>>;
};

export type InteractionsInteractions = {
  readonly __typename?: "Interaction";

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<InteractionsTranslations>>;
};

export type InteractionsTranslations = {
  readonly __typename?: "InteractionLang";

  readonly interactionlangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly interactionid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly interaction: Maybe<string>;
};

export type SaveInteractionVariables = {
  readonly parentObj: InteractionInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveInteractionMutation = {
  readonly __typename?: "Mutation";

  readonly saveInteraction: Maybe<SaveInteractionSaveInteraction>;
};

export type SaveInteractionSaveInteraction = {
  readonly __typename?: "Interaction";

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SaveInteractionTranslations>>;
};

export type SaveInteractionTranslations = {
  readonly __typename?: "InteractionLang";

  readonly interactionlangid: Maybe<number>;

  readonly interactionid: Maybe<number>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type UniqueInteractionVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
  readonly languageId: number;
};

export type UniqueInteractionQuery = {
  readonly __typename?: "Query";

  readonly UniqueInteraction: Maybe<boolean>;
};

export type MedicamentsVariables = {
  readonly filterBy: Maybe<MapStringObjectScalar>;
};

export type MedicamentsQuery = {
  readonly __typename?: "Query";

  readonly Medicaments: Maybe<ReadonlyArray<MedicamentsMedicaments>>;
};

export type MedicamentsMedicaments = {
  readonly __typename?: "Medicament";

  readonly manufactor: Maybe<string>;

  readonly status: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medStrengths: Maybe<ReadonlyArray<MedicamentsMedStrengths>>;
} & MedicamentFragmentFragment;

export type MedicamentsMedStrengths = MedStrengthFragmentFragment;

export type MedicamentsWithSideEffectQuery = {
  readonly __typename?: "Query";

  readonly Medicaments: Maybe<ReadonlyArray<MedicamentsWithSideEffectMedicaments>>;
};

export type MedicamentsWithSideEffectMedicaments = {
  readonly __typename?: "Medicament";

  readonly medSideEffects: Maybe<ReadonlyArray<PatientFragmentMedSideEffects>>;

} & MedicamentsMedicaments & MedicamentFragmentFragment

export type SaveMedBackgroundVariables = {
  readonly parentObj: MedicamentInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: number;
};

export type SaveMedBackgroundMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedBackground: Maybe<SaveMedBackgroundSaveMedBackground>;
};

export type SaveMedBackgroundSaveMedBackground = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;
};

export type SaveMedCheckBoxVariables = {
  readonly medicamentId: Maybe<number>;
  readonly countryId: Maybe<number>;
  readonly idList: ReadonlyArray<number>;
  readonly type: Maybe<number>;
  readonly medEnum: Maybe<MedCheckBoxEnum>;
  readonly userId: Maybe<number>;
};

export type SaveMedCheckBoxMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedCheckBox: Maybe<SaveMedCheckBoxSaveMedCheckBox>;
};

export type SaveMedCheckBoxSaveMedCheckBox = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;
};

export type UpdateMedicamentLeafletVariables = {
  readonly parentObj: MedicamentInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: number;
};

export type UpdateMedicamentLeafletMutation = {
  readonly __typename?: "Mutation";

  readonly updateMedicamentLeaflet: Maybe<
    UpdateMedicamentLeafletUpdateMedicamentLeaflet
  >;
};

export type UpdateMedicamentLeafletUpdateMedicamentLeaflet = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;
};

export type SaveMedNoteVariables = {
  readonly medNote: MedNoteInput;
};

export type SaveMedNoteMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedNote: Maybe<SaveMedNoteSaveMedNote>;
};

export type SaveMedNoteSaveMedNote = {
  readonly __typename?: "MedNote";

  readonly medicamentid: Maybe<number>;
};

export type SaveMedStrengthVariables = {
  readonly medStrength: MedStrengthInput;
};

export type SaveMedStrengthMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedStrength: Maybe<SaveMedStrengthSaveMedStrength>;
};

export type SaveMedStrengthSaveMedStrength = {
  readonly __typename?: "MedStrength";

  readonly medstrengthid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly perdays: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly individual: Maybe<boolean>;
};

export type SaveMedicamentVariables = {
  readonly parentObj: MedicamentInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: Maybe<number>;
};

export type SaveMedicamentMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedicament: Maybe<SaveMedicamentSaveMedicament>;
};

export type SaveMedicamentSaveMedicament = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;
};

export type CopyMedicamentVariables = {
  readonly name: Maybe<string>;
  readonly medicamentId: Maybe<number>;
};

export type CopyMedicamentMutation = {
  readonly __typename?: "Mutation";

  readonly saveMedicament: Maybe<CopyMedicamentCopyMedicament>;
};

export type CopyMedicamentCopyMedicament = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;
};

export type OtherNotesByIdVariables = {
  readonly ids: Maybe<ReadonlyArray<number>>;
};

export type OtherNotesByIdQuery = {
  readonly __typename?: "Query";

  readonly OtherNotesById: Maybe<ReadonlyArray<OtherNotesByIdOtherNotesById>>;
};

export type OtherNotesByIdOtherNotesById = {
  readonly __typename?: "OtherNote";

  readonly othernoteid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly othernote: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<OtherNotesByIdTranslations>>;
};

export type OtherNotesByIdTranslations = {
  readonly __typename?: "OtherNoteLang";

  readonly othernotelangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly othernote: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type OtherNotesVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly languageId: number;
};

export type OtherNotesQuery = {
  readonly __typename?: "Query";

  readonly OtherNotes: Maybe<ReadonlyArray<OtherNotesOtherNotes>>;
};

export type OtherNotesOtherNotes = {
  readonly __typename?: "OtherNote";

  readonly othernoteid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<OtherNotesTranslations>>;
};

export type OtherNotesTranslations = {
  readonly __typename?: "OtherNoteLang";

  readonly countryid: Maybe<number>;

  readonly othernotelangid: Maybe<number>;

  readonly othernoteid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly othernote: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type SaveOtherNoteVariables = {
  readonly parentObj: OtherNoteInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveOtherNoteMutation = {
  readonly __typename?: "Mutation";

  readonly saveOtherNote: Maybe<SaveOtherNoteSaveOtherNote>;
};

export type SaveOtherNoteSaveOtherNote = {
  readonly __typename?: "OtherNote";

  readonly othernoteid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SaveOtherNoteTranslations>>;
};

export type SaveOtherNoteTranslations = {
  readonly __typename?: "OtherNoteLang";

  readonly othernotelangid: Maybe<number>;

  readonly othernoteid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type UniqueOtherNoteVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
};

export type UniqueOtherNoteQuery = {
  readonly __typename?: "Query";

  readonly UniqueOtherNote: Maybe<boolean>;
};

export type PatientLanguagesVariables = {
  readonly language: Maybe<string>;
};

export type PatientLanguagesQuery = {
  readonly __typename?: "Query";

  readonly PatientLanguages: Maybe<
    ReadonlyArray<PatientLanguagesPatientLanguages>
  >;
};

export type PatientLanguagesPatientLanguages = {
  readonly __typename?: "PatientLanguage";

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly status: Maybe<number>;

  readonly flag: Maybe<Base64String>;
};

export type SavePatientlanguageVariables = {
  readonly parentObj: PatientLanguageInput;
  readonly usersId: Maybe<ReadonlyArray<number>>;
  readonly action: ActionStateEnum;
};

export type SavePatientlanguageMutation = {
  readonly __typename?: "Mutation";

  readonly savePatientlanguage: Maybe<SavePatientlanguageSavePatientlanguage>;
};

export type SavePatientlanguageSavePatientlanguage = {
  readonly __typename?: "PatientLanguage";

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly status: Maybe<number>;

  readonly flag: Maybe<Base64String>;
};

export type UsersCountryAllowedVariables = {};

export type UsersCountryAllowedQuery = {
  readonly __typename?: "Query";

  readonly UsersCountryAllowed: Maybe<
    ReadonlyArray<UsersCountryAllowedUsersCountryAllowed>
  >;
};

export type UsersCountryAllowedUsersCountryAllowed = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly email: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly title: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly status: Maybe<number>;
};

export type UserLanguageVariables = {
  readonly languageId: Maybe<number>;
};

export type UserLanguageQuery = {
  readonly __typename?: "Query";

  readonly UserLanguage: Maybe<ReadonlyArray<UserLanguageUserLanguage>>;
};

export type UserLanguageUserLanguage = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly email: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly title: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly status: Maybe<number>;
};

export type ChangePatientDoctorVariables = {
  readonly patientDoctorId: number;
  readonly patientId: number;
};

export type ChangePatientDoctorMutation = {
  readonly __typename?: "Mutation";

  readonly changePatientDoctor: Maybe<ChangePatientDoctorChangePatientDoctor>;
};

export type ChangePatientDoctorChangePatientDoctor = PatientFragmentFragment;

export type DeletePatientDoctorVariables = {
  readonly patientDoctorId: number;
  readonly patientId: number;
};

export type DeletePatientDoctorMutation = {
  readonly __typename?: "Mutation";

  readonly deletePatientDoctor: Maybe<DeletePatientDoctorDeletePatientDoctor>;
};

export type DeletePatientDoctorDeletePatientDoctor = PatientFragmentFragment;

export type PatientDoctorsFreeVariables = {
  readonly patientId: Maybe<number>;
  readonly pharmacyId: number;
};

export type PatientDoctorsFreeQuery = {
  readonly __typename?: "Query";

  readonly PatientDoctorsFree: Maybe<
    ReadonlyArray<PatientDoctorsFreePatientDoctorsFree>
  >;
};

export type PatientDoctorsFreePatientDoctorsFree = {
  readonly __typename?: "Doctor";

  readonly doctorid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
};

export type SavePatientDoctorVariables = {
  readonly pharmacyId: Maybe<number>;
  readonly countryId: Maybe<number>;
  readonly patientId: Maybe<number>;
  readonly doctorId: Maybe<number>;
  readonly prescribing: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: Maybe<number>;
};

export type SavePatientDoctorMutation = {
  readonly __typename?: "Mutation";

  readonly savePatientDoctor: Maybe<SavePatientDoctorSavePatientDoctor>;
};

export type SavePatientDoctorSavePatientDoctor = {
  readonly __typename?: "PatientDoctor";

  readonly countryid: Maybe<number>;

  readonly patientdoctorid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly prescribing: Maybe<number>;
};

export type DeleteDocSideEffectVariables = {
  readonly item: number;
  readonly patientId: number;
};

export type DeleteDocSideEffectMutation = {
  readonly __typename?: "Mutation";

  readonly deleteDocSideEffect: Maybe<DeleteDocSideEffectDeleteDocSideEffect>;
};

export type DeleteDocSideEffectDeleteDocSideEffect = {
  readonly __typename?: "Patient";

  readonly patientid: Maybe<number>;
};

export type DeleteDocumentationVariables = {
  readonly item: number;
  readonly patientId: number;
};

export type DeleteDocumentationMutation = {
  readonly __typename?: "Mutation";

  readonly deleteDocumentation: Maybe<DeleteDocumentationDeleteDocumentation>;
};

export type DeleteDocumentationDeleteDocumentation = PatientFragmentFragment;

export type SavePatientDocumentationVariables = {
  readonly parentObj: PatientDocInput;
  readonly action: ActionStateEnum;
  readonly userId: number;
};

export type SavePatientDocumentationMutation = {
  readonly __typename?: "Mutation";

  readonly savePatientDocumentation: Maybe<
    SavePatientDocumentationSavePatientDocumentation
  >;
};

export type SavePatientDocumentationSavePatientDocumentation = PatientFragmentFragment;

export type DeleteIntakeAddPrepVariables = {
  readonly item: number;
  readonly patientId: number;
};

export type DeleteIntakeAddPrepMutation = {
  readonly __typename?: "Mutation";

  readonly deleteIntakeAddPrep: Maybe<DeleteIntakeAddPrepDeleteIntakeAddPrep>;
};

export type DeleteIntakeAddPrepDeleteIntakeAddPrep = PatientFragmentFragment;

export type DeleteIntakeMedVariables = {
  readonly item: number;
  readonly patientId: number;
};

export type DeleteIntakeMedMutation = {
  readonly __typename?: "Mutation";

  readonly deleteIntakeMed: Maybe<DeleteIntakeMedDeleteIntakeMed>;
};

export type DeleteIntakeMedDeleteIntakeMed = PatientFragmentFragment;

export type DeleteIntakePlanVariables = {
  readonly planId: number;
  readonly patientId: number;
};

export type DeleteIntakePlanMutation = {
  readonly __typename?: "Mutation";

  readonly deleteIntakePlan: Maybe<DeleteIntakePlanDeleteIntakePlan>;
};

export type DeleteIntakePlanDeleteIntakePlan = PatientFragmentFragment;

export type SaveIntakePlanVariables = {
  readonly intakePlan: IntakePlanInput;
  readonly intakePlanDates: Maybe<ReadonlyArray<IntakePlanDateInput>>;
  readonly action: ActionStateEnum;
  readonly forceDelete: Maybe<boolean>;
  readonly userId: Maybe<number>;
};

export type UpdateIntakeAddPrepVariables = {
  readonly intakeAddPrepInput: Maybe<IntakeAddPrepInput>
  // readonly additionalPreparation: Maybe<number>;
  // readonly days: Maybe<string>;
  // readonly evening: Maybe<number>;
  // readonly instruction: Maybe<string>;
  // readonly midday: Maybe<number>;
  // readonly morning: Maybe<number>;
  // readonly night: Maybe<number>;
  // readonly patientid: Maybe<number>;
  // readonly pieces07clock: Maybe<number>;
  // readonly pieces13clock: Maybe<number>;
  // readonly pieces19clock: Maybe<number>;
  // readonly pieces23clock: Maybe<number>;
  // readonly planaddprepid: Maybe<number>;
  // readonly strength: Maybe<number>;
};

export type SaveIntakePlanMutation = {
  readonly __typename?: "Mutation";

  readonly saveIntakePlan: Maybe<SaveIntakePlanSaveIntakePlan>;
};

export type SaveIntakePlanPlanIdMutation = {
  readonly __typename?: "Mutation";

  readonly saveIntakePlan: Maybe<SaveIntakePlanSaveIntakePlanPlanId>;
};

export type UpdateIntakeAddPrepMutation = {
  readonly __typename?: "Mutation";

  readonly saveIntakePlan: Maybe<UpdateIntakeAddPrep>;
};

export type SaveIntakePlanSaveIntakePlan = {
  readonly __typename?: "Patient";

  readonly patientid: Maybe<number>;
};

export type SaveIntakePlanSaveIntakePlanPlanId = {
  readonly __typename?: "SaveIntakePlanOutput";

  readonly patient: Maybe<SaveIntakePlanSaveIntakePlan>;
};

export type UpdateIntakeAddPrep = {
  readonly __typename?: "IntakeAddPrep";

  readonly planaddprepid: Maybe<number>;
};

export type DeletePatientNoteVariables = {
  readonly patientNoteId: number;
  readonly patientId: number;
};

export type DeletePatientNoteMutation = {
  readonly __typename?: "Mutation";

  readonly deletePatientNote: Maybe<DeletePatientNoteDeletePatientNote>;
};

export type DeletePatientNoteDeletePatientNote = PatientFragmentFragment;

export type SavePatientNoteVariables = {
  readonly parentObj: PatientNoteInput;
  readonly action: ActionStateEnum;
  readonly userId: Maybe<number>;
};

export type SavePatientNoteMutation = {
  readonly __typename?: "Mutation";

  readonly savePatientNote: Maybe<SavePatientNoteSavePatientNote>;
};

export type SavePatientNoteSavePatientNote = {
  readonly __typename?: "PatientNote";

  readonly noteid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly note: Maybe<string>;

  readonly theme: Maybe<string>;

  readonly status: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly countryid: Maybe<number>;
};

export type PatientVariables = {
  readonly id: number;
};

export type PatientQuery = {
  readonly __typename?: "Query";

  readonly Patient: Maybe<PatientPatient>;
};

export type PatientPatient = PatientFragmentFragment;

export type PatientsVariables = {
  readonly patientno: Maybe<string>;
  readonly pharmacyid: number;
};

export type PatientsQuery = {
  readonly __typename?: "Query";

  readonly Patients: Maybe<ReadonlyArray<PatientsPatients>>;
};

export type PatientsPatients = {
  readonly __typename?: "Patient";

  readonly patientid: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly acceptance: Maybe<number>;

  readonly birthyear: Maybe<number>;

  readonly disease: Maybe<string>;

  readonly email: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly status: Maybe<number>;

  readonly intakePlans: Maybe<ReadonlyArray<IntakePlanDateFromTo>>;
};

export type PatientsDeletePatientsDelete = {
  readonly __typename?: "PatientForDeleteListOutput";

  readonly patientid: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly datefrom: Maybe<SqlDate>;

  readonly dateto: Maybe<SqlDate>;

};

export type PatientUniqueVariables = {
  readonly patientNo: string;
  readonly pharmacyId: number;
};

export type PatientUniqueQuery = {
  readonly __typename?: "Query";

  readonly PatientUnique: Maybe<ReadonlyArray<PatientUniquePatientUnique>>;
};

export type PatientUniquePatientUnique = {
  readonly __typename?: "Patient";

  readonly patientid: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly pharmacyid: Maybe<number>;
};

export type SavePatientVariables = {
  readonly parentObj: PatientInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
  readonly userId: Maybe<number>;
};

export type SavePatientMutation = {
  readonly __typename?: "Mutation";

  readonly savePatient: Maybe<SavePatientSavePatient>;
};

export type SavePatientSavePatient = PatientFragmentFragment;

export type MedicamentsIntakePlanVariables = {
  readonly planid: Maybe<number>;
};

export type MedicamentsIntakePlanQuery = {
  readonly __typename?: "Query";

  readonly MedicamentsIntakePlan: Maybe<
    ReadonlyArray<MedicamentsIntakePlanMedicamentsIntakePlan>
  >;
};

export type MedicamentsIntakePlanMedicamentsIntakePlan = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly manufactor: Maybe<string>;

  readonly status: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly translations: Maybe<
    ReadonlyArray<MedicamentsIntakePlanTranslations>
  >;
};

export type MedicamentsIntakePlanTranslations = {
  readonly __typename?: "MedLang";

  readonly medlanguageid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly shortname: Maybe<string>;
};

export type StrengthsMedicamentVariables = {
  readonly medicamentId: number;
};

export type StrengthsMedicamentQuery = {
  readonly __typename?: "Query";

  readonly StrengthsMedicament: Maybe<
    ReadonlyArray<StrengthsMedicamentStrengthsMedicament>
  >;
};

export type StrengthsMedicamentStrengthsMedicament = {
  readonly __typename?: "MedStrength";

  readonly medstrengthid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly perdays: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly individual: Maybe<boolean>;
};

export type StrengthsAddPreparationVariables = {
  readonly preparationId: number;
};

export type StrengthsAddPreparationQuery = {
  readonly __typename?: "Query";

  readonly StrengthsAddPreparation: Maybe<
    ReadonlyArray<StrengthsAddPreparationStrengthsAddPreparation>
  >;
};

export type StrengthsAddPreparationStrengthsAddPreparation = {
  readonly __typename?: "AddPrepStrength";

  readonly strengthid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly preparationid: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly strength: Maybe<string>;
};

export type PharmaciesVariables = {
  readonly name: Maybe<string>;
  readonly userId: Maybe<number>;
};

export type PharmaciesQuery = {
  readonly __typename?: "Query";

  readonly Pharmacies: Maybe<ReadonlyArray<PharmaciesPharmacies>>;
};

export type PharmaciesPharmacies = {
  readonly __typename?: "Pharmacy";

  readonly pharmacyid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly name: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly bgano: Maybe<string>;

  readonly city: Maybe<string>;

  readonly street: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly logo: Maybe<Base64String>;
};

export type PharmacyVariables = {
  readonly id: Maybe<number>;
};

export type PharmacyQuery = {
  readonly __typename?: "Query";

  readonly Pharmacy: Maybe<PharmacyPharmacy>;
};

export type PharmacyPharmacy = {
  readonly __typename?: "Pharmacy";

  readonly pharmacyid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly name: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly bgano: Maybe<string>;

  readonly city: Maybe<string>;

  readonly street: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly logo: Maybe<Base64String>;
};

export type SavePharmacyVariables = {
  readonly parentObj: PharmacyInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SavePharmacyMutation = {
  readonly __typename?: "Mutation";

  readonly savePharmacy: Maybe<SavePharmacySavePharmacy>;
};

export type SavePharmacySavePharmacy = {
  readonly __typename?: "Pharmacy";

  readonly pharmacyid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly name: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly bgano: Maybe<string>;

  readonly city: Maybe<string>;

  readonly street: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly logo: Maybe<Base64String>;
};

export type ConfirmRegistrationVariables = {
  readonly confirmUserId: number;
  readonly userId: number;
  readonly reason: Maybe<string>;
};

export type ConfirmRegistrationMutation = {
  readonly __typename?: "Mutation";

  readonly confirmRegistration: boolean;
};

export type ResetPasswordVariables = {
  readonly email: string;
};

export type ResetPasswordQuery = {
  readonly __typename?: "Query";

  readonly resetPassword: boolean;
};

export type RejectRegistrationVariables = {
  readonly confirmUserId: number;
  readonly userId: number;
  readonly reason: Maybe<string>;
};

export type RejectRegistrationMutation = {
  readonly __typename?: "Mutation";

  readonly rejectRegistration: boolean;
};

export type SaveSideEffectVariables = {
  readonly parentObj: SideEffectInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveSideEffectMutation = {
  readonly __typename?: "Mutation";

  readonly saveSideEffect: Maybe<SaveSideEffectSaveSideEffect>;
};

export type SaveSideEffectSaveSideEffect = SideEffectFragmentFragment;

export type SideEffectsByIdVariables = {
  readonly ids: Maybe<ReadonlyArray<number>>;
  readonly notIn: Maybe<boolean>;
};

export type SideEffectsByIdQuery = {
  readonly __typename?: "Query";

  readonly SideEffectsById: Maybe<
    ReadonlyArray<SideEffectsByIdSideEffectsById>
  >;
};

export type SideEffectsByIdSideEffectsById = {
  readonly __typename?: "SideEffect";

  readonly sideeffectid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<SideEffectsByIdTranslations>>;
};

export type SideEffectsByIdTranslations = {
  readonly __typename?: "SideEffectLang";

  readonly sideeffectlangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type SideEffectsVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly languageId: number;
};

export type SideEffectsQuery = {
  readonly __typename?: "Query";

  readonly SideEffects: Maybe<ReadonlyArray<SideEffectsSideEffects>>;
};

export type SideEffectsSideEffects = SideEffectFragmentFragment;

export type UniqueSideEffectVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
};

export type UniqueSideEffectQuery = {
  readonly __typename?: "Query";

  readonly UniqueSideEffect: Maybe<boolean>;
};

export type SaveSponsorVariables = {
  readonly parentObj: SponsorInput;
  readonly action: ActionStateEnum;
};

export type SaveSponsorMutation = {
  readonly __typename?: "Mutation";

  readonly saveSponsor: Maybe<SaveSponsorSaveSponsor>;
};

export type SaveSponsorSaveSponsor = SponsorFragmentFragment;

export type SponsorsVariables = {
  readonly label: Maybe<string>;
  readonly countryId: Maybe<number>;
};

export type SponsorsQuery = {
  readonly __typename?: "Query";

  readonly Sponsors: Maybe<ReadonlyArray<SponsorsSponsors>>;
};

export type SponsorsSponsors = SponsorFragmentFragment;

export type SaveSubstanceVariables = {
  readonly parentObj: SubstanceInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveSubstanceMutation = {
  readonly __typename?: "Mutation";

  readonly saveSubstance: Maybe<SaveSubstanceSaveSubstance>;
};

export type SaveSubstanceSaveSubstance = {
  readonly __typename?: "Substance";

  readonly substanceid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SaveSubstanceTranslations>>;
};

export type SaveSubstanceTranslations = {
  readonly __typename?: "SubstanceLang";

  readonly substancelangid: Maybe<number>;

  readonly substanceid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type SubstancesVariables = {
  readonly label: Maybe<string>;
  readonly status: Maybe<ReadonlyArray<number>>;
  readonly languageId: number;
};

export type SubstancesQuery = {
  readonly __typename?: "Query";

  readonly Substances: Maybe<ReadonlyArray<SubstancesSubstances>>;
};

export type SubstancesSubstances = {
  readonly __typename?: "Substance";

  readonly substanceid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly label: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<SubstancesTranslations>>;
};

export type SubstancesTranslations = {
  readonly __typename?: "SubstanceLang";

  readonly substancelangid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly substanceid: Maybe<number>;
};

export type UniqueSubstanceVariables = {
  readonly label: Maybe<string>;
  readonly objectId: number;
};

export type CheckToShowPopupVariables = {

  readonly userid: number;

  readonly textid: number;
};

export type ListOfEmployeesVariables = {

  readonly pharmacyid: number;
};

export type DeleteListOfEmployeesVariables = {

  readonly employeeIds: Maybe<number>;

  readonly pharmacyid: number;
};

export type ListOfPatientsVariables = {

  readonly pharmacyid: number;
};

export type DeleteListOfPatientsVariables = {

  readonly patientIds: Maybe<number>;

  readonly pharmacyid: number;
};

export type DeletePharmacyVariables = {

  readonly pharmacyid: number;
};

export type UniqueSubstanceQuery = {
  readonly __typename?: "Query";

  readonly UniqueSubstance: Maybe<boolean>;
};

export type CheckToShowPopupQuery = {
  readonly __typename?: "Query";

  readonly CheckToShowPopup: Maybe<boolean>;
};

export type ListOfEmployeesQuery = {
  readonly __typename?: "Query";

  readonly Employees: Maybe<ReadonlyArray<Employee>>;
};

export type DeleteListOfEmployeesQuery = {
  readonly __typename?: "Query";

  readonly Employees: Maybe<ReadonlyArray<Employee>>;
};

export type ListOfPatientsQuery = {
  readonly __typename?: "Query";

  readonly Patients: Maybe<ReadonlyArray<PatientsDeletePatientsDelete>>;
};

export type DeleteListOfPatientsQuery = {
  readonly __typename?: "Query";

  readonly Patients: Maybe<ReadonlyArray<PatientsPatients>>;
};

export type DeletePharmacyQuery = {
  readonly __typename?: "Query";
};

export type SaveTextVariables = {
  readonly parentObj: TextInput;
  readonly languageId: Maybe<number>;
  readonly countryId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SavePdfTextVariables = {
  readonly parentObj: PdfTextLangInput;
  readonly languageId: Maybe<number>;
  readonly countryId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveTextMutation = {
  readonly __typename?: "Mutation";

  readonly saveText: Maybe<SaveTextSaveText>;
};

export type SaveTextSaveText = TextFragmentFragment;

export type SavePdfTextMutation = {
  readonly __typename?: "Mutation";

  readonly savePdfText: Maybe<SavePdfTextSaveText>;
};

export type SavePdfTextSaveText = PdfTextLangFragmentFragment;

export type TextsVariables = {
  readonly label: Maybe<string>;
};

export type PdfTextsVariables = {
  readonly label: Maybe<string>;

  readonly languageId: Maybe<number>;

  readonly countryId: Maybe<number>;
};

export type TextsQuery = {
  readonly __typename?: "Query";

  readonly Texts: Maybe<ReadonlyArray<TextsTexts>>;
};

export type TextsTexts = TextFragmentFragment;

export type PdfTextsQuery = {
  readonly __typename?: "Query";

  readonly PdfTexts: Maybe<ReadonlyArray<PdfTextsTexts>>;
};

export type PdfTextsTexts = PdfTextLangFragmentFragment;

export type TextLoginVariables = {};

export type TextLoginQuery = {
  readonly __typename?: "Query";

  readonly TextLogin: Maybe<ReadonlyArray<TextLoginTextLogin>>;
};

export type TextLoginTextLogin = TextFragmentFragment;

export type UserLanguagesVariables = {};

export type UserTranslationLanguagesVariables = {
  readonly userId: Maybe<number>;
};

export type UserLanguagesQuery = {
  readonly __typename?: "Query";

  readonly UserLanguages: Maybe<ReadonlyArray<UserLanguagesUserLanguages>>;
};
export type ActiveUserLanguagesQuery = {
  readonly __typename?: "Query";

  readonly UserLanguages: Maybe<ReadonlyArray<UserLanguagesUserLanguages>>;
};

export type UserLanguagesUserLanguages = {
  readonly __typename?: "UserLanguage";

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<UserLanguagesTranslations>>;
};

export type UserLanguagesTranslations = {
  readonly __typename?: "UserLanguageLang";

  readonly languagelangid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly userlanguageid: Maybe<number>;

  readonly languageid: Maybe<number>;
};

export type DoLoginVariables = {
  readonly email: Maybe<string>;
  readonly password: Maybe<string>;
};

export type DoLoginQuery = {
  readonly __typename?: "Query";

  readonly doLogin: Maybe<DoLoginDoLogin>;
};

export type DoLoginDoLogin = UserBasicFragmentFragment &
  UserRolesFragmentFragment &
  UserLangFragmentFragment;

export type RegistrationsVariables = {
  readonly name: Maybe<string>;
  readonly countryId: Maybe<number>;
};

export type RegistrationsQuery = {
  readonly __typename?: "Query";

  readonly Registrations: Maybe<ReadonlyArray<RegistrationsRegistrations>>;
};

export type RegistrationsRegistrations = UserBasicFragmentFragment;

export type SaveRegistrationVariables = {
  readonly parentObj: UserInput;
  readonly pharmacy: Maybe<PharmacyInput>;
  readonly isPharmacy: number;
  readonly action: ActionStateEnum;
};

export type SaveRegistrationMutation = {
  readonly __typename?: "Mutation";

  readonly saveRegistration: Maybe<SaveRegistrationSaveRegistration>;
};

export type SaveRegistrationSaveRegistration = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;
};

export type SaveUserAccountVariables = {
  readonly parentObj: AccountInputInput;
  readonly languageId: Maybe<number>;
  readonly action: ActionStateEnum;
};

export type SaveUserAccountMutation = {
  readonly __typename?: "Mutation";

  readonly saveUserAccount: Maybe<SaveUserAccountSaveUserAccount>;
};

export type SaveUserAccountSaveUserAccount = UserBasicFragmentFragment &
  UserRolesFragmentFragment &
  UserLangFragmentFragment;

export type SaveUserVariables = {
  readonly parentObj: UserInput;
  readonly pharmacy: Maybe<PharmacyInput>;
  readonly isPharmacy: number;
  readonly performer: number;
  readonly action: ActionStateEnum;
};

export type SaveUserMutation = {
  readonly __typename?: "Mutation";

  readonly saveUser: Maybe<SaveUserSaveUser>;
};

export type SaveUserSaveUser = UserBasicFragmentFragment;

export type UniqueEmailVariables = {
  readonly email: string;
};

export type UniqueEmailQuery = {
  readonly __typename?: "Query";

  readonly UniqueEmail: Maybe<boolean>;
};

export type UserVariables = {
  readonly id: number;
};

export type UserQuery = {
  readonly __typename?: "Query";

  readonly User: Maybe<UserUser>;
};

export type UserUser = UserBasicFragmentFragment &
  UserRolesFragmentFragment &
  UserLangFragmentFragment &
  UserExtraFragmentFragment &
  UserDocsFragmentFragment;

export type UsersVariables = {
  readonly name: Maybe<string>;
  readonly userId: Maybe<number>;
  readonly zip: Maybe<string>;
  readonly city: Maybe<string>;
  readonly pharmacy: Maybe<string>;
  readonly countryId: Maybe<number>;
  readonly email: Maybe<string>;
};

export type UsersQuery = {
  readonly __typename?: "Query";

  readonly Users: Maybe<ReadonlyArray<UsersUsers>>;
};

export type UsersUsers = UserBasicFragmentFragment;

export type UserUniqueVariables = {
  readonly email: Maybe<string>;
  readonly userId: number;
};

export type UserUniqueQuery = {
  readonly __typename?: "Query";

  readonly UserUnique: Maybe<UserUniqueUserUnique>;
};

export type UserUniqueUserUnique = UserBasicFragmentFragment;

export type AddPreparationFragmentFragment = {
  readonly __typename?: "AddPreparation";

  readonly addpreparationid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly hints: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly label: Maybe<string>;

  readonly mischint: Maybe<string>;

  readonly status: Maybe<number>;

  readonly patientinfo: Maybe<string>;

  readonly lastchange: Maybe<SqlDate>;

  readonly addPrepStrengths: Maybe<
    ReadonlyArray<AddPreparationFragmentAddPrepStrengths>
  >;

  readonly translations: Maybe<
    ReadonlyArray<AddPreparationFragmentTranslations>
  >;
};

export type AddPreparationFragmentAddPrepStrengths = {
  readonly __typename?: "AddPrepStrength";

  readonly countryid: Maybe<number>;

  readonly intakes: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly preparationid: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly strengthid: Maybe<number>;
};

export type AddPreparationFragmentTranslations = {
  readonly __typename?: "AddPrepLang";

  readonly addpreplangid: Maybe<number>;

  readonly addpreparationid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly hints: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly mischint: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly patientinfo: Maybe<string>;
};

export type CountryFragmentFragment = {
  readonly __typename?: "Country";

  readonly countryid: Maybe<number>;

  readonly doccheck: Maybe<number>;

  readonly email: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly name: Maybe<string>;

  readonly organisation: Maybe<string>;

  readonly status: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly street: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly city: Maybe<string>;

  readonly logo: Maybe<Base64String>;
};

export type EmployeeFragmentFragment = {
  readonly __typename?: "Employee";

  readonly apothekemaid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly email: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly shortname: Maybe<string>;

  readonly status: Maybe<number>;

  readonly title: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly professionalgroup: Maybe<string>;
};


export type EmployeeOutputFragmentFragment = {
  readonly __typename?: "EmployeesOutput";

  readonly apothekemaid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly email: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly shortname: Maybe<string>;

  readonly status: Maybe<number>;

  readonly title: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly isPharmacy: Maybe<boolean>

  readonly professionalgroup: Maybe<string>
};

export type FileFragmentFragment = {
  readonly __typename?: "File";

  readonly fileid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly filename: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<FileFragmentTranslations>>;
};

export type FileFragmentTranslations = {
  readonly __typename?: "FileCountryLang";

  readonly filecountrylangid: Maybe<number>;

  readonly fileid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly filename: Maybe<string>;

  readonly filetype: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly file: Maybe<Base64String>;
};

export type MedStrengthFragmentFragment = {
  readonly __typename?: "MedStrength";

  readonly medstrengthid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly strength: Maybe<string>;

  readonly perdays: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly individual: Maybe<boolean>;
};

export type MedicamentFragmentFragment = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly background: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<MedicamentFragmentTranslations>>;
};

export type MedicamentFragmentTranslations = {
  readonly __typename?: "MedLang";

  readonly medlanguageid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly background: Maybe<string>;
};

export type PatientFragmentFragment = {
  readonly __typename?: "Patient";

  readonly patientid: Maybe<number>;

  readonly acceptance: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly email: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly birthyear: Maybe<number>;

  readonly status: Maybe<number>;

  readonly disease: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly notes: Maybe<ReadonlyArray<PatientFragmentNotes>>;

  readonly patientDoctors: Maybe<ReadonlyArray<PatientFragmentPatientDoctors>>;

  readonly intakePlans: Maybe<ReadonlyArray<PatientFragmentIntakePlans>>;

  readonly docs: Maybe<ReadonlyArray<PatientFragmentDocs>>;
};

export type PatientFragmentNotes = {
  readonly __typename?: "PatientNote";

  readonly noteid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly note: Maybe<string>;

  readonly theme: Maybe<string>;

  readonly status: Maybe<number>;
};

export type PatientFragmentPatientDoctors = {
  readonly __typename?: "PatientDoctor";

  readonly patientdoctorid: Maybe<number>;

  readonly prescribing: Maybe<number>;

  readonly patientDoctorDoctor: Maybe<PatientFragmentPatientDoctorDoctor>;
};

export type PatientFragmentPatientDoctorDoctor = {
  readonly __typename?: "Doctor";

  readonly doctorid: Maybe<number>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
};

export type PatientFragmentIntakePlans = {
  readonly __typename?: "IntakePlan";

  readonly planid: Maybe<number>;

  readonly datefrom: Maybe<SqlDate>;

  readonly dateto: Maybe<SqlDate>;

  readonly planperpatient: Maybe<number>;

  readonly status: Maybe<number>;

  readonly intakeAddPreps: Maybe<ReadonlyArray<PatientFragmentIntakeAddPreps>>;

  readonly intakeDocs: Maybe<ReadonlyArray<PatientFragmentIntakeDocs>>;

  readonly intakeMeds: Maybe<ReadonlyArray<PatientFragmentIntakeMeds>>;

  readonly intakePlanDates: Maybe<
    ReadonlyArray<PatientFragmentIntakePlanDates>
  >;
};

export type PatientFragmentIntakeAddPreps = {
  readonly __typename?: "IntakeAddPrep";

  readonly planaddprepid: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly intakes: number;

  readonly days: number;

  readonly pieces: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces06clock: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly pieces12clock: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly pieces15clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly pieces21clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly additionalPreparation: Maybe<PatientFragmentAdditionalPreparation>;
};

export type PatientFragmentAdditionalPreparation = {
  readonly __typename?: "AddPreparation";

  readonly addpreparationid: Maybe<number>;

  readonly hints: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly label: Maybe<string>;

  readonly mischint: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<PatientFragmentTranslations>>;

  readonly addPrepStrengths: Maybe<
    ReadonlyArray<PatientFragmentAddPrepStrengths>
  >;
};

export type PatientFragmentTranslations = {
  readonly __typename?: "AddPrepLang";

  readonly addpreparationid: Maybe<number>;

  readonly addpreplangid: Maybe<number>;

  readonly hints: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly mischint: Maybe<string>;
};

export type PatientFragmentAddPrepStrengths = {
  readonly __typename?: "AddPrepStrength";

  readonly strengthid: Maybe<number>;

  readonly intakes: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly strength: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly preparationid: Maybe<number>;
};

export type PatientFragmentIntakeDocs = {
  readonly __typename?: "IntakeDoc";

  readonly plandocid: Maybe<number>;

  readonly datetime: Maybe<SqlDate>;

  readonly countryid: Maybe<number>;
};

export type PatientFragmentIntakeMeds = {
  readonly __typename?: "IntakeMed";

  readonly intakemedid: Maybe<number>;

  readonly startstock: Maybe<number>;

  readonly endstock: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly intakes: Maybe<number>;

  readonly noofpackings: Maybe<number>;

  readonly packingsize: Maybe<number>;

  readonly days: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces06clock: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly pieces12clock: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly pieces15clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly pieces21clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly medicament: Maybe<PatientFragmentMedicament>;
};

export type PatientFragmentMedicament = {
  readonly __typename?: "Medicament";

  readonly medicamentid: Maybe<number>;

  readonly datemedicalinfo: Maybe<string>;

  readonly label: Maybe<string>;

  readonly manufactor: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly medStrengths: Maybe<ReadonlyArray<PatientFragmentMedStrengths>>;

  readonly medSideEffects: Maybe<ReadonlyArray<PatientFragmentMedSideEffects>>;
};

export type PatientFragmentMedStrengths = {
  readonly __typename?: "MedStrength";

  readonly medstrengthid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly perdays: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly morning: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly night: Maybe<number>;

  readonly individual: Maybe<boolean>;
};

export type PatientFragmentMedSideEffects = {
  readonly __typename?: "MedSideEffect";

  readonly type: number;

  readonly medeffectid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;
};

export type PatientFragmentIntakePlanDates = {
  readonly __typename?: "IntakePlanDate";

  readonly planid: Maybe<number>;

  readonly id: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly intakePlanDateTimes: Maybe<
    ReadonlyArray<PatientFragmentIntakePlanDateTimes>
  >;
};

export type PatientFragmentIntakePlanDateTimes = {
  readonly __typename?: "IntakePlanDateTime";

  readonly id: Maybe<number>;

  readonly time: Maybe<SqlTime>;

  readonly plandateid: Maybe<number>;

  readonly intakePlanPreparations: Maybe<
    ReadonlyArray<PatientFragmentIntakePlanPreparations>
  >;

  readonly intakePlanMedicaments: Maybe<
    ReadonlyArray<PatientFragmentIntakePlanMedicaments>
  >;
};

export type PatientFragmentIntakePlanPreparations = {
  readonly __typename?: "IntakePlanPreparation";

  readonly id: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly pieces: Maybe<number>;

  readonly plandatetimeid: Maybe<number>;

  readonly addPreparation: Maybe<PatientFragmentAddPreparation>;
};

export type PatientFragmentAddPreparation = AddPreparationFragmentFragment;

export type PatientFragmentIntakePlanMedicaments = {
  readonly __typename?: "IntakePlanMedicament";

  readonly id: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly pieces: Maybe<number>;

  readonly plandatetimeid: Maybe<number>;

  readonly medicament: Maybe<PatientFragment_Medicament>;
};

export type PatientFragment_Medicament = MedicamentFragmentFragment;

export type PatientFragmentDocs = {
  readonly __typename?: "PatientDoc";

  readonly docid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly protocol: Maybe<string>;

  readonly sideEffects: Maybe<ReadonlyArray<PatientFragmentSideEffects>>;
};

export type PatientFragmentSideEffects = {
  readonly __typename?: "PatientDocSide";

  readonly doceffectid: Maybe<number>;

  readonly doctorreference: Maybe<number>;

  readonly level: Maybe<number>;

  readonly measures: Maybe<string>;

  readonly note: Maybe<string>;

  readonly soldpreparates: Maybe<string>;

  readonly sideEffect: Maybe<PatientFragmentSideEffect>;
};

export type PatientFragmentSideEffect = SideEffectFragmentFragment;

export type SideEffectFragmentFragment = {
  readonly __typename?: "SideEffect";

  readonly label: Maybe<string>;

  readonly sideeffectid: Maybe<number>;

  readonly sideeffect: Maybe<string>;

  readonly symptoms: Maybe<string>;

  readonly level1: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly level3: Maybe<string>;

  readonly level4: Maybe<string>;

  readonly level5: Maybe<string>;

  readonly doctorfromlevel: Maybe<number>;

  readonly medicalterm: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly measures: Maybe<string>;

  readonly literature: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SideEffectFragmentTranslations>>;
};

export type SideEffectFragmentTranslations = {
  readonly __typename?: "SideEffectLang";

  readonly sideeffectlangid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;

  readonly sideeffect: Maybe<string>;

  readonly label: Maybe<string>;

  readonly symptoms: Maybe<string>;

  readonly level1: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly level3: Maybe<string>;

  readonly level4: Maybe<string>;

  readonly level5: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly measures: Maybe<string>;

  readonly languageid: Maybe<number>;
};

export type SponsorFragmentFragment = {
  readonly __typename?: "Sponsor";

  readonly sponsorid: Maybe<number>;

  readonly sponsor: Maybe<string>;

  readonly link: Maybe<string>;

  readonly text: Maybe<string>;

  readonly logo: Maybe<Base64String>;

  readonly datestart: Maybe<SqlDate>;

  readonly dateend: Maybe<SqlDate>;

  readonly countryid: Maybe<number>;
};

export type TextFragmentFragment = {
  readonly __typename?: "Text";

  readonly textid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly text: Maybe<string>;

  readonly subject: Maybe<string>;

  readonly showpopuponetime: Maybe<boolean>;

  readonly translations: Maybe<ReadonlyArray<TextFragmentTranslations>>;
};

export type TextFragmentTranslations = {
  readonly __typename?: "TextCountryLang";

  readonly textcountrylangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly text: Maybe<string>;

  readonly subject: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly languageid: Maybe<number>;

  readonly textid: Maybe<number>;
};

export type PdfTextFragmentFragment = {
  readonly __typename?: "PdfText";

  readonly pdftextid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly text: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<PdfTextFragmentTranslations>>;
};


export type PdfTextFragmentTranslations = {
  readonly __typename?: "PdfTextLang";

  readonly pdftextlangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly labelText: Maybe<string>;

  readonly text: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly countryid: Maybe<number>;

};

export type PdfTextLangFragmentFragment = {
  readonly __typename?: "PdfTextLang";

  readonly pdftextlangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly text: Maybe<string>;

  readonly labelText: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<PdfTextFragmentTranslations>>;
}

export type UserBasicFragmentFragment = {
  readonly __typename?: "User";

  readonly userid: Maybe<number>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly email: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly title: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly status: Maybe<number>;

  readonly password: Maybe<string>;
};

export type UserDocsFragmentFragment = {
  readonly __typename?: "User";

  readonly userDocs: Maybe<ReadonlyArray<UserDocsFragmentUserDocs>>;
};

export type UserDocsFragmentUserDocs = {
  readonly __typename?: "UserDoc";

  readonly userdocid: Maybe<number>;

  readonly document: Maybe<Base64String>;

  readonly content: Maybe<string>;

  readonly label: Maybe<string>;

  readonly filetype: Maybe<string>;

  readonly size: number;
};

export type UserExtraFragmentFragment = {
  readonly __typename?: "User";

  readonly city: Maybe<string>;

  readonly doccheck: Maybe<number>;

  readonly enddate: Maybe<Date>;

  readonly institutiontype: Maybe<number>;

  readonly name: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly startdate: Maybe<Date>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;

  readonly lastlogin: Maybe<Date>;

  readonly created: Maybe<Date>;

  readonly freeofcharge: Maybe<number>;

  readonly endoffreeuse: Maybe<Date>;

  readonly professionalgroup: Maybe<string>;
};

export type UserLangFragmentFragment = {
  readonly __typename?: "User";

  readonly pharmacyid: Maybe<number>;

  readonly userLanguage: Maybe<UserLangFragmentUserLanguage>;

  readonly country: Maybe<UserLangFragmentCountry>;

  readonly translationLang: Maybe<
    ReadonlyArray<UserLangFragmentTranslationLang>
  >;
};

export type UserLangFragmentUserLanguage = {
  readonly __typename?: "UserLanguage";

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly shortname: Maybe<string>;
};

export type UserLangFragmentCountry = {
  readonly __typename?: "Country";

  readonly countryid: Maybe<number>;

  readonly name: Maybe<string>;

  readonly doccheck: Maybe<number>;

  readonly organisation: Maybe<string>;

  readonly status: Maybe<number>;

  readonly languageid: Maybe<number>;
};

export type UserLangFragmentTranslationLang = {
  readonly __typename?: "PatientLanguage";

  readonly languageid: Maybe<number>;

  readonly language: Maybe<string>;

  readonly status: Maybe<number>;
};

export type UserRolesFragmentFragment = {
  readonly __typename?: "User";

  readonly ismainadmin: boolean;

  readonly ismaineditor: boolean;

  readonly ispharmacy: boolean;

  readonly isemployee: boolean;

  readonly iscountryadmin: boolean;

  readonly iscountryeditor: boolean;

  readonly issimpleuser: boolean;
};

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

/** Query root */
export interface Query {
  readonly resetPassword: boolean;

  readonly MedNote: Maybe<MedNote>;

  readonly Countries: Maybe<ReadonlyArray<Country>>;

  readonly Texts: Maybe<ReadonlyArray<Text>>;

  readonly Users: Maybe<ReadonlyArray<User>>;

  readonly History: Maybe<ReadonlyArray<History>>;

  readonly TextLogin: Maybe<ReadonlyArray<Text>>;

  readonly Substances: Maybe<ReadonlyArray<Substance>>;

  readonly PatientLanguage: Maybe<PatientLanguage>;

  readonly UniqueCountry: Maybe<boolean>;

  readonly doLogin: Maybe<User>;

  readonly Registrations: Maybe<ReadonlyArray<User>>;

  readonly StrengthsMedicament: Maybe<ReadonlyArray<MedStrength>>;

  readonly UniqueInteraction: Maybe<boolean>;

  readonly OtherNotesById: Maybe<ReadonlyArray<OtherNote>>;

  readonly InteractionsById: Maybe<ReadonlyArray<Interaction>>;

  readonly UniqueSubstance: Maybe<boolean>;

  readonly CheckToShowPopup: Maybe<boolean>;

  readonly SideEffectsById: Maybe<ReadonlyArray<SideEffect>>;

  readonly Patients: Maybe<ReadonlyArray<Patient>>;

  readonly UniqueEmail: Maybe<boolean>;

  readonly Doctors: Maybe<ReadonlyArray<Doctor>>;

  readonly Pharmacies: Maybe<ReadonlyArray<Pharmacy>>;

  readonly UsersCountryAllowed: Maybe<ReadonlyArray<User>>;

  readonly OtherNotes: Maybe<ReadonlyArray<OtherNote>>;

  readonly AddPreparation: Maybe<AddPreparation>;

  readonly Employee: Maybe<Employee>;

  readonly StrengthsAddPreparation: Maybe<ReadonlyArray<AddPrepStrength>>;

  readonly MedStrengths: Maybe<ReadonlyArray<MedStrength>>;

  readonly Pharmacy: Maybe<Pharmacy>;

  readonly Country: Maybe<Country>;

  readonly UserLanguage: Maybe<ReadonlyArray<User>>;

  readonly File: Maybe<File>;

  readonly PatientLanguages: Maybe<ReadonlyArray<PatientLanguage>>;

  readonly UniqueSideEffect: Maybe<boolean>;

  readonly Medicaments: Maybe<ReadonlyArray<Medicament>>;

  readonly User: Maybe<User>;

  readonly MedNotes: Maybe<ReadonlyArray<MedNote>>;

  readonly Employees: Maybe<ReadonlyArray<Employee>>;

  readonly SideEffects: Maybe<ReadonlyArray<SideEffect>>;

  readonly Medicament: Maybe<Medicament>;

  readonly Sponsors: Maybe<ReadonlyArray<Sponsor>>;

  readonly UserUnique: Maybe<User>;

  readonly UniqueIntakeNote: Maybe<boolean>;

  readonly Substance: Maybe<Substance>;

  readonly Histories: Maybe<ReadonlyArray<History>>;

  readonly PatientDoctorsFree: Maybe<ReadonlyArray<Doctor>>;

  readonly Files: Maybe<ReadonlyArray<File>>;

  readonly UserLanguages: Maybe<ReadonlyArray<UserLanguage>>;

  readonly intakePlan: Maybe<IntakePlan>;

  readonly AddPreparations: Maybe<ReadonlyArray<AddPreparation>>;

  readonly UniqueAddPreparation: Maybe<boolean>;

  readonly Patient: Maybe<Patient>;

  readonly Text: Maybe<Text>;

  readonly PdfText: Maybe<PdfTextLang>;

  readonly Doctor: Maybe<Doctor>;

  readonly UniqueOtherNote: Maybe<boolean>;

  readonly PatientUnique: Maybe<ReadonlyArray<Patient>>;

  readonly IntakeNotes: Maybe<ReadonlyArray<IntakeNote>>;

  readonly Interactions: Maybe<ReadonlyArray<Interaction>>;

  readonly MedicamentsIntakePlan: Maybe<ReadonlyArray<Medicament>>;

  readonly entity: Maybe<ObjectScalar>;

  readonly IntakeNotesById: Maybe<ReadonlyArray<IntakeNote>>;
}

export interface MedNote {
  readonly countryid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly medicamentid: Maybe<number>;

  readonly mednoteid: Maybe<number>;

  readonly note: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly theme: Maybe<string>;
}

export interface Country {
  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly doccheck: Maybe<number>;

  readonly documents: Maybe<string>;

  readonly email: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly logo: Maybe<Base64String>;

  readonly name: Maybe<string>;

  readonly organisation: Maybe<string>;

  readonly phone: Maybe<string>;

  readonly status: Maybe<number>;

  readonly street: Maybe<string>;

  readonly zip: Maybe<string>;
}

export interface Text {
  readonly label: Maybe<string>;

  readonly subject: Maybe<string>;

  readonly text: Maybe<string>;

  readonly textid: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<TextCountryLang>>;
}

export interface PdfTextLang {

  readonly label: Maybe<string>;

  readonly text: Maybe<string>;

  readonly textid: Maybe<number>;

  readonly labelText: Maybe<string>;
}

export interface TextCountryLang {
  readonly countryid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly subject: Maybe<string>;

  readonly text: Maybe<string>;

  readonly textcountrylangid: Maybe<number>;

  readonly textid: Maybe<number>;
}

export interface User {
  readonly city: Maybe<string>;

  readonly country: Maybe<Country>;

  readonly created: Maybe<Date>;

  readonly doccheck: Maybe<number>;

  readonly email: Maybe<string>;

  readonly enddate: Maybe<Date>;

  readonly firstname: Maybe<string>;

  readonly institutiontype: Maybe<number>;

  readonly iscountryadmin: boolean;

  readonly iscountryeditor: boolean;

  readonly isemployee: boolean;

  readonly ismainadmin: boolean;

  readonly ismaineditor: boolean;

  readonly ispharmacy: boolean;

  readonly issimpleuser: boolean;

  readonly lastlogin: Maybe<Date>;

  readonly lastname: Maybe<string>;

  readonly name: Maybe<string>;

  readonly password: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly startdate: Maybe<Date>;

  readonly status: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly translationLang: Maybe<ReadonlyArray<PatientLanguage>>;

  readonly userDocs: Maybe<ReadonlyArray<UserDoc>>;

  readonly userLanguage: Maybe<UserLanguage>;

  readonly userid: Maybe<number>;

  readonly zip: Maybe<string>;
}

export interface PatientLanguage {
  readonly flag: Maybe<Base64String>;

  readonly language: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly status: Maybe<number>;
}

export interface UserDoc {
  readonly content: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly document: Maybe<Base64String>;

  readonly filetype: Maybe<string>;

  readonly label: Maybe<string>;

  readonly size: number;

  readonly userdocid: Maybe<number>;

  readonly userid: Maybe<number>;
}

export interface UserLanguage {
  readonly language: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly shortname: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<UserLanguageLang>>;
}

export interface UserLanguageLang {
  readonly language: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly languagelangid: Maybe<number>;

  readonly userlanguageid: Maybe<number>;
}

export interface History {
  readonly datetime: Maybe<Date>;

  readonly historyid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly label2: Maybe<string>;

  readonly objectid: Maybe<number>;

  readonly operation: Maybe<number>;

  readonly tableid: Maybe<number>;

  readonly user: Maybe<User>;
}

export interface Substance {
  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly substanceid: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<SubstanceLang>>;
}

export interface SubstanceLang {
  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly substanceid: Maybe<number>;

  readonly substancelangid: Maybe<number>;
}

export interface MedStrength {
  readonly countryid: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly medicamentid: Maybe<number>;

  readonly medstrengthid: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly night: Maybe<number>;

  readonly note: Maybe<string>;

  readonly packingsizes: Maybe<string>;

  readonly perdays: Maybe<number>;

  readonly strength: Maybe<string>;
}

export interface OtherNote {
  readonly countryid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly othernote: Maybe<string>;

  readonly othernoteid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<OtherNoteLang>>;
}

export interface OtherNoteLang {
  readonly countryid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly othernoteid: Maybe<number>;

  readonly othernotelangid: Maybe<number>;
}

export interface Interaction {
  readonly interaction: Maybe<string>;

  readonly interactionid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<InteractionLang>>;
}

export interface InteractionLang {
  readonly interaction: Maybe<string>;

  readonly interactionid: Maybe<number>;

  readonly interactionlangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
}

export interface SideEffect {
  readonly doctorfromlevel: Maybe<number>;

  readonly label: Maybe<string>;

  readonly level1: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly level3: Maybe<string>;

  readonly level4: Maybe<string>;

  readonly level5: Maybe<string>;

  readonly literature: Maybe<string>;

  readonly measures: Maybe<string>;

  readonly medicalterm: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly sideeffect: Maybe<string>;

  readonly sideeffectid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly symptoms: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<SideEffectLang>>;
}

export interface SideEffectLang {
  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly level1: Maybe<string>;

  readonly level2: Maybe<string>;

  readonly level3: Maybe<string>;

  readonly level4: Maybe<string>;

  readonly level5: Maybe<string>;

  readonly measures: Maybe<string>;

  readonly riskfactors: Maybe<string>;

  readonly sideeffect: Maybe<string>;

  readonly sideeffectid: Maybe<number>;

  readonly sideeffectlangid: Maybe<number>;

  readonly symptoms: Maybe<string>;
}

export interface Patient {
  readonly acceptance: Maybe<number>;

  readonly birthyear: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly disease: Maybe<string>;

  readonly docs: Maybe<ReadonlyArray<PatientDoc>>;

  readonly doctorid: Maybe<number>;

  readonly email: Maybe<string>;

  readonly intakePlans: Maybe<ReadonlyArray<IntakePlan>>;

  readonly languageid: Maybe<number>;

  readonly notes: Maybe<ReadonlyArray<PatientNote>>;

  readonly patientDoctors: Maybe<ReadonlyArray<PatientDoctor>>;

  readonly patientid: Maybe<number>;

  readonly patientno: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly status: Maybe<number>;
}

export interface PatientDoc {
  readonly countryid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly docid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly protocol: Maybe<string>;

  readonly sideEffects: Maybe<ReadonlyArray<PatientDocSide>>;
}

export interface PatientDocSide {
  readonly countryid: Maybe<number>;

  readonly doceffectid: Maybe<number>;

  readonly docid: Maybe<number>;

  readonly doctorreference: Maybe<number>;

  readonly level: Maybe<number>;

  readonly measures: Maybe<string>;

  readonly note: Maybe<string>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly sideEffect: Maybe<SideEffect>;

  readonly soldpreparates: Maybe<string>;
}

export interface IntakePlan {
  readonly countryid: Maybe<number>;

  readonly datefrom: Maybe<SqlDate>;

  readonly dateto: Maybe<SqlDate>;

  readonly firstplanid: Maybe<number>;

  readonly intakeAddPreps: Maybe<ReadonlyArray<IntakeAddPrep>>;

  readonly intakeDocs: Maybe<ReadonlyArray<IntakeDoc>>;

  readonly intakeMeds: Maybe<ReadonlyArray<IntakeMed>>;

  readonly intakePlanDates: Maybe<ReadonlyArray<IntakePlanDate>>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly planperpatient: Maybe<number>;

  readonly status: Maybe<number>;
}

export interface IntakePlanDateFromTo {
  readonly datefrom: Maybe<SqlDate>;

  readonly dateto: Maybe<SqlDate>;
}

export interface IntakeAddPrep {
  readonly additionalPreparation: Maybe<AddPreparation>;

  readonly countryid: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly intakes: number;

  readonly midday: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly night: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly days: number;

  readonly pharmacyid: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces06clock: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly pieces12clock: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly pieces15clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly pieces21clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly planaddprepid: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly strength: Maybe<string>;
}

export interface AddPreparation {
  readonly addPrepSides: Maybe<ReadonlyArray<AddPrepSide>>;

  readonly addPrepStrengths: Maybe<ReadonlyArray<AddPrepStrength>>;

  readonly addpreparationid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly hints: Maybe<string>;

  readonly history: Maybe<ReadonlyArray<History>>;

  readonly informations: Maybe<string>;

  readonly label: Maybe<string>;

  readonly lastchange: Maybe<SqlDate>;

  readonly mischint: Maybe<string>;

  readonly patientinfo: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<AddPrepLang>>;
}

export interface AddPrepSide {
  readonly addpreparationid: Maybe<number>;

  readonly addprepsidegid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;
}

export interface AddPrepStrength {
  readonly countryid: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly individual: Maybe<boolean>;

  readonly intakes: Maybe<number>;

  readonly midday: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly night: Maybe<number>;

  readonly perdays: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly preparationid: Maybe<number>;

  readonly strength: Maybe<string>;

  readonly strengthid: Maybe<number>;
}

export interface AddPrepLang {
  readonly addpreparationid: Maybe<number>;

  readonly addpreplangid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly hints: Maybe<string>;

  readonly informations: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly mischint: Maybe<string>;

  readonly patientinfo: Maybe<string>;
}

export interface IntakeDoc {
  readonly countryid: Maybe<number>;

  readonly datetime: Maybe<SqlDate>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly plandocid: Maybe<number>;

  readonly planid: Maybe<number>;
}

export interface IntakeMed {
  readonly countryid: Maybe<number>;

  readonly endstock: Maybe<number>;

  readonly evening: Maybe<number>;

  readonly instruction: Maybe<string>;

  readonly intakemedid: Maybe<number>;

  readonly intakes: Maybe<number>;

  readonly medicament: Maybe<Medicament>;

  readonly midday: Maybe<number>;

  readonly morning: Maybe<number>;

  readonly night: Maybe<number>;

  readonly noofpackings: Maybe<number>;

  readonly packingsize: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly days: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly pieces00clock: Maybe<number>;

  readonly pieces01clock: Maybe<number>;

  readonly pieces02clock: Maybe<number>;

  readonly pieces03clock: Maybe<number>;

  readonly pieces04clock: Maybe<number>;

  readonly pieces05clock: Maybe<number>;

  readonly pieces06clock: Maybe<number>;

  readonly pieces07clock: Maybe<number>;

  readonly pieces08clock: Maybe<number>;

  readonly pieces09clock: Maybe<number>;

  readonly pieces10clock: Maybe<number>;

  readonly pieces11clock: Maybe<number>;

  readonly pieces12clock: Maybe<number>;

  readonly pieces13clock: Maybe<number>;

  readonly pieces14clock: Maybe<number>;

  readonly pieces15clock: Maybe<number>;

  readonly pieces16clock: Maybe<number>;

  readonly pieces17clock: Maybe<number>;

  readonly pieces18clock: Maybe<number>;

  readonly pieces19clock: Maybe<number>;

  readonly pieces20clock: Maybe<number>;

  readonly pieces21clock: Maybe<number>;

  readonly pieces22clock: Maybe<number>;

  readonly pieces23clock: Maybe<number>;

  readonly planid: Maybe<number>;

  readonly shortnotice: Maybe<string>;

  readonly startstock: Maybe<number>;

  readonly strength: Maybe<string>;
}

export interface Medicament {
  readonly background: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly datemedicalinfo: Maybe<string>;

  readonly editors: Maybe<string>;

  readonly history: Maybe<ReadonlyArray<History>>;

  readonly intakelong: Maybe<string>;

  readonly intakeshort: Maybe<string>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly link1: Maybe<string>;

  readonly link1text: Maybe<string>;

  readonly link2: Maybe<string>;

  readonly link2text: Maybe<string>;

  readonly link3: Maybe<string>;

  readonly link3text: Maybe<string>;

  readonly manufactor: Maybe<string>;

  readonly medIntakeNotes: Maybe<ReadonlyArray<MedIntakeNote>>;

  readonly medInteractions: Maybe<ReadonlyArray<MedInteraction>>;

  readonly medOtherNotes: Maybe<ReadonlyArray<MedOtherNote>>;

  readonly medSideEffects: Maybe<ReadonlyArray<MedSideEffect>>;

  readonly medStrengths: Maybe<ReadonlyArray<MedStrength>>;

  readonly medicamentid: Maybe<number>;

  readonly mischint: Maybe<string>;

  readonly notes: Maybe<ReadonlyArray<MedNote>>;

  readonly shortname: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly sideeffects: Maybe<string>;

  readonly status: Maybe<number>;

  readonly substance: Maybe<Substance>;

  readonly translations: Maybe<ReadonlyArray<MedLang>>;
}

export interface MedIntakeNote {
  readonly countryid: Maybe<number>;

  readonly intakenoteid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly mednoteid: Maybe<number>;
}

export interface MedInteraction {
  readonly countryid: Maybe<number>;

  readonly interactionid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly medinteractionid: Maybe<number>;
}

export interface MedOtherNote {
  readonly countryid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly medothernoteid: Maybe<number>;

  readonly othernoteid: Maybe<number>;
}

export interface MedSideEffect {
  readonly countryid: Maybe<number>;

  readonly medeffectid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly sideeffectid: Maybe<number>;

  readonly type: number;
}

export interface MedLang {
  readonly background: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly intakelong: Maybe<string>;

  readonly intakeshort: Maybe<string>;

  readonly interaction: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;

  readonly medicamentid: Maybe<number>;

  readonly medlanguageid: Maybe<number>;

  readonly othernote: Maybe<string>;

  readonly shortname: Maybe<string>;

  readonly shortnotice: Maybe<string>;

  readonly sideeffects: Maybe<string>;
}

export interface IntakePlanDate {
  readonly date: Maybe<SqlDate>;

  readonly id: Maybe<number>;

  readonly intakePlanDateTimes: Maybe<ReadonlyArray<IntakePlanDateTime>>;

  readonly planid: Maybe<number>;
}

export interface IntakePlanDateTime {
  readonly id: Maybe<number>;

  readonly intakePlanMedicaments: Maybe<ReadonlyArray<IntakePlanMedicament>>;

  readonly intakePlanPreparations: Maybe<ReadonlyArray<IntakePlanPreparation>>;

  readonly plandateid: Maybe<number>;

  readonly time: Maybe<SqlTime>;
}

export interface IntakePlanMedicament {
  readonly id: Maybe<number>;

  readonly medicament: Maybe<Medicament>;

  readonly pieces: Maybe<number>;

  readonly plandatetimeid: Maybe<number>;

  readonly strength: Maybe<string>;
}

export interface IntakePlanPreparation {
  readonly addPreparation: Maybe<AddPreparation>;

  readonly id: Maybe<number>;

  readonly pieces: Maybe<number>;

  readonly plandatetimeid: Maybe<number>;

  readonly strength: Maybe<string>;
}

export interface PatientNote {
  readonly countryid: Maybe<number>;

  readonly date: Maybe<SqlDate>;

  readonly note: Maybe<string>;

  readonly noteid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly status: Maybe<number>;

  readonly theme: Maybe<string>;
}

export interface PatientDoctor {
  readonly countryid: Maybe<number>;

  readonly patientDoctorDoctor: Maybe<Doctor>;

  readonly patientdoctorid: Maybe<number>;

  readonly patientid: Maybe<number>;

  readonly pharmacyid: Maybe<number>;

  readonly prescribing: Maybe<number>;
}

export interface Doctor {
  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly doctorid: Maybe<number>;

  readonly fax: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly mail: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly sex: Maybe<number>;

  readonly street: Maybe<string>;

  readonly title: Maybe<string>;

  readonly zip: Maybe<string>;
}

export interface Pharmacy {
  readonly bgano: Maybe<string>;

  readonly city: Maybe<string>;

  readonly countryid: Maybe<number>;

  readonly logo: Maybe<Base64String>;

  readonly mail: Maybe<string>;

  readonly name: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly street: Maybe<string>;

  readonly userid: Maybe<number>;

  readonly zip: Maybe<string>;
}

export interface Employee {
  readonly apothekemaid: Maybe<number>;

  readonly countryid: Maybe<number>;

  readonly email: Maybe<string>;

  readonly firstname: Maybe<string>;

  readonly lastname: Maybe<string>;

  readonly pharmacyid: Maybe<number>;

  readonly phone: Maybe<string>;

  readonly salutation: Maybe<number>;

  readonly shortname: Maybe<string>;

  readonly status: Maybe<number>;

  readonly title: Maybe<string>;

  readonly userid: Maybe<number>;
}

export interface File {
  readonly fileid: Maybe<number>;

  readonly filename: Maybe<string>;

  readonly label: Maybe<string>;

  readonly translations: Maybe<ReadonlyArray<FileCountryLang>>;
}

export interface FileCountryLang {
  readonly countryid: Maybe<number>;

  readonly file: Maybe<Base64String>;

  readonly filecountrylangid: Maybe<number>;

  readonly fileid: Maybe<number>;

  readonly filename: Maybe<string>;

  readonly filetype: Maybe<string>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
}

export interface Sponsor {
  readonly countryid: Maybe<number>;

  readonly dateend: Maybe<SqlDate>;

  readonly datestart: Maybe<SqlDate>;

  readonly link: Maybe<string>;

  readonly logo: Maybe<Base64String>;

  readonly sponsor: Maybe<string>;

  readonly sponsorid: Maybe<number>;

  readonly text: Maybe<string>;
}

export interface IntakeNote {
  readonly intakenote: Maybe<string>;

  readonly intakenoteid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly status: Maybe<number>;

  readonly translations: Maybe<ReadonlyArray<IntakeNoteLang>>;
}

export interface IntakeNoteLang {
  readonly intakenote: Maybe<string>;

  readonly intakenoteid: Maybe<number>;

  readonly intakenotelangid: Maybe<number>;

  readonly label: Maybe<string>;

  readonly languageid: Maybe<number>;
}

/** Mutation root */
export interface Mutation {
  readonly saveMedStrength: Maybe<MedStrength>;

  readonly confirmRegistration: boolean;

  readonly deletePatientNote: Maybe<Patient>;

  readonly saveEmployee: Maybe<Employee>;

  readonly deleteDocSideEffect: Maybe<Patient>;

  readonly updateMedicamentLeaflet: Maybe<Medicament>;

  readonly deleteIntakeMed: Maybe<Patient>;

  readonly savePatientNote: Maybe<PatientNote>;

  readonly saveAddPreparation: Maybe<AddPreparation>;

  readonly deleteMedStrength: Maybe<Medicament>;

  readonly changePatientDoctor: Maybe<Patient>;

  readonly saveMedNote: Maybe<MedNote>;

  readonly saveIntakeNote: Maybe<IntakeNote>;

  readonly saveCountry: Maybe<Country>;

  readonly saveSponsor: Maybe<Sponsor>;

  readonly saveMedCheckBox: Maybe<Medicament>;

  readonly saveDoctor: Maybe<Doctor>;

  readonly rejectRegistration: boolean;

  readonly saveIntakePlan: Maybe<Patient>;

  readonly saveUser: Maybe<User>;

  readonly deleteMedNote: Maybe<Medicament>;

  readonly savePharmacy: Maybe<Pharmacy>;

  readonly emailDoctor: boolean;

  readonly saveAddSideCheckBox: Maybe<AddPreparation>;

  readonly savePatientDocumentation: Maybe<Patient>;

  readonly savePatientDoctor: Maybe<PatientDoctor>;

  readonly saveOtherNote: Maybe<OtherNote>;

  readonly saveFile: Maybe<File>;

  readonly saveSubstance: Maybe<Substance>;

  readonly saveMedBackground: Maybe<Medicament>;

  readonly saveMedicament: Maybe<Medicament>;

  readonly deleteIntakeAddPrep: Maybe<Patient>;

  readonly deletePatientDoctor: Maybe<Patient>;

  readonly deleteIntakePlan: Maybe<Patient>;

  readonly saveUserAccount: Maybe<User>;

  readonly deleteDocumentation: Maybe<Patient>;

  readonly saveRegistration: Maybe<User>;

  readonly savePatient: Maybe<Patient>;

  readonly saveAddStrength: Maybe<AddPrepStrength>;

  readonly saveInteraction: Maybe<Interaction>;

  readonly IntakePlan: Maybe<IntakePlan>;

  readonly saveText: Maybe<Text>;

  readonly savePdfText: Maybe<PdfTextLang>;

  readonly savePatientlanguage: Maybe<PatientLanguage>;

  readonly saveSideEffect: Maybe<SideEffect>;
}

// ====================================================
// Arguments
// ====================================================

export interface ResetPasswordQueryArgs {
  email: Maybe<string>;
}
export interface MedNoteQueryArgs {
  id: Maybe<number>;
}
export interface CountriesQueryArgs {
  name: Maybe<string>;
}
export interface TextsQueryArgs {
  label: Maybe<string>;
}
export interface PdfTextsQueryArgs {
  label: Maybe<number>;
}
export interface UsersQueryArgs {
  zip: Maybe<string>;

  city: Maybe<string>;

  pharmacy: Maybe<string>;

  name: Maybe<string>;

  userId: Maybe<number>;

  email: Maybe<string>;

  countryId: Maybe<number>;
}
export interface HistoryQueryArgs {
  tableId: Maybe<number>;

  objectId: Maybe<number>;
}
export interface SubstancesQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface PatientLanguageQueryArgs {
  id: Maybe<number>;
}
export interface UniqueCountryQueryArgs {
  name: Maybe<string>;

  objectId: Maybe<number>;
}
export interface DoLoginQueryArgs {
  password: Maybe<string>;

  email: Maybe<string>;
}
export interface RegistrationsQueryArgs {
  name: Maybe<string>;

  countryId: Maybe<number>;
}
export interface StrengthsMedicamentQueryArgs {
  medicamentId: Maybe<number>;
}
export interface UniqueInteractionQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  objectId: Maybe<number>;
}
export interface OtherNotesByIdQueryArgs {
  ids: Maybe<ReadonlyArray<number>>;
}
export interface InteractionsByIdQueryArgs {
  ids: Maybe<ReadonlyArray<number>>;
}
export interface UniqueSubstanceQueryArgs {
  label: Maybe<string>;

  objectId: Maybe<number>;
}
export interface SideEffectsByIdQueryArgs {
  ids: Maybe<ReadonlyArray<number>>;

  notIn: Maybe<boolean>;
}
export interface PatientsQueryArgs {
  pharmacyid: Maybe<number>;

  patientno: Maybe<string>;
}
export interface UniqueEmailQueryArgs {
  email: Maybe<string>;
}
export interface DoctorsQueryArgs {
  pharmacyId: Maybe<number>;

  name: Maybe<string>;
}
export interface PharmaciesQueryArgs {
  name: Maybe<string>;

  userId: Maybe<number>;
}
export interface OtherNotesQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface AddPreparationQueryArgs {
  id: Maybe<number>;
}
export interface EmployeeQueryArgs {
  id: Maybe<number>;
}
export interface StrengthsAddPreparationQueryArgs {
  preparationId: Maybe<number>;
}
export interface PharmacyQueryArgs {
  id: Maybe<number>;
}
export interface CountryQueryArgs {
  id: Maybe<number>;
}
export interface UserLanguageQueryArgs {
  languageId: Maybe<number>;
}
export interface FileQueryArgs {
  id: Maybe<number>;
}
export interface PatientLanguagesQueryArgs {
  language: Maybe<string>;
}
export interface UniqueSideEffectQueryArgs {
  label: Maybe<string>;

  objectId: Maybe<number>;
}
export interface MedicamentsQueryArgs {
  filterBy: Maybe<MapStringObjectScalar>;
}
export interface UserQueryArgs {
  id: Maybe<number>;
}
export interface EmployeesQueryArgs {
  name: Maybe<string>;

  userId: Maybe<number>;
}
export interface SideEffectsQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface MedicamentQueryArgs {
  pharmacyId: Maybe<number>;

  id: Maybe<number>;
}
export interface SponsorsQueryArgs {
  label: Maybe<string>;

  countryId: number;
}
export interface UserUniqueQueryArgs {
  userId: Maybe<number>;

  email: Maybe<string>;
}
export interface UniqueIntakeNoteQueryArgs {
  label: Maybe<string>;

  objectId: Maybe<number>;
}
export interface SubstanceQueryArgs {
  id: Maybe<number>;
}
export interface HistoriesQueryArgs {
  tableId: Maybe<number>;

  label: Maybe<string>;
}
export interface PatientDoctorsFreeQueryArgs {
  pharmacyId: Maybe<number>;

  patientId: Maybe<number>;
}
export interface FilesQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;
}
export interface IntakePlanQueryArgs {
  planid: Maybe<number>;
}
export interface AddPreparationsQueryArgs {
  label: Maybe<string>;

  countryId: Maybe<number>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface UniqueAddPreparationQueryArgs {
  label: Maybe<string>;

  countryId: Maybe<number>;

  objectId: Maybe<number>;
}
export interface PatientQueryArgs {
  id: Maybe<number>;
}
export interface TextQueryArgs {
  id: Maybe<number>;
}
export interface DoctorQueryArgs {
  id: Maybe<number>;
}
export interface UniqueOtherNoteQueryArgs {
  label: Maybe<string>;

  objectId: Maybe<number>;
}
export interface PatientUniqueQueryArgs {
  pharmacyId: Maybe<number>;

  patientNo: Maybe<string>;
}
export interface IntakeNotesQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface InteractionsQueryArgs {
  languageId: Maybe<number>;

  label: Maybe<string>;

  status: Maybe<ReadonlyArray<number>>;
}
export interface MedicamentsIntakePlanQueryArgs {
  planid: Maybe<number>;
}
export interface EntityQueryArgs {
  id: Maybe<number>;

  class: Maybe<string>;
}
export interface IntakeNotesByIdQueryArgs {
  ids: Maybe<ReadonlyArray<number>>;
}
export interface SaveMedStrengthMutationArgs {
  medStrength: Maybe<MedStrengthInput>;
}
export interface ConfirmRegistrationMutationArgs {
  reason: Maybe<string>;

  userId: Maybe<number>;

  confirmUserId: Maybe<number>;
}
export interface DeletePatientNoteMutationArgs {
  patientNoteId: Maybe<number>;

  patientId: Maybe<number>;
}
export interface SaveEmployeeMutationArgs {
  languageId: Maybe<number>;

  isPharmacy: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<EmployeeInput>;
}
export interface DeleteDocSideEffectMutationArgs {
  item: Maybe<number>;

  patientId: Maybe<number>;
}
export interface UpdateMedicamentLeafletMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<MedicamentInput>;

  userId: Maybe<number>;
}
export interface DeleteIntakeMedMutationArgs {
  item: Maybe<number>;

  patientId: Maybe<number>;
}
export interface SavePatientNoteMutationArgs {
  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PatientNoteInput>;

  userId: Maybe<number>;
}
export interface SaveAddPreparationMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<AddPreparationInput>;

  userId: Maybe<number>;
}
export interface DeleteMedStrengthMutationArgs {
  medStrengthId: Maybe<number>;

  medicamentId: Maybe<number>;
}
export interface ChangePatientDoctorMutationArgs {
  patientId: Maybe<number>;

  patientDoctorId: Maybe<number>;
}
export interface SaveMedNoteMutationArgs {
  medNote: Maybe<MedNoteInput>;
}
export interface SaveIntakeNoteMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<IntakeNoteInput>;
}
export interface SaveCountryMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<CountryInput>;
}
export interface SaveSponsorMutationArgs {
  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<SponsorInput>;
}
export interface SaveMedCheckBoxMutationArgs {
  medicamentId: Maybe<number>;

  idList: Maybe<ReadonlyArray<number>>;

  type: Maybe<number>;

  userId: Maybe<number>;

  countryId: Maybe<number>;

  medEnum: Maybe<MedCheckBoxEnum>;
}
export interface SaveDoctorMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<DoctorInput>;
}
export interface RejectRegistrationMutationArgs {
  reason: Maybe<string>;

  userId: Maybe<number>;

  confirmUserId: Maybe<number>;
}
export interface SaveIntakePlanMutationArgs {
  intakePlan: Maybe<IntakePlanInput>;

  forceDelete: Maybe<boolean>;

  action: Maybe<ActionStateEnum>;

  intakePlanDates: Maybe<ReadonlyArray<IntakePlanDateInput>>;

  userId: Maybe<number>;
}
export interface SaveUserMutationArgs {
  performer: Maybe<number>;

  pharmacy: Maybe<PharmacyInput>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<UserInput>;

  isPharmacy: Maybe<number>;
}
export interface DeleteMedNoteMutationArgs {
  medicamentId: Maybe<number>;

  medNoteId: Maybe<number>;
}
export interface SavePharmacyMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PharmacyInput>;
}
export interface EmailDoctorMutationArgs {
  subject: Maybe<string>;

  letter: Maybe<string>;

  email: Maybe<string>;
}
export interface SaveAddSideCheckBoxMutationArgs {
  addpreparationId: Maybe<number>;

  idList: Maybe<ReadonlyArray<number>>;

  userId: Maybe<number>;

  countryId: Maybe<number>;
}
export interface SavePatientDocumentationMutationArgs {
  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PatientDocInput>;

  userId: Maybe<number>;
}
export interface SavePatientDoctorMutationArgs {
  pharmacyId: Maybe<number>;

  doctorId: Maybe<number>;

  patientId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  prescribing: Maybe<number>;

  userId: Maybe<number>;

  countryId: Maybe<number>;
}
export interface SaveOtherNoteMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<OtherNoteInput>;
}
export interface SaveFileMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<FileInput>;

  countryId: Maybe<number>;
}
export interface SaveSubstanceMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<SubstanceInput>;
}
export interface SaveMedBackgroundMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<MedicamentInput>;

  userId: Maybe<number>;
}
export interface SaveMedicamentMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<MedicamentInput>;

  userId: Maybe<number>;
}
export interface DeleteIntakeAddPrepMutationArgs {
  item: Maybe<number>;

  patientId: Maybe<number>;
}
export interface DeletePatientDoctorMutationArgs {
  patientId: Maybe<number>;

  patientDoctorId: Maybe<number>;
}
export interface DeleteIntakePlanMutationArgs {
  patientId: Maybe<number>;

  planId: Maybe<number>;
}
export interface SaveUserAccountMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<AccountInputInput>;
}
export interface DeleteDocumentationMutationArgs {
  item: Maybe<number>;

  patientId: Maybe<number>;
}
export interface SaveRegistrationMutationArgs {
  pharmacy: Maybe<PharmacyInput>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<UserInput>;

  isPharmacy: Maybe<number>;
}
export interface SavePatientMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PatientInput>;

  userId: Maybe<number>;
}
export interface SaveAddStrengthMutationArgs {
  addPrepStrength: Maybe<AddPrepStrengthInput>;

  action: Maybe<ActionStateEnum>;
}
export interface SaveInteractionMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<InteractionInput>;
}
export interface IntakePlanMutationArgs {
  planid: Maybe<number>;
}
export interface SaveTextMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<TextInput>;

  countryId: Maybe<number>;
}
export interface SavePdfTextMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PdfTextLangInput>;

  countryId: Maybe<number>;
}
export interface SavePatientlanguageMutationArgs {
  usersId: Maybe<ReadonlyArray<number>>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<PatientLanguageInput>;
}
export interface SaveSideEffectMutationArgs {
  languageId: Maybe<number>;

  action: Maybe<ActionStateEnum>;

  parentObj: Maybe<SideEffectInput>;
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";

// ====================================================
// GraphQL Fragments
// ====================================================

export const CountryFragmentFragment = gql`
  fragment countryFragment on Country {
    countryid
    doccheck
    email
    languageid
    name
    organisation
    status
    phone
    street
    zip
    city
    logo
  }
`;

export const EmployeeOutputFragmentFragment = gql`
  fragment employeeOutputFragment on EmployeesOutput  {
    apothekemaid
    countryid
    pharmacyid
    email
    firstname
    lastname
    phone
    salutation
    shortname
    status
    title
    userid
    isPharmacy
    professionalgroup
  }
`;

export const EmployeeFragmentFragment = gql`
  fragment employeeFragment on Employee  {
    apothekemaid
    countryid
    pharmacyid
    email
    firstname
    lastname
    phone
    salutation
    shortname
    status
    title
    userid
    professionalgroup
  }
`;

export const FileFragmentFragment = gql`
  fragment fileFragment on File {
    fileid
    label
    filename
    translations {
      filecountrylangid
      fileid
      label
      filename
      filetype
      countryid
      languageid
      file
    }
  }
`;

export const MedStrengthFragmentFragment = gql`
  fragment medStrengthFragment on MedStrength {
    medstrengthid
    countryid
    medicamentid
    note
    packingsizes
    strength
    perdays
    morning
    midday
    evening
    night
    individual
  }
`;

export const AddPreparationFragmentFragment = gql`
  fragment addPreparationFragment on AddPreparation {
    addpreparationid
    countryid
    hints
    informations
    label
    mischint
    status
    patientinfo
    lastchange
    addPrepStrengths {
      countryid
      intakes
      perdays
      pieces
      individual
      preparationid
      strength
      morning
      midday
      evening
      night
      strengthid
    }
    translations {
      addpreplangid
      addpreparationid
      countryid
      label
      hints
      informations
      mischint
      languageid
      patientinfo
    }
  }
`;

export const MedicamentFragmentFragment = gql`
  fragment medicamentFragment on Medicament {
    medicamentid
    label
    shortname
    shortnotice
    background
    translations {
      medlanguageid
      languageid
      medicamentid
      label
      shortname
      shortnotice
      background
    }
  }
`;

export const SideEffectFragmentFragment = gql`
  fragment sideEffectFragment on SideEffect {
    label
    sideeffectid
    sideeffect
    symptoms
    level1
    level2
    level3
    level4
    level5
    doctorfromlevel
    medicalterm
    riskfactors
    measures
    literature
    status
    translations {
      sideeffectlangid
      sideeffectid
      sideeffect
      label
      symptoms
      level1
      level2
      level3
      level4
      level5
      riskfactors
      measures
      languageid
    }
  }
`;

export const PatientFragmentFragment = gql`
  fragment patientFragment on Patient {
    patientid
    acceptance
    patientno
    email
    sex
    languageid
    birthyear
    status
    disease
    phone
    countryid
    pharmacyid
    notes {
      noteid
      date
      note
      theme
      status
    }
    patientDoctors {
      patientdoctorid
      prescribing
      patientDoctorDoctor {
        doctorid
        city
        countryid
        pharmacyid
        fax
        firstname
        lastname
        mail
        phone
        sex
        street
        title
        zip
      }
    }
    intakePlans {
      planid
      datefrom
      dateto
      planperpatient
      status
      intakeAddPreps {
        planaddprepid
        instruction
        intakes
        days
        pieces
        strength
        morning
        midday
        evening
        night
        pieces00clock
        pieces01clock
        pieces02clock
        pieces03clock
        pieces04clock
        pieces05clock
        pieces06clock
        pieces07clock
        pieces08clock
        pieces09clock
        pieces10clock
        pieces11clock
        pieces12clock
        pieces13clock
        pieces14clock
        pieces15clock
        pieces16clock
        pieces17clock
        pieces18clock
        pieces19clock
        pieces20clock
        pieces21clock
        pieces22clock
        pieces23clock
        additionalPreparation {
          addpreparationid
          hints
          informations
          label
          mischint
          status
          translations {
            addpreparationid
            addpreplangid
            hints
            informations
            label
            languageid
            mischint
          }
          addPrepStrengths {
            strengthid
            intakes
            perdays
            pieces
            individual
            strength
            countryid
            preparationid
          }
        }
      }
      intakeDocs {
        plandocid
        datetime
        countryid
      }
      intakeMeds {
        intakemedid
        startstock
        endstock
        instruction
        shortnotice
        intakes
        noofpackings
        packingsize
        days
        strength
        morning
        midday
        evening
        night
        pieces00clock
        pieces01clock
        pieces02clock
        pieces03clock
        pieces04clock
        pieces05clock
        pieces06clock
        pieces07clock
        pieces08clock
        pieces09clock
        pieces10clock
        pieces11clock
        pieces12clock
        pieces13clock
        pieces14clock
        pieces15clock
        pieces16clock
        pieces17clock
        pieces18clock
        pieces19clock
        pieces20clock
        pieces21clock
        pieces22clock
        pieces23clock
        medicament {
          medicamentid
          datemedicalinfo
          label
          manufactor
          shortname
          medStrengths {
            medstrengthid
            countryid
            medicamentid
            note
            packingsizes
            perdays
            strength
            morning
            midday
            evening
            night
            individual
          }
          medSideEffects {
            type
            medeffectid
            sideeffectid
          }
        }
      }
      intakePlanDates {
        planid
        id
        date
        intakePlanDateTimes {
          id
          time
          plandateid
          intakePlanPreparations {
            id
            strength
            pieces
            plandatetimeid
            addPreparation {
              ...addPreparationFragment
            }
          }
          intakePlanMedicaments {
            id
            strength
            pieces
            plandatetimeid
            medicament {
              ...medicamentFragment
            }
          }
        }
      }
    }
    docs {
      docid
      date
      protocol
      sideEffects {
        doceffectid
        doctorreference
        level
        measures
        note
        soldpreparates
        sideEffect {
          ...sideEffectFragment
        }
      }
    }
  }

  ${AddPreparationFragmentFragment}
  ${MedicamentFragmentFragment}
  ${SideEffectFragmentFragment}
`;

export const SponsorFragmentFragment = gql`
  fragment sponsorFragment on Sponsor {
    sponsorid
    sponsor
    link
    text
    logo
    datestart
    dateend
    countryid
  }
`;

export const TextFragmentFragment = gql`
  fragment textFragment on Text {
    textid
    label
    text
    subject
    showpopuponetime
    translations {
      textcountrylangid
      label
      text
      subject
      countryid
      languageid
      textid
    }
  }
`;

export const PdfTextFragmentFragment = gql`
  fragment pdfTextFragment on PdfText {
    pdftextid
    label
    text
    translations {
      pdftextlangid
      label
      text
      languageid
    }
  }
`;

// export const PdfTextLangFragmentFragment = gql`
//   fragment pdfTextLangFragment on PdfTextLang {
//     pdftextlangid
//     label
//     languageid
//     text
//     labelText
//     translations {
//       textcountrylangid
//       label
//       text
//       subject
//       countryid
//       languageid
//       textid
//     }
//   }
// `;
export const PdfTextLangFragmentFragment = gql`
  fragment pdfTextLangFragment on PdfTextLang {
    pdftextlangid
    label
    languageid
    text
    labelText
    translations {
      pdftextlangid
      label
      languageid
      countryid
      text
      labelText
    }
  }
`;

export const UserBasicFragmentFragment = gql`
  fragment userBasicFragment on User {
    userid
    firstname
    lastname
    email
    salutation
    title
    phone
    status
    password
  }
`;

export const UserDocsFragmentFragment = gql`
  fragment userDocsFragment on User {
    userDocs {
      userdocid
      document
      content
      label
      filetype
      size
    }
  }
`;

export const UserExtraFragmentFragment = gql`
  fragment userExtraFragment on User {
    city
    doccheck
    enddate
    institutiontype
    name
    phone
    salutation
    startdate
    street
    title
    zip
    lastlogin
    created
    freeofcharge
    endoffreeuse
    professionalgroup
  }
`;

export const UserLangFragmentFragment = gql`
  fragment userLangFragment on User {
    pharmacyid
    userLanguage {
      languageid
      language
      shortname
    }
    country {
      countryid
      name
      doccheck
      organisation
      status
      languageid
    }
    translationLang {
      languageid
      language
      status
    }
  }
`;

export const UserRolesFragmentFragment = gql`
  fragment userRolesFragment on User {
    ismainadmin
    ismaineditor
    ispharmacy
    isemployee
    iscountryadmin
    iscountryeditor
    issimpleuser
  }
`;

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
  providedIn: "root"
})
export class AddPreparationsGQL extends Apollo.Query<
  AddPreparationsQuery,
  AddPreparationsVariables
> {
  document: any = gql`
    query AddPreparations($label: String, $status: [Int], $countryId: Int!) {
      AddPreparations(label: $label, status: $status, countryId: $countryId) {
        ...addPreparationFragment
      }
    }

    ${AddPreparationFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveAddSideCheckBoxGQL extends Apollo.Mutation<
  SaveAddSideCheckBoxMutation,
  SaveAddSideCheckBoxVariables
> {
  document: any = gql`
    mutation saveAddSideCheckBox(
      $addpreparationId: Int
      $countryId: Int
      $idList: [Int]!
      $userId: Int
    ) {
      saveAddSideCheckBox(
        addpreparationId: $addpreparationId
        countryId: $countryId
        idList: $idList
        userId: $userId
      ) {
        ...addPreparationFragment
      }
    }

    ${AddPreparationFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveAddPreparationGQL extends Apollo.Mutation<
  SaveAddPreparationMutation,
  SaveAddPreparationVariables
> {
  document: any = gql`
    mutation saveAddPreparation(
      $parentObj: AddPreparationInput!
      $languageId: Int
      $action: ActionStateEnum!
      $userId: Int
    ) {
      saveAddPreparation(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
        userId: $userId
      ) {
        ...addPreparationFragment
      }
    }

    ${AddPreparationFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveAddStrengthGQL extends Apollo.Mutation<
  SaveAddStrengthMutation,
  SaveAddStrengthVariables
> {
  document: any = gql`
    mutation saveAddStrength(
      $addPrepStrength: AddPrepStrengthInput!
      $action: ActionStateEnum!
    ) {
      saveAddStrength(addPrepStrength: $addPrepStrength, action: $action) {
        countryid
        intakes
        perdays
        pieces
        individual
        preparationid
        strength
        morning
        midday
        evening
        night
        strengthid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueAddPreparationGQL extends Apollo.Query<
  UniqueAddPreparationQuery,
  UniqueAddPreparationVariables
> {
  document: any = gql`
    query UniqueAddPreparation(
      $label: String
      $objectId: Int!
      $countryId: Int
    ) {
      UniqueAddPreparation(
        label: $label
        objectId: $objectId
        countryId: $countryId
      )
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CountryGQL extends Apollo.Query<CountryQuery, CountryVariables> {
  document: any = gql`
    query Country($id: Int) {
      Country(id: $id) {
        ...countryFragment
      }
    }

    ${CountryFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class CountriesGQL extends Apollo.Query<
  CountriesQuery,
  CountriesVariables
> {
  document: any = gql`
    query Countries($name: String) {
      Countries(name: $name) {
        ...countryFragment
      }
    }

    ${CountryFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveCountryGQL extends Apollo.Mutation<
  SaveCountryMutation,
  SaveCountryVariables
> {
  document: any = gql`
    mutation saveCountry(
      $parentObj: CountryInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveCountry(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        ...countryFragment
      }
    }

    ${CountryFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueCountryGQL extends Apollo.Query<
  UniqueCountryQuery,
  UniqueCountryVariables
> {
  document: any = gql`
    query UniqueCountry($name: String, $objectId: Int!) {
      UniqueCountry(name: $name, objectId: $objectId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DoctorGQL extends Apollo.Query<DoctorQuery, DoctorVariables> {
  document: any = gql`
    query Doctor($id: Int) {
      Doctor(id: $id) {
        doctorid
        city
        countryid
        fax
        firstname
        lastname
        mail
        pharmacyid
        phone
        sex
        street
        title
        zip
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DoctorsGQL extends Apollo.Query<DoctorsQuery, DoctorsVariables> {
  document: any = gql`
    query Doctors($name: String, $pharmacyId: Int!) {
      Doctors(name: $name, pharmacyId: $pharmacyId) {
        doctorid
        city
        countryid
        pharmacyid
        fax
        firstname
        lastname
        mail
        phone
        sex
        street
        title
        zip
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class EmailDoctorGQL extends Apollo.Mutation<
  EmailDoctorMutation,
  EmailDoctorVariables
> {
  document: any = gql`
    mutation emailDoctor($subject: String, $email: String!, $letter: String) {
      emailDoctor(subject: $subject, email: $email, letter: $letter)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveDoctorGQL extends Apollo.Mutation<
  SaveDoctorMutation,
  SaveDoctorVariables
> {
  document: any = gql`
    mutation saveDoctor($parentObj: DoctorInput!, $action: ActionStateEnum!) {
      saveDoctor(parentObj: $parentObj, action: $action) {
        doctorid
        city
        countryid
        fax
        firstname
        lastname
        mail
        pharmacyid
        phone
        sex
        street
        title
        zip
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class EmployeeGQL extends Apollo.Query<
  EmployeeQuery,
  EmployeeVariables
> {
  document: any = gql`
    query Employee($id: Int) {
      Employee(id: $id) {
        ...employeeFragment
      }
    }

    ${EmployeeFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class EmployeesGQL extends Apollo.Query<
  EmployeesQuery,
  EmployeesVariables
> {
  document: any = gql`
    query Employees($name: String, $userId: Int) {
      Employees(name: $name, userId: $userId) {
        ...employeeOutputFragment
      }
    }

    ${EmployeeOutputFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveEmployeeGQL extends Apollo.Mutation<
  SaveEmployeeMutation,
  SaveEmployeeVariables
> {
  document: any = gql`
    mutation saveEmployee(
      $parentObj: EmployeeInput!
      $languageId: Int
      $isPharmacy: Int
      $action: ActionStateEnum!
    ) {
      saveEmployee(
        parentObj: $parentObj
        languageId: $languageId
        isPharmacy: $isPharmacy
        action: $action
      ) {
        ...employeeOutputFragment
      }
    }

    ${EmployeeOutputFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class FilesGQL extends Apollo.Query<FilesQuery, FilesVariables> {
  document: any = gql`
    query Files($label: String, $languageId: Int) {
      Files(label: $label, languageId: $languageId) {
        ...fileFragment
      }
    }

    ${FileFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class FileGQL extends Apollo.Query<FileQuery, FileVariables> {
  document: any = gql`
    query File($id: Int) {
      File(id: $id) {
        ...fileFragment
      }
    }

    ${FileFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveFileGQL extends Apollo.Mutation<
  SaveFileMutation,
  SaveFileVariables
> {
  document: any = gql`
    mutation saveFile(
      $parentObj: FileInput!
      $countryId: Int
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveFile(
        parentObj: $parentObj
        countryId: $countryId
        languageId: $languageId
        action: $action
      ) {
        ...fileFragment
      }
    }

    ${FileFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class HistoryGQL extends Apollo.Query<HistoryQuery, HistoryVariables> {
  document: any = gql`
    query History($tableId: Int!, $objectId: Int!) {
      History(tableId: $tableId, objectId: $objectId) {
        historyid
        datetime
        label
        label2
        objectid
        operation
        tableid
        user {
          userid
          firstname
          lastname
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class HistoriesGQL extends Apollo.Query<
  HistoriesQuery,
  HistoriesVariables
> {
  document: any = gql`
    query Histories($tableId: Int!, $label: String) {
      Histories(tableId: $tableId, label: $label) {
        historyid
        datetime
        label
        label2
        objectid
        operation
        tableid
        user {
          userid
          firstname
          lastname
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class IntakeNotesByIdGQL extends Apollo.Query<
  IntakeNotesByIdQuery,
  IntakeNotesByIdVariables
> {
  document: any = gql`
    query IntakeNotesById($ids: [Int]) {
      IntakeNotesById(ids: $ids) {
        intakenoteid
        intakenote
        label
        status
        translations {
          intakenotelangid
          intakenoteid
          label
          intakenote
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class IntakeNotesGQL extends Apollo.Query<
  IntakeNotesQuery,
  IntakeNotesVariables
> {
  document: any = gql`
    query IntakeNotes($label: String, $status: [Int], $languageId: Int!) {
      IntakeNotes(label: $label, status: $status, languageId: $languageId) {
        intakenoteid
        intakenote
        label
        status
        translations {
          intakenotelangid
          intakenoteid
          label
          intakenote
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveIntakeNoteGQL extends Apollo.Mutation<
  SaveIntakeNoteMutation,
  SaveIntakeNoteVariables
> {
  document: any = gql`
    mutation saveIntakeNote(
      $parentObj: IntakeNoteInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveIntakeNote(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        intakenoteid
        intakenote
        label
        status
        translations {
          intakenotelangid
          intakenoteid
          intakenote
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueIntakeNoteGQL extends Apollo.Query<
  UniqueIntakeNoteQuery,
  UniqueIntakeNoteVariables
> {
  document: any = gql`
    query UniqueIntakeNote($label: String, $objectId: Int!) {
      UniqueIntakeNote(label: $label, objectId: $objectId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class InteractionsByIdGQL extends Apollo.Query<
  InteractionsByIdQuery,
  InteractionsByIdVariables
> {
  document: any = gql`
    query InteractionsById($ids: [Int]) {
      InteractionsById(ids: $ids) {
        interactionid
        interaction
        label
        status
        translations {
          interactionlangid
          interactionid
          interaction
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class InteractionsGQL extends Apollo.Query<
  InteractionsQuery,
  InteractionsVariables
> {
  document: any = gql`
    query Interactions($label: String, $status: [Int], $languageId: Int!) {
      Interactions(label: $label, status: $status, languageId: $languageId) {
        interactionid
        interaction
        label
        status
        translations {
          interactionlangid
          label
          interactionid
          languageid
          interaction
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveInteractionGQL extends Apollo.Mutation<
  SaveInteractionMutation,
  SaveInteractionVariables
> {
  document: any = gql`
    mutation saveInteraction(
      $parentObj: InteractionInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveInteraction(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        interactionid
        interaction
        label
        status
        translations {
          interactionlangid
          interactionid
          interaction
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueInteractionGQL extends Apollo.Query<
  UniqueInteractionQuery,
  UniqueInteractionVariables
> {
  document: any = gql`
    query UniqueInteraction(
      $label: String
      $objectId: Int!
      $languageId: Int!
    ) {
      UniqueInteraction(
        label: $label
        objectId: $objectId
        languageId: $languageId
      )
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class MedicamentsGQL extends Apollo.Query<
  MedicamentsQuery,
  MedicamentsVariables
> {
  document: any = gql`
    query Medicaments($filterBy: Map_String_ObjectScalar) {
      Medicaments(filterBy: $filterBy) {
        ...medicamentFragment
        manufactor
        status
        countryid
        medStrengths {
          ...medStrengthFragment
        }
      }
    }

    ${MedicamentFragmentFragment}
    ${MedStrengthFragmentFragment}
  `;
}

@Injectable({
  providedIn: "root"
})
export class MedicamentsWithSideEffectsGQL extends Apollo.Query<
  MedicamentsWithSideEffectQuery,
  MedicamentsVariables
> {
  document: any = gql`
    query Medicaments($filterBy: Map_String_ObjectScalar) {
      Medicaments(filterBy: $filterBy) {
        ...medicamentFragment
        manufactor
        status
        countryid
        medSideEffects {
          type
          medeffectid
          sideeffectid
        }
        medStrengths {
          ...medStrengthFragment
        }
      }
    }

    ${MedicamentFragmentFragment}
    ${MedStrengthFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveMedBackgroundGQL extends Apollo.Mutation<
  SaveMedBackgroundMutation,
  SaveMedBackgroundVariables
> {
  document: any = gql`
    mutation saveMedBackground(
      $parentObj: MedicamentInput!
      $languageId: Int
      $action: ActionStateEnum!
      $userId: Int!
    ) {
      saveMedBackground(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
        userId: $userId
      ) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveMedCheckBoxGQL extends Apollo.Mutation<
  SaveMedCheckBoxMutation,
  SaveMedCheckBoxVariables
> {
  document: any = gql`
    mutation saveMedCheckBox(
      $medicamentId: Int
      $countryId: Int
      $idList: [Int]!
      $type: Int
      $medEnum: MedCheckBoxEnum
      $userId: Int
    ) {
      saveMedCheckBox(
        medicamentId: $medicamentId
        countryId: $countryId
        idList: $idList
        type: $type
        medEnum: $medEnum
        userId: $userId
      ) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UpdateMedicamentLeafletGQL extends Apollo.Mutation<
  UpdateMedicamentLeafletMutation,
  UpdateMedicamentLeafletVariables
> {
  document: any = gql`
    mutation updateMedicamentLeaflet(
      $parentObj: MedicamentInput!
      $languageId: Int
      $action: ActionStateEnum!
      $userId: Int!
    ) {
      updateMedicamentLeaflet(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
        userId: $userId
      ) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveMedNoteGQL extends Apollo.Mutation<
  SaveMedNoteMutation,
  SaveMedNoteVariables
> {
  document: any = gql`
    mutation saveMedNote($medNote: MedNoteInput!) {
      saveMedNote(medNote: $medNote) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveMedStrengthGQL extends Apollo.Mutation<
  SaveMedStrengthMutation,
  SaveMedStrengthVariables
> {
  document: any = gql`
    mutation saveMedStrength($medStrength: MedStrengthInput!) {
      saveMedStrength(medStrength: $medStrength) {
        medstrengthid
        countryid
        medicamentid
        note
        packingsizes
        strength
        perdays
        morning
        midday
        evening
        night
        individual
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveMedicamentGQL extends Apollo.Mutation<
  SaveMedicamentMutation,
  SaveMedicamentVariables
> {
  document: any = gql`
    mutation saveMedicament(
      $parentObj: MedicamentInput!
      $languageId: Int
      $action: ActionStateEnum!
      $userId: Int
    ) {
      saveMedicament(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
        userId: $userId
      ) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CopyMedicamentGQL extends Apollo.Mutation<
  CopyMedicamentMutation,
  CopyMedicamentVariables
> {
  document: any = gql`
    mutation copyMedicament(
      $name: String
      $medicamentId: Int!
    ) {
      copyMedicament(
        name: $name
        medicamentId: $medicamentId
      ) {
        medicamentid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class OtherNotesByIdGQL extends Apollo.Query<
  OtherNotesByIdQuery,
  OtherNotesByIdVariables
> {
  document: any = gql`
    query OtherNotesById($ids: [Int]) {
      OtherNotesById(ids: $ids) {
        othernoteid
        label
        othernote
        translations {
          othernotelangid
          label
          othernote
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class OtherNotesGQL extends Apollo.Query<
  OtherNotesQuery,
  OtherNotesVariables
> {
  document: any = gql`
    query OtherNotes($label: String, $status: [Int], $languageId: Int!) {
      OtherNotes(label: $label, status: $status, languageId: $languageId) {
        othernoteid
        othernote
        label
        status
        countryid
        translations {
          countryid
          othernotelangid
          othernoteid
          label
          othernote
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveOtherNoteGQL extends Apollo.Mutation<
  SaveOtherNoteMutation,
  SaveOtherNoteVariables
> {
  document: any = gql`
    mutation saveOtherNote(
      $parentObj: OtherNoteInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveOtherNote(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        othernoteid
        othernote
        label
        status
        translations {
          othernotelangid
          othernoteid
          othernote
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueOtherNoteGQL extends Apollo.Query<
  UniqueOtherNoteQuery,
  UniqueOtherNoteVariables
> {
  document: any = gql`
    query UniqueOtherNote($label: String, $objectId: Int!) {
      UniqueOtherNote(label: $label, objectId: $objectId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class PatientLanguagesGQL extends Apollo.Query<
  PatientLanguagesQuery,
  PatientLanguagesVariables
> {
  document: any = gql`
    query PatientLanguages($language: String) {
      PatientLanguages(language: $language) {
        languageid
        language
        status
        flag
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePatientlanguageGQL extends Apollo.Mutation<
  SavePatientlanguageMutation,
  SavePatientlanguageVariables
> {
  document: any = gql`
    mutation savePatientlanguage(
      $parentObj: PatientLanguageInput!
      $usersId: [Int]
      $action: ActionStateEnum!
    ) {
      savePatientlanguage(
        parentObj: $parentObj
        usersId: $usersId
        action: $action
      ) {
        languageid
        language
        status
        flag
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UsersCountryAllowedGQL extends Apollo.Query<
  UsersCountryAllowedQuery,
  UsersCountryAllowedVariables
> {
  document: any = gql`
    query UsersCountryAllowed {
      UsersCountryAllowed {
        userid
        firstname
        lastname
        email
        salutation
        title
        phone
        status
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserLanguageGQL extends Apollo.Query<
  UserLanguageQuery,
  UserLanguageVariables
> {
  document: any = gql`
    query UserLanguage($languageId: Int) {
      UserLanguage(languageId: $languageId) {
        userid
        firstname
        lastname
        email
        salutation
        title
        phone
        status
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ChangePatientDoctorGQL extends Apollo.Mutation<
  ChangePatientDoctorMutation,
  ChangePatientDoctorVariables
> {
  document: any = gql`
    mutation changePatientDoctor($patientDoctorId: Int!, $patientId: Int!) {
      changePatientDoctor(
        patientDoctorId: $patientDoctorId
        patientId: $patientId
      ) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeletePatientDoctorGQL extends Apollo.Mutation<
  DeletePatientDoctorMutation,
  DeletePatientDoctorVariables
> {
  document: any = gql`
    mutation deletePatientDoctor($patientDoctorId: Int!, $patientId: Int!) {
      deletePatientDoctor(
        patientDoctorId: $patientDoctorId
        patientId: $patientId
      ) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class PatientDoctorsFreeGQL extends Apollo.Query<
  PatientDoctorsFreeQuery,
  PatientDoctorsFreeVariables
> {
  document: any = gql`
    query PatientDoctorsFree($patientId: Int, $pharmacyId: Int!) {
      PatientDoctorsFree(patientId: $patientId, pharmacyId: $pharmacyId) {
        doctorid
        city
        countryid
        pharmacyid
        fax
        firstname
        lastname
        mail
        phone
        sex
        street
        title
        zip
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePatientDoctorGQL extends Apollo.Mutation<
  SavePatientDoctorMutation,
  SavePatientDoctorVariables
> {
  document: any = gql`
    mutation savePatientDoctor(
      $pharmacyId: Int
      $countryId: Int
      $patientId: Int
      $doctorId: Int
      $prescribing: Int
      $action: ActionStateEnum!
      $userId: Int
    ) {
      savePatientDoctor(
        pharmacyId: $pharmacyId
        countryId: $countryId
        patientId: $patientId
        doctorId: $doctorId
        prescribing: $prescribing
        action: $action
        userId: $userId
      ) {
        countryid
        patientdoctorid
        patientid
        pharmacyid
        prescribing
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteDocSideEffectGQL extends Apollo.Mutation<
  DeleteDocSideEffectMutation,
  DeleteDocSideEffectVariables
> {
  document: any = gql`
    mutation deleteDocSideEffect($item: Int!, $patientId: Int!) {
      deleteDocSideEffect(item: $item, patientId: $patientId) {
        patientid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteDocumentationGQL extends Apollo.Mutation<
  DeleteDocumentationMutation,
  DeleteDocumentationVariables
> {
  document: any = gql`
    mutation deleteDocumentation($item: Int!, $patientId: Int!) {
      deleteDocumentation(item: $item, patientId: $patientId) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePatientDocumentationGQL extends Apollo.Mutation<
  SavePatientDocumentationMutation,
  SavePatientDocumentationVariables
> {
  document: any = gql`
    mutation savePatientDocumentation(
      $parentObj: PatientDocInput!
      $action: ActionStateEnum!
      $userId: Int!
    ) {
      savePatientDocumentation(
        parentObj: $parentObj
        action: $action
        userId: $userId
      ) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteIntakeAddPrepGQL extends Apollo.Mutation<
  DeleteIntakeAddPrepMutation,
  DeleteIntakeAddPrepVariables
> {
  document: any = gql`
    mutation deleteIntakeAddPrep($item: Int!, $patientId: Int!) {
      deleteIntakeAddPrep(item: $item, patientId: $patientId) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteIntakeMedGQL extends Apollo.Mutation<
  DeleteIntakeMedMutation,
  DeleteIntakeMedVariables
> {
  document: any = gql`
    mutation deleteIntakeMed($item: Int!, $patientId: Int!) {
      deleteIntakeMed(item: $item, patientId: $patientId) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteIntakePlanGQL extends Apollo.Mutation<
  DeleteIntakePlanMutation,
  DeleteIntakePlanVariables
> {
  document: any = gql`
    mutation deleteIntakePlan($planId: Int!, $patientId: Int!) {
      deleteIntakePlan(planId: $planId, patientId: $patientId) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveIntakePlanGQL extends Apollo.Mutation<
  SaveIntakePlanPlanIdMutation,
  SaveIntakePlanVariables
> {
  document: any = gql`
    mutation saveIntakePlan(
      $intakePlan: IntakePlanInput!
      $intakePlanDates: [IntakePlanDateInput]
      $action: ActionStateEnum!
      $forceDelete: Boolean
      $userId: Int
    ) {
      saveIntakePlan(
        intakePlan: $intakePlan
        intakePlanDates: $intakePlanDates
        action: $action
        forceDelete: $forceDelete
        userId: $userId
      ) {
        planid
        patient {
          patientid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UpdateIntakeAddPrepGQL extends Apollo.Mutation<
  UpdateIntakeAddPrepMutation,
  UpdateIntakeAddPrepVariables
> {
  document: any = gql`
    mutation updateIntakeAddPrep(
      $intakeAddPrep: IntakeAddPrepInput!
    ) {
      updateIntakeAddPrep(
        intakeAddPrep: $intakeAddPrep
      ) {
        patientid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeletePatientNoteGQL extends Apollo.Mutation<
  DeletePatientNoteMutation,
  DeletePatientNoteVariables
> {
  document: any = gql`
    mutation deletePatientNote($patientNoteId: Int!, $patientId: Int!) {
      deletePatientNote(patientNoteId: $patientNoteId, patientId: $patientId) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePatientNoteGQL extends Apollo.Mutation<
  SavePatientNoteMutation,
  SavePatientNoteVariables
> {
  document: any = gql`
    mutation savePatientNote(
      $parentObj: PatientNoteInput!
      $action: ActionStateEnum!
      $userId: Int
    ) {
      savePatientNote(parentObj: $parentObj, action: $action, userId: $userId) {
        noteid
        date
        note
        theme
        status
        pharmacyid
        patientid
        countryid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class PatientGQL extends Apollo.Query<PatientQuery, PatientVariables> {
  document: any = gql`
    query Patient($id: Int!) {
      Patient(id: $id) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class PatientsGQL extends Apollo.Query<
  PatientsQuery,
  PatientsVariables
> {
  document: any = gql`
    query Patients($patientno: String, $pharmacyid: Int!) {
      Patients(patientno: $patientno, pharmacyid: $pharmacyid) {
        patientid
        patientno
        acceptance
        birthyear
        disease
        email
        languageid
        pharmacyid
        phone
        sex
        status
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class PatientUniqueGQL extends Apollo.Query<
  PatientUniqueQuery,
  PatientUniqueVariables
> {
  document: any = gql`
    query PatientUnique($patientNo: String!, $pharmacyId: Int!) {
      PatientUnique(patientNo: $patientNo, pharmacyId: $pharmacyId) {
        patientid
        patientno
        pharmacyid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePatientGQL extends Apollo.Mutation<
  SavePatientMutation,
  SavePatientVariables
> {
  document: any = gql`
    mutation savePatient(
      $parentObj: PatientInput!
      $languageId: Int
      $action: ActionStateEnum!
      $userId: Int
    ) {
      savePatient(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
        userId: $userId
      ) {
        ...patientFragment
      }
    }

    ${PatientFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class MedicamentsIntakePlanGQL extends Apollo.Query<
  MedicamentsIntakePlanQuery,
  MedicamentsIntakePlanVariables
> {
  document: any = gql`
    query MedicamentsIntakePlan($planid: Int) {
      MedicamentsIntakePlan(planid: $planid) {
        medicamentid
        label
        shortname
        manufactor
        status
        countryid
        translations {
          medlanguageid
          languageid
          medicamentid
          label
          shortname
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class StrengthsMedicamentGQL extends Apollo.Query<
  StrengthsMedicamentQuery,
  StrengthsMedicamentVariables
> {
  document: any = gql`
    query StrengthsMedicament($medicamentId: Int!) {
      StrengthsMedicament(medicamentId: $medicamentId) {
        medstrengthid
        countryid
        medicamentid
        note
        packingsizes
        strength
        perdays
        morning
        midday
        evening
        night
        individual
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class StrengthsAddPreparationGQL extends Apollo.Query<
  StrengthsAddPreparationQuery,
  StrengthsAddPreparationVariables
> {
  document: any = gql`
    query StrengthsAddPreparation($preparationId: Int!) {
      StrengthsAddPreparation(preparationId: $preparationId) {
        strengthid
        countryid
        preparationid
        perdays
        morning
        midday
        evening
        night
        strength
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class PharmaciesGQL extends Apollo.Query<
  PharmaciesQuery,
  PharmaciesVariables
> {
  document: any = gql`
    query Pharmacies($name: String, $userId: Int) {
      Pharmacies(name: $name, userId: $userId) {
        pharmacyid
        countryid
        name
        mail
        bgano
        city
        street
        zip
        phone
        userid
        logo
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class PharmacyGQL extends Apollo.Query<
  PharmacyQuery,
  PharmacyVariables
> {
  document: any = gql`
    query Pharmacy($id: Int) {
      Pharmacy(id: $id) {
        pharmacyid
        countryid
        name
        mail
        bgano
        city
        street
        zip
        phone
        userid
        logo
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePharmacyGQL extends Apollo.Mutation<
  SavePharmacyMutation,
  SavePharmacyVariables
> {
  document: any = gql`
    mutation savePharmacy(
      $parentObj: PharmacyInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      savePharmacy(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        pharmacyid
        countryid
        name
        mail
        bgano
        city
        street
        zip
        phone
        userid
        logo
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ConfirmRegistrationGQL extends Apollo.Mutation<
  ConfirmRegistrationMutation,
  ConfirmRegistrationVariables
> {
  document: any = gql`
    mutation confirmRegistration(
      $confirmUserId: Int!
      $userId: Int!
      $reason: String
    ) {
      confirmRegistration(
        confirmUserId: $confirmUserId
        userId: $userId
        reason: $reason
      )
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ResetPasswordGQL extends Apollo.Query<
  ResetPasswordQuery,
  ResetPasswordVariables
> {
  document: any = gql`
    query resetPassword($email: String!) {
      resetPassword(email: $email)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class RejectRegistrationGQL extends Apollo.Mutation<
  RejectRegistrationMutation,
  RejectRegistrationVariables
> {
  document: any = gql`
    mutation rejectRegistration(
      $confirmUserId: Int!
      $userId: Int!
      $reason: String
    ) {
      rejectRegistration(
        confirmUserId: $confirmUserId
        userId: $userId
        reason: $reason
      )
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveSideEffectGQL extends Apollo.Mutation<
  SaveSideEffectMutation,
  SaveSideEffectVariables
> {
  document: any = gql`
    mutation saveSideEffect(
      $parentObj: SideEffectInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveSideEffect(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        ...sideEffectFragment
      }
    }

    ${SideEffectFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SideEffectsByIdGQL extends Apollo.Query<
  SideEffectsByIdQuery,
  SideEffectsByIdVariables
> {
  document: any = gql`
    query SideEffectsById($ids: [Int], $notIn: Boolean) {
      SideEffectsById(ids: $ids, notIn: $notIn) {
        sideeffectid
        label
        translations {
          sideeffectlangid
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SideEffectsGQL extends Apollo.Query<
  SideEffectsQuery,
  SideEffectsVariables
> {
  document: any = gql`
    query SideEffects($label: String, $status: [Int], $languageId: Int!) {
      SideEffects(label: $label, status: $status, languageId: $languageId) {
        ...sideEffectFragment
      }
    }

    ${SideEffectFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueSideEffectGQL extends Apollo.Query<
  UniqueSideEffectQuery,
  UniqueSideEffectVariables
> {
  document: any = gql`
    query UniqueSideEffect($label: String, $objectId: Int!) {
      UniqueSideEffect(label: $label, objectId: $objectId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveSponsorGQL extends Apollo.Mutation<
  SaveSponsorMutation,
  SaveSponsorVariables
> {
  document: any = gql`
    mutation saveSponsor($parentObj: SponsorInput!, $action: ActionStateEnum!) {
      saveSponsor(parentObj: $parentObj, action: $action) {
        ...sponsorFragment
      }
    }

    ${SponsorFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SponsorsGQL extends Apollo.Query<
  SponsorsQuery,
  SponsorsVariables
> {
  document: any = gql`
    query Sponsors($label: String, $countryId: Int) {
      Sponsors(label: $label, countryId: $countryId) {
        ...sponsorFragment
      }
    }

    ${SponsorFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveSubstanceGQL extends Apollo.Mutation<
  SaveSubstanceMutation,
  SaveSubstanceVariables
> {
  document: any = gql`
    mutation saveSubstance(
      $parentObj: SubstanceInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveSubstance(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        substanceid
        label
        status
        translations {
          substancelangid
          substanceid
          label
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SubstancesGQL extends Apollo.Query<
  SubstancesQuery,
  SubstancesVariables
> {
  document: any = gql`
    query Substances($label: String, $status: [Int], $languageId: Int!) {
      Substances(label: $label, status: $status, languageId: $languageId) {
        substanceid
        status
        label
        translations {
          substancelangid
          languageid
          label
          substanceid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueSubstanceGQL extends Apollo.Query<
  UniqueSubstanceQuery,
  UniqueSubstanceVariables
> {
  document: any = gql`
    query UniqueSubstance($label: String, $objectId: Int!) {
      UniqueSubstance(label: $label, objectId: $objectId)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CheckToShowPopupGQL extends Apollo.Query<
  CheckToShowPopupQuery,
  CheckToShowPopupVariables
> {
  document: any = gql`
    query CheckToShowPopup($userid: Int!, $textid: Int!) {
      CheckToShowPopup(userid: $userid, textid: $textid)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ListOfEmployeesGQL extends Apollo.Query<
  ListOfEmployeesQuery,
  ListOfEmployeesVariables
> {
  document: any = gql`
    query ListOfEmployees($pharmacyid: Int!) {
      ListOfEmployees(pharmacyid: $pharmacyid) {
        ...employeeFragment
      }
    }

    ${EmployeeFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class DeleteListOfEmployeesGQL extends Apollo.Query<
  DeleteListOfEmployeesQuery,
  DeleteListOfEmployeesVariables
> {
  document: any = gql`
    query DeleteListOfEmployees($employeeIds: [Int], $pharmacyid: Int!) {
      DeleteListOfEmployees(employeeIds: $employeeIds, pharmacyid: $pharmacyid) {
        ...employeeFragment
      }
    }

    ${EmployeeFragmentFragment}
  `;
}

@Injectable({
  providedIn: "root"
})
export class DeleteListOfPatientsGQL extends Apollo.Query<
  DeleteListOfPatientsQuery,
  DeleteListOfPatientsVariables
> {
  document: any = gql`
    query DeleteListOfPatients($patientIds: [Int], $pharmacyid: Int!) {
      DeleteListOfPatients(patientIds: $patientIds, pharmacyid: $pharmacyid) {
        patientid
        patientno
        acceptance
        birthyear
        disease
        email
        languageid
        pharmacyid
        phone
        sex
        status
        intakePlans {
          datefrom
          dateto
        }
      }
    }
  `;
}


@Injectable({
  providedIn: "root"
})
export class DeletePharmacyGQL extends Apollo.Query<
  DeletePharmacyQuery,
  DeletePharmacyVariables
> {
  document: any = gql`
    query DeletePharmacy($pharmacyid: Int!) {
      DeletePharmacy(pharmacyid: $pharmacyid) {
        pharmacyid
      }
    }
  `;
}

@Injectable({
  providedIn: "root"
})
export class ListOfPatientsGQL extends Apollo.Query<
  ListOfPatientsQuery,
  ListOfPatientsVariables
> {
  document: any = gql`
    query ListOfPatients($pharmacyid: Int!) {
      ListOfPatients(pharmacyid: $pharmacyid) {
        patientid
        patientno
        sex
        datefrom
        dateto
      }
    }
  `;
}
// @Injectable({
//   providedIn: "root"
// })
// export class PatientsGQL extends Apollo.Query<
//   PatientsQuery,
//   PatientsVariables
// > {
//   document: any = gql`
//     query Patients($patientno: String, $pharmacyid: Int!) {
//       Patients(patientno: $patientno, pharmacyid: $pharmacyid) {
//         patientid
//         patientno
//         acceptance
//         birthyear
//         disease
//         email
//         languageid
//         pharmacyid
//         phone
//         sex
//         status
//       }
//     }
//   `;
// }


@Injectable({
  providedIn: "root"
})
export class SaveTextGQL extends Apollo.Mutation<
  SaveTextMutation,
  SaveTextVariables
> {
  document: any = gql`
    mutation saveText(
      $parentObj: TextInput!
      $languageId: Int
      $countryId: Int
      $action: ActionStateEnum!
      $showpopuponetime: Boolean
    ) {
      saveText(
        parentObj: $parentObj
        languageId: $languageId
        countryId: $countryId
        action: $action
        showpopuponetime: $showpopuponetime
      ) {
        ...textFragment
      }
    }

    ${TextFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SavePdfTextGQL extends Apollo.Mutation<
  SavePdfTextMutation,
  SavePdfTextVariables
> {
  document: any = gql`
    mutation savePdfText(
      $parentObj: PdfTextLangInput!
      $languageId: Int
      $countryId: Int
      $action: ActionStateEnum!
    ) {
      savePdfText(
        parentObj: $parentObj
        languageId: $languageId
        countryId: $countryId
        action: $action
      ) {
        ...pdfTextLangFragment
      }
    }

    ${PdfTextLangFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class TextsGQL extends Apollo.Query<TextsQuery, TextsVariables> {
  document: any = gql`
    query Texts($label: String) {
      Texts(label: $label) {
        ...textFragment
      }
    }

    ${TextFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class PdfTextsGQL extends Apollo.Query<PdfTextsQuery, PdfTextsVariables> {
  document: any = gql`
    query PdfTexts ($label: String, $languageId: Int, $countryId: Int){
      PdfTexts(label: $label, languageId: $languageId, countryId: $countryId) {
        ...pdfTextLangFragment
      }
    }

    ${PdfTextLangFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class TextLoginGQL extends Apollo.Query<
  TextLoginQuery,
  TextLoginVariables
> {
  document: any = gql`
    query TextLogin {
      TextLogin {
        ...textFragment
      }
    }

    ${TextFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserLanguagesGQL extends Apollo.Query<
  UserLanguagesQuery,
  UserLanguagesVariables
> {
  document: any = gql`
    query UserLanguages {
      UserLanguages {
        languageid
        language
        shortname
        translations {
          languagelangid
          language
          userlanguageid
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class ActiveUserLanguages extends Apollo.Query<
  ActiveUserLanguagesQuery,
  UserLanguagesVariables
> {
  document: any = gql`
    query ActiveUserLanguages {
      ActiveUserLanguages {
        languageid
        language
        shortname
        translations {
          languagelangid
          language
          userlanguageid
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserTranslationLanguages extends Apollo.Query<
  ActiveUserLanguagesQuery,
  UserTranslationLanguagesVariables
> {
  document: any = gql`
    query UserTranslationLanguages($userId: Int) {
      UserTranslationLanguages(userId: $userId) {
        languageid
        language
        shortname
        translations {
          languagelangid
          language
          userlanguageid
          languageid
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class DoLoginGQL extends Apollo.Query<DoLoginQuery, DoLoginVariables> {
  document: any = gql`
    query doLogin($email: String, $password: String) {
      doLogin(email: $email, password: $password) {
        ...userBasicFragment
        ...userRolesFragment
        ...userLangFragment
      }
    }

    ${UserBasicFragmentFragment}
    ${UserRolesFragmentFragment}
    ${UserLangFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class RegistrationsGQL extends Apollo.Query<
  RegistrationsQuery,
  RegistrationsVariables
> {
  document: any = gql`
    query Registrations($name: String, $countryId: Int) {
      Registrations(name: $name, countryId: $countryId) {
        ...userBasicFragment
      }
    }

    ${UserBasicFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveRegistrationGQL extends Apollo.Mutation<
  SaveRegistrationMutation,
  SaveRegistrationVariables
> {
  document: any = gql`
    mutation saveRegistration(
      $parentObj: UserInput!
      $pharmacy: PharmacyInput
      $isPharmacy: Int!
      $action: ActionStateEnum!
    ) {
      saveRegistration(
        parentObj: $parentObj
        pharmacy: $pharmacy
        isPharmacy: $isPharmacy
        action: $action
      ) {
        userid
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveUserAccountGQL extends Apollo.Mutation<
  SaveUserAccountMutation,
  SaveUserAccountVariables
> {
  document: any = gql`
    mutation saveUserAccount(
      $parentObj: AccountInputInput!
      $languageId: Int
      $action: ActionStateEnum!
    ) {
      saveUserAccount(
        parentObj: $parentObj
        languageId: $languageId
        action: $action
      ) {
        ...userBasicFragment
        ...userRolesFragment
        ...userLangFragment
      }
    }

    ${UserBasicFragmentFragment}
    ${UserRolesFragmentFragment}
    ${UserLangFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class SaveUserGQL extends Apollo.Mutation<
  SaveUserMutation,
  SaveUserVariables
> {
  document: any = gql`
    mutation saveUser(
      $parentObj: UserInput!
      $pharmacy: PharmacyInput
      $isPharmacy: Int!
      $performer: Int!
      $action: ActionStateEnum!
    ) {
      saveUser(
        parentObj: $parentObj
        pharmacy: $pharmacy
        isPharmacy: $isPharmacy
        performer: $performer
        action: $action
      ) {
        ...userBasicFragment
      }
    }

    ${UserBasicFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UniqueEmailGQL extends Apollo.Query<
  UniqueEmailQuery,
  UniqueEmailVariables
> {
  document: any = gql`
    query UniqueEmail($email: String!) {
      UniqueEmail(email: $email)
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserGQL extends Apollo.Query<UserQuery, UserVariables> {
  document: any = gql`
    query User($id: Int!) {
      User(id: $id) {
        ...userBasicFragment
        ...userRolesFragment
        ...userLangFragment
        ...userExtraFragment
        ...userDocsFragment
      }
    }

    ${UserBasicFragmentFragment}
    ${UserRolesFragmentFragment}
    ${UserLangFragmentFragment}
    ${UserExtraFragmentFragment}
    ${UserDocsFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UsersGQL extends Apollo.Query<UsersQuery, UsersVariables> {
  document: any = gql`
    query Users(
      $name: String
      $userId: Int
      $zip: String
      $city: String
      $pharmacy: String
      $countryId: Int
      $email: String
    ) {
      Users(
        name: $name
        userId: $userId
        zip: $zip
        city: $city
        pharmacy: $pharmacy
        countryId: $countryId
        email: $email
      ) {
        ...userBasicFragment
      }
    }

    ${UserBasicFragmentFragment}
  `;
}
@Injectable({
  providedIn: "root"
})
export class UserUniqueGQL extends Apollo.Query<
  UserUniqueQuery,
  UserUniqueVariables
> {
  document: any = gql`
    query UserUnique($email: String, $userId: Int!) {
      UserUnique(email: $email, userId: $userId) {
        ...userBasicFragment
      }
    }

    ${UserBasicFragmentFragment}
  `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================
