import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';

export enum TextActions {
  LOAD_TEXT = '[Text] LOAD_TEXT',
  LOAD_TEXT_BY_ID = '[Text] LOAD_TEXT_BY_ID',
  SELECT_TEXT = '[Text] SELECT_TEXT'
}

export class LoadTextListAction implements Action {
  readonly type = TextActions.LOAD_TEXT;

  constructor(public payload: PaginationData) {
  }
}

export class LoadTextByIdAction implements Action {
  readonly type = TextActions.LOAD_TEXT_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectTextAction implements Action {
  readonly type = TextActions.SELECT_TEXT;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadTextListAction
  | LoadTextByIdAction
  | SelectTextAction;
