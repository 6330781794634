import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileCountryLang} from '../models/file-country-lang';
import {FileGQL} from '../api/graphql';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FacadeService} from './facade.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private _http: HttpClient, private fileGQL: FileGQL,
              private toastrService: ToastrService, private translate: TranslateService,
              private facadeService: FacadeService) {
  }

  public afterDownload(res) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        res.data, res.filename);
    } else {
      const linkSource = window.URL.createObjectURL(res.data);
      const downloadResource = document.createElement('a');
      // Set attributes
      downloadResource.href = linkSource;
      downloadResource.download = res.filename;
      downloadResource.click();
      window.URL.revokeObjectURL(linkSource);
      downloadResource.remove(); // remove the element
    }
  }

  public downloadFromLobData(fileCountryLang: FileCountryLang) {
    const linkSource = `data:${fileCountryLang.filetype};charset=utf-8;base64,${fileCountryLang.file}`;
    const downloadLink = document.createElement('a');
    // Set attributes
    downloadLink.href = linkSource;
    downloadLink.download = fileCountryLang.filename;
    downloadLink.className = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.onclick = function () {
      const timeOut1 = setTimeout(() => {
        downloadLink.remove();
        URL.revokeObjectURL(linkSource);
        clearTimeout(timeOut1);
      }, 300);
    };
    downloadLink.click();
  }

  public downloadFileWithFileID(fileID, languageID) {
    const _cascadeEffect = (res) => {
      if (res.data.File.translations.length > 0) {
        const files = res.data.File.translations;
        let languageFile = files.find(t => t.languageid === languageID);
        if (languageFile) {
          this.downloadFromLobData(languageFile);
        } else {
          languageFile = files.find(t => t.languageid === 1);
          if (languageFile) {
            this.downloadFromLobData(languageFile);
          } else {
            this.toastrService.info(this.translate.instant('resourceNotFound'));
          }
        }
      }
    };
    this.facadeService.callQueryUnique(this.fileGQL, { id: fileID }, _cascadeEffect);
  }
}
