import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {Country, CountryValidate} from '../../../models/country';
import {select, Store} from '@ngrx/store';
import {AppState, selectCountryReducer} from '../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {SaveCountryGQL, UniqueCountryGQL, UserLanguagesGQL} from '../../../api/graphql';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {SelectCountryAction} from '../../../store/actions/country.actions';
import {UserLanguage} from '../../../models/user-language';
import {isNullOrUndefined} from 'util';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {UploadFileService} from '../../../services/upload-file.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent extends FormAbstract<Country> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  public mainLanguage: UserLanguage;
  public languageList: Array<UserLanguage>;
  public selectedLanguageId: number;
  @ViewChild('file') file;

  public logoSrc: any;
  public validImage: boolean;
  public messageimage: string;

  constructor(protected store: Store<AppState>, private translate: TranslateService,
              protected bsRef: BsModalService, protected shareService: ShareService, private userLanguageGQL: UserLanguagesGQL,
              protected facadeService: FacadeService, protected authService: AuthService, private saveCountryGQL: SaveCountryGQL,
              private uploadFileService: UploadFileService, private  uniqueCountryGQL: UniqueCountryGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.logoSrc = null;
    this.validImage = true;
    this.messageimage = '';
    this.baseFields = [
      {field: 'countryid'},
      {field: 'doccheck', validator: [], defaultValue: 0},
      {field: 'email', validator: CountryValidate.rules.email},
      {field: 'languageid', validator: CountryValidate.rules.languageid},
      {field: 'name', validator: CountryValidate.rules.name},
      {field: 'phone', validator: CountryValidate.rules.phone},
      {field: 'street', validator: CountryValidate.rules.street},
      {field: 'zip', validator: CountryValidate.rules.zip},
      {field: 'city', validator: CountryValidate.rules.city},
      {field: 'organisation', validator: CountryValidate.rules.organisation},
      {field: 'status', validator: [], defaultValue: 1},
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'countryid',
      translationId: null
    };
    if (this.authService.userLanguage) {
      this.mainLanguage = this.authService.userLanguage;
    }
    this.languageId = this.authService.user.userLanguage.languageid;
    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      this.baseObject = new Country();
      this.baseObject.doccheck = 0;
      this.formGroup.patchValue({'doccheck': 0});
      this.formGroup.patchValue({'languageid': this.authService.userLanguage.languageid});
    } else {
      this.store.pipe(select(selectCountryReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
          this.formGroup.patchValue({'status': this.baseObject.status});
          this.formGroup.patchValue({'doccheck': this.baseObject.doccheck ? this.baseObject : 0});
          if (!isNullOrUndefined(this.baseObject.logo)) {
            this.logoSrc = this.baseObject.logo;
            if (this.logoSrc.substring(0, 4) !== 'data') {
              this.logoSrc = 'data:image/png;base64,' + this.logoSrc;
            }
          } else {
            this.logoSrc = null;
          }
        }
      });
    }
  }

  ngOnInit() {
    this.userLanguageGQL.watch({}
    ).result().then((data) => {
      this.languageList = data.data['UserLanguages'] as any;
    });
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public getStatusById(args) {
    if (!isNullOrUndefined(args)) {
      return this.statusList.find(t => t.id === args).name;
    } else {
      return '';
    }
  }

  public changeDocCheck(args) {
    if (args === 1) {
      this.baseObject.doccheck = 0;
      this.formGroup.patchValue({'doccheck': 0});
    } else {
      this.formGroup.patchValue({'doccheck': 1});
      this.baseObject.doccheck = 1;
    }
  }

  public getLanguageNameById(args) {
    if (args) {
      return this.languageList.find(t => t.languageid === args).language;
    }
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      const variables = {
        parentObj: this.baseObject,
        languageId: this.action === 'TRANSLATE' ? this.languageId : this.authService.user.userLanguage.languageid,
        action: this.action
      };
      const _afterSave = (args) => {
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[0]}));
        if (this.facadeService.filterArrayContains(args.data['saveCountry'], ['name'], LinkKey.COUNTRIES)) {
          this.store.dispatch(new SelectCountryAction({selected: args.data['saveCountry']}));
          this.facadeService.setDetailHidden(false);
          this.facadeService.loadCountries();
        } else {
          this.store.dispatch(new SelectCountryAction({selected: {}}));
        }
      };
      const _cascadeEffect = (args) => {
        this.uploadFileService.uploadGenericFile(this.file.nativeElement.files, args.data['saveCountry'].countryid, 0, 'COUNTRY')
          .subscribe(next => {
            if (next.type === 4) {
              args.data['saveCountry'].logo = this.logoSrc;
              _afterSave(args);
            }
          });
      };
      const VarUnique = {
        name: this.baseObject.name,
        objectId: isNullOrUndefined(this.baseObject.countryid) ? 0 : this.baseObject.countryid,
      };
      const _cascadeUnique = (args) => {
        if (!isNullOrUndefined(args)) {
          if (args.data['UniqueCountry'] === true) {
            this.facadeService.mutateObject(this.saveCountryGQL, variables, this.action === 'TRANSLATE' ? _afterSave : _cascadeEffect);
          } else {
            this.formGroup.get('name').setErrors({'labelunique': true});
          }
        }
      };
      this.facadeService.callQueryUnique(this.uniqueCountryGQL, VarUnique, _cascadeUnique);
    }
  }

  public headerViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.country.newCountry';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.country.editCountry';
      }
      default: {
        return 'administration.country.country';
      }
    }
  }

  public updateFileSource() {
    const size = ((<File>this.file.nativeElement.files[0]).size) / (1024 * 1024);
    const final = parseFloat(size + '').toFixed(2);
    if (Number(final) < 4) {
      this.validImage = true;
      this.messageimage = '';
      const reader = new FileReader();
      reader.onloadend = () => {
        this.logoSrc = reader.result;
      };
      reader.readAsDataURL((<File>this.file.nativeElement.files[0]));
    } else {
      this.validImage = false;
      this.messageimage = this.translate.instant('validationMessages.imageMustBeLessThan');
    }
  }
}
