import gql from 'graphql-tag';
import {SubstanceLang} from './substance-lang';
import {Validators } from '@angular/forms';
import {CustomValidate} from './custom-validate';

export class Substance {
  substanceid?: number;
  label?: string;
  status?: number;
  translations?: Array<SubstanceLang>;
}
export class SubstanceValidate {
  public static rules = {
    ['label']: [Validators.required, Validators.maxLength(60)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}
