/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {LinkKey} from '../models/enums/link-key.enum';

export function SUB_MENU_LIST_ORALIA_DB(): Array<SubMenu> {
  return [
    {
      translateKey: 'submenus.oraliadb.medicament',
      routerLink: LinkKey.MEDICAMENT
    },
    {
      translateKey: 'submenus.oraliadb.substances',
      routerLink: LinkKey.SUBSTANCES
    },
    {
      translateKey: 'submenus.oraliadb.sideEffects',
      routerLink: LinkKey.SIDE_EFFECTS
    },
    {
      translateKey: 'submenus.oraliadb.interactions',
      routerLink: LinkKey.INTERACTIONS
    },
    {
      translateKey: 'submenus.oraliadb.intakeNotes',
      routerLink: LinkKey.INTAKE_NOTES
    },
    {
      translateKey: 'submenus.oraliadb.otherNotes',
      routerLink: LinkKey.OTHER_NOTES
    },
    {
      translateKey: 'submenus.oraliadb.additionalPreparations',
      routerLink: LinkKey.ADDITIONAL_PREPARATIONS
    },
    {
      translateKey: 'submenus.oraliadb.history',
      routerLink: LinkKey.HISTORY
    }
  ];
}

export function SUB_MENU_LIST_PATIENTS(): Array<SubMenu> {
  return [
    {
      translateKey: 'submenus.patients.patients',
      routerLink: LinkKey.PATIENTS
    },
    {
      translateKey: 'submenus.patients.doctors',
      routerLink: LinkKey.DOCTORS
    },
    {
      translateKey: 'submenus.patients.evaluations',
      routerLink: LinkKey.EVALUATIONS
    }
  ];
}

export function SUB_MENU_LIST_ADMINISTRATION(): Array<SubMenu> {
  return [
    {
      translateKey: 'submenus.administration.countries',
      routerLink: LinkKey.COUNTRIES
    },
    {
      translateKey: 'submenus.administration.patientLanguages',
      routerLink: LinkKey.PATIENT_LANGUAGES
    },
    {
      translateKey: 'submenus.administration.users',
      routerLink: LinkKey.USERS
    },
    {
      translateKey: 'submenus.administration.registrations',
      routerLink: LinkKey.REGISTRATIONS
    },
    {
      translateKey: 'submenus.administration.text',
      routerLink: LinkKey.TEXT
    },
    {
      translateKey: 'submenus.administration.pdfText',
      routerLink: LinkKey.PDF_TEXT
    },
    {
      translateKey: 'submenus.administration.files',
      routerLink: LinkKey.FILES
    },
    {
      translateKey: 'submenus.administration.sponsor',
      routerLink: LinkKey.SPONSORS
    }
  ];
}

export function SUB_MENU_LIST_MASTER_DATA(): Array<SubMenu> {
  return [
    {
      translateKey: 'submenus.masterData.pharmacy',
      routerLink: LinkKey.PHARMACY
    },
    {
      translateKey: 'submenus.masterData.employees',
      routerLink: LinkKey.EMPLOYEES
    },
    {
      translateKey: 'submenus.masterData.userAccount',
      routerLink: LinkKey.USER_ACCOUNT
    }
  ];
}

export function HISTORY_TABLE_LIST(): Array<HistoryFilterModel> {
  return [
    {
      tableId: 1,
      name: 'submenus.oraliadb.medicament',
      selected: true
    },
    {
      tableId: 2, // 4
      name: 'submenus.patients.patients',
      selected: false
    },
    {
      tableId: 3,
      name: 'patient.patients.intakePlan',
      selected: false
    },
    {
      tableId: 4, // 2
      name: 'submenus.oraliadb.sideEffects',
      selected: false
    },
    {
      tableId: 5, // 2
      name: 'submenus.oraliadb.additionalPreparations',
      selected: false
    },
    {
      tableId: 6, // 2
      name: 'submenus.administration.users',
      selected: false
    }
  ];
}

export interface SubMenu {
  translateKey: string;
  routerLink: string;
  permission?: Permission;
}

export interface Permission {
  isAdmin: boolean;
}

export interface HistoryFilterModel {
  tableId: number;
  name: string;
  selected?: boolean;
}
