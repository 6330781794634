/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';
import {Interaction} from '../../models/interaction';
import {Substance} from '../../models/substance';

export enum SubstanceActions {
  LOAD_SUBSTANCES = '[SUBSTANCE] Load',
  SELECT_SUBSTANCE = '[SUBSTANCE] Change Selected',
  LOAD_SUBSTANCE_SUCCESS = '[SUBSTANCE] Load medicament success',
  LOAD_SUBSTANCE_FAILURE = '[SUBSTANCE] Load medicament failure',
  ADD_SUBSTANCE = '[SUBSTANCE] Add Substance',
}

export class LoadSubstanceAction implements Action {
  readonly type = SubstanceActions.LOAD_SUBSTANCES;
  constructor(public payload: PaginationData) {}
}

export class SelectSubstanceAction implements Action {
  readonly type = SubstanceActions.SELECT_SUBSTANCE;

  constructor(public payload: any) {
  }
}

export class LoadSubstanceSuccess implements Action {
  readonly type = SubstanceActions.LOAD_SUBSTANCE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadSubstanceFailure implements Action {
  readonly type = SubstanceActions.LOAD_SUBSTANCE_FAILURE;

  constructor(public payload: any) {
  }
}
export class AddSubstanceAction implements Action {
  readonly type = SubstanceActions.ADD_SUBSTANCE;

  constructor(public payload: Substance) {
  }
}

export type All =
  | LoadSubstanceAction
  | SelectSubstanceAction
  | LoadSubstanceSuccess
  | LoadSubstanceFailure
  | AddSubstanceAction;
