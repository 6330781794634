import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ShareService} from './share.service';
import {ToastrService} from 'ngx-toastr';

const composeRegex = /(\/enrollment)(\/users)(\/searchByText)/gi;
const countersRegex = /(\/countUnread)(\?)/gi;

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private shareService: ShareService,
              private _toasterService: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === '/graphql') {
      this.shareService.startLoading();
    }
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.shareService.stopLoading();
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.shareService.stopLoading();
        if (err.status === 401) {
          this._toasterService.error('unknown error', 'Something went wrong ....');
        } else if (err.status === 400) {
          this._toasterService.error('error',
            JSON.parse((<XMLHttpRequest>event.currentTarget).response).messages[0].message);
        } else if (err.status === 500) {
          this._toasterService.error('unknown error', 'Something went wrong ....');
        }
      }
    });
  }
}
