import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from './store/app.states';
import {Store} from '@ngrx/store';
import {LoadLanguagesAction, LoadUserLanguagesAction} from './store/actions/lang.actions';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {FacadeService} from './services/facade.service';
import {LoginFormState} from './models/enums/login-form-state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';

  constructor(private translate: TranslateService, private store: Store<AppState>,
              private facadeService: FacadeService, private authService: AuthService,
              private router: Router) {
    translate.addLangs(['en', 'de', 'ee', 'si', 'pl', 'ua']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    // translate.use((<any>browserLang).match(/de|fr/) ? browserLang : 'de');
    if (this.authService.getToken()) {
      if (!isNullOrUndefined(this.authService.user)) {
        this.router.navigate(['main'], {skipLocationChange: true});
      } else {
        this.router.navigate(['/'], {skipLocationChange: true});
      }
    }
  }

  ngOnInit() {
    // this.store.dispatch(new LoadLanguagesAction());
    this.store.dispatch(new LoadUserLanguagesAction());
    this.facadeService.loadCountries();
  }
  ngAfterViewInit() {
    const search = location.search.substring(1);
    if (search) {
      const params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      if (params['dc'] === '1' && params['dc_timestamp']) {
        this.facadeService.loginState(LoginFormState.REGISTRATION);
        this.facadeService.passDockCheck = true;
        this.router.navigate([], {queryParams: {page: null}, queryParamsHandling: 'merge'});
      }
    }
  }
}
