import {Validators} from '@angular/forms';

export class PatientNote {
  noteid?: number;
  date?: Date | any;
  note?: string;
  theme?: string;
  status?: number;
  pharmacyid?: number;
  patientid?: number;
  countryid?: number;
}

export class PatientNoteValidate {
  public static rules = {
    ['note']: [Validators.required],
    ['theme']: [Validators.required, Validators.maxLength(60)],
  };
}

