import {Component, OnInit} from '@angular/core';
import {AppState, selectSubstanceReducer} from '../../../store/app.states';
import {select, Store} from '@ngrx/store';
import {FormGroup} from '@angular/forms';
import {Substance, SubstanceValidate} from '../../../models/substance';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {SelectSubstanceAction} from '../../../store/actions/substance.actions';
import {SUB_MENU_LIST_ORALIA_DB} from '../../../config/sub-menu';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {SaveSubstanceGQL, UniqueSubstanceGQL} from '../../../api/graphql';
import {AuthService} from '../../../services/auth.service';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-substances',
  templateUrl: './substances.component.html',
  styleUrls: ['./substances.component.scss']
})
export class SubstancesComponent extends FormAbstract<Substance> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private saveSubstanceGQL: SaveSubstanceGQL, private uniqueSubstance: UniqueSubstanceGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'substanceid'},
      {field: 'label', validator: SubstanceValidate.rules.label},
      {field: 'status', validator: SubstanceValidate.rules.status, defaultValue: 1}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'substanceid',
      translationId: 'substancelangid'
    };

    if (this.bsRef.config.initialState['action']) {
      this.baseObject = new Substance();
      this.action = this.bsRef.config.initialState['action'];
    } else {
      this.store.pipe(select(selectSubstanceReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.originalObject = next.selected;
          this.languageId = this.authService.userLanguage.languageid;
          this.prepareForm();
          this.formGroup.patchValue({'status': this.baseObject.status});
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      const variables = {
        parentObj: this.baseObject,
        languageId: this.languageId,
        action: this.action
      };
      const _cascadeEffect = (args) => {
        this.languageId = this.authService.userLanguage.languageid;
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ORALIA_DB()[1]}));
        if (this.facadeService.filterArrayContains(args.data['saveSubstance'], ['label', 'status'], LinkKey.SUBSTANCES)) {
          this.store.dispatch(new SelectSubstanceAction({selected: args.data['saveSubstance']}));
          this.facadeService.setDetailHidden(false);
        }
      };
      const _cascadeUnique = (args) => {
        if (!isNullOrUndefined(args)) {
          if (args.data['UniqueSubstance'] === true) {
            this.facadeService.mutateObject(this.saveSubstanceGQL, variables, _cascadeEffect);
          } else {
            this.formGroup.get('label').setErrors({'labelunique': true});
          }
        }
      };
      const VarUnique = {
        label: this.baseObject.label,
        objectId: isNullOrUndefined(this.baseObject.substanceid) ? 0 : this.baseObject.substanceid,
      };
      if (this.languageId === 1 || this.action === ActionStateEnum.EDIT || this.action === ActionStateEnum.CREATE) {
        this.facadeService.callQueryUnique(this.uniqueSubstance, VarUnique, _cascadeUnique);
      } else {
        this.facadeService.mutateObject(this.saveSubstanceGQL, variables, _cascadeEffect);
      }
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.TRANSLATE: {
        return 'oraliadb.substance.translateSubstance';
      }
      case ActionStateEnum.CREATE: {
        return 'oraliadb.substance.newSubstance';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.substance.editSubstance';
      }
      default: {
        return 'oraliadb.substance.substance';
      }
    }
  }
}
