import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User, UserValidate} from '../../../models/user';
import {AppState, selectLanguageState} from '../../../store/app.states';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {FormGroup, Validators} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, flatMap} from 'rxjs/operators';
// @ts-ignore
import {SaveRegistrationGQL, UniqueEmailGQL, UserUniqueGQL} from '../../../api/graphql';
import {LoginFormState} from '../../../models/enums/login-form-state';
import {Country} from '../../../models/country';
import {UserLanguage} from '../../../models/user-language';
import {Pharmacy} from '../../../models/pharmacy';
import {isNullOrUndefined} from 'util';
import {UploadFileService} from '../../../services/upload-file.service';
import {ToastrService} from 'ngx-toastr';
import {Text} from '../../../models/text';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends FormAbstract<User> implements OnInit, OnDestroy {
  user: User;
  languageState: Observable<any>;
  loginFormState = LoginFormState;
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public countryList: Array<any>;
  public keyUp = new Subject<any>();
  public subscription: Subscription;
  public isUniqueEmail = true;
  public isPharmacy: number;
  public pharmacy: Pharmacy;
  public uploadedFiles: Array<File> = [];
  public country: any;
  public selectedCountryHasDocCheck: boolean;
  public salutationList: Array<any>;
  public contents: Array<string>;
  public selectedProffesionalGroup: string;
  public proffesionalGroupList: Array<any>;
  @ViewChild('file') file;
  @Input('documentInformation') documentInformation: Text;

  constructor(protected store: Store<AppState>, private uploadService: UploadFileService,
              protected bsRef: BsModalService, protected shareService: ShareService,
              private toastrService: ToastrService,
              public facadeService: FacadeService, protected authService: AuthService,
              private translate: TranslateService, private uniqueEmailGQL: UniqueEmailGQL,
              private saveRegistrationGQL: SaveRegistrationGQL, private userUniqueGQL: UserUniqueGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.languageState = this.store.pipe(select(selectLanguageState));
    this.action = ActionStateEnum.EDIT;
    this.subscription = new Subscription();
    this.contents = new Array<string>();
    this.baseFields = [
      {field: 'userid'},
      {field: 'pharmacyid'},
      {field: 'city'},
      {field: 'doccheck'},
      {field: 'email', validator: UserValidate.rules.email},
      {field: 'enddate'},
      {field: 'firstname', validator: UserValidate.rules.firstname},
      {field: 'issimpleuser', defaultValue: 0},
      {field: 'institutiontype', defaultValue: 1},
      {field: 'iscountryadmin', defaultValue: 0},
      {field: 'iscountryeditor', defaultValue: 0},
      {field: 'isemployee', defaultValue: 0},
      {field: 'ismainadmin', defaultValue: 0},
      {field: 'ismaineditor', defaultValue: 0},
      {field: 'ispharmacy', defaultValue: 0},
      {field: 'lastname', validator: UserValidate.rules.lastname},
      {field: 'name', validator: Validators.maxLength(60)},
      {field: 'password', validator: UserValidate.rules.password},
      {field: 'phone', validator: UserValidate.rules.phone},
      {field: 'salutation', defaultValue: null, validator: [Validators.required]},
      {field: 'startdate'},
      {field: 'street', validator: UserValidate.rules.street},
      {field: 'title', validator: UserValidate.rules.title},
      {field: 'zip', validator: UserValidate.rules.zip},
      {field: 'status', validator: [], defaultValue: 1},
      {field: 'country', validator: [Validators.required]},
      {field: 'userLanguage', validator: [Validators.required]},
      {field: 'professionalgroup', validator: [Validators.required]}
    ];
    this.formGroup = this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'patientid',
      translationId: null
    };
    this.baseObject = new User();
    this.prepareForm();
    this.formGroup.patchValue({'doccheck': 0});
    this.isPharmacy = 1;
    this.selectedCountryHasDocCheck = false;
    this.pharmacy = new Pharmacy();
    this.subscription = this.keyUp
      .map(event => event.target.value)
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(flatMap(search => this.searchUniqueEmails(search).then(args => args.data)))
      .subscribe((next) => {
        this.isUniqueEmail = next['UniqueEmail'] === true && this.formGroup.controls['email'].valid;
      });
  }

  ngOnInit() {
    this.baseObject.institutiontype = 1;
    this.salutationList = this.facadeService.getSalutation();
    this.proffesionalGroupList = this.facadeService.getProffesionalGroupList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public doSelect(value: any) {
    this.isPharmacy = value;
  }

  public onSelectCountry() {
    const country = {
      ...this.facadeService.availableCountries.find(
        c => c.countryid === this.formGroup.value['country'])
    } as Country;
    if ((country.doccheck === 0 || this.facadeService.passDockCheck === true)) {
      this.selectedCountryHasDocCheck = false;
      this.facadeService.forcePassDockCheck = true;
      this.facadeService.passDockCheck = false;
    } else {
      if (country.doccheck === 1 && this.facadeService.passDockCheck) {
        this.selectedCountryHasDocCheck = false;
        this.facadeService.passDockCheck = true;
        this.facadeService.forcePassDockCheck = false;
      } else {
        this.selectedCountryHasDocCheck = true;
        this.facadeService.passDockCheck = false;
        this.facadeService.forcePassDockCheck = false;
      }
    }
    this.country = country;
    this.salutationList = this.facadeService.getSalutation();
  }

  upload(userId: number, countryId: number, actionEnum) {
    this.uploadService.uploadFile(this.uploadedFiles, userId, countryId, actionEnum, this.contents).subscribe(next => {
      if (next.type === 4) {
        console.dir(next);
      }
    }, e => {
      console.dir(e);
    });
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    this.baseObject.status = 2;
    const country = {
      ...this.facadeService.availableCountries.find(
        c => c.countryid === this.formGroup.value['country'])
    } as Country;
    this.baseObject.country = {
      countryid: country.countryid,
      languageid: country.languageid,
      doccheck: country.doccheck,
      email: country.email,
      name: country.name,
      organisation: country.organisation,
      status: country.status
    };
    const userLanguage = {
      ...this.facadeService.activeUserLanguages.find(
        c => c.languageid === this.formGroup.value['userLanguage'])
    } as UserLanguage;
    this.baseObject.userLanguage = {
      languageid: userLanguage.languageid,
      language: userLanguage.language,
      shortname: userLanguage.shortname
    };
    this.baseObject.iscountryadmin = 0;
    this.baseObject.iscountryeditor = 0;
    this.baseObject.isemployee = 0;
    this.baseObject.ismainadmin = 0;
    this.baseObject.ismaineditor = 0;
    this.baseObject.ispharmacy = 0;
    this.baseObject.issimpleuser = 0;
    this.baseObject.freeofcharge = 0;
    const variables = {
      parentObj: this.baseObject,
      pharmacy: this.pharmacy,
      isPharmacy: this.isPharmacy,
      action: ActionStateEnum.CREATE
    };
    const _cascadeEffect = (args) => {
      this.toastrService.info(this.translate.instant('accountSuccessfullyCreated'));
      this.upload(args.data['saveRegistration'].userid,
        country.countryid, 'USER');
      this.formGroup.reset();
      this.facadeService.passDockCheck = false;
      this.facadeService.loginState(LoginFormState.LOGIN);
    };
    this.facadeService.mutateRegistration(this.saveRegistrationGQL, variables, _cascadeEffect);
  }

  public searchUniqueEmails(search: string) {
    if (!isNullOrUndefined(search) && this.formGroup.controls['email'].valid) {
      return this.uniqueEmailGQL.watch({email: search}).result();
    }
  }

  public checkUniqueUser(event: any) {
    if (event.target.value && this.formGroup.controls['email'].valid) {
      this.userUniqueGQL.watch(
        {email: event.target.value, userId: 0}).result().then(
        (data) => {
          if (data.data['UserUnique']) {
            this.formGroup.get('email').setErrors({'emailUnique': true});
          }
        }
      );
    }
  }

  public getAvailableCountries() {
    return [ {countryid: null, name: '', languageid: null}, ...this.facadeService.availableCountries];
  }

  public getAvailableLanguages() {
    return [ {languageid: null, language: '', shortname: ''}, ...this.facadeService.activeUserLanguages];
  }

  public addFile(files: any) {
    for (const one of files) {
      this.uploadedFiles.push(one);
      this.contents.push('');
    }
  }
  public get passDockCheck() {
    return this.facadeService.passDockCheck;
  }

  public get forcePassDockCheck() {
    return this.facadeService.forcePassDockCheck;
  }

  public updateFileSource(index, $event) {
    this.uploadedFiles[index] = $event[0];
  }

  public removeFile(index) {
    this.uploadedFiles.splice(index, 1);
    this.contents.splice(index, 1);
  }

  public isCountryDockCheck(id: number) {
    if (!isNullOrUndefined(id)) {
      if (this.facadeService.availableCountries.find(c => c.countryid === id).doccheck === 1) {
        return false;
      }
      return true;
    }
    return true;
  }

  public changeFormTo(state: LoginFormState) {
    this.facadeService.passDockCheck = false;
    this.facadeService.loginState(state);
  }

  public pharmacyInValid() {
    const fields = [
      {field: 'name', defaultValue: this.pharmacy.name, validator: [Validators.required, Validators.maxLength(60)]},
      {field: 'street', defaultValue: this.pharmacy.street, validator: [Validators.maxLength(60) ]},
      {field: 'zip', defaultValue: this.pharmacy.zip, validator: [Validators.required]},
      {field: 'city', defaultValue: this.pharmacy.city, validator: [Validators.required, Validators.maxLength(40)]},
      {field: 'mail', defaultValue: this.pharmacy.mail, validator: UserValidate.rules.email},
    ];
    const formGroup = this.facadeService.createFormWithValidators(fields);
    return formGroup.invalid;
  }
  public documentsInvalid() {
    const length = this.uploadedFiles.length;
    const cLength = this.contents.filter(t => {
      if (t.trim() !== '' && t.length >= 1) {
        return t;
      }
    }).length;
    return length > 0 && cLength !== length;
  }
  public changeSectionLanguage(args) {
    this.languageId = args.languageid;
  }
  public changeProffesionalGroupName() {
    if (this.selectedProffesionalGroup === 'other') {
      this.formGroup.patchValue({'professionalgroup': ''});
    }else{
      this.formGroup.patchValue({'professionalgroup': this.selectedProffesionalGroup});
    }
  }
}
