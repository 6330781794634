import { Injectable } from '@angular/core';
import { RolesAvailableActions } from '../models/roles/roles-available-actions';
import { AvailableActions } from '../models/roles/available-actions';
import { DefaultRoles } from '../models/roles/default-roles';
import { AuthService } from './auth.service';

@Injectable()
export class RolesService {
  private _rolesMapping: RolesAvailableActions;

  constructor(private authService: AuthService) {
    this._rolesMapping = {};
    this.buildOraliaModuleRoles();
    this.buildPatientsModuleRoles();
    this.buildAdministrationModuleRoles();
    this.buildMasterAdministration();
    this.buildMasterDataModuleRoles();
  }

  private buildOraliaModuleRoles() {
    this.buildMedicamentRoles();
    this.buildNotesRoles();
    this.buildMedBackgroundRoles();
    this.buildSubstancesRoles();
    this.buildSideEffectsRoles();
    this.buildInteractionRoles();
    // SAME AS INTERACTIONS
    this.buildIntakeNotesRoles();
    // SAME AS INTERACTIONS
    this.buildOtherNotesRoles();
    // SAME AS MEDICAMENT
    this.buildAdditionalPreparationsRoles();
    this.buildHistoryRoles();
  }

  /**
   *@PATIENTS
   * */
  private buildPatientsModuleRoles() {
    // MAIN VIEW
    this.buildMasterPatientsRoles();
    this.buildPatientsRoles();
    this.buildDoctorRoles();
    this.buildEvaluationRoles();
  }

  /**
   *@ADMINISTRATION
   * */
  private buildAdministrationModuleRoles() {
    this.buildPharmacyRoles();
    this.buildEmployeeRoles();
    this.buildUserAccountRoles();
  }

  /**
   * @MASTER_DATA
   * */
  private buildMasterDataModuleRoles() {
    // MAIN VIEW
    this.buildMasterDataRoles();
    this.buildCountryRoles();
    this.buildPatientLanguagesRoles();
    this.buildUserRoles();
    this.buildRegistrationRoles();
    this.buildTextRoles();
    this.buildPdfTextRoles();
    this.buildFilesRoles();
    this.buildSponsorRoles();
  }

  /**
   * @MEDICAMENT ROLES
   * */
  public buildMedicamentRoles() {
    this._rolesMapping.medicament = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.NEW]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.TRANSLATE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.DELETE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.FILTER]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true,
          [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.HISTORY]: {
        roles: {
          [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.COPY]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true }
      },
    };
  }

  /**
   * @NOTES ROLES
   * */
  public buildNotesRoles() {
    this._rolesMapping.notes = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true
        }
      },
      [AvailableActions.DELETE]: {
        roles: {
          [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true
        }
      }
    };
  }


  /**
   * @NOTES ROLES
   * */
  public buildMedBackgroundRoles() {
    this._rolesMapping.medBackgroud = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.NEW]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.TRANSLATE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.DELETE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
    };
  }

  /**
   * @SUBSTANCES ROLES
   * */
  public buildSubstancesRoles() {
    this._rolesMapping.substances = {
      [AvailableActions.VIEW]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true }
      },
      [AvailableActions.EDIT]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.NEW]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.TRANSLATE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      },
    };
  }

  /**
   * @SIDE-EFFECTS ROLES
   * */
  public buildSideEffectsRoles() {
    this._rolesMapping.sideEffects = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.NEW]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.TRANSLATE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      }
    };
  }

  /**
   * @INTERACTIONS ROLES
   * */
  public buildInteractionRoles() {
    this._rolesMapping.interactions = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.NEW]: {
        roles: { [DefaultRoles.MAIN_EDITOR]: true }
      },
      [AvailableActions.TRANSLATE]: {
        roles: { [DefaultRoles.COUNTRY_EDITOR]: true }
      }
    };
  }

  /**
   * @INTAKE-NOTES ROLES
   * */
  public buildIntakeNotesRoles() {
    this._rolesMapping.intakeNotes = { ...this._rolesMapping.interactions };
  }

  /**
   * @OTHER-NOTES ROLES
   * */
  public buildOtherNotesRoles() {
    this._rolesMapping.otherNotes = { ...this._rolesMapping.interactions };
  }

  /**
   * @ADDITIONAL-PREPARATIONS ROLES
   * */
  public buildAdditionalPreparationsRoles() {
    this._rolesMapping.additionalPreparations = { ...this._rolesMapping.medicament };
  }

  /**
   * @HISTORY ROLES
   * */
  public buildHistoryRoles() {
    this._rolesMapping.history = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      }
    };
  }

  /**
   * @PATIENTS ROLES
   */
  public buildPatientsRoles() {
    this._rolesMapping.patients = {
      ...this._rolesMapping.notes, [AvailableActions.EDIT]: {
        roles: {}
      }
    };
  }

  /**
   * @DOCTOR ROLES
   */
  public buildDoctorRoles() {
    this._rolesMapping.doctors = { ...this._rolesMapping.notes };
  }

  /**
   * @EVALUATION ROLES
   */
  public buildEvaluationRoles() {
    this._rolesMapping.evaluations = { ...this._rolesMapping.history };
  }

  /**
   * @PHARMACY ROLES
   */
  private buildPharmacyRoles() {
    this._rolesMapping.pharmacy = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true
        }
      }
    };
  }

  /**
   * @EMPLOYEE ROLES
   */
  private buildEmployeeRoles() {
    this._rolesMapping.employees = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.PHARMACY]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true
        }
      }
    };
  }

  /**
   * @USER ACCOUNT ROLES
   */
  private buildUserAccountRoles() {
    this._rolesMapping.userAccount = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      }
    };
  }

  /**
   * @COUNTRIES ROLES
   */
  public buildCountryRoles() {
    this._rolesMapping.countries = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      },
      [AvailableActions.TRANSLATE]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true,
          [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      }
    };
  }

  /**
   * @PATIENT_LANGUAGES ROLES
   */
  public buildPatientLanguagesRoles() {
    this._rolesMapping.patientLanguages = { ...this._rolesMapping.countries };
  }

  /**
   * @USERS ROLES
   */
  public buildUserRoles() {
    this._rolesMapping.users = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      }
    };
  }

  /**
   * @REGISTRATIONS ROLES
   */
  public buildRegistrationRoles() {
    this._rolesMapping.registrations = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true,
        }
      }
    };
  }

  /**
   * @TEXT ROLES
   */
  public buildTextRoles() {
    this._rolesMapping.text = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.TRANSLATE]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      }
    };
  }

  /**
   * @PDF_TEXT ROLES
   */
  public buildPdfTextRoles() {
    this._rolesMapping.pdfText = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      },
      [AvailableActions.TRANSLATE]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true
        }
      }
    };
  }

  /**
   * @FILES ROLES
   */
  public buildFilesRoles() {
    this._rolesMapping.files = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.TRANSLATE]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      }
    };
  }

  /**
   * @FILES ROLES
   */
  public buildSponsorRoles() {
    this._rolesMapping.sponsors = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.NEW]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      },
      [AvailableActions.TRANSLATE]: {
        roles: {
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true
        }
      }
    };
  }

  public hasAccessTo(masterField: string, action: AvailableActions) {
    let access = false;
    if (this._rolesMapping[masterField][action]) {
      for (const key in (this._rolesMapping[masterField])[action].roles) {
        if (this.authService.user[key] === (this._rolesMapping[masterField])[action].roles[key]) {
          access = true;
          break;
        }
      }
    }
    return access;
  }


  private buildMasterDataRoles() {
    this._rolesMapping.masterData = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      },
      [AvailableActions.EDIT]: {
        roles: {
          [DefaultRoles.SIMPLE_USER]: true, [DefaultRoles.PHARMACY]: true,
          [DefaultRoles.EMPLOYEE]: true, [DefaultRoles.MAIN_ADMIN]: true,
          [DefaultRoles.COUNTRY_ADMIN]: true, [DefaultRoles.MAIN_EDITOR]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      }
    };
  }

  private buildMasterPatientsRoles() {
    this._rolesMapping.masterPatients = {
      [AvailableActions.VIEW]: {
        roles: {
          [DefaultRoles.PHARMACY]: true, [DefaultRoles.EMPLOYEE]: true,
          [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_EDITOR]: true,
        }
      }
    };
  }

  private buildMasterAdministration() {
    this._rolesMapping.masterAdministration = {
      [AvailableActions.VIEW]: {
        roles: { [DefaultRoles.MAIN_ADMIN]: true, [DefaultRoles.COUNTRY_ADMIN]: true }
      }
    };
  }
}
