import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
// @ts-ignore
import {Observable} from 'rxjs/Observable';
import {User} from '../../models/user';
import {AppState, selectAuthState, selectLanguageState, selectLoginState} from '../../store/app.states';
import {LogIn} from '../../store/actions/auth.actions';
import {LoginFormState} from '../../models/enums/login-form-state';
import {ChangeLoginAction} from '../../store/actions/login.actions';
import {TextLoginGQL} from '../../api/graphql';
import {Subscription} from 'rxjs';
import {FacadeService} from '../../services/facade.service';
import {Text} from '../../models/text';
import {TextOperationEnum} from '../../models/enums/text-operation.enum';
import {DownloadService} from '../../services/download.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
})
export class LogInComponent implements OnInit, OnDestroy {
  loginFormState = LoginFormState;
  user: User = new User();
  authState: Observable<any>;
  subscription: Subscription;
  languageState: Observable<any>;
  loginState: Observable<any>;
  loginPageInformation: Text;
  documentInformation: Text;
  systemNameHeader: Text;
  countryLanguageId: number;

  constructor(private store: Store<AppState>,
              private textLoginGQL: TextLoginGQL,
              public facadeService: FacadeService, private downloadService: DownloadService) {
    this.authState = this.store.pipe(select(selectAuthState));
    this.languageState = this.store.pipe(select(selectLanguageState));
    this.subscription = this.languageState.subscribe(res => {
      this.countryLanguageId = res.selectedLanguage.languageid;
    });
    this.loginPageInformation = {};
    this.documentInformation = {};
    this.systemNameHeader = {};
  }

  ngOnInit() {
    this.textLoginGQL.watch(
    ).result().then((data: any) => {
      const textList: Array<Text> = data.data['TextLogin'];
      // this.loginPageInformation = data.data['TextLogin'];
      this.loginPageInformation = textList.find(t => t.textid === TextOperationEnum.LOGIN_PAGE_INFORMATION);
      this.documentInformation = textList.find(t => t.textid === TextOperationEnum.DOCUMENT_INFORMATION);
      this.systemNameHeader = textList.find(t => t.textid === TextOperationEnum.SYSTEM_NAME_HEADER);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public downloadDocument()  {
    this.downloadService.downloadFileWithFileID(1, this.countryLanguageId);
  }

  public onSubmit(): void {
    const payload = {
      email: this.user.email,
      password: this.user.password
    };
    this.store.dispatch(new LogIn(payload));
  }

  public changeSectionLanguage(args) {
    this.countryLanguageId = args.languageid;
  }

  public changeFormTo(state: LoginFormState) {
    this.facadeService.passDockCheck = false;
    this.facadeService.loginState(state);
  }

  public get loginLogo() {
    if (this.facadeService.availableCountries) {
      const one = this.facadeService.availableCountries.find(c => c.languageid === this.countryLanguageId);
      if (one) {
        return one.logo ? ('data:image/png;base64,' + one.logo) : 'assets/images/logos/dgop.gif';
      }
    }
    return 'assets/images/logos/dgop.gif';
  }
  public removeHTML(html) {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, '\n');
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  }
}
