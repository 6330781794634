import gql from 'graphql-tag';
import {IntakeNoteLang} from './intake-note-lang';
import {CustomValidate} from './custom-validate';
import {Validators} from '@angular/forms';

export class IntakeNote {
  intakenoteid?: number;
  intakenote?: string;
  label?: string;
  status?: number; // smallint(6) NOT NULL,
  translations?: Array<IntakeNoteLang>;
}
export  class IntakeNoteValidate {
  public static rules = {
    ['intakenote']: [Validators.required],
    ['label']: [Validators.required, Validators.maxLength(50)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}
export function gql_MED_INTAKE_NOTE_LIST() {
  return gql`
  query IntakeNotes($label: String, $status: [Int]){
    IntakeNotes(label: $label, status: $status){
      label
      intakenoteid
      translations {
        intakenotelangid
        intakenoteid
        label
        languageid
      }
    }
  }
  `;
}
export function gql_INTAKENOTE_LIST() {
  return gql`
  query IntakeNotes($label: String, $status: [Int]){
    IntakeNotes(label: $label, status: $status){
        intakenoteid
        intakenote
        label
        status
        translations {
          intakenotelangid
          intakenoteid
          label
          intakenote
          languageid
      }
    }
  }`;
}

export function gql_INSERT_INTAKENOTE($intakenote: IntakeNote) {
  let translations = `
    translations: []
  `;
  if ($intakenote.translations) {
    let transItems = '';
    $intakenote.translations.forEach(t => {
      transItems +=
        `{
          intakenoteid: ${t.intakenoteid || null},
          intakenotelangid: ${t.intakenotelangid || null}
          languageid: ${t.languageid},
          intakenote: "${t.intakenote}",
          label: "${t.label}"
        },
         `;
    });
    translations = `
    translations: [
      ${transItems}
    ]`;
  }
  return gql`
    mutation saveIntakeNote {
      saveIntakeNote(
      intakeNote: {
        intakenoteid: ${$intakenote.intakenoteid || null},
        intakenote: "${$intakenote.intakenote.trim()}",
        label: "${$intakenote.label.trim()}",
        status: ${$intakenote.status},
        ${translations}
      }) {
        intakenoteid
        intakenote
        label
        status
        translations {
          intakenotelangid
          intakenoteid
          intakenote
          label
          languageid
        }
      }
    }`;
}
