import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum DoctorActions {
  LOAD_DOCTOR = '[Doctor] LOAD_DOCTOR',
  LOAD_DOCTOR_BY_ID = '[Doctor] LOAD_DOCTOR_BY_ID',
  SELECT_DOCTOR = '[Doctor] SELECT_DOCTOR'
}

export class LoadDoctorListAction implements Action {
  readonly type = DoctorActions.LOAD_DOCTOR;

  constructor(public payload: PaginationData) {
  }
}

export class LoadDoctorByIdAction implements Action {
  readonly type = DoctorActions.LOAD_DOCTOR_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectDoctorAction implements Action {
  readonly type = DoctorActions.SELECT_DOCTOR;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadDoctorListAction
  | LoadDoctorByIdAction
  | SelectDoctorAction;
