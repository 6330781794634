import {Component, OnInit} from '@angular/core';
import {SponsorsGQL, TextsGQL} from '../../../../api/graphql';
import {FacadeService} from '../../../../services/facade.service';
import {AuthService} from '../../../../services/auth.service';
import {LinkKey} from '../../../../models/enums/link-key.enum';
import {Sponsor} from '../../../../models/sponsor';
import {Text} from '../../../../models/text';
import {chunk} from 'lodash';
@Component({
  selector: 'app-sponsor-view',
  templateUrl: './sponsor-view.component.html',
  styleUrls: ['./sponsor-view.component.scss']
})
export class SponsorViewComponent implements OnInit {

  public sponsors: Array<Array<Sponsor>>;
  public sponsorB: Array<Sponsor>;
  public textList: Array<Text>;
  public headerText: Text;
  constructor(private sponsorsGQL: SponsorsGQL, private facadeService: FacadeService,
              private authService: AuthService, private textsGQL: TextsGQL) {
    this.sponsors = [];
    this.textList = [];
  }

  ngOnInit() {
    const _cascadeEffect = (args) => {
      const sponsors = args.data['Sponsors'].filter(t => {
        if (this.calculateDates(t)) {
          return t;
        }
      });
      this.sponsorB = [...sponsors];
      this.sponsors  = chunk(sponsors, 2);
    };
    this.facadeService.callQueryUnique(this.sponsorsGQL, {
      ...this.facadeService.filterData[LinkKey.SPONSORS],
      countryId: this.authService.user.country.countryid
    }, _cascadeEffect);
    this.chargeDefaultListOfAuxiliaryData();
  }

  public chargeDefaultListOfAuxiliaryData() {
    this.textsGQL.watch({label: ''}).result().then((next: any) => {
      this.textList = next.data['Texts'];
      this.headerText = this.textList.find(t => t.textid === 18);
    });
  }

  public openLink(htmlLink) {
    const prefix = 'http://';
    const prefixSecure = 'https://';
    if (htmlLink.substr(0, prefix.length) !== prefix || htmlLink.substr(0, prefix.length) !== prefixSecure) {
      htmlLink = prefix + htmlLink;
    }
    window.open(htmlLink, '_blank');
  }
  public calculateDates(item: Sponsor) {
    const now = new Date();
    return new Date(item.datestart) <= now && now <= new Date(item.dateend);
  }
}
