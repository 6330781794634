import {All, SubMenuActions} from '../actions/sub-menu.actions';
import {SUB_MENU_LIST_ORALIA_DB, SubMenu} from '../../config/sub-menu';
import {LinkKey} from '../../models/enums/link-key.enum';

/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
export interface SubMenuState {
  selected: SubMenu;
  list: Array<any>;
}

export const initialState: SubMenuState = {
  selected: {
    translateKey: 'submenus.oraliadb.medicament',
    routerLink: LinkKey.MEDICAMENT
  },
  list: [...SUB_MENU_LIST_ORALIA_DB()]
};

export function reducer(state = initialState, action: All): SubMenuState {
  switch (action.type) {
    case SubMenuActions.CHANGE: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    case SubMenuActions.CHANGE_MODULE: {
      return {
        ...state,
        selected: action.payload.selected,
        list: [...action.payload.list]
      };
    }
    default: {
      return state;
    }
  }
}
