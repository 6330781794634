import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {ErrorsService} from '../errors-service/errors.service';


@Injectable()
export class ErrorsHandler implements ErrorHandler {

  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorsService = this.injector.get(ErrorsService);
    const toastService  = this.injector.get(ToastrService);

    if ( error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        console.log('Error is here offline');
      }

      errorsService.log(error).subscribe();
      if (environment.showErrorMessage) {
        console.log(`${error.status} - ${error.message}`);
        toastService.error(error.message, error.status.toString());
      }



    } else {

      errorsService
        .log(error)
        .subscribe(errorWithContextInfo => {
          if (environment.showErrorMessage ) {
            console.dir(errorWithContextInfo);
            console.dir(`${errorWithContextInfo.status} - ${errorWithContextInfo.message}`);
            // toastService.error(errorWithContextInfo.message, errorWithContextInfo.status);
          }
        });
    }
  }
}
