import {Component, OnInit} from '@angular/core';
import {AppState, selectSideEffectReducer} from '../../../store/app.states';
import {select, Store} from '@ngrx/store';
import {SideEffect, SideEffectValidate} from '../../../models/side-effect';
import {SelectSideEffectAction} from '../../../store/actions/side-effect.actions';
import {ShareService} from '../../../services/share.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {FacadeService} from '../../../services/facade.service';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {SUB_MENU_LIST_ORALIA_DB} from '../../../config/sub-menu';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {SaveSideEffectGQL, UniqueSideEffectGQL} from '../../../api/graphql';
import {AuthService} from '../../../services/auth.service';
import {ModalPrintComponent} from '../modal-print/modal-print.component';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {isNullOrUndefined} from 'util';
import {SelectOtherNotesAction} from "../../../store/actions/other-notes.actions";

@Component({
  selector: 'app-side-effects',
  templateUrl: './side-effects.component.html',
  styleUrls: ['./side-effects.component.scss']
})
export class SideEffectsComponent extends FormAbstract<SideEffect> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public isShowContent: boolean;

  public sideEffectLevels: number[];
  public modalRef: BsModalRef;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private saveSideEffectGQL: SaveSideEffectGQL, private uniqueSideEffectGQL: UniqueSideEffectGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    // Side Effect Levels
    this.sideEffectLevels = [1, 2, 3, 4, 5];
    // Regular Abstract
    this.isShowContent = false;
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'sideeffectid'},
      {field: 'doctorfromlevel'},
      {field: 'sideeffect'},
      {field: 'level1', validator: SideEffectValidate.rules.level1},
      {field: 'level2', validator: SideEffectValidate.rules.level2},
      {field: 'level3', validator: SideEffectValidate.rules.level3},
      {field: 'level4', validator: SideEffectValidate.rules.level4},
      {field: 'level5', validator: SideEffectValidate.rules.level5},
      {field: 'literature'},
      {field: 'measures'},
      {field: 'medicalterm', validator: SideEffectValidate.rules.medicalterm},
      {field: 'riskfactors'},
      {field: 'sideeffect'},
      {field: 'symptoms'},
      {field: 'label', validator: SideEffectValidate.rules.label},
      {field: 'status', validator: SideEffectValidate.rules.status, defaultValue: 1}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'sideeffectid',
      translationId: 'sideeffectlangid'
    };
    this.languageId = this.authService.userLanguage.languageid;
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
    } else {
      this.store.pipe(select(selectSideEffectReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          if (this.baseObject.sideeffectid) {
            this.isShowContent = true;
          }
          this.prepareForm();
          this.formGroup.patchValue({'status': this.baseObject.status});
        }
      });
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public pdfReportModal() {
    this.modalRef = this.bsRef.show(ModalPrintComponent, {
      class: 'modal-lg',
      initialState: {
        object: 'sideEffect',
        objectId: this.baseObject.sideeffectid,
        title: this.baseObject.label,
        selectedLanguageId: this.authService.user.userLanguage.languageid
      },
      ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public onSubmit() {
    if (this.formGroup.valid) {
      this.baseObject = this.formGroup.value;
      const variables = {
        parentObj: this.baseObject,
        languageId: this.languageId,
        action: this.action
      };
      const _cascadeUnique = (args) => {
        if (!isNullOrUndefined(args)) {
          if (args.data['UniqueSideEffect'] === true) {
            this.facadeService.mutateObject(this.saveSideEffectGQL, variables, _cascadeEffect);
          } else {
            this.formGroup.get('label').setErrors({'labelunique': true});
          }
        }
      };
      const _cascadeEffect = (args) => {
        this.languageId = this.authService.userLanguage.languageid;
        this.closeModal();
        this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ORALIA_DB()[2]}));
        if (this.facadeService.filterArrayContains(args.data['saveSideEffect'], ['label', 'status'], LinkKey.SIDE_EFFECTS)) {
          this.store.dispatch(new SelectSideEffectAction({selected: args.data['saveSideEffect']}));
          this.facadeService.setDetailHidden(false);
        } else {
          this.store.dispatch(new SelectSideEffectAction({selected: {}}));
        }
      };
      const VarUnique = {
        label: this.baseObject.label,
        objectId: isNullOrUndefined(this.baseObject.sideeffectid) ? 0 : this.baseObject.sideeffectid,
      };
      if (this.languageId === 1 || this.action === ActionStateEnum.EDIT || this.action === ActionStateEnum.CREATE) {
        this.facadeService.callQueryUnique(this.uniqueSideEffectGQL, VarUnique, _cascadeUnique);
      } else {
        this.facadeService.mutateObject(this.saveSideEffectGQL, variables, _cascadeEffect);
      }
    } else {
      this.validateAllFormFields(this.formGroup);
    }
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.TRANSLATE: {
        return 'oraliadb.sideEffects.translateSideEffect';
      }
      case ActionStateEnum.CREATE: {
        return 'oraliadb.sideEffects.newSideEffect';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.sideEffects.editSideEffect';
      }
      default: {
        return 'oraliadb.sideEffects.sideEffect';
      }
    }
  }

  public updateDoctorFromLevel(args) {
    this.formGroup.patchValue({'doctorfromlevel': args});
  }

  public updateBelow(item, $event) {
    if ($event === '' || isNullOrUndefined($event)) {
      let i = item + 1;
      for (i; i <= 5; i++) {
        const key = 'level' + i;
        this.formGroup.patchValue({[key]: ''});
      }
    }
  }

  public hasValueLevel(item) {
    if (item > 1) {
      const numberVal = item - 1;
      return !isNullOrUndefined(this.formGroup.get('level' + numberVal).value) && this.formGroup.get('level' + numberVal).value !== '';
    }
    return true;
  }

  public hasEnabledLevel(item) {
    if (item > 0) {
      return !isNullOrUndefined(this.formGroup.get('level' + item).value) && this.formGroup.get('level' + item).value !== '';
    }
    return true;
  }

  public get sideLevels() {
    const list = [0];
    for (const one of [1, 2, 3, 4, 5]) {
      if (!isNullOrUndefined(this.formGroup.get('level' + one).value) && this.formGroup.get('level' + one).value !== '') {
        list.push(one);
      }
    }
    return list;
  }
}
