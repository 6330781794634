import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';

@Directive({
  selector: '[actionVisibility]'
})
export class ActionVisibilityDirective {
  @Input('actionVisibility')
  set actionVisibility(args: ActionVisible | any) {
    if (args.canView.indexOf(args.action) >= 0) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }
}

export interface ActionVisible {
  action: ActionStateEnum;
  canView: ActionStateEnum[];
}
