import {ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type} from '@angular/core';
import {LinkKey} from '../models/enums/link-key.enum';
import {BehaviorSubject, Observable} from 'rxjs';
import {MedicamentsComponent} from '../modules/oralia-db/medicaments/medicaments.component';
import {SubstancesComponent} from '../modules/oralia-db/substances/substances.component';
import {EmployeesComponent} from '../modules/oralia-db/employees/employees.component';
import {PharmaciesComponent} from '../modules/oralia-db/pharmacies/pharmacies.component';
import {TextComponent} from '../modules/oralia-db/text/text.component';
import {UsersComponent} from '../modules/oralia-db/users/users.component';
import {PatientLanguagesComponent} from '../modules/oralia-db/patient-languages/patient-languages.component';
import {CountriesComponent} from '../modules/oralia-db/countries/countries.component';
import {DoctorsComponent} from '../modules/oralia-db/doctors/doctors.component';
import {PatientsComponent} from '../modules/oralia-db/patients/patients.component';
import {HistoryComponent} from '../modules/oralia-db/history/history.component';
import {AdditionalPreparationsComponent} from '../modules/oralia-db/additional-preparations/additional-preparations.component';
import {OtherNotesComponent} from '../modules/oralia-db/other-notes/other-notes.component';
import {IntakeNotesComponent} from '../modules/oralia-db/intake-notes/intake-notes.component';
import {InteractionsComponent} from '../modules/oralia-db/interactions/interactions.component';
import {SideEffectsComponent} from '../modules/oralia-db/side-effects/side-effects.component';
import {RegistrationsComponent} from '../modules/oralia-db/registrations/registrations.component';
import {EvaluationsComponent} from '../modules/oralia-db/evaluations/evaluations.component';
import {UserAccountComponent} from '../modules/oralia-db/user-account/user-account.component';
import {FilesComponent} from '../modules/oralia-db/files/files.component';
import {SponsorComponent} from "../modules/oralia-db/sponsor/sponsor.component";
import { PdfTextComponent } from '../modules/oralia-db/pdf-text/pdf-text.component';

@Injectable()
export class InjectorService {
  private _displayableComponent: BehaviorSubject<ComponentFactory<any>>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this._displayableComponent = new BehaviorSubject(null);
  }

  public getDisplayableComponent(): Observable<ComponentFactory<any>> {
    return this._displayableComponent.asObservable();
  }

  private setDisplayableComponent(next: ComponentFactory<any>) {
    this._displayableComponent.next(next);
  }

  private injectComponentInViewDetail(componentType: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.setDisplayableComponent(componentFactory);
  }

  public removeComponentInViewDetail() {
    this._displayableComponent.next(null);
  }

  public getComponentByLinkKey(link: LinkKey) {
    switch (link) {
      // Oralia Database
      case LinkKey.MEDICAMENT: {
        this.injectComponentInViewDetail(MedicamentsComponent);
        break;
      }
      case LinkKey.SUBSTANCES: {
        this.injectComponentInViewDetail(SubstancesComponent);
        break;
      }
      case LinkKey.SIDE_EFFECTS: {
        this.injectComponentInViewDetail(SideEffectsComponent);
        break;
      }
      case LinkKey.INTERACTIONS: {
        this.injectComponentInViewDetail(InteractionsComponent);
        break;
      }
      case LinkKey.INTAKE_NOTES: {
        this.injectComponentInViewDetail(IntakeNotesComponent);
        break;
      }
      case LinkKey.OTHER_NOTES: {
        this.injectComponentInViewDetail(OtherNotesComponent);
        break;
      }
      case LinkKey.ADDITIONAL_PREPARATIONS: {
        this.injectComponentInViewDetail(AdditionalPreparationsComponent);
        break;
      }
      case LinkKey.HISTORY: {
        this.injectComponentInViewDetail(HistoryComponent);
        break;
      }
      // Patients
      case LinkKey.PATIENTS: {
        this.injectComponentInViewDetail(PatientsComponent);
        break;
      }
      case LinkKey.DOCTORS: {
        this.injectComponentInViewDetail(DoctorsComponent);
        break;
      }
      case LinkKey.EVALUATIONS: {
        this.injectComponentInViewDetail(EvaluationsComponent);
        break;
      }
      // Administration
      case LinkKey.COUNTRIES: {
        this.injectComponentInViewDetail(CountriesComponent);
        break;
      }
      case LinkKey.PATIENT_LANGUAGES: {
        this.injectComponentInViewDetail(PatientLanguagesComponent);
        break;
      }
      case LinkKey.USERS: {
        this.injectComponentInViewDetail(UsersComponent);
        break;
      }
      case LinkKey.REGISTRATIONS: {
        this.injectComponentInViewDetail(RegistrationsComponent);
        break;
      }
      case LinkKey.TEXT: {
        this.injectComponentInViewDetail(TextComponent);
        break;
      }
      case LinkKey.PDF_TEXT: {
        this.injectComponentInViewDetail(PdfTextComponent);
        break;
      }
      case LinkKey.FILES: {
        this.injectComponentInViewDetail(FilesComponent);
        break;
      }
      case LinkKey.SPONSORS: {
        this.injectComponentInViewDetail(SponsorComponent);
        break;
      }
      // Master Data
      case LinkKey.PHARMACY: {
        this.injectComponentInViewDetail(PharmaciesComponent);
        break;
      }
      case LinkKey.EMPLOYEES: {
        this.injectComponentInViewDetail(EmployeesComponent);
        break;
      }
      case LinkKey.USER_ACCOUNT: {
        this.injectComponentInViewDetail(UserAccountComponent);
        break;
      }
    }
  }
}

