import {SideEffect} from './side-effect';

export class MedSideEffect {
  medeffectid?: number;
  countryid?: number;
  medicamentid?: number;
  sideeffectid?: number;
  type?: number;
  sideEffect?: SideEffect;
}
