export const apexLocales = {
  defaultLocale: 'en',
  locales: [{
    name: 'en',
    options: {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      }
    },
  },
  {
    name: 'de',
    options: {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      }
    },
  },
  {
    name: 'ee',
    options: {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      }
    },
  },
  {
    name: 'si',
    options: {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      }
    },
  },
    {
      name: 'pl',
      options: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        toolbar: {
          download: 'Download SVG',
          selection: 'Selection',
          selectionZoom: 'Selection Zoom',
          zoomIn: 'Zoom In',
          zoomOut: 'Zoom Out',
          pan: 'Panning',
          reset: 'Reset Zoom',
        }
      },
    }]
};
