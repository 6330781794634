import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dock-check',
  templateUrl: './dock-check.component.html',
  styleUrls: ['./dock-check.component.scss']
})
export class DockCheckComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
