import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'app-reject',
  templateUrl: './reject.component.html',
  styleUrls: ['./reject.component.scss']
})
export class RejectComponent implements OnInit {
  public reason: string;

  constructor(public bsRef: BsModalService) {
    this.reason = '';
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.bsRef.config.initialState['callback'](this.reason);
  }

  public closeModal() {
    this.bsRef.hide(1);
  }
}
