import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum PharmacyActions {
  LOAD_PHARMACY = '[Pharmacy] LOAD_PHARMACY',
  LOAD_PHARMACY_BY_ID = '[Pharmacy] LOAD_PHARMACY_BY_ID',
  SELECT_PHARMACY = '[Pharmacy] SELECT_PHARMACY'
}

export class LoadPharmacyListAction implements Action {
  readonly type = PharmacyActions.LOAD_PHARMACY;

  constructor(public payload: PaginationData) {
  }
}

export class LoadPharmacyByIdAction implements Action {
  readonly type = PharmacyActions.LOAD_PHARMACY_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectPharmacyAction implements Action {
  readonly type = PharmacyActions.SELECT_PHARMACY;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadPharmacyListAction
  | LoadPharmacyByIdAction
  | SelectPharmacyAction;
