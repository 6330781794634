import {Component, OnInit} from '@angular/core';
import {User} from '../../../models/user';
// @ts-ignore
import {ResetPasswordGQL} from '../../../api/graphql';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ChangeLoginAction} from '../../../store/actions/login.actions';
import {LoginFormState} from '../../../models/enums/login-form-state';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.states';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgotten',
  templateUrl: './forgotten.component.html',
  styleUrls: ['./forgotten.component.scss']
})
export class ForgottenComponent implements OnInit {
  loginFormState = LoginFormState;
  user: User;
  formGroup: FormGroup;

  constructor(private resetPassword: ResetPasswordGQL,  private store: Store<AppState>,
              private toastrService: ToastrService,  private translate: TranslateService) {
    this.formGroup = new FormGroup({
      email: new FormControl(null, [Validators.minLength(1), Validators.required, Validators.email])
    });
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.resetPassword.watch({email: this.formGroup.value['email']}).result().then(next => {
      if (next.data['resetPassword'] === true) {
        this.formGroup.reset();
        const payload = {
          loginFormState: this.loginFormState.LOGIN
        };
        this.store.dispatch(new ChangeLoginAction(payload));
        this.toastrService.info(this.translate.instant('yourPasswordHasBeenSentToYourEmail'));
      } else {
        this.toastrService.info(this.translate.instant('accountInactiveOrAccountNotExistInTheSystemOralia'));
      }
    });
  }
}
