/**
 * @author Jonathan Almanza
 * 30/11/2018
 */
export enum DefaultRoles {
  PHARMACY = 'ispharmacy',
  EMPLOYEE = 'isemployee',
  MAIN_ADMIN = 'ismainadmin',
  COUNTRY_ADMIN = 'iscountryadmin',
  MAIN_EDITOR = 'ismaineditor',
  COUNTRY_EDITOR = 'iscountryeditor',
  SIMPLE_USER = 'issimpleuser'
}
