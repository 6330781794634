import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {
  AddPreparationActions, LoadAddByIdAction,
  SelectAddPreparationsAction
} from '../actions/add-preparation.actions';
import {gql_ADD_PREPARATION, gql_ADD_PREPARATION_BY_ID} from '../../models/add-preparation';
import {SelectInteractionAction} from '../actions/interaction.actions';

/**
 * @author Alavi
 * 02/12/2018
 */
@Injectable()
export class AddPreparationsEffect {

  constructor(
    private actions: Actions,
    private router: Router,
    private apollo: Apollo
  ) {
  }

  @Effect()
  LoadAddPreparationDetail: Observable<any> = this.actions.pipe(
    ofType(AddPreparationActions.LOAD_ADDPREPARATION_DETAIL),
    map((action: SelectAddPreparationsAction) => action.payload),
    switchMap(payload => {
      return this.apollo
        .watchQuery({
          query: gql_ADD_PREPARATION(payload.selected['addpreparationid'])
        })
        .valueChanges
        .pipe(
          map(fetch => {
            return new SelectInteractionAction({
              selected: fetch.data['AddPreparation']
            });
          }));
    })
  );

  @Effect()
  AddById: Observable<any> = this.actions.pipe(
    ofType(AddPreparationActions.LOAD_ADD_BY_ID),
    map((action: LoadAddByIdAction) => action.payload),
    switchMap(payload => {
      return this.apollo
        .watchQuery({
          query: gql_ADD_PREPARATION_BY_ID(),
          variables: {
            id: payload['id']
          },
          fetchPolicy: 'network-only'
        })
        .valueChanges
        .pipe(
          map(fetch => {
              return new SelectAddPreparationsAction({selected: fetch.data['AddPreparation']});
            }
          ));
    }));
}
