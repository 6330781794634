import {PatientDoctor} from './patient-doctor';
import {Validators} from '@angular/forms';
import {CustomValidate} from './custom-validate';
import {IntakePlan} from './intake-plan';
import {PatientNote} from './patient-note';
import {PatientDocSide} from './patient-doc-side';
import {PatientDoc} from './patient-doc';

export class Patient {
  patientid?: number;
  acceptance?: number;
  patientno?: string;
  email?: string;
  sex?: number;
  languageid?: string;
  birthyear?: number;
  status?: number;
  disease?: string;
  phone?: string;
  doctorid?: string;
  pharmacyid?: number;
  countryid?: number;
  notes?: Array<PatientNote>;
  patientDoctors?: Array<PatientDoctor>;
  intakePlans?: Array<IntakePlan>;
  docs?: Array<PatientDoc>;
  isSelected?: boolean;
}

export class PatientValidate {
  public static rules = {
    ['languageid']: [Validators.required],
    ['countryid']: [Validators.required],
    ['pharmacyid']: [Validators.required],
    ['acceptance']: [CustomValidate.onlyNumber],
    ['birthyear']: [CustomValidate.numeric, CustomValidate.birthyearLength],
    ['email']: [Validators.email, Validators.maxLength(100)],
    ['patientno']: [Validators.required, Validators.maxLength(60)],
    ['phone']: [Validators.maxLength(60)],
    ['sex']: [CustomValidate.onlyNumber, Validators.required],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}
