import {All, PatientLanguagesActions} from '../actions/patient-language.actions';
import {PatientLanguage} from '../../models/patient-language';

/**
 * @author Jonathan Almanza
 * 02/12/2018
 */
export interface PatientLanguagesState {
  selected: PatientLanguage;
}

export const initialState: PatientLanguagesState = {
  selected: null
};

export function reducer(state = initialState, action: All): PatientLanguagesState {
  switch (action.type) {
    case PatientLanguagesActions.LOAD_PATIENT_LANGUAGES_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case PatientLanguagesActions.SELECT_PATIENT_LANGUAGES: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
