/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';

export enum InteractionActions {
  LOAD_INTERACTION = '[INTERACTION] LOAD_INTERACTION',
  SELECT_INTERACTION = '[INTERACTION] SELECT_INTERACTION',
  LOAD_INTERACTION_SUCCESS = '[INTERACTION] LOAD_INTERACTION_SUCCESS',
  LOAD_INTERACTION_FAILURE = '[INTERACTION] LOAD_INTERACTION_FAILURE'
}

export class LoadInteractionAction implements Action {
  readonly type = InteractionActions.LOAD_INTERACTION;
  constructor(public payload: PaginationData) {}
}

export class SelectInteractionAction implements Action {
  readonly type = InteractionActions.SELECT_INTERACTION;

  constructor(public payload: any) {
  }
}
export class LoadInteractionSuccess implements Action {
  readonly type = InteractionActions.LOAD_INTERACTION_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadInteractionFailure implements Action {
  readonly type = InteractionActions.LOAD_INTERACTION_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadInteractionAction
  | LoadInteractionSuccess
  | LoadInteractionFailure
  | SelectInteractionAction;
