import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum SponsorActions {
  LOAD_SPONSOR = '[Sponsor] LOAD_SPONSOR',
  LOAD_SPONSOR_BY_ID = '[Sponsor] LOAD_SPONSOR_BY_ID',
  SELECT_SPONSOR = '[Sponsor] SELECT_SPONSOR'
}

export class LoadSponsorsListAction implements Action {
  readonly type = SponsorActions.LOAD_SPONSOR;

  constructor(public payload: PaginationData) {
  }
}

export class LoadSponsorByIdAction implements Action {
  readonly type = SponsorActions.LOAD_SPONSOR_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectSponsorAction implements Action {
  readonly type = SponsorActions.SELECT_SPONSOR;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadSponsorsListAction
  | LoadSponsorByIdAction
  | SelectSponsorAction;
