import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {select, State, Store} from '@ngrx/store';
import {AppState, selectSubMenusReducer} from '../../../store/app.states';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Apollo} from 'apollo-angular';
import {IntakeNotesComponent} from '../../oralia-db/intake-notes/intake-notes.component';
import {SubstancesComponent} from '../../oralia-db/substances/substances.component';
import {SideEffectsComponent} from '../../oralia-db/side-effects/side-effects.component';
import {OtherNotesComponent} from '../../oralia-db/other-notes/other-notes.component';
import {AdditionalPreparationsComponent} from '../../oralia-db/additional-preparations/additional-preparations.component';
import {InteractionsComponent} from '../../oralia-db/interactions/interactions.component';
import {FacadeService} from '../../../services/facade.service';
import {AuthService} from '../../../services/auth.service';
import {BasicDataEditComponent} from '../../oralia-db/medicaments/modals/basic-data-edit/basic-data-edit.component';
import {
  AddPreparationsGQL,
  CountriesGQL,
  DoctorsGQL,
  EmployeesGQL, FilesGQL,
  HistoriesGQL,
  IntakeNotesGQL,
  InteractionsGQL,
  MedicamentsGQL,
  OtherNotesGQL,
  PatientLanguagesGQL,
  PatientsGQL,
  PdfTextsGQL,
  PharmaciesGQL, RegistrationsGQL,
  SideEffectsGQL, SponsorsGQL,
  SubstancesGQL,
  TextsGQL,
  UsersGQL
} from '../../../api/graphql';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {ModuleKey} from '../../../models/enums/module-key.enum';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {PatientsComponent} from '../../oralia-db/patients/patients.component';
import {DoctorsComponent} from '../../oralia-db/doctors/doctors.component';
import {CountriesComponent} from '../../oralia-db/countries/countries.component';
import {PatientLanguagesComponent} from '../../oralia-db/patient-languages/patient-languages.component';
import {UsersComponent} from '../../oralia-db/users/users.component';
import {TextComponent} from '../../oralia-db/text/text.component';
import {PharmaciesComponent} from '../../oralia-db/pharmacies/pharmacies.component';
import {EmployeesComponent} from '../../oralia-db/employees/employees.component';
import {HISTORY_TABLE_LIST, HistoryFilterModel} from '../../../config/sub-menu';
import {isNullOrUndefined} from 'util';
import {ToastrService} from 'ngx-toastr';
import {SponsorComponent} from '../../oralia-db/sponsor/sponsor.component';
import { PdfTextComponent } from '../../oralia-db/pdf-text/pdf-text.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public listOfTable: Array<HistoryFilterModel>;
  public filterData: {
    label?: string,
    manufactor?: string,
    status?: any,
    patientno?: string
    tableId?: number,
    label2?: string
  };
  public manufactor: { name: string };
  public selectedRouterLink: string;
  public selectedModule: string;
  public modalRef: BsModalRef;
  public LinkKey = LinkKey;

  constructor(private store: Store<AppState>,
              private modalService: BsModalService, private apollo: Apollo,
              public facadeService: FacadeService, private authService: AuthService,
              private medicamentGQL: MedicamentsGQL, private otherNoteGQL: OtherNotesGQL,
              private intakeNoteGQL: IntakeNotesGQL, private addPreparationGQL: AddPreparationsGQL,
              private interactionGQL: InteractionsGQL, private sideEffectGQL: SideEffectsGQL,
              private substanceGQL: SubstancesGQL, private historiesGQL: HistoriesGQL,
              private patientsGQL: PatientsGQL, private doctorsGQL: DoctorsGQL, private registrationsGQL: RegistrationsGQL,
              private countriesGQL: CountriesGQL, private patientLanguagesGQL: PatientLanguagesGQL,
              private usersGQL: UsersGQL, private textsGQL: TextsGQL, private pdfTextsGQL: PdfTextsGQL, private pharmaciesGQL: PharmaciesGQL,
              private filesGQL: FilesGQL, private sponsorsGQL: SponsorsGQL,
              private employeesGQL: EmployeesGQL, private toastrService: ToastrService) {
    this.listOfTable = HISTORY_TABLE_LIST();
    this.manufactor = {name: ''};
    this.filterData = {
      label: '',
      manufactor: '',
      status: 1,
      patientno: '',
      tableId: 1,
      label2: ''
    };
  }

  ngOnInit() {
    this.facadeService.loadManufacturers();
    this.store.pipe(select(selectSubMenusReducer)).subscribe((state: any) => {
      this.selectedRouterLink = state.selected.routerLink;
      this.selectedModule = state.selected.translateKey.split('.')[1];
      if (this.selectedRouterLink === LinkKey.MEDICAMENT) {
        this.filterData = {...this.facadeService.filterData[this.selectedRouterLink].filterBy};
      } else {
        this.filterData = {...this.facadeService.filterData[this.selectedRouterLink]};
      }
    });
  }

  onSubmit() {
    this.filterData.status = this.filterData.status !== <any>'null' ? parseInt(this.filterData.status, 0) : 'null';
    // FilterBuilder
    if (this.selectedRouterLink === LinkKey.SUBSTANCES || this.selectedRouterLink === LinkKey.SIDE_EFFECTS) {
      this.facadeService.filterData[this.selectedRouterLink] = {
        label: this.filterData.label,
        status: [0, 1]
      };
    } else if ([LinkKey.INTERACTIONS, LinkKey.INTAKE_NOTES, LinkKey.OTHER_NOTES, LinkKey.ADDITIONAL_PREPARATIONS]
      .indexOf(this.selectedRouterLink as LinkKey) >= 0) {
      this.facadeService.filterData[this.selectedRouterLink] = {
        label: this.filterData.label,
        status: this.filterData.status !== <any>'null' ? [this.filterData.status] : [0, 1]
      };
    }
    switch (this.selectedModule) {
      case ModuleKey.ORALIA_DB: {
        // Facade query updater
        switch (this.selectedRouterLink) {
          case LinkKey.MEDICAMENT: {
            this.facadeService.filterData[this.selectedRouterLink] = {
              filterBy: {
                label: this.filterData.label,
                manufactor: this.filterData.manufactor,
                status: this.filterData.status !== <any>'null' ? [this.filterData.status] : [0, 1],
                countryid: this.authService.user.country.countryid,
                label2: this.filterData.label2
              }
            };
            this.facadeService.callQueryListToUpdate(this.medicamentGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.SUBSTANCES: {
            this.facadeService.callQueryListToUpdate(this.substanceGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.SIDE_EFFECTS: {
            const user = this.authService.user;
            if ((user.isemployee || user.ispharmacy) &&
              (!user.iscountryadmin || !user.iscountryeditor || !user.ismainadmin || !user.ismaineditor)) {
              this.facadeService.filterData[LinkKey.SIDE_EFFECTS].status = [1];
            }
            this.facadeService.callQueryListToUpdate(this.sideEffectGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.INTERACTIONS: {
            this.facadeService.callQueryListToUpdate(this.interactionGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.INTAKE_NOTES: {
            this.facadeService.callQueryListToUpdate(this.intakeNoteGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.OTHER_NOTES: {
            this.facadeService.callQueryListToUpdate(this.otherNoteGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.ADDITIONAL_PREPARATIONS: {
            this.facadeService.callQueryListToUpdate(this.addPreparationGQL, {
              ...this.facadeService.filterData[this.selectedRouterLink],
              countryId: this.authService.user.country.countryid
            });
            break;
          }
          case LinkKey.HISTORY: {
            this.facadeService.filterData[this.selectedRouterLink] = {
              label: this.filterData.label,
              tableId: this.filterData.tableId
            };
            this.facadeService.callQueryListToUpdate(this.historiesGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
        }
        break;
      }
      case ModuleKey.PATIENTS: {
        // Facade query updater
        switch (this.selectedRouterLink) {
          case LinkKey.PATIENTS: {
            this.facadeService.callQueryListToUpdate(this.patientsGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.DOCTORS: {
            this.facadeService.callQueryListToUpdate(this.doctorsGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.EVALUATIONS: {
            // this.facadeService.callQueryListToUpdate(this.sideEffectGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
        }
        break;
      }
      case ModuleKey.ADMINISTRATION: {
        switch (this.selectedRouterLink) {
          case LinkKey.COUNTRIES: {
            this.facadeService.callQueryListToUpdate(this.countriesGQL, this.facadeService.filterData[LinkKey.COUNTRIES]);
            break;
          }
          case LinkKey.PATIENT_LANGUAGES: {
            this.facadeService.callQueryListToUpdate(this.patientLanguagesGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.USERS: {
            this.facadeService.callQueryListToUpdate(this.usersGQL, {
              ...this.facadeService.filterData[LinkKey.USERS],
              userId: this.authService.user.userid,
              countryId: this.authService.user.country.countryid
            });
            break;
          }
          case LinkKey.REGISTRATIONS: {
            this.facadeService.callQueryListToUpdate(this.registrationsGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.TEXT: {
            this.facadeService.callQueryListToUpdate(this.textsGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.PDF_TEXT: {
            this.facadeService.callQueryListToUpdate(this.pdfTextsGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.FILES: {
            this.facadeService.callQueryListToUpdate(this.filesGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
          case LinkKey.SPONSORS: {
            this.facadeService.callQueryListToUpdate(this.sponsorsGQL,
              {
                ...this.facadeService.filterData[this.selectedRouterLink],
                countryId: this.authService.user.country.countryid
              });
            break;
          }
        }
        break;
      }
      case ModuleKey.MASTER_DATA: {
        switch (this.selectedRouterLink) {
          case LinkKey.PHARMACY: {
            this.facadeService.filterData[this.selectedRouterLink] = {
              name: this.filterData.label,
              userId: this.authService.user.userid
            };
            this.facadeService.callQueryListToUpdate(this.pharmaciesGQL, this.facadeService.filterData[LinkKey.PHARMACY]);
            break;
          }
          case LinkKey.EMPLOYEES: {
            this.facadeService.filterData[this.selectedRouterLink] = {
              name: this.filterData.label,
              userId: this.authService.user.userid
            };
            this.facadeService.callQueryListToUpdate(this.employeesGQL, this.facadeService.filterData[LinkKey.EMPLOYEES]);
            break;
          }
          case LinkKey.USER_ACCOUNT: {
            // this.facadeService.callQueryListToUpdate(this.sideEffectGQL, this.facadeService.filterData[this.selectedRouterLink]);
            break;
          }
        }
        break;
      }
    }
  }

  public setManufacturer($event) {
    this.filterData.manufactor = $event.name;
  }

  public setTable($event) {
    this.filterData.tableId = $event.tableId;
  }

  public newMedicament() {
    this.modalRef = this.modalService.show(BasicDataEditComponent, {
      class: 'modal-lg',
      initialState: {
        action: ActionStateEnum.CREATE
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newInteraction() {
    this.modalRef = this.modalService.show(InteractionsComponent, {
      class: 'modal-lg',
      initialState: {
        action: ActionStateEnum.CREATE
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
    // this.modalRef.content.closeEvent.subscribe(value => this.closeModal(value));
  }

  public newSubstance() {
    this.modalRef = this.modalService.show(SubstancesComponent, {
      class: 'modal-lg',
      initialState: {
        action: ActionStateEnum.CREATE
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newSideEffect() {
    this.modalRef = this.modalService.show(SideEffectsComponent, {
      class: 'modal-lg',
      initialState: {
        action: ActionStateEnum.CREATE
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newIntakeNote() {
    this.modalRef = this.modalService.show(IntakeNotesComponent, {
      class: 'modal-lg',
      initialState: {
        action: ActionStateEnum.CREATE
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public newOtherNotes() {
    this.modalRef = this.modalService.show(OtherNotesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
    this.modalRef.content.isModal = true;
  }

  public newAddPreparation() {
    this.modalRef = this.modalService.show(AdditionalPreparationsComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
    this.modalRef.content.isModal = true;
  }

  public newPatient() {
    if (!isNullOrUndefined(this.authService.user.pharmacyid)) {
      this.modalRef = this.modalService.show(PatientsComponent, {
        class: 'modal-lg',
        ignoreBackdropClick: true,
        initialState: {
          isCreation: true,
          action: ActionStateEnum.CREATE
        }
      });
      this.modalRef.content.isModal = true;
    } else {
      this.toastrService.info('You do not have a pharmacy, contact the administrator. ');
    }
  }

  public newDoctor() {
    if (!isNullOrUndefined(this.authService.user.pharmacyid)) {
      this.modalRef = this.modalService.show(DoctorsComponent, {
        class: 'modal-lg',
        ignoreBackdropClick: true,
        initialState: {
          action: ActionStateEnum.CREATE
        }
      });
      this.modalRef.content.isModal = true;
    } else {
      this.toastrService.info('You do not have a pharmacy, contact the administrator. ');
    }
  }

  public newCountry() {
    this.modalRef = this.modalService.show(CountriesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
    this.modalRef.content.isModal = true;
  }

  public newPatientLanguage() {
    this.modalRef = this.modalService.show(PatientLanguagesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newUser() {
    this.modalRef = this.modalService.show(UsersComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newText() {
    this.modalRef = this.modalService.show(TextComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newPdfText() {
    this.modalRef = this.modalService.show(PdfTextComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newPharmacy() {
    this.modalRef = this.modalService.show(PharmaciesComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newEmployee() {
    this.modalRef = this.modalService.show(EmployeesComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public newSponsor() {
    this.modalRef = this.modalService.show(SponsorComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      initialState: {
        action: ActionStateEnum.CREATE
      }
    });
  }

  public closeModal(close: boolean): void {
    if (close && this.modalRef) {
      this.modalRef.hide();
      if (this.modalRef.content.closeEvent) {
        this.modalRef.content.closeEvent.unsubscribe();
      }
    }
  }
}
