import {Validators} from '@angular/forms';

export class PatientLanguage {
  languageid?: number;
  flag?: any;
  language?: string;
  status?: string;
}

export  class PatientLanguageValidate {
  public static rules = {
    ['language']: [Validators.required, Validators.maxLength(60)],
    ['flag']: [Validators.required]
  };
}
