import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {HistoryActions, LoadHistoryAction, SelectHistoryAction} from '../actions/history.actions';
import {HistoryGQL} from '../../api/graphql';

@Injectable()
export class HistoryEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private apollo: Apollo,
    private historyGQL: HistoryGQL
  ) {
  }

  @Effect()
  HistoryByObjected: Observable<any> = this.actions.pipe(
    ofType(HistoryActions.LOAD_HISTORY_BY_ID),
    map((action: LoadHistoryAction) => action.payload),
    switchMap(payload => {
      return this.historyGQL.watch(payload.variables)
        .valueChanges
        .pipe(
          map(fetch => {
              return new SelectHistoryAction({selected: payload.selected, list: fetch.data['History']});
            }
          ));
    }));
}
