import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CommonActions, LoadDetailByIdAction} from '../actions/common.actions';

@Injectable()
export class LoadDetailEffects {

  constructor(
    private actions: Actions,
  ) {
  }

  @Effect()
  LoadDetailById: Observable<any> = this.actions.pipe(
    ofType(CommonActions.LOAD_DETAIL_BY_ID),
    map((action: LoadDetailByIdAction) => action.payload),
    switchMap(payload => {
        return payload.query.watch(payload.variables)
          .result().then(fetch => {
              return new payload.action({selected: fetch.data[payload.object]});
            }
          );
      }
    ));
}
