import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, selectMedicamentReducer } from '../../../store/app.states';
import { select, Store } from '@ngrx/store';
import { gql_MEDICAMENT_FRAGMENT, Medicament } from '../../../models/medicament';
import { BasicDataEditComponent } from './modals/basic-data-edit/basic-data-edit.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NoteComponent } from './modals/note/note.component';
import { StrengthComponent } from './modals/strength/strength.component';
import { LeafletComponent } from './modals/leaflet/leaflet.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MedCheckBoxComponent } from './modals/side-effect/med-check-box.component';
import { MedNote } from '../../../models/med-note';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { LoadMedByIdAction, SelectMedicamentAction } from '../../../store/actions/medicament.actions';
import { gql_MED_SIDE_EFFECT_LIST, SideEffect } from '../../../models/side-effect';
import { gql_MED_INTERACTION_LIST, Interaction } from '../../../models/interaction';
import { gql_MED_INTAKE_NOTE_LIST, IntakeNote } from '../../../models/intake-note';
import { gql_MED_OTHER_NOTE_LIST, OtherNote } from '../../../models/other-note';
import { MedCheckBoxEnum } from '../../../models/enums/med-check-box-enum';
import { AuthService } from '../../../services/auth.service';
import { MedStrength } from '../../../models/med-strength';
import { RolesService } from '../../../services/roles.service';
import { ActionStateEnum } from '../../../models/enums/actions-state.enum';
import { isNullOrUndefined } from 'util';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { FacadeService } from '../../../services/facade.service';
import 'rxjs/add/operator/map';
import { Subscription } from 'rxjs';
import { ShareService } from '../../../services/share.service';
import { MedSideEffect } from '../../../models/med-side-effect';
import { MedInteraction } from '../../../models/med-interaction';
import { MedIntakeNote } from '../../../models/med-intake-note';
import { MedOtherNote } from '../../../models/med-other-note';
import { ModalPrintComponent } from '../modal-print/modal-print.component';
import { BackgroundComponent } from './modals/background/background.component';
import { DomSanitizer } from '@angular/platform-browser';
import { IntakeNotesByIdGQL, InteractionsByIdGQL, OtherNotesByIdGQL, SideEffectsByIdGQL } from 'src/app/api/graphql';
import { CopyMedicamentComponent } from './modals/copy-medicament/copy-medicament.component';

@Component({
  selector: 'app-medicaments',
  templateUrl: './medicaments.component.html',
  styleUrls: ['./medicaments.component.scss']
})
export class MedicamentsComponent implements OnInit, OnDestroy {
  public medicamentData: Medicament;
  public formGroup: FormGroup;
  modalRef: BsModalRef;
  public isShowContent: boolean;
  public sideEffectsList: Array<SideEffect>;
  public interactionsList: Array<Interaction>;
  public intakeNotesList: Array<IntakeNote>;
  public otherNotesList: Array<OtherNote>;
  public subscription: Subscription;
  public detailSubscription: Subscription;
  public countryLanguageId: number;
  public actionEnum: ActionStateEnum;
  public editors: string;
  public modals = {
    'backgroundComponent': BackgroundComponent,
    'basicDataEditComponent': BasicDataEditComponent,
    'noteComponent': NoteComponent,
    'leafletComponent': LeafletComponent,
    'strengthComponent': StrengthComponent,
    'copyMedicamentComponent': CopyMedicamentComponent,
  };

  constructor(private store: Store<AppState>, private bsRef: BsModalService,
    private formBuilder: FormBuilder, private apollo: Apollo,
    private authService: AuthService, private rolesService: RolesService,
    private coolDialogs: NgxCoolDialogsService, private facadeService: FacadeService,
    private shareService: ShareService, public sanitizer: DomSanitizer,
    private sideEffectsByIdGQL: SideEffectsByIdGQL, private interactionsByIdGQL: InteractionsByIdGQL,
    private intakeNotesByIdGQL: IntakeNotesByIdGQL, private otherNotesByIdGQL: OtherNotesByIdGQL) {
    this.subscription = new Subscription();
    this.detailSubscription = new Subscription();
    this.actionEnum = ActionStateEnum.VIEW;
    this.formGroup = this.formBuilder.group({
      shortname: [''], label: [''], status: [''], datemedicalinfo: [''],
      substance: this.formBuilder.group({
        label: ['']
      }), editors: [''], manufactor: [''],
      link1: [''], link2: [''], link3: [''],
      link1text: [''], link2text: [''], link3text: [''],
      sideeffects: [''], interaction: [''], intakelong: [''], intakeshort: [''],
    });
    this.isShowContent = false;
    if (this.bsRef.config.initialState['action'] === ActionStateEnum.MODAL) {
      this.actionEnum = ActionStateEnum.MODAL;
      const med = this.bsRef.config.initialState['data'];
      this.store.dispatch(new LoadMedByIdAction({ id: med['medicamentid'] }));
    }
  }

  ngOnInit() {
    this.countryLanguageId = this.authService.user.userLanguage.languageid;
    this.detailSubscription = this.store.pipe(select(selectMedicamentReducer)).subscribe(next => {
      this.sideEffectsList = [];
      this.medicamentData = (<any>next).selected;
      if (next['selected']) {
        if (isNullOrUndefined(this.medicamentData.substance)) {
          this.medicamentData.substance = {
            label: '',
            substanceid: null,
            status: 0,
            translations: []
          };
        }
        this.getListByNameAndIds(this.medicamentData.medSideEffects.map((s: MedSideEffect) => s.sideeffectid), 'sideEffects', { notIn: false });
        this.getListByNameAndIds(this.medicamentData.medInteractions.map((s: MedInteraction) => s.interactionid), 'interactions');
        this.getListByNameAndIds(this.medicamentData.medIntakeNotes.map((s: MedIntakeNote) => s.intakenoteid), 'intakeNotes');
        this.getListByNameAndIds(this.medicamentData.medOtherNotes.map((s: MedOtherNote) => s.othernoteid), 'otherNotes');
        this.formGroup.patchValue(<any>this.medicamentData);
        this.getEditorsList(this.medicamentData.editors);
      }
    });
  }

  public getListByNameAndIds(ids: Array<number>, name: string, extraArgs: any = null) {
    if (ids.length > 0) {
      // name+GQL
      const upperName = name.charAt(0).toUpperCase() + name.slice(1);
      this[`${name}ByIdGQL`].watch({ ids: ids, ...extraArgs }).result().then((next: any) => {
        // name+List
        this[`${name}List`] = next.data[`${upperName}ById`];
      });
    } else {
      this[`${name}List`] = [];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.detailSubscription.unsubscribe();
  }

  public pdfReport() {
    this.modalRef = this.bsRef.show(ModalPrintComponent, {
      class: 'modal-lg',
      initialState: {
        object: 'medicament',
        objectId: this.medicamentData.medicamentid,
        sideEffects: this.sideEffectsList,
        title: this.medicamentData.label,
        selectedLanguageId: this.authService.user.country.languageid,
        checkedSideEffects: this.sideEffectsList.map(s => s.sideeffectid)
      },
      ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public nl2br(str) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '' + '$2');
  }

  public processOperation(op) {
    switch (op) {
      case 1: {
        return 'history.operation.created';
      }
      case 2: {
        return 'history.operation.changed';
      }
      case 3: {
        return 'history.operation.deleted';
      }
      case 4: {
        return 'history.operation.checked';
      }
      case 5: {
        return 'history.operation.printed';
      }
    }
  }

  public openModal(component: string, action: any) {
    this.modalRef = this.bsRef.show(this.modals[component], {
      class: 'modal-lg', initialState: { data: this.medicamentData, action: action }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public closeModal(close: boolean): void {
    if (close && this.modalRef) {
      this.modalRef.hide();
      if (this.modalRef.content.closeEvent) {
        this.modalRef.content.closeEvent.unsubscribe();
      }
    }
  }

  public sideEffect(args: string) {
    switch (args) {
      case 'sideEffects': {
        this.openSelectionModal(gql_MED_SIDE_EFFECT_LIST(),
          'label', 'medSideEffects', 'sideeffectid', 'SideEffects', MedCheckBoxEnum.SIDE_EFFECTS);
        break;
      }
      case 'interactions': {
        this.openSelectionModal(gql_MED_INTERACTION_LIST(),
          'label', 'medInteractions', 'interactionid', 'Interactions', MedCheckBoxEnum.INTERACTIONS);
        break;
      }
      case 'intakeNotes': {
        this.openSelectionModal(gql_MED_INTAKE_NOTE_LIST(),
          'label', 'medIntakeNotes', 'intakenoteid', 'IntakeNotes', MedCheckBoxEnum.INTAKE_NOTES);
        break;
      }
      case 'otherNotes': {
        this.openSelectionModal(gql_MED_OTHER_NOTE_LIST(),
          'label', 'medOtherNotes', 'othernoteid', 'OtherNotes', MedCheckBoxEnum.OTHER_NOTES);
        break;
      }
    }
    this.modalRef.content.isModal = true;
  }

  private openSelectionModal(query: any, displayLabel: string, relatedList: string,
    relatedId: string, queryList: string, medEnum: MedCheckBoxEnum) {
    this.modalRef = this.bsRef.show(MedCheckBoxComponent, {
      class: 'modal-lg',
      initialState: {
        data: this.medicamentData,
        isCreation: true,
        query: query,
        displayLabel: displayLabel,
        relatedList: relatedList,
        relatedId: relatedId,
        queryList: queryList,
        medEnum: medEnum
      }, ignoreBackdropClick: true
    });
  }

  public editNote(item) {
    this.modalRef = this.bsRef.show(NoteComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...item,
          countryid: this.medicamentData.countryid
        },
        action: ActionStateEnum.EDIT
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public editStrength(item) {
    this.modalRef = this.bsRef.show(StrengthComponent, {
      class: 'modal-lg',
      initialState: {
        data: {
          ...item,
          countryid: this.medicamentData.countryid
        },
        isCreation: false
      }, ignoreBackdropClick: true
    });
    this.modalRef.content.isModal = true;
  }

  public deleteNote(args: MedNote) {
    // Confirm
    const _cascadeEffect = () => {
      this.subscription = this.apollo.mutate({
        mutation: gql`
          mutation deleteMedNote($medNoteId: Int!, $medicamentId: Int!) {
            deleteMedNote(medNoteId: $medNoteId, medicamentId: $medicamentId) {
              ${gql_MEDICAMENT_FRAGMENT()}
            }
          }`,
        variables: {
          medNoteId: args.mednoteid,
          medicamentId: args.medicamentid
        }
      }).subscribe((next: any) => {
        this.store.dispatch(new SelectMedicamentAction({ selected: next.data['deleteMedNote'] }));
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public deleteMedStrength(args: MedStrength) {
    const _cascadeEffect = () => {
      this.subscription = this.apollo.mutate<any>({
        mutation: gql`
          mutation deleteMedStrength($medStrengthId: Int!, $medicamentId: Int!) {
            deleteMedStrength(medStrengthId: $medStrengthId, medicamentId: $medicamentId) {
              ${gql_MEDICAMENT_FRAGMENT()}
            }
          }`,
        variables: {
          medStrengthId: args.medstrengthid,
          medicamentId: args.medicamentid
        }
      }).subscribe((next: any) => {
        this.store.dispatch(new SelectMedicamentAction({ selected: next.data['deleteMedStrength'] }));
      });
    };
    this.shareService.confirmDelete(_cascadeEffect, this.coolDialogs);
  }

  public canEdit(args) {
    return this.rolesService.hasAccessTo(args.obj, args.access);
  }

  public getTextByTextId(textId: number) {
    return this.shareService.getTextByTextId(textId);
  }

  public openLink(htmlLink) {
    const prefix = 'http://';
    const prefixSecure = 'https://';
    if (htmlLink.substr(0, prefix.length) !== prefix || htmlLink.substr(0, prefix.length) !== prefixSecure) {
      htmlLink = prefix + htmlLink;
    }
    window.open(htmlLink, '_blank');
  }

  public forceCloseModal() {
    this.bsRef.hide(1);
  }
  public getEditorsList(ids) {
    this.editors = '';
    if (ids) {
      const _callback = (next) => {
        this.editors = next.join(', ');
      };
      this.facadeService.loadUserListByIds(ids, _callback);
    }
  }

  copyMedicament(){

  }
}
