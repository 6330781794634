import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ShareService} from '../../../../../services/share.service';
import {FacadeService} from '../../../../../services/facade.service';
import {AppState} from '../../../../../store/app.states';
import {BsModalService} from 'ngx-bootstrap';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {LoadMedByIdAction} from '../../../../../store/actions/medicament.actions';
import {MedSideEffect} from '../../../../../models/med-side-effect';
import {Subscription} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {AuthService} from '../../../../../services/auth.service';
import {FormGroup} from '@angular/forms';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {SaveMedCheckBoxGQL} from '../../../../../api/graphql';
import {MedCheckBoxEnum} from '../../../../../models/enums/med-check-box-enum';

@Component({
  selector: 'app-side-effect',
  templateUrl: './med-check-box.component.html',
  styleUrls: ['./med-check-box.component.scss']
})
export class MedCheckBoxComponent extends FormAbstract<any> implements OnInit {
  public objectList: any;
  private querySubscription: Subscription;
  public checked: number [] = [];
  public initialState: any;

  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(private apollo: Apollo, protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private medCheckBox: SaveMedCheckBoxGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.baseId = {
      parentId: 'medsideeffectid',
      translationId: null
    };
  }

  ngOnInit() {
    if (this.bsRef.config.initialState['isCreation']) {
      this.action = this.bsRef.config.initialState['isCreation'] ? ActionStateEnum.CREATE : ActionStateEnum.EDIT;
      this.initialState = this.bsRef.config.initialState;
      this.baseObject = new MedSideEffect();
      if (this.bsRef.config.initialState['data'][this.initialState['relatedList']].length > 0) {
        (<Array<any>>(this.bsRef.config.initialState['data'])[this.initialState['relatedList']]).forEach(item => {
          this.checked.push(item[this.initialState['relatedId']]);
        });
      }
      this.baseObject.medicamentid = this.bsRef.config.initialState['data'].medicamentid;
      this.baseObject.countryid = this.bsRef.config.initialState['data'].countryid;
    }
    this.apollo.watchQuery<any>({
      query: this.initialState['query'],
      variables: {
        label: '',
        status: [1],
        languageId: this.authService.userLanguage.languageid,
      },
      fetchPolicy: 'network-only'
    }).result().then((data) => {
      this.objectList = data.data[this.initialState['queryList']];
    });
  }

  public updateChecked(selectableItem, $event) {
    const index = this.checked.indexOf(selectableItem[this.initialState['relatedId']]);
    if ($event.target.checked) {
      if (index === -1) {
        this.checked.push(selectableItem[this.initialState['relatedId']]);
      }
    } else {
      if (index !== -1) {
        this.checked.splice(index, 1);
      }
    }
  }

  public onSubmit() {
    const dataSend = {
      medicamentId: this.baseObject.medicamentid,
      idList: this.checked,
      countryId: this.authService.user.country.countryid,
      type: 1,
      medEnum: MedCheckBoxEnum[this.initialState['medEnum']]
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new LoadMedByIdAction({id: args.data['saveMedCheckBox'].medicamentid}));
    };
    this.facadeService.mutateObject(this.medCheckBox, dataSend, _cascadeEffect);
  }
}
