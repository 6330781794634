import {Action} from '@ngrx/store';
import {PaginationData} from '../../models/request/pagination-data';


export enum CountryActions {
  LOAD_COUNTRY = '[Country] LOAD_COUNTRY',
  LOAD_COUNTRY_BY_ID = '[Country] LOAD_COUNTRY_BY_ID',
  SELECT_COUNTRY = '[Country] SELECT_COUNTRY'
}

export class LoadCountryListAction implements Action {
  readonly type = CountryActions.LOAD_COUNTRY;

  constructor(public payload: PaginationData) {
  }
}

export class LoadCountryByIdAction implements Action {
  readonly type = CountryActions.LOAD_COUNTRY_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectCountryAction implements Action {
  readonly type = CountryActions.SELECT_COUNTRY;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadCountryListAction
  | LoadCountryByIdAction
  | SelectCountryAction;
