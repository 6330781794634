import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState, selectSubMenusReducer} from '../../../store/app.states';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {RolesService} from '../../../services/roles.service';
import {AvailableActions} from '../../../models/roles/available-actions';
import {InjectorService} from '../../../services/injector.service';
import {isNullOrUndefined} from 'util';
import {LinkKey} from '../../../models/enums/link-key.enum';

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss']
})
export class SubNavComponent implements OnInit {
  public subMenus: any;
  public lastLinkKey: LinkKey;

  constructor(private translate: TranslateService, private store: Store<AppState>,
              private rolesService: RolesService, private injectorService: InjectorService) {
    this.store.pipe(select(selectSubMenusReducer)).subscribe((next: any) => {
      next.list = next.list.filter(obj => this.rolesService.hasAccessTo(obj.routerLink, AvailableActions.VIEW));
      this.subMenus = next;
      if (!isNullOrUndefined(this.subMenus.selected)) {
        if (this.lastLinkKey !== this.subMenus.selected.routerLink) {
          this.lastLinkKey = this.subMenus.selected.routerLink;
          this.injectorService.getComponentByLinkKey(this.lastLinkKey);
        }
      }
    });
  }

  ngOnInit() {
  }

  public changeTab(item) {
    const payload = {
      selected: item
    };
    this.store.dispatch(new ChangeSubMenuAction(payload));
  }
}
