import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeTime', pure: false,
})
export class RemoveTimePipe implements PipeTransform {

  transform(value: any): any {
    if (null !== value) {
      const aux = value.split("pieces");
      // aux  = aux.replaceAll("<", " ");
      // aux = StringEscapeUtils.escapeJava(aux);
      return aux[1].split("clock")[0] + ":00";
    } else {
      return "";
    }
  }
}
