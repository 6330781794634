/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {Action} from '@ngrx/store';

export enum HistoryActions {
  LOAD_HISTORY_BY_ID = '[HISTORY] LOAD_HISTORY_BY_ID',
  SELECT_HISTORY = '[HISTORY] Change Selected',
  LOAD_HISTORY_SUCCESS = '[HISTORY] Load history success',
  LOAD_HISTORY_FAILURE = '[HISTORY] Load history failure',
}

export class LoadHistoryAction implements Action {
  readonly type = HistoryActions.LOAD_HISTORY_BY_ID;

  constructor(public payload: any) {
  }
}

export class SelectHistoryAction implements Action {
  readonly type = HistoryActions.SELECT_HISTORY;

  constructor(public payload: any) {
  }
}

export class LoadHistorySuccess implements Action {
  readonly type = HistoryActions.LOAD_HISTORY_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadHistoryFailure implements Action {
  readonly type = HistoryActions.LOAD_HISTORY_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadHistorySuccess
  | SelectHistoryAction
  | LoadHistorySuccess
  | LoadHistoryFailure;
