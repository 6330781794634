import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'intakeDays'
})
export class IntakeDaysPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(value: any, args?: any): any {
    if (value === 1) {
      return this.translateService.instant('customFormat.daily');
    }
    const module = value % 7;
    const isFirst = (value / 7) === 1;
    if (isFirst) {
      return this.translateService.instant('customFormat.weekly');
    }
    if (module === 0) {
      return this.translateService.instant('customFormat.everyWeeks', {value: (value / 7)});
    } else {
      return this.translateService.instant('customFormat.everyDays', {value: value});
    }
  }

}
