import {Pipe, PipeTransform} from '@angular/core';
import {ShareService} from '../../../services/share.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private shareService: ShareService) {
  }

  transform(value: any, onlyDay = false): any {
    try {
      if (value) {
        if (onlyDay) {
          return new Date(value).getDay() + 1;
        }
        return this.shareService.getDateFromStorableDate(value);
      } else {
        return '';
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
