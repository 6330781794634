import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LogInComponent} from './components/log-in/log-in.component';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {MainViewComponent} from './modules/main-view/main-view.component';
const routes: Routes = [
  {path: '', component: LogInComponent},
  {path: 'main', component: MainViewComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
