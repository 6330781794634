import {Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {UsersCountryAllowedGQL} from '../../../../../api/graphql';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.states';
import {ShareService} from '../../../../../services/share.service';
import {FacadeService} from '../../../../../services/facade.service';
import {BsModalService} from 'ngx-bootstrap';
import {AuthService} from '../../../../../services/auth.service';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {FormGroup} from '@angular/forms';
import {PatientLanguage} from '../../../../../models/patient-language';

@Component({
  selector: 'app-add-user-country',
  templateUrl: './add-user-country.component.html',
  styleUrls: ['./add-user-country.component.scss']
})
export class AddUserCountryComponent extends FormAbstract<any> implements OnInit {
  public objectList: any;
  public checked: Array<number>;
  public initialState: any;

  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public patientlanguage: PatientLanguage;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              protected  userCountryAllowedGQL: UsersCountryAllowedGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.checked = Array<number>();
  }

  ngOnInit() {
    if (this.bsRef.config.initialState['action']) {
      const actionable = this.bsRef.config.initialState['action'];
      this.action = this.bsRef.config.initialState['action'];
      this.initialState = this.bsRef.config.initialState;
      if (this.bsRef.config.initialState['data']) {
        this.checked = this.bsRef.config.initialState['usersActive'];
      }
      this.patientlanguage = this.bsRef.config.initialState['data'];
    }
    this.userCountryAllowedGQL.watch().result().then((data) => {
      this.objectList = data.data['UsersCountryAllowed'];
    });
  }

  public updateChecked(selectableItem, $event) {
    const index = this.checked.indexOf(selectableItem['userid']);
    if ($event.target.checked) {
      if (index === -1) {
        this.checked.push(selectableItem['userid']);
      }
    } else {
      if (index !== -1) {
        this.checked.splice(index, 1);
      }
    }
  }

  public onSubmit() {
    const users = this.objectList.filter(t => (<any>this.checked).indexOf(t.userid) >= 0);
    const _callback = this.bsRef.config.initialState['callback'];
    _callback(users);
  }
}
