/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
// @ts-ignore
import {Observable} from 'rxjs';
// @ts-ignore
import {tap, map, switchMap} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {ChangeLanguageAction, LangActionTypes, LoadTranslationFailureAction, LoadTranslationLanguageAction, LoadTranslationSuccessAction} from '../actions/lang.actions';
import {TranslateService} from '@ngx-translate/core';
import {ActiveUserLanguages, UserLanguagesGQL, UserTranslationLanguages} from '../../api/graphql';
import {FacadeService} from '../../services/facade.service';


@Injectable()
export class LangEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private apollo: Apollo,
    private translate: TranslateService,
    private userLangGQL: UserLanguagesGQL,
    private activeUserLangGQL: ActiveUserLanguages,
    private userTranslationLangGQL: UserTranslationLanguages,
    private facadeService: FacadeService
  ) {
  }

  @Effect()
  LoadLanguages: Observable<any> = this.actions.pipe(
    ofType(LangActionTypes.LOAD_LANGUAGES),
    map((action: ChangeLanguageAction) => action.payload),
    switchMap(payload => {
      return this.userLangGQL
        .watch()
        .valueChanges
        .pipe(
          map(fetch => {
              // this.translate.use('de');
            let langCookie = null;
            const match = document.cookie.match(new RegExp('(^| )' + 'languageId' + '=([^;]+)'));
            if (match) {
              langCookie = parseInt(match[2], 0);
            }
            this.translate.use(<any>fetch.data['UserLanguages'].find(t => {
              if (langCookie) {
                return t.languageid === langCookie;
              } else {
                if (t.shortname) {
                  return t.shortname === 'en';
                }
              }
            }).shortname);
            this.facadeService.availableLanguages = <any>fetch.data['UserLanguages'];
            return new LoadTranslationSuccessAction({
              availableLang: <any>fetch.data['UserLanguages'],
              actionType: LangActionTypes.LOAD_LANGUAGES
            });
          })
        );
    })
  );

  @Effect({dispatch: false})
  LanguageLoadSuccess: Observable<any> = this.actions.pipe(
    ofType(LangActionTypes.CHANGE),
    tap((lang: any) => {
      if (lang.payload.selectedLanguage.shortname) {
        this.translate.use(lang.payload.selectedLanguage.shortname);
      }
    })
  );

  @Effect()
  LoadUserLanguages: Observable<any> = this.actions.pipe(
    ofType(LangActionTypes.LOAD_USER_LANGUAGES),
    map((action: ChangeLanguageAction) => action.payload),
    switchMap(payload => {
      return this.activeUserLangGQL
        .watch()
        .valueChanges
        .pipe(
          map(fetch => {
              // this.translate.use('de');
            let langCookie = null;
            const match = document.cookie.match(new RegExp('(^| )' + 'languageId' + '=([^;]+)'));
            if (match) {
              langCookie = parseInt(match[2], 0);
            }
            
            const foundLanguage = fetch.data['ActiveUserLanguages'].find(t => {
              if (langCookie) {
                return t.languageid === langCookie;
              } else {
                if (t.shortname) {
                  return t.shortname === 'de';
                }
              }
            });
            if (foundLanguage && foundLanguage.shortname) {
                this.translate.use(foundLanguage.shortname);
            }else{
              this.translate.use("de");
            }

            this.facadeService.activeUserLanguages = <any>fetch.data['ActiveUserLanguages'];
            return new LoadTranslationSuccessAction({
              availableLang: <any>fetch.data['ActiveUserLanguages'],
              actionType: LangActionTypes.LOAD_USER_LANGUAGES
            });
          })
        );
    })
  );

  @Effect()
  LoadTranslationLanguage: Observable<any> = this.actions.pipe(
    ofType(LangActionTypes.LOAD_TRANSLATION_LANGUAGES),
    map((action: LoadTranslationLanguageAction) => action.payload),
    switchMap(payload => {
      const userId = payload.userId; 
      return this.userTranslationLangGQL
        .watch({userId})
        .valueChanges
        .pipe(
          map(fetch => {
            this.facadeService.userTranslationLanguages = <any>fetch.data['UserTranslationLanguages'];
            return new LoadTranslationSuccessAction({
              availableLang: <any>fetch.data['UserTranslationLanguages'],
              actionType: LangActionTypes.LOAD_TRANSLATION_LANGUAGES
            });
          })
        );
    })
  );
}
