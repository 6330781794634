import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';

export enum PatientNotesActions {
  LOAD_PATIENT_NOTES = '[PATIENT_NOTES] LOAD_PATIENT_NOTES',
  SELECT_PATIENT_NOTES = '[PATIENT_NOTES] SELECT_PATIENT_NOTES',
  LOAD_PATIENT_NOTES_SUCCESS = '[PATIENT_NOTES] LOAD_PATIENT_NOTES_SUCCESS',
  LOAD_PATIENT_NOTES_FAILURE = '[PATIENT_NOTES] LOAD_PATIENT_NOTES_FAILURE'
}

export class LoadPatientNotesAction implements Action {
  readonly type = PatientNotesActions.LOAD_PATIENT_NOTES;
  constructor(public payload: PaginationData) {}
}

export class SelectPatientNotesAction implements Action {
  readonly type = PatientNotesActions.SELECT_PATIENT_NOTES;

  constructor(public payload: any) {
  }
}

export class LoadPatientNotesSuccess implements Action {
  readonly type = PatientNotesActions.LOAD_PATIENT_NOTES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPatientNotesFailure implements Action {
  readonly type = PatientNotesActions.LOAD_PATIENT_NOTES_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadPatientNotesAction
  | SelectPatientNotesAction
  | LoadPatientNotesSuccess
  | LoadPatientNotesFailure;
