import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {RolesService} from '../../../services/roles.service';
import {AvailableActions} from '../../../models/roles/available-actions';
import {AuthService} from '../../../services/auth.service';
import {isNullOrUndefined} from 'util';
import {FacadeService} from '../../../services/facade.service';

@Directive({
  selector: '[checkRoles]'
})
export class CheckRolesDirective {
  @Input('checkRoles')
  set checkRoles(args: CanDisplayObject) {
    if (!isNullOrUndefined(this.authService.user)) {
      const display = this.rolesService.hasAccessTo(args.obj, args.access);
      if (display) {
        if (args.obj !== 'pdfText' && AvailableActions.TRANSLATE === args.access) {
          if (this.facadeService.translationLanguages.length > 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
      }
    }
  }

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private rolesService: RolesService,
              private authService: AuthService,
              private facadeService: FacadeService) {
  }
}

export class CanDisplayObject {
  obj: any;
  access: AvailableActions;
}
