import {Component, OnInit, ViewChild} from '@angular/core';
import {FormAbstract} from '../../../models/abstract/form.abstract';
import {File} from '../../../models/file';
import {select, Store} from '@ngrx/store';
import {AppState, selectFileReducer} from '../../../store/app.states';
import {AuthService} from '../../../services/auth.service';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../services/share.service';
import {FacadeService} from '../../../services/facade.service';
import {ActionStateEnum} from '../../../models/enums/actions-state.enum';
import {ChangeSubMenuAction} from '../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ADMINISTRATION} from '../../../config/sub-menu';
import {LinkKey} from '../../../models/enums/link-key.enum';
import {SelectFileAction} from '../../../store/actions/file.actions';
import {UploadFileService} from '../../../services/upload-file.service';
import {SaveFileGQL} from '../../../api/graphql';
import {FileCountryLang} from '../../../models/file-country-lang';
import {UserLanguage} from '../../../models/user-language';
import {isNullOrUndefined} from 'util';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent extends FormAbstract<File> implements OnInit {
  public logoSrc: any;
  @ViewChild('file') file;
  public isWord: boolean;
  public isPdf: boolean;
  public fileCountryLang: FileCountryLang;

  constructor(protected store: Store<AppState>, protected  authService: AuthService,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, private uploadFileService: UploadFileService, private saveFileGQL: SaveFileGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.isPdf = false;
    this.isWord = false;
    this.baseFields = [
      {field: 'label', validator: [Validators.required]},
      {field: 'filename', validator: [Validators.required]},
      {field: 'fileid'}
    ];
    this.languageId = this.authService.userLanguage.languageid;
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'fileid',
      translationId: ''
    };

    /**
     * @NGx-Bootstrap Modal
     */
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
    } else {
      this.store.pipe(select(selectFileReducer)).subscribe((next: any) => {
        this.action = ActionStateEnum.VIEW;
        if (next.selected) {
          this.baseObject = next.selected;
          this.prepareForm();
          this.fileCountryLang = this.baseObject.translations.find(t => t.languageid === this.languageId);
          if (this.fileCountryLang) {
            this.logoSrc = this.fileCountryLang.file;
            this.checkFileType(this.fileCountryLang.filetype);
          }
        }
      });
    }
  }

  ngOnInit() {
  }

  public changeSectionLanguage(language: UserLanguage): void {
    this.languageId = language.languageid;
    this.prepareForm();
    this.fileCountryLang = this.baseObject.translations.find(t => t.languageid === this.languageId);
    if (this.fileCountryLang) {
      this.logoSrc = this.fileCountryLang.file;
      this.checkFileType(this.fileCountryLang.filetype);
    } else {
      this.logoSrc = null;
      this.checkFileType('');
    }
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'administration.files.newFile';
      }
      case ActionStateEnum.EDIT: {
        return 'administration.files.editFile';
      }
      case ActionStateEnum.TRANSLATE: {
        return 'administration.files.editFile';
      }
      default: {
        return 'administration.files.file';
      }
    }
  }

  public updateFileSource($event: FileList) {
    const file = $event.item(0);
    this.formGroup.patchValue({'filename': file.name});
    this.checkFileType(file.type);
    const reader = new FileReader();
    reader.onloadend = () => {
      this.logoSrc = reader.result;
    };
    reader.readAsDataURL(file);
  }

  public checkFileType(fileType: string) {
    switch (fileType) {
      case 'application/pdf': {
        this.isPdf = true;
        this.isWord = false;
        break;
      }
      case 'application/msword': {
        this.isPdf = false;
        this.isWord = true;
        break;
      }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        this.isPdf = false;
        this.isWord = true;
        break;
      }
      default: {
        this.isPdf = false;
        this.isWord = false;
      }
    }
  }

  public onSubmit(): void {
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: this.baseObject,
      countryId: this.authService.user.country.countryid,
      languageId: this.languageId,
      action: this.action
    };
    const _that = this;
    const _afterSave = (args) => {
      _that.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ADMINISTRATION()[5]}));
      if (_that.facadeService.filterArrayContains(args.data['saveFile'], ['label'], LinkKey.FILES)) {
        _that.store.dispatch(new SelectFileAction({selected: args.data['saveFile']}));
        _that.facadeService.setDetailHidden(false);
      } else {
        _that.store.dispatch(new SelectFileAction({selected: {}}));
      }
    };
    const _cascadeEffect = (args) => {
      this.uploadFileService
        .uploadGenericFile(this.file.nativeElement.files,
          args.data['saveFile'].fileid,
          this.authService.user.country.countryid, 'FILE', this.languageId)
        .subscribe(next => {
          if (next.type === 4) {
            args.data['saveFile'].file = this.logoSrc;
            _afterSave(args);
          }
        });
    };
    this.facadeService.mutateObject(this.saveFileGQL, variables, _cascadeEffect);
  }

  public closeModal(cancelPatch?: boolean) {
    this.languageId = this.authService.userLanguage.languageid;
    if (this.action === ActionStateEnum.EDIT) {
      if (isNullOrUndefined(cancelPatch)) {
        this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields, this.languageId);
      }
    }
    if (this.action === ActionStateEnum.TRANSLATE) {
      this.facadeService.patchWholeObjectAndTranslations(this.formGroup, this.baseObject, this.baseFields, this.languageId);
    }
    this.changeSectionLanguage({languageid: this.languageId});
    this.action = ActionStateEnum[ActionStateEnum.VIEW];
    this.bsRef.hide(1);
    this.bsRef.config.initialState = {};
  }
}
