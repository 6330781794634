import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';

export enum PatientLanguagesActions {
  LOAD_PATIENT_LANGUAGES = '[PATIENT_LANGUAGE] LOAD_PATIENT_LANGUAGE',
  SELECT_PATIENT_LANGUAGES = '[PATIENT_LANGUAGE] SELECT_PATIENT_LANGUAGE',
  LOAD_PATIENT_LANGUAGES_SUCCESS = '[PATIENT_LANGUAGE] LOAD_PATIENT_LANGUAGE_SUCCESS',
  LOAD_PATIENT_LANGUAGES_FAILURE = '[PATIENT_LANGUAGE] LOAD_PATIENT_LANGUAGE_FAILURE'
}

export class LoadPatientLanguagesAction implements Action {
  readonly type = PatientLanguagesActions.LOAD_PATIENT_LANGUAGES;
  constructor(public payload: PaginationData) {}
}

export class SelectPatientLanguagesAction implements Action {
  readonly type = PatientLanguagesActions.SELECT_PATIENT_LANGUAGES;

  constructor(public payload: any) {
  }
}

export class LoadPatientLanguagesSuccess implements Action {
  readonly type = PatientLanguagesActions.LOAD_PATIENT_LANGUAGES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadPatientLanguagesFailure implements Action {
  readonly type = PatientLanguagesActions.LOAD_PATIENT_LANGUAGES_FAILURE;

  constructor(public payload: any) {
  }
}

export type All =
  | LoadPatientLanguagesAction
  | SelectPatientLanguagesAction
  | LoadPatientLanguagesSuccess
  | LoadPatientLanguagesFailure;
