import {isNullOrUndefined} from 'util';
import {Pipe, PipeTransform} from '@angular/core';
import {find} from 'lodash';
import {AuthService} from '../../../services/auth.service';

/**
 * @author Jonathan Almanza
 * 10/12/2018
 */
@Pipe({
  name: 'sortList'
})
export class SortListPipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(array: any[], field?: any, forceLangId?: any): any {
    if (!isNullOrUndefined(array)) {
      const arrayCopy = [...array];
      arrayCopy.map(t => {
        let aTranslate = null;
        if (!isNullOrUndefined(t.translations) && t.translations.length > 0) {
          let langId;
          if (forceLangId || !this.authService.user) {
            langId = forceLangId;
          } else {
            langId = this.authService.userLanguage.languageid;
          }
          aTranslate = find(t.translations, ['languageid', langId]);
          if (aTranslate && aTranslate[field]) {
            t[field] = aTranslate[field].trim();
            return t;
          } else {
            t[field] = t[field] ? t[field].trim() : '';
          }
        }
        return t;
      });
      try {
        arrayCopy.sort((a, b) => {
          return (<string>a[field]).localeCompare(b[field]);
        });
      } catch (e) {
        return arrayCopy;
      }
      return arrayCopy;
    }
    return array;
  }
}
