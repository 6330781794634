import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

/**
 * Components
 * */
import {AppComponent} from './app.component';
import {LogInComponent} from './components/log-in/log-in.component';
import {RegistrationComponent} from './components/log-in/registration/registration.component';
import {ForgottenComponent} from './components/log-in/forgotten/forgotten.component';
import {AuthService} from './services/auth.service';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
/**
 * Angular
 * */
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor, TokenInterceptor} from './services/token.service';
/**
 * NgRx
 * */
import {AuthEffects} from './store/effects/auth.effects';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store/app.states';
/**
 * Ng Bootstrap
 * */
import {LoginComponent} from './components/log-in/login/login.component';
import {GraphQLModule} from './graphql.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {MainViewModule} from './modules/main-view/main-view.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './modules/shared/shared.module';
// Services
import {RolesService} from './services/roles.service';
import {ShareService} from './services/share.service';
import {FacadeService} from './services/facade.service';
// NgRx
import {AddPreparationsEffect} from './store/effects/add-preparations.effect';
import {LangEffects} from './store/effects/lang.effects';
import {MedicamentEffect} from './store/effects/medicament.effect';
import {SubMenuEffects} from './store/effects/sub-menu.effects';
import {LoadDetailEffects} from './store/effects/load-detail.effects';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HistoryEffects} from './store/effects/history.effects';
import {OraliaDbModule} from './modules/oralia-db/oralia-db.module';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MainViewComponent} from './modules/main-view/main-view.component';
import {SubNavComponent} from './modules/main-view/sub-nav/sub-nav.component';
import {MainNavComponent} from './modules/main-view/main-nav/main-nav.component';
import {NgxSelectModule} from 'ngx-select-ex';
import {ErrorsModule} from './modules/errors/errors.module';
import {JwtInterceptorService} from './services/jwt-interceptor.service';
import {BlockUIModule} from 'ng-block-ui';

/**
 * upload
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
import {UploadFileService} from './services/upload-file.service';
import {FileSizeModule} from 'ngx-filesize';
import {TooltipModule, PopoverModule} from 'ngx-bootstrap';
import {DownloadService} from './services/download.service';
import {DockCheckComponent} from './components/log-in/registration/dock-check/dock-check.component';
import {ServiceWorkerModule, SwUpdate} from '@angular/service-worker';
import { environment } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    ForgottenComponent,
    LoginComponent,
    RegistrationComponent,
    MainViewComponent,
    MainNavComponent,
    SubNavComponent,
    DockCheckComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {metaReducers: metaReducers}),
    EffectsModule.forRoot([
      AuthEffects, LangEffects, SubMenuEffects, MedicamentEffect,
      AddPreparationsEffect, LoadDetailEffects, HistoryEffects]),
    // StoreRouterConnectingModule.forRoot(),
    NgbModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSelectModule.forRoot(<any>{
      keyCodeToOptionsOpen: 'Space'
    }),
    // MainViewModule,
    OraliaDbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BlockUIModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    /**
     * @graphQL
     * */
    GraphQLModule,
    SharedModule,
    ErrorsModule,
    FileSizeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  /**
   * @Security
   * */
  providers: [
    AuthService,
    AuthGuard,
    RolesService,
    FacadeService,
    ShareService,
    UploadFileService,
    DownloadService,
    JwtInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      // if (askUserToUpdate()) {
      window.location.reload();
      // }
    });
  }
}
