import {All, HistoryActions} from '../actions/history.actions';
import {History} from '../../models/history';

/**
 * @author Alavi
 * 03/12/2018
 */
export interface HistoryState {
  selected: History;
  list: Array<History>;
}

export const initialState: HistoryState = {
  selected: null,
  list: Array<History>()
};

export function reducer(state = initialState, action: All): HistoryState {
  switch (action.type) {
    case HistoryActions.SELECT_HISTORY: {
      return {
        ...state,
        selected: action.payload.selected,
        list: action.payload.list
      };
    }
    default: {
      return state;
    }
  }
}
