/**
 * @author Jonathan Almanza
 * 26/11/2018
 */
import {PaginationData} from '../../models/request/pagination-data';
import {Action} from '@ngrx/store';
import {Interaction} from '../../models/interaction';
import {SideEffect} from '../../models/side-effect';

export enum SideEffectActions {
  LOAD_SIDE_EFFECT = '[SIDE_EFFECT] Load',
  SELECT_SIDE_EFFECT = '[SIDE_EFFECT] Change Selected',
  LOAD_SIDE_EFFECT_SUCCESS = '[SIDE_EFFECT] Load medicament success',
  LOAD_SIDE_EFFECT_FAILURE = '[SIDE_EFFECT] Load medicament failure',
  ADD_SIDEEFFECT = '[SIDE_EFFECT] Add Sideeffect',
}

export class LoadSideEffectAction implements Action {
  readonly type = SideEffectActions.LOAD_SIDE_EFFECT;
  constructor(public payload: PaginationData) {}
}

export class SelectSideEffectAction implements Action {
  readonly type = SideEffectActions.SELECT_SIDE_EFFECT;

  constructor(public payload: any) {
  }
}

export class LoadSideEffectSuccess implements Action {
  readonly type = SideEffectActions.LOAD_SIDE_EFFECT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadSideEffectFailure implements Action {
  readonly type = SideEffectActions.LOAD_SIDE_EFFECT_FAILURE;

  constructor(public payload: any) {
  }
}
export class AddSiteEffectAction implements Action {
  readonly type = SideEffectActions.ADD_SIDEEFFECT;

  constructor(public payload: SideEffect) {
  }
}
export type All =
  | LoadSideEffectSuccess
  | SelectSideEffectAction
  | LoadSideEffectSuccess
  | LoadSideEffectFailure
  | AddSiteEffectAction ;
