import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {HttpEvent} from '@angular/common/http/src/response';

@Injectable()
export class UploadFileService {

  constructor(private _http: HttpClient) {
  }

  public uploadFile(fileList: Array<File>, userId: number, countryId: number, action, contents: Array<string>): Observable<HttpEvent<any>> {
    const url = `graphql/uploadFile`;
    const formData: FormData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      delete fileList[i]['document'];
      formData.append('file', fileList[i]);
    }
    formData.append('contents', contents.join(',').toString());
    formData.append('userId', userId.toString());
    formData.append('countryId', countryId.toString());
    formData.append('actionEnum', action.toString());
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('charset', 'UTF-8');
    const req = new HttpRequest(
      'POST',
      url,
      formData, {
        reportProgress: true,
        // responseType: 'text',
        headers: headers
      });
    return this._http.request(req);
  }

  public uploadGenericFile(fileList: FileList, objectId: number, additionalId: number = 0, actionEnum: string, languageId: number = 1) {
    const url = `graphql/uploadGenericFile`;
    const formData: FormData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('file', fileList[i]);
    }
    formData.append('objectId', objectId.toString());
    formData.append('additionalId', additionalId.toString());
    formData.append('languageId', languageId.toString());
    formData.append('actionEnum', actionEnum);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('charset', 'UTF-8');
    const req = new HttpRequest(
      'POST',
      url,
      formData, {
        reportProgress: true,
        headers: headers
      });
    return this._http.request(req);
  }
}
