import { Component, OnInit } from '@angular/core';
import { FormAbstract } from '../../../../../models/abstract/form.abstract';
import { FacadeService } from '../../../../../services/facade.service';
import { ShareService } from '../../../../../services/share.service';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { AppState } from '../../../../../store/app.states';
import { FormGroup } from '@angular/forms';
import { ActionStateEnum } from '../../../../../models/enums/actions-state.enum';
import { AuthService } from '../../../../../services/auth.service';
import { AddPrepStrength, AddPrepStrengthValidate } from '../../../../../models/add-prep-strength';
import { AddPreparation } from '../../../../../models/add-preparation';
import { SaveAddStrengthGQL } from '../../../../../api/graphql';
import { LoadAddByIdAction } from '../../../../../store/actions/add-preparation.actions';

@Component({
  selector: 'app-strength',
  templateUrl: './add-strength.component.html',
  styleUrls: ['./add-strength.component.scss']
})
export class AddStrengthComponent extends FormAbstract<AddPrepStrength> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public addPreparation: AddPreparation;

  constructor(protected store: Store<AppState>,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, protected authService: AuthService,
    private saveAddStrengthGQL: SaveAddStrengthGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      { field: 'strengthid' },
      { field: 'countryid' },
      { field: 'preparationid' },
      { field: 'perdays', validator: AddPrepStrengthValidate.rules.perdays },
      { field: 'morning', validator: AddPrepStrengthValidate.rules.intakes },
      { field: 'midday', validator: AddPrepStrengthValidate.rules.intakes },
      { field: 'evening', validator: AddPrepStrengthValidate.rules.intakes },
      { field: 'night', validator: AddPrepStrengthValidate.rules.intakes },
      { field: 'strength', validator: AddPrepStrengthValidate.rules.strength },
      { field: 'individual', validator: [], defaultValue: false },
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'strengthid',
      translationId: null
    };
    if (this.bsRef.config.initialState['action']) {
      this.action = this.bsRef.config.initialState['action'];
      if (this.action === ActionStateEnum.CREATE) {
        this.addPreparation = this.bsRef.config.initialState['data'] as AddPreparation;
        this.baseObject = new AddPrepStrength();
        this.languageId = this.authService.user.country.languageid;
        this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
        this.formGroup.patchValue({ ['preparationid']: this.addPreparation.addpreparationid });
        this.formGroup.patchValue({ ['perdays']: 1 });
        this.formGroup.patchValue({ ['morning']: 0 });
        this.formGroup.patchValue({ ['midday']: 0 });
        this.formGroup.patchValue({ ['evening']: 0 });
        this.formGroup.patchValue({ ['night']: 0 });
        this.formGroup.patchValue({ ['individual']: false });
      } else {
        this.addPreparation = new AddPreparation();
        this.baseObject = this.bsRef.config.initialState['data'];
        this.languageId = this.authService.user.country.languageid;
        this.addPreparation.addpreparationid = this.baseObject.preparationid;
        this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
        this.formGroup.patchValue({ ['preparationid']: this.baseObject.preparationid });
        this.formGroup.patchValue({ ['morning']: this.baseObject.morning ? this.baseObject.morning : 0 });
        this.formGroup.patchValue({ ['midday']: this.baseObject.midday ? this.baseObject.midday : 0 });
        this.formGroup.patchValue({ ['evening']: this.baseObject.evening ? this.baseObject.evening : 0 });
        this.formGroup.patchValue({ ['night']: this.baseObject.night ? this.baseObject.night : 0 });
      }
    }
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    this.baseObject.countryid = this.authService.user.country.countryid;
    this.baseObject.preparationid = this.addPreparation.addpreparationid;
    const variables = {
      addPrepStrength: this.baseObject,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new LoadAddByIdAction({ id: args.data['saveAddStrength'].preparationid }));
    };
    const _callbackX = () => {
      variables.addPrepStrength.individual = true;
      this.facadeService.mutateObject(this.saveAddStrengthGQL, variables, _cascadeEffect);
    };
    if (this.baseObject.morning === 0 && this.baseObject.midday === 0
      && this.baseObject.evening === 0 && this.baseObject.night === 0) {
      this.shareService.confirmAction('validationMessages.shouldBeStored', _callbackX);
    } else {
      this.facadeService.mutateObject(this.saveAddStrengthGQL, variables, _cascadeEffect);
    }
  }

  public isValid(formKey, validationType) {
    const value = this.formGroup.getRawValue()[formKey];
    switch (validationType) {
      case 'decimal': {
        return /^-?\d*[.,]?\d{0,2}$/.test(value);
      }
      default: {

      }
    }
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'oraliadb.medicament.newStrength';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.medicament.editStrength';
      }
      default: {
        return 'oraliadb.medicament.strength';
      }
    }
  }

  public changedCheckbox(values) {
    const checked = values.currentTarget.checked;
    if (checked) {
      this.formGroup.patchValue({ ['perdays']: 1 });
      this.formGroup.patchValue({ ['morning']: 0 });
      this.formGroup.patchValue({ ['midday']: 0 });
      this.formGroup.patchValue({ ['evening']: 0 });
      this.formGroup.patchValue({ ['night']: 0 });
    }
  }
}
