import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {LoadMedByIdAction, MedicamentActions, SelectMedicamentAction} from '../actions/medicament.actions';
import {gql_MEDICAMENT_BY_ID} from '../../models/medicament';
import {AuthService} from '../../services/auth.service';

@Injectable()
export class MedicamentEffect {

  constructor(
    private actions: Actions,
    private router: Router,
    private apollo: Apollo,
    private authService: AuthService
  ) {
  }

  @Effect()
  MedById: Observable<any> = this.actions.pipe(
    ofType(MedicamentActions.LOAD_MED_BY_ID),
    map((action: LoadMedByIdAction) => action.payload),
    switchMap(payload => {
      return this.apollo
        .watchQuery({
          query: gql_MEDICAMENT_BY_ID(),
          variables: {
            id: payload['id'],
            pharmacyId: this.authService.user.pharmacyid,
          },
          fetchPolicy: 'network-only'
        })
        .valueChanges
        .pipe(
          map(fetch => {
              return new SelectMedicamentAction({selected: fetch.data['Medicament']});
            }
          ));
    }));
}
