import {All, SponsorActions} from '../actions/sponsor.actions';
import {Sponsor} from "../../models/sponsor";

export interface SponsorState {
  selected: Sponsor;
}

export const initialState: SponsorState = {
  selected: null
};

export function reducer(state = initialState, action: All): SponsorState {
  switch (action.type) {
    case SponsorActions.SELECT_SPONSOR: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
