import { Validators } from '@angular/forms';
import { CustomValidate } from './custom-validate';

export class AddPrepStrength {
  strengthid?: number;
  countryid?: number;
  preparationid?: number;
  intakes?: number;
  perdays?: number;
  pieces?: number; // decimal(3,1) DEFAULT NULL,
  morning?: number; // decimal(3,1) DEFAULT NULL,
  midday?: number; // decimal(3,1) DEFAULT NULL,
  evening?: number; // decimal(3,1) DEFAULT NULL,
  night?: number; // decimal(3,1) DEFAULT NULL,
  strength?: string;
  individual?: boolean;
}
export class AddPrepStrengthValidate {
  public static rules = {
    ['intakes']: [CustomValidate.numeric, Validators.required],
    ['perdays']: [CustomValidate.numeric, Validators.minLength(1)],
    ['pieces']: [CustomValidate.numeric, Validators.minLength(3)],
    ['strength']: []
  };
}
