import {Component, OnInit} from '@angular/core';
import {BsLocaleService, BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../../../services/share.service';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
import {Store} from '@ngrx/store';
import {FacadeService} from '../../../../../services/facade.service';
import {Medicament, MedicamentValidate} from '../../../../../models/medicament';
import {AppState} from '../../../../../store/app.states';
import {AuthService} from '../../../../../services/auth.service';
import {LoadMedByIdAction} from '../../../../../store/actions/medicament.actions';
import {Apollo} from 'apollo-angular';
import {Substance} from '../../../../../models/substance';
import {FormGroup} from '@angular/forms';
import {ChangeSubMenuAction} from '../../../../../store/actions/sub-menu.actions';
import {SUB_MENU_LIST_ORALIA_DB} from '../../../../../config/sub-menu';
import {SaveMedicamentGQL, SubstancesGQL} from '../../../../../api/graphql';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {LinkKey} from '../../../../../models/enums/link-key.enum';

@Component({
  selector: 'app-basic-data-edit',
  templateUrl: './basic-data-edit.component.html',
  styleUrls: ['./basic-data-edit.component.scss']
})
export class BasicDataEditComponent extends FormAbstract<Medicament> implements OnInit {
  public substanceList: Array<Substance>;
  public selectedSubstance: Substance;
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  public errorLinkMessage = 'validationMessages.errorLinkMessage';
  public errorLinkTextMessage = 'validationMessages.errorLinkTextMessage';

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private bsLocaleService: BsLocaleService, private apollo: Apollo,
              private substanceGQL: SubstancesGQL, private saveMedicamentGQL: SaveMedicamentGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.baseFields = [
      {field: 'medicamentid'},
      {field: 'label', validator: MedicamentValidate.rules.label},
      {field: 'status', defaultValue: 1},
      {field: 'shortname', validator: MedicamentValidate.rules.shortname},
      {field: 'editors', validator: []},
      {field: 'manufactor', validator: MedicamentValidate.rules.manufactor, defaultValue: ''},
      {field: 'datemedicalinfo', validator: MedicamentValidate.rules.datemedicalinfo},
      {field: 'link1', validator: MedicamentValidate.rules.link1},
      {field: 'link1text', validator: MedicamentValidate.rules.link1text},
      {field: 'link2', validator: MedicamentValidate.rules.link2},
      {field: 'link2text', validator: MedicamentValidate.rules.link2text},
      {field: 'link3', validator: MedicamentValidate.rules.link3},
      {field: 'link3text', validator: MedicamentValidate.rules.link3text}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    /**
     * @NGx-Bootstrap Modal
     */
    this.action = this.bsRef.config.initialState['action'];
    if (this.action === ActionStateEnum.CREATE) {
      this.baseObject = new Medicament();
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['status']: 1});
      // Substance
      this.selectedSubstance = new Substance();
    } else {
      this.baseObject = this.bsRef.config.initialState['data'];
      this.languageId = this.authService.user.country.languageid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['status']: this.baseObject['status']});
      // Substance
      if (this.baseObject.substance) {
        this.selectedSubstance = this.baseObject.substance;
      } else {
        this.selectedSubstance = new Substance();
      }
    }
  }

  ngOnInit() {
    this.bsLocaleService.use(this.shareService.getLocaleById(this.languageId));
    this.substanceGQL.watch({
        label: '',
        status: [1],
        languageId: this.authService.user.userLanguage.languageid
      }
    ).result().then((data) => {
      this.substanceList = data.data['Substances'] as any;
    });
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  public onSubmit() {
    this.baseObject = {...this.formGroup.value};
    this.baseObject.countryid = this.authService.user.country.countryid;
    this.baseObject.substance = {
      substanceid: this.selectedSubstance.substanceid,
      label: this.selectedSubstance.label
    };
    const variables = {
      parentObj: this.baseObject,
      languageId: this.authService.user.country.languageid,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal();
      this.store.dispatch(new ChangeSubMenuAction({selected: SUB_MENU_LIST_ORALIA_DB()[0]}));
      // Filter Match with edited Item
      const filterBy = {...this.facadeService.filterData[LinkKey.MEDICAMENT].filterBy};
      if (filterBy.status.indexOf(this.baseObject.status) >= 0) {
        this.store.dispatch(new LoadMedByIdAction({id: args.data['saveMedicament'].medicamentid}));
        this.facadeService.setDetailHidden(false);
      }
      this.facadeService.loadManufacturers();
    };
    this.facadeService.mutateObject(this.saveMedicamentGQL, variables, _cascadeEffect);
  }

  public doSelectOptions(value: any) {
    this.selectedSubstance = {...value[0]['data']};
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'oraliadb.medicament.newMedicament';
      }
      case ActionStateEnum.EDIT: {
        return 'oraliadb.medicament.editMedicament';
      }
      default: {
        return 'common.labels.medicament';
      }
    }
  }

  public get invalidLink() {
    if (this.formGroup.get('link1').valid && this.formGroup.get('link1text').value === '' && this.formGroup.get('link1').value !== '') {
      return true;
    }
    if (this.formGroup.get('link2').valid && this.formGroup.get('link2text').value === '' && this.formGroup.get('link2').value !== '') {
      return true;
    }
    if (this.formGroup.get('link3').valid && this.formGroup.get('link3text').value === '' && this.formGroup.get('link3').value !== '') {
      return true;
    }
    return false;
  }
}
