/**
 * @author Jonathan Almanza
 * 21/11/2018
 */
import {Action} from '@ngrx/store';

export enum LoginActions {
  CHANGE = '[LoginForm] Change view',
}

export class ChangeLoginAction implements Action {
  readonly type = LoginActions.CHANGE;

  constructor(public payload: any) {
  }
}

export type All =
  | ChangeLoginAction;
