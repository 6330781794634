import { AddPrepLang } from './add-prep-lang';
import gql from 'graphql-tag';
import { CustomValidate } from './custom-validate';
import { Validators } from '@angular/forms';
import { AddPrepStrength } from './add-prep-strength';
import { AddPrepSide } from './add-prep-side';
import { History } from './history';

export class AddPreparation {
  addpreparationid?: number;
  countryid?: number;
  hints?: string;
  informations?: string;
  label?: string;
  mischint?: string;
  status?: number;
  translations?: Array<AddPrepLang>;
  addPrepStrengths?: Array<AddPrepStrength>;
  addPrepSides?: Array<AddPrepSide>;
  lastchange?: Date | any;
  history?: Array<History>;
}
export class AddPreparationValidate {
  public static rules = {
    ['countryid']: [Validators.required],
    ['label']: [Validators.required, Validators.maxLength(60)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}
export function gql_ADD_PREPARATION_BY_ID() {
  return gql`
    query AddPreparation($id: Int){
      AddPreparation(id: $id) {
        addpreparationid
        countryid
        hints
        informations
        label
        mischint
        status
        patientinfo
        lastchange
        translations {
          addpreplangid
          addpreparationid
          countryid
          label
          hints
          informations
          mischint
          patientinfo
          languageid
        }
        addPrepStrengths {
          strengthid
          intakes
          perdays
          pieces
          individual
          strength
          morning
          midday
          evening
          night
          countryid
          preparationid
        }
      }
    }
  `;
}
export function gql_ADD_PREPARATION($id) {
  return gql`
  {
     AddPreparation(addpreparationid: ${$id}) {
        addpreparationid
        label
        mischint
        status
        lastchange
        translations {
          addpreparationid
          label
          mischint
          languageid
          addpreplangid
        }
     }
  }`;
}
