import gql from 'graphql-tag';
import {OtherNoteLang} from './other-note-lang';
import {CustomValidate} from './custom-validate';
import {Validators} from '@angular/forms';

export class OtherNote {
  othernoteid?: number;
  countryid?: number;
  label?: string;
  othernote?: string;
  status?: number; // smallint(6) NOT NULL,
  translations?: Array<OtherNoteLang>;
}
export class OtherNoteValidate {
  public static rules = {
    ['othernote']: [Validators.required],
    ['countryid']: [Validators.required],
    ['label']: [Validators.required, Validators.maxLength(40)],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}
export function gql_MED_OTHER_NOTE_LIST() {
  return gql`
  query OtherNotes($label: String, $status: [Int]){
    OtherNotes(label: $label, status: $status){
      label
      othernoteid
      translations {
        othernotelangid
        othernoteid
        label
        languageid
      }
    }
  }
  `;
}

export function gql_OTHER_NOTE_LIST() {
  return gql`
  query OtherNotes($label: String, $status: [Int]){
    OtherNotes(label: $label, status: $status){
        othernoteid
        othernote
        label
        status
        translations {
          othernotelangid
          othernoteid
          label
          othernote
          languageid
        }
    }
  }`;
}
export function gql_INSERT_OTHERNOTE($otherNote: OtherNote) {
  let translations = `
    translations: []
  `;
  if ($otherNote.translations) {
    let transItems = '';
    $otherNote.translations.forEach(t => {
      transItems +=
        `{
          othernoteid: ${t.othernoteid || null},
          othernotelangid: ${t.othernotelangid || null}
          languageid: ${t.languageid},
          othernote: "${t.othernote}",
          label: "${t.label}"
        },
         `;
    });
    translations = `
    translations: [
      ${transItems}
    ]`;
  }
  return gql`
    mutation saveOtherNote {
      saveOtherNote(
      otherNote: {
        othernoteid: ${$otherNote.othernoteid || null},
        othernote: "${$otherNote.othernote.trim()}",
        countryid: ${$otherNote.countryid ||  null},
        label: "${$otherNote.label.trim()}",
        status: ${$otherNote.status},
        ${translations}
      }) {
        othernoteid
        othernote
        label
        status
        translations {
          othernotelangid
          othernoteid
          othernote
          label
          languageid
        }
      }
    }`;
}
