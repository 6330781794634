import { Component, OnInit } from '@angular/core';
import { FacadeService } from '../../../../../services/facade.service';
import { ShareService } from '../../../../../services/share.service';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { AppState } from '../../../../../store/app.states';
import { Medicament } from '../../../../../models/medicament';
import { FormAbstract } from '../../../../../models/abstract/form.abstract';
import { find } from 'lodash';
import { LoadMedByIdAction } from '../../../../../store/actions/medicament.actions';
import { AuthService } from '../../../../../services/auth.service';
import { FormGroup } from '@angular/forms';
import { ActionStateEnum } from '../../../../../models/enums/actions-state.enum';
import { SaveMedBackgroundGQL } from '../../../../../api/graphql';

@Component({
  selector: 'app-leaflet',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent extends FormAbstract<Medicament> implements OnInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;
  public languageName: string;

  constructor(protected store: Store<AppState>,
    protected bsRef: BsModalService, protected shareService: ShareService,
    protected facadeService: FacadeService, protected authService: AuthService,
    private saveMedBackgroundGQL: SaveMedBackgroundGQL) {
    super(store, bsRef, shareService, facadeService, authService);
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      { field: 'medicamentid' },
      { field: 'background' },
      { field: 'shortname' },
      { field: 'label' },
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'medicamentid',
      translationId: 'medlanguageid'
    };
    /**
     * @NGx-Bootstrap Modal
     */
    this.action = this.bsRef.config.initialState['action'];
    if (this.action === ActionStateEnum.CREATE) {
      this.baseObject = new Medicament();
      this.languageId = this.authService.user.country.languageid;
      this.prepareForm();
    } else if (this.action === ActionStateEnum.EDIT) {
      this.baseObject = this.bsRef.config.initialState['data'];
      this.languageId = this.authService.user.country.languageid;
      this.prepareForm();
    } else {
      this.baseObject = this.bsRef.config.initialState['data'];
      let currentTranslate = find(this.facadeService.translationLanguages, (translate) => {
        return translate.languageid === this.authService.user.country.languageid;
      });
      if (currentTranslate) {
        this.languageId = currentTranslate.languageid;
      } else {
        currentTranslate = find(this.facadeService.translationLanguages, (translate) => {
          return translate.languageid === this.authService.user.userLanguage.languageid;
        });
        if (currentTranslate) {
          this.languageId = currentTranslate.languageid;
        } else {
          this.languageId = this.facadeService.translationLanguages[0].languageid;
        }
      }
      this.prepareForm();
    }
  }

  ngOnInit() {
    const language = this.facadeService.availableLanguages.find(l => l.languageid === this.authService.user.country.languageid);
    if (language) {
      this.languageName = language.language;
    } else {
      this.languageName = 'English';
    }
  }

  public onSubmit() {
    this.baseObject = this.formGroup.value;
    const variables = {
      parentObj: this.baseObject,
      languageId: this.languageId,
      action: this.action
    };
    this.baseObject.countryid = this.authService.user.country.countryid;
    const _cascadeEffect = (args) => {
      this.closeModal();
      this.store.dispatch(new LoadMedByIdAction({ id: args.data['saveMedBackground'].medicamentid }));
    };
    this.facadeService.mutateObject(this.saveMedBackgroundGQL, variables, _cascadeEffect);
  }

  public getCountryLanguage() {

  }

  public userViewLabel(): string {
    switch (this.action) {
      case ActionStateEnum.EDIT: {
        return 'modals.medicament.editleaflet.title';
      }
      case ActionStateEnum.TRANSLATE: {
        return 'modals.medicament.translateLeaflet';
      }
    }
  }
}
