import {IntakeNote} from '../../models/intake-note';
import {All, IntakeNotesActions} from '../actions/intake-notes.actions';

/**
 * @author Jonathan Almanza
 * 02/12/2018
 */
export interface IntakeNotesState {
  selected: IntakeNote;
}

export const initialState: IntakeNotesState = {
  selected: null
};

export function reducer(state = initialState, action: All): IntakeNotesState {
  switch (action.type) {
    case IntakeNotesActions.LOAD_INTAKE_NOTES_SUCCESS: {
      return {
        ...state,
        selected: action.payload.data.select[0]
      };
    }
    case IntakeNotesActions.SELECT_INTAKE_NOTES: {
      return {
        ...state,
        selected: action.payload.selected
      };
    }
    default: {
      return state;
    }
  }
}
