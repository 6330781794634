/**
 * @author Jonathan Almanza
 * 31/01/2019
 */
export enum TextOperationEnum {
  SIDE_EFFECT_INTRODUCTION = 1,
  INTERACTION_INTRODUCTION = 2,
  INTAKE_NOTE_INTRODUCTION = 3,
  OTHER_NOTE_INTRODUCTION = 4,
  LOGIN_PAGE_INFORMATION = 5,
  DECLARATION_CONCEALMENT = 6,
  SYSTEM_NAME_HEADER = 7,
  EMAIL_RESET_PASSWORD = 8,
  EMAIL_WAIT_CONFIRMATION = 9,
  EMAIL_REGISTRATION_REJECTED = 10,
  EMAIL_REGISTRATION_APPROVED = 11,
  SUBSTANCE_CHANGED = 12,
  NEW_USER_REGISTERED = 13,
  LOGIN_ALERT = 14,
  DOCUMENT_INFORMATION = 17,
  SPONSOR_HEADER = 18
}
