import { Pipe, PipeTransform } from '@angular/core';
import {uniqBy} from "lodash";
import {History} from "../../../models/history";
import {ShareService} from "../../../services/share.service";
@Pipe({
  name: 'historyFilter'
})
export class HistoryFilterPipe implements PipeTransform {
  constructor(private shareService: ShareService) {}

  transform(value: Array<History>, args?: any): any {
    return uniqBy(value, (e) => {
      return `${this.shareService.getDateFromStorableDate(e.datetime)},${e.userId},${e.operation}`;
    });
  }

}
