import gql from 'graphql-tag';
export class MedNote {
  mednoteid?: number;
  countryid?: number;
  medicamentid?: number;
  pharmacyid?: number;
  date?: Date | any;
  note?: string;
  status?: number;
  theme?: string;
}
