import {InteractionLang} from './interaction-lang';
import gql from 'graphql-tag';
import {CustomValidate} from './custom-validate';
import {Validators} from '@angular/forms';

export class Interaction {
  interactionid?: number;
  interaction?: string;
  label?: string;
  status?: string;
  translations?: Array<InteractionLang>;
}
export class InteractionValidate {
  public static rules = {
    ['label']: [Validators.required, Validators.maxLength(40)],
    ['interaction']: [Validators.required],
    ['status']: [Validators.required, CustomValidate.onlyNumber]
  };
}

export function gql_MED_INTERACTION_LIST() {
  return gql`
  query Interactions($label: String, $status: [Int]){
    Interactions(
      label: $label,
      status: $status
     ) {
      label
      interactionid
      translations {
        interactionlangid
        interactionid
        label
        languageid
      }
    }
  }
  `;
}
