import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormAbstract} from '../../../../../models/abstract/form.abstract';
// @ts-ignore
import {PatientNote, PatientNoteValidate} from '../../../../../models/patient-note';
import {ActionStateEnum} from '../../../../../models/enums/actions-state.enum';
import {FormGroup, Validators} from '@angular/forms';
import {AppState} from '../../../../../store/app.states';
import {Store} from '@ngrx/store';
import {BsModalService} from 'ngx-bootstrap';
import {ShareService} from '../../../../../services/share.service';
import {FacadeService} from '../../../../../services/facade.service';
import {AuthService} from '../../../../../services/auth.service';
import {Patient} from '../../../../../models/patient';
import {PatientGQL, SavePatientNoteGQL} from '../../../../../api/graphql';
import {LoadDetailByIdAction} from '../../../../../store/actions/common.actions';
import {SelectPatientAction} from '../../../../../store/actions/patient.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-nota',
  templateUrl: './patient-nota.component.html',
  styleUrls: ['./patient-nota.component.scss']
})
export class PatientNotaComponent extends FormAbstract<PatientNote> implements OnInit, AfterViewInit {
  public languageId: any;
  public formGroup: FormGroup;
  public action: ActionStateEnum;

  constructor(protected store: Store<AppState>,
              protected bsRef: BsModalService, protected shareService: ShareService,
              protected facadeService: FacadeService, protected authService: AuthService,
              private savePatientNoteGQL: SavePatientNoteGQL, private patientGQL: PatientGQL) {

    super(store, bsRef, shareService, facadeService, authService);
    this.baseObject = new PatientNote();
    this.action = ActionStateEnum.VIEW;
    this.baseFields = [
      {field: 'noteid'},
      {field: 'date', validator: [Validators.required]},
      {field: 'note', validator: PatientNoteValidate.rules.note},
      {field: 'theme', validator: PatientNoteValidate.rules.theme},
      {field: 'status', validator: [], defaultValue: 1},
      {field: 'pharmacyid', validator: [], defaultValue: this.authService.user.pharmacyid},
      {field: 'patientid'},
      {field: 'countryid', validator: [], defaultValue: this.authService.user.country.countryid}
    ];
    this.formGroup =
      this.facadeService.createFormWithValidators(this.baseFields);
    this.baseId = {
      parentId: 'noteid',
      translationId: null
    };
    /**
     * @NGx-Bootstrap Modal
     */
    this.action = this.bsRef.config.initialState['action'];
    const patient = this.bsRef.config.initialState['data'];
    this.languageId = this.authService.user.userLanguage.languageid;
    if (this.action === ActionStateEnum.CREATE) {
      this.baseObject.countryid = this.authService.user.country.countryid;
      this.baseObject.pharmacyid = this.authService.user.pharmacyid;
      this.baseObject.patientid = patient.patientid;
      this.formGroup.patchValue({['date']: this.shareService.getDate(moment())});
      this.formGroup.patchValue({['patientid']: patient.patientid});
    } else {
      this.baseObject = this.bsRef.config.initialState['data'];
      this.baseObject.countryid = this.authService.user.country.countryid;
      this.baseObject.pharmacyid = this.authService.user.pharmacyid;
      this.baseObject.patientid = patient.patientid;
      this.facadeService.patchFormWithValidator(this.formGroup, this.baseObject, this.baseFields);
      this.formGroup.patchValue({['date']: this.shareService.getDate(this.baseObject.date)});
      this.formGroup.patchValue({['patientid']: patient.patientid});
    }
  }

  ngOnInit() {
    this.statusList = this.facadeService.getStatus(this.languageId);
  }

  ngAfterViewInit() {
    const modal: HTMLCollection = document.getElementsByTagName('modal-container');
    modal.item(0).className += ' modal-center';
  }

  public onSubmit() {
    this.baseObject = {...this.formGroup.value};
    this.baseObject.date = this.shareService.getStorableDate(this.formGroup.value['date']);
    const variables = {
      parentObj: this.baseObject,
      action: this.action
    };
    const _cascadeEffect = (args) => {
      this.closeModal(true);
      this.store.dispatch(new LoadDetailByIdAction({
        object: 'Patient', query: this.patientGQL,
        action: SelectPatientAction, variables: {id: args.data['savePatientNote'].patientid}
      }));
    };
    this.facadeService.mutateObject(this.savePatientNoteGQL, variables, _cascadeEffect);
  }

  public userViewLabel() {
    switch (this.action) {
      case ActionStateEnum.CREATE: {
        return 'modals.newNote.title';
      }
      case ActionStateEnum.EDIT: {
        return 'modals.editNote.title';
      }
      default: {
        return 'common.labels.note';
      }
    }
  }
}
