import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'singleSort'
})
export class SingleSortPipe implements PipeTransform {

  transform(array: Array<any>, propertyA?: any, propertyB?: any): any {
    if (!isNullOrUndefined(array)) {
      const arrayCopy = [...array];
      try {
        arrayCopy.sort((a, b) => {
          const textA = a[propertyA] + ', ' + a[propertyB];
          const textB = b[propertyA] + ', ' + b[propertyB];
          return (<string>textA).localeCompare(textB);
        });
      } catch (e) {
        return arrayCopy;
      }
      return arrayCopy;
    }
    return array;
  }

}
